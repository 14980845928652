import React from "react";
import styled from "styled-components";
import { isMobileDevice } from "../helpers";

const Ring = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #6ec6ff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingIcon = (props) => {
  const backgroundStyles = {
    width: "300px",
    height: "300px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    borderRadius: "55px",
    position: "absolute",
    zIndex: "20"
  };
  const offset = isMobileDevice() ? 0 : props.width / 4;
  backgroundStyles["top"] = offset;
  if (props["offsetRight"]) {
    backgroundStyles["right"] = offset;
  } else if (props["offsetLeft"]) {
    backgroundStyles["left"] = offset;
  } else {
    // it's no offset, which means center it
    backgroundStyles["left"] = offset * 2 - 150; // -150 to account for the width: "300px"
  }
  return (
    <div style={{ ...backgroundStyles }}>
      <Ring>
        <div />
        <div />
        <div />
        <div />
      </Ring>
    </div>
  );
};

export default LoadingIcon;

import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import ShareModal from "./components/ShareModal";
import StyleModal from "./components/StyleModal";
import {
  CustomNavbar,
  CustomNav,
  CustomNavItem,
  CustomNavbarBrand,
} from "./styles";
import { Share } from "./components/icons";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "react-vis/dist/style.css";
import "rc-slider/assets/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropdown-tree-select/dist/styles.css";
import "./App.css";
import "./Accordion.css";
import { colors, getLocation } from "./helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Lock } from "./components/icons";
import logo from "./constants/VectorSurv_Icon.svg";
import { agencies as pacificAgencies } from "./components/Pacific";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarToggler,
} from "reactstrap";
import { useState } from "react";

// CODE SPLIT MAPS
const Arbo = React.lazy(() => import("./components/Arbo"));
const Abundance = React.lazy(() => import("./components/Abundance"));
const Invasive = React.lazy(() => import("./components/Invasive/"));
const Zika = React.lazy(() => import("./components/Zika/"));
const Tick = React.lazy(() => import("./components/TickAbundance/"));
const Dengue = React.lazy(() => import("./components/Dengue/"));
const Pacific = React.lazy(() => import("./components/Pacific"));
const Resistance = React.lazy(() => import("./components/Resistance/"));
const Risk = React.lazy(() => import("./components/Risk/"));

const cookies = new Cookies();

const usState = localStorage.getItem("usState") || "CA";
const primaryAgency = localStorage.getItem("primaryAgency") || "";
const loginType = localStorage.getItem("loginType") || "";
const mapStyle = localStorage.getItem("mapStyle") || "Light (Recommended)";

const messageStyles = {
  display: "flex",
  fontSize: 24,
  justifyContent: "center",
  margin: "200px auto",
  textAlign: "center",
  width: 600,
};

// toastContent such that mapname: toasthtml
const toastContents = {
  /*
    mapName: (
      <div>
        This map has recently updated. To view the old version, visit{" "}
        <a href='https://maps.vectorsurv.org/invasive2'>
          https://maps.vectorsurv.org/invasive2
        </a>
      </div>
    ),
  */
};

const PrivateRoute = ({
  component: Component,
  authenticated,
  ...restOfProps
}) => {
  return (
    <Route
      render={(props) =>
        authenticated === true ? (
          <Component {...props} {...restOfProps} />
        ) : (
          <Restricted />
        )
      }
    />
  );
};

// A component to render out if a route is protected
const Restricted = () => {
  return (
    <div style={messageStyles}>
      <div>
        This map is restricted to gateway users only. Log in to your account at{" "}
        <a
          href="https://gateway.vectorsurv.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          gateway.vectorsurv.org
        </a>{" "}
        , then come back here and refresh the page! <br />
      </div>
    </div>
  );
};

const Navbar = ({ authenticated, loginType, openModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandAbout, setExpandAbout] = useState(false);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const toggleAbout = () => setExpandAbout(!expandAbout);

  return (
    <CustomNavbar expand="md">
      <CustomNavbarBrand
        href="/arbo"
        style={{ fontSize: "24px", fontWeight: "bold", marginLeft: "20px" }}
      >
        <img
          alt="VectorSurv logo"
          src={logo}
          style={{
            height: 35,
            width: 35,
            marginRight: 5,
          }}
        />
        VectorSurv Maps
      </CustomNavbarBrand>
      <NavbarToggler
        onClick={toggleNavbar}
        style={{ backgroundColor: colors["vectorsurv-blue"] }}
      />
      <Collapse
        isOpen={isOpen}
        style={{ justifyContent: "space-between", margin: "0px" }}
        navbar
      >
        <CustomNav className="mr-auto" navbar>
          <CustomNavItem>
            <NavLink
              to="/arbo"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Arbovirus
            </NavLink>
          </CustomNavItem>
          <CustomNavItem>
            <NavLink
              to="/invasive"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Invasive
            </NavLink>
          </CustomNavItem>
          <CustomNavItem>
            <NavLink
              to="/dengue"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Dengue
            </NavLink>
          </CustomNavItem>
          {authenticated &&
            (pacificAgencies[primaryAgency] ||
              loginType === "Administrator") && (
              <CustomNavItem>
                <NavLink
                  to="/pacific"
                  activeClassName="navbar--active"
                  className="nav-link"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  U.S. Pacific Islands
                </NavLink>
              </CustomNavItem>
            )}
          <CustomNavItem>
            <NavLink
              to="/abundance"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Abundance {authenticated ? "" : <Lock />}
            </NavLink>
          </CustomNavItem>
          <CustomNavItem>
            <NavLink
              to="/tick"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Tick {authenticated ? "" : <Lock />}
            </NavLink>
          </CustomNavItem>
          <CustomNavItem>
            <NavLink
              to="/resistance"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              Resistance {authenticated ? "" : <Lock />}
            </NavLink>
          </CustomNavItem>
          <CustomNavItem>
            <NavLink
              to="/risk"
              activeClassName="navbar--active"
              className="nav-link"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              WNV Risk {authenticated ? "" : <Lock />}
            </NavLink>
          </CustomNavItem>
        </CustomNav>

        <div className="button-container dropdown-container">
          <div className="share-button" onClick={() => openModal()}>
            <Share color="white" /> Share
          </div>
          <Dropdown isOpen={expandAbout} toggle={toggleAbout}>
            <DropdownToggle caret>About</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                href="https://vectorsurv.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                About VectorSurv
              </DropdownItem>
              <DropdownItem
                href="https://vectorsurv.org/policy/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </DropdownItem>
              <DropdownItem
                href="https://vectorsurv.org/policy/termsofuse"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Collapse>
    </CustomNavbar>
  );
};

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      authtoken: null,
      currentMap: document.location.pathname.replace("/", "").replace("/", ""),
      defaultMapStyle: mapStyle,
      downloadInfo: {
        risk: {
          "Agency Boundaries": true,
          "Surface Type": null,
          Species: null,
          Date: null,
          "Map Colors": null,
          "Current Agency": null,
          "Chart Type": null,
          "Chart Data": {},
          timeSeries: {},
          projectedCoordinates: null,
          mapDimensions: null,
        },
        abundance: {},
        pacific: {},
        zika: {},
        dengue: {},
        invasive: {},
        arbo: {
          "Agency Boundaries": false,
          "Current City": null,
          "End Date": null,
          Datasets: [],
          "Start Date": null,
          Surveillance: false,
        },
        resistance: {},
      },
      location: null,
      lockIcon: true,
      loginType,
      map: null,
      mapStyle,
      openModal: false,
      primaryAgency,
      styleModal: false,
      svgOptions: {},
      toastMap: null,
      usState,
      authenticated: false,
      expandAbout: false,
    };
  }

  geolocateUser = async () => {
    let usState = "CA"; // Default to CA
    try {
      usState = await getLocation();
      this.updateUsState(usState);
      return usState;
    } catch (err) {
      console.error(err);
      return usState;
    }
  };

  componentDidMount = async () => {
    const { authtoken, username } = cookies.getAll();

    try {
      this.geolocateUser(); // The func does all the state setting for you. Future, may handle off returns
    } catch (err) {
      console.error(err);
    }

    let authenticated = false;
    let primaryAgency = "";
    let loginType = "";
    try {
      if (process.env.NODE_ENV === "development") {
        authenticated = true;
        primaryAgency = "Davis Arbovirus Research & Training";
        loginType = "Administrator";
      } else {
        const res = await axios.get(
          `https://mathew.vectorsurv.org/v2/auth/${username}/${authtoken}`
        );

        primaryAgency = res?.data?.agency;
        loginType = res?.data?.login_type;
        authenticated = res.status === 200;
      }
      this.updatePrimaryAgency(primaryAgency);
      this.updateLoginType(loginType);
      this.setState({ authenticated });
    } catch (err) {
      this.updatePrimaryAgency(primaryAgency);
      this.updateLoginType(loginType);
      this.setState({ authenticated });
      console.error(err);
    }
  };

  // Toast the user when called for
  notify = () => {
    const { toastMap } = this.state;
    if (toastMap && toastContents[toastMap]) {
      toast(toastContents[toastMap], { autoClose: 5000 });
    }
  };

  updatePrimaryAgency = (agencyName) => {
    if (!agencyName) {
      localStorage.removeItem("primaryAgency");
    } else {
      localStorage.setItem("primaryAgency", agencyName);
    }
    this.setState({ primaryAgency: agencyName });
  };

  updateLoginType = (loginType) => {
    if (!loginType) {
      localStorage.removeItem("loginType");
    } else {
      localStorage.setItem("loginType", loginType);
    }
    this.setState({ loginType });
  };

  updateMap = (map) => {
    this.setState({ map });
  };
  updateSvgInfo = (svgOptions) => {
    this.setState({ svgOptions });
  };
  updateUsState = (usState) => {
    localStorage.setItem("usState", usState);
    this.setState({ usState });
  };
  updateDefaultMapStyle = (mapStyle) => {
    localStorage.setItem("mapStyle", mapStyle);
    this.setState({ defaultMapStyle: mapStyle });
  };
  updateMapStyle = (mapStyle) => {
    this.setState({ mapStyle });
  };
  // Used to tell which map we're on currently
  updateToastMap = () => {
    const newToastMap = document.location.pathname
      .replace("/", "")
      .replace("/", "");

    const { toastMap } = this.state;

    // Only attempt toast if map changed
    if (toastMap !== newToastMap) {
      this.setState({ toastMap: newToastMap }, this.notify);
    }
  };

  updateDownloadInfo = (newDownloadInfo = {}) => {
    // this just updates current map
    const currentMap = document.location.pathname
      .replace("/", "")
      .replace("/", "");

    // below here is updating the download info
    const { downloadInfo } = this.state;
    // copy
    const finalDownloadInfo = JSON.parse(JSON.stringify(downloadInfo));
    finalDownloadInfo[currentMap] = {
      ...downloadInfo[currentMap],
      ...newDownloadInfo,
    };

    this.setState({ currentMap, downloadInfo: finalDownloadInfo });
  };

  openStyleModal = () => {
    this.setState({ styleModal: true });
  };
  closeStyleModal = () => {
    this.setState({ styleModal: false });
  };

  openModal = () => {
    this.setState({ openModal: true, styleModal: false });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };

  updateExpandAbout = () => {
    this.setState((prevState) => ({ expandAbout: !prevState.expandAbout }));
  };

  render() {
    const {
      currentMap,
      defaultMapStyle,
      downloadInfo,
      loginType,
      map,
      mapStyle,
      openModal,
      primaryAgency,
      styleModal,
      svgOptions,
      toastMap,
      usState,
      authenticated,
      expandAbout,
    } = this.state;
    const displayModal = openModal ? (
      styleModal ? (
        <StyleModal
          changeDefaultMapStyle={this.updateDefaultMapStyle}
          changeMapStyle={this.updateMapStyle}
          defaultMapStyle={defaultMapStyle}
          closeModal={this.closeModal}
          map={map}
          mapStyle={mapStyle}
          svgOptions={svgOptions}
          url={window.location.href}
        />
      ) : (
        <ShareModal
          changeMapStyle={this.updateMapStyle}
          closeModal={this.closeModal}
          currentMap={currentMap}
          downloadInfo={downloadInfo}
          map={map}
          mapStyle={mapStyle}
          openStyleModal={this.openStyleModal}
          svgOptions={svgOptions}
          updateDownloadInfo={this.updateDownloadInfo}
          url={window.location.href}
        />
      )
    ) : null;

    const commonProps = {
      updateDownloadInfo: this.updateDownloadInfo,
      updateMap: this.updateMap,
      updateMapStyle: this.updateMapStyle,
      mapStyle,
      updateSvgInfo: this.updateSvgInfo,
      updateToastMap: this.updateToastMap,
      updateUsState: this.updateUsState,
      usState,
    };

    const pacificProps = {
      usState: "PI",
      // agency: "Guam Department of Public Health and Social Services",
      agency: primaryAgency,
    };

    const navProps = {
      authenticated,
      expandAbout,
      updateExpandAbout: this.updateExpandAbout,
      loginType,
      openModal: this.openModal,
    };

    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="main-container">
          {toastMap in toastContents && (
            <div>
              <ToastContainer position="top-center" />
            </div>
          )}
          <Navbar {...navProps} />
          {displayModal}
          <React.Fragment>
            <Suspense
              fallback={<div style={messageStyles}>Loading new map...</div>}
            >
              <Switch>
                <Route
                  path="/arbo"
                  render={(props) => <Arbo {...props} {...commonProps} />}
                />
                <Route
                  path="/invasive"
                  render={(props) => <Invasive {...props} {...commonProps} />}
                />
                <Route
                  path="/dengue"
                  render={(props) => <Dengue {...props} {...commonProps} />}
                />
                <Route
                  path="/zika"
                  render={(props) => <Zika {...props} {...commonProps} />}
                />
                <Route
                  path="/pacific"
                  render={(props) => (
                    <Pacific {...props} {...commonProps} {...pacificProps} />
                  )}
                />
                <PrivateRoute
                  path="/abundance"
                  authenticated={authenticated}
                  component={Abundance}
                  {...commonProps}
                />
                <PrivateRoute
                  path="/resistance"
                  authenticated={authenticated}
                  component={Resistance}
                  {...commonProps}
                />
                <PrivateRoute
                  path="/risk"
                  authenticated={authenticated}
                  component={Risk}
                  {...commonProps}
                />
                <PrivateRoute
                  path="/tick"
                  authenticated={authenticated}
                  component={Tick}
                  {...commonProps}
                />
                <PrivateRoute
                  path="/ticks"
                  authenticated={authenticated}
                  component={Tick}
                  {...commonProps}
                />
                <Redirect from="/" to="/arbo" />
              </Switch>
            </Suspense>
          </React.Fragment>
        </div>
      </Router>
    );
  }
}

export default App;

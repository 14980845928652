import React from "react";
// import Proptypes from "prop-types";
import { RadialChart, DiscreteColorLegend, Hint } from "react-vis";
import { Card, Tooltip } from "../../styles";
import PieChartTitle from "../Pacific/PieChartTitle";

class SpeciesPieChart extends React.Component {
  state = {
    hint: null,
  };

  updateHint = (d) => {
    this.setState({
      hint: d,
    });
  };

  removeHint = () => {
    this.setState({ hint: null });
  };

  // Ultimately when multi select, this would get an array of things
  handleClick = (e) => {
    const species = e.label;
    if (species === "All others") return;
    this.props.changeSpecies(species);
  };

  render() {
    const {
      abundanceRates,
      chartType,
      handleDropdown,
      loading,
      pieChartDate,
      pieChartDropdownDisabledValues,
      pieChartDropdownSelectedValue,
      pieChartDropdownValues,
      place,
    } = this.props;
    const { hint } = this.state;
    const formatted = abundanceRates.map((d) => {
      return {
        angle: +(d.value * 100).toFixed(1),
        color: d.color,
        label: d.species,
        subLabel: +(d.value * 100).toFixed(1),
      };
    });

    let topTen = formatted.slice(0, 9);
    const others = { angle: 100, label: "All others", color: "gray" };
    topTen.forEach((d) => (others.angle = others.angle - d.angle));
    others.angle = +others.angle.toFixed(1);
    if (others.angle < 0) others.angle = 0;
    others.subLabel = others.angle;
    topTen.push(others);
    const legendItems = topTen.map((d, i) => {
      return {
        title: `${d.label.replace(/\//g, "/")} ${d.angle}%`, // This right now does nothing
        color: d.color,
        strokeWidth: 15,
      };
    });

    const chartTitleProps = {
      action: handleDropdown,
      place,
      disabledValues: pieChartDropdownDisabledValues,
      loading,
      units: `Breakdown of species found ${pieChartDate}`,
      value: pieChartDropdownSelectedValue || chartType || "help",
      values: pieChartDropdownValues,
    };

    if (place.length === 2) {
      // This is when place = "PI"
      chartTitleProps["noAction"] = true;
    }

    return (
      <Card style={{ display: "block", height: 400, overflow: "hidden" }}>
        <PieChartTitle {...chartTitleProps} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <RadialChart
            width={300}
            height={300}
            data={topTen}
            radius={150}
            colorType="literal"
            animation={{ damping: 10, stiffness: 200 }}
            onValueMouseOver={(v) => this.setState({ hint: v })}
            onSeriesMouseOut={this.removeHint}
            onValueClick={this.handleClick}
          >
            {hint && (
              <Hint value={hint}>
                <Tooltip>
                  <div>
                    <i>{hint.label.replace(/\//g, "/")}</i>
                  </div>
                  <div>{hint.subLabel}%</div>
                </Tooltip>
              </Hint>
            )}
          </RadialChart>
          <DiscreteColorLegend items={legendItems} />
        </div>
      </Card>
    );
  }
}

// SpeciesPieChart.propTypes = {
//   data: Proptypes.arrayOf(Proptypes.object),
//   pieChartDate: Proptypes.number,// @shawn this was renamed by me to this
//   width: Proptypes.number.isRequired,
//   clearAgency: Proptypes.func.isRequired,
//   place: Proptypes.string.isRequired,
//   changeSpecies: Proptypes.func.isRequired
// };

export default SpeciesPieChart;

// This had to be separated from helpers because react-pdf overwrites the Image constructor that's used in convertSVGtoImage
import watermark from "watermarkjs";
import {
  // arboDatasets,//this will be used when there's a table going on
  addDays,
  arboToFull,
  colors,
  convertSVGtoImage,
  translateSVGToString,
} from "./helpers";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import {
  formatDate,
  getInitialSaturday,
  getNumberOfWeeksInYear,
  numToWeek,
} from "../src/components/Risk/riskHelpers";
import smallGreyX from "../src/components/Risk/smallGreyX.png";
import React from "react";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#eee",
  },
  dataPage: {
    flexDirection: "column",
    backgroundColor: "#eee",
    alignContent: "center",
  },
  section: {
    margin: 5,
    padding: 5,
    // flexGrow: 1,
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    marginTop: 10,
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: "solid",
    //borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  upDownTableRow: { margin: "auto", flexDirection: "column" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  riskChartCol: {
    width: "9%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },

  tableCell: {
    margin: 0,
    marginTop: 1,
    fontSize: 8,
    // flexDirection: "row",
    // justifyContent: "center",
    // alignContent: "center",
    textAlign: "center",
  },
});

const formatArboDatasets = (datasets) => {
  const formattedDatasets = datasets.map((dataset) => {
    return (
      <Text style={styles.tableCell} key={dataset}>
        {arboToFull[dataset]}
      </Text>
    );
  });

  if (datasets.length) {
    return (
      <View style={styles.upDownTableRow} key="datasets">
        <View style={styles.tableCol}>{formattedDatasets}</View>
      </View>
    );
  } else {
    return (
      <View style={styles.upDownTableRow} key="datasets">
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>None, how is this useful?</Text>
        </View>
      </View>
    );
  }
};

// format arbo map info
const formatArboMapInfo = (downloadInfo) => {
  const cityView = downloadInfo["Current City"] ? (
    <View style={styles.tableRow} key={"Current City"}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>Location</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{downloadInfo["Current City"]}</Text>
      </View>
    </View>
  ) : null;

  const datasetHeader = (
    <View style={styles.tableRow}>
      <Text key="datasetsHeader" style={styles.header}>
        Datasets Included
      </Text>
    </View>
  );

  return (
    <View style={[styles.table, {}]}>
      <View style={styles.tableRow}>
        <Text key="arboHeader" style={styles.header}>
          Arbovirus Map and Chart
        </Text>
      </View>
      <View style={styles.tableRow} key={"Start Date"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Start Date</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{downloadInfo["Start Date"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"End Date"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>End Date</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{downloadInfo["End Date"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"Agency Boundaries"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Showing Agency Boundaries</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {downloadInfo["Agency Boundaries"] ? "True" : "False"}
          </Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"Surveillance"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Showing Surveillance Heatmap</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {downloadInfo["Surveillance"] ? "True" : "False"}
          </Text>
        </View>
      </View>
      {cityView}
      {datasetHeader}
      {formatArboDatasets(downloadInfo.Datasets || [])}
    </View>
  );
};

// Create Document Component
// this should eventually just dynamically decide how to render based on the image count
const MyDocument = (downloadInfo, img1, img2) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View
        style={[
          styles.section,
          { flexDirection: "column", justifyContent: "space-around" },
        ]}
      >
        <Image
          style={{
            width: "360",
            height: "270",
          }}
          src={img1}
        />
      </View>
      <View
        style={[
          styles.section,
          { flexDirection: "column", justifyContent: "space-around" },
        ]}
      >
        <Image
          style={{
            width: "360",
            height: "270",
          }}
          src={img2 || img1}
        />
      </View>
    </Page>
    <Page size="A4" orientation="landscape" style={styles.dataPage}>
      {formatArboMapInfo(downloadInfo)}
    </Page>
  </Document>
);

const formatRiskWeekInfo = (downloadInfo) => {
  const chartView =
    downloadInfo?.["Chart Data"] &&
    Object.keys(downloadInfo["Chart Data"]).length ? (
      <View style={[styles.table, { marginBottom: 10 }]}>
        <View style={styles.tableRow}>
          <Text key="chartHeader" style={styles.header}>
            {downloadInfo["Species"]} Risk Components for {downloadInfo["Date"]}
          </Text>
        </View>
        <View style={styles.tableRow} key={"chartHeaderRow"}>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Date</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Overall Risk</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Environment</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Abundance</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Infection</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Seroconversion</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>Dead Bird</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>{downloadInfo["Date"]}</Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Risk"]}
            </Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Environment"]}
            </Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Abundance"]}
            </Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Infection"]}
            </Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Seroconversion"]}
            </Text>
          </View>
          <View style={styles.riskChartCol}>
            <Text style={styles.tableCell}>
              {downloadInfo["Chart Data"]["Dead Bird"]}
            </Text>
          </View>
        </View>
      </View>
    ) : null;

  return chartView;
};

// format Risk map info
const formatRiskMapInfo = (downloadInfo) => {
  const agencyView = downloadInfo["Current Agency"] ? (
    <View style={styles.tableRow} key={"Current Agency"}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>Location</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{downloadInfo["Current Agency"]}</Text>
      </View>
    </View>
  ) : null;

  // console.log(downloadInfo, downloadInfo["Date"]);
  // {/*<Page size='A4' orientation='portrait' style={styles.dataPage}>*/}    {/*</Page>*/}
  return (
    <View style={[styles.table, {}]}>
      <View style={styles.tableRow}>
        <Text key="riskHeader" style={styles.header}>
          Risk Map Information
        </Text>
      </View>
      <View style={styles.tableRow} key={"surfaceType"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Risk Surface Type</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{downloadInfo["Surface Type"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"Date"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Week Ending</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{downloadInfo["Date"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"species"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Species</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{downloadInfo["Species"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow} key={"Agency Boundaries"}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Showing Agency Boundaries</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {downloadInfo["Agency Boundaries"] ? "True" : "False"}
          </Text>
        </View>
      </View>
      {agencyView}
    </View>
  );
};

const formatRiskChartInfo = (downloadInfo) => {
  const allChartData = downloadInfo["timeSeries"];
  const year = +downloadInfo["Date"].slice(-4);
  const chartType = downloadInfo["Chart Type"];
  let chartData = allChartData[year];
  if (!chartType || !chartData) {
    // if no chart, no chart data
    // console.log("there was no either of these", chartType, chartData);
    return null;
  }
  const initialSaturday = getInitialSaturday(year);
  const numberOfWeeks = getNumberOfWeeksInYear(year, initialSaturday);

  const headerRow = (
    <View style={styles.tableRow} key={"chartHeaderRow"}>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Week Ending</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Overall Risk Cx tarsalis</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Overall Risk Cx pipiens complex</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Environment</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Abundance Cx tarsalis</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Infection Cx tarsalis</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Abundance Cx pipiens complex</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Infection Cx pipiens complex</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Seroconversion</Text>
      </View>
      <View style={styles.riskChartCol}>
        <Text style={styles.tableCell}>Dead Bird</Text>
      </View>
    </View>
  );

  // date.setHours(0, 0, 0, 0);
  const chartTableRows = [];
  let week = 0;
  const pipsRisk = chartData.species["Cx pipiens complex"]["Risk"];
  const pipsAbun = chartData.species["Cx pipiens complex"]["Abundance"];
  const pipsInf = chartData.species["Cx pipiens complex"]["Infection"];
  const tarsRisk = chartData.species["Cx pipiens complex"]["Risk"];
  const tarsAbun = chartData.species["Cx pipiens complex"]["Abundance"];
  const tarsInf = chartData.species["Cx pipiens complex"]["Infection"];
  const envData = chartData["Environment"];
  const birdData = chartData["Dead Bird"];
  const seroData = chartData["Seroconversion"];

  // This is going to be unfinished by default, must optimize later

  while (week < numberOfWeeks) {
    let currentDate = new Date(
      formatDate(numToWeek(week, year, initialSaturday))
    );
    currentDate.setHours(0, 0, 0, 0);

    const currentRowDate = formatDate(currentDate);
    const currentPipsRisk =
      pipsRisk.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentPipsAbun =
      pipsAbun.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentPipsInf =
      pipsInf.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentTarsRisk =
      tarsRisk.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentTarsAbun =
      tarsAbun.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentTarsInf =
      tarsInf.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentEnvData =
      envData.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentBirdData =
      birdData.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;
    const currentSeroData =
      seroData.filter((data) => {
        let dataDate = new Date(data.date);
        dataDate.setHours(0, 0, 0, 0);
        dataDate = addDays(dataDate, 1); // @shawn may not need all the time
        return (
          dataDate.getDate() === currentDate.getDate() &&
          dataDate.getMonth() === currentDate.getMonth() &&
          dataDate.getFullYear() === currentDate.getFullYear()
        );
      }) || null;

    const chartTableRow = (
      <View style={styles.tableRow} key={currentDate.getTime()}>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>{currentRowDate}</Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentTarsRisk.length && currentTarsRisk[0].value
              ? currentTarsRisk[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentPipsRisk.length && currentPipsRisk[0].value
              ? currentPipsRisk[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentEnvData.length && currentEnvData[0].value
              ? currentEnvData[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentTarsAbun.length && currentTarsAbun[0].value
              ? currentTarsAbun[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentTarsInf.length && currentTarsInf[0].value
              ? currentTarsInf[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentPipsAbun.length && currentPipsAbun[0].value
              ? currentPipsAbun[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentPipsInf.length && currentPipsInf[0].value
              ? currentPipsInf[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentSeroData.length && currentSeroData[0].value
              ? currentSeroData[0].value.toFixed(1)
              : null}
          </Text>
        </View>
        <View style={styles.riskChartCol}>
          <Text style={styles.tableCell}>
            {currentBirdData.length && currentBirdData[0].value
              ? currentBirdData[0].value.toFixed(1)
              : null}
          </Text>
        </View>
      </View>
    );

    chartTableRows.push(chartTableRow);

    currentDate = addDays(currentDate, 7);
    week += 1;
  }

  let location = "at selected point";
  if (chartType === "point") {
    location = "at selected point";
  }
  if (chartType === "agency") {
    location = `in ${downloadInfo["Current Agency"]}`;
  }
  if (chartType === "polygon") {
    location = "in custom area";
  }

  return (
    <Page size="A4" orientation="portrait" style={styles.dataPage}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text key="riskHeader" style={styles.header}>
            {year} Risk Components {location}
          </Text>
        </View>
        {headerRow}
        {chartTableRows}
      </View>
    </Page>
  );

  // this had the image on the same page as the table
  // return (
  //   <Page size='A4' orientation='portrait' style={styles.dataPage}>
  //     <View style={[styles.table, {}]}>
  //       <View style={styles.tableRow}>
  //         <Text key='riskHeader' style={styles.header}>
  //           {downloadInfo["Surface Type"] === "Risk"
  //             ? "Overall Risk"
  //             : downloadInfo["Surface Type"]}{" "}
  //           series for {year}
  //         </Text>
  //       </View>
  //     </View>
  //     {thirdImageSection}
  //     <View style={styles.table}>
  //       <View style={styles.tableRow}>
  //         <Text key='riskHeader' style={styles.header}>
  //           {year} Risk Components {location}
  //         </Text>
  //       </View>
  //       {headerRow}
  //       {chartTableRows}
  //     </View>
  //   </Page>
  // );
};

// Create Document Component
// this should eventually just dynamically decide how to render based on the image count
const MyDocumentThree = (downloadInfo, img1, img2, img3) => {
  let imageCount = 0;
  if (img1) {
    imageCount += 1;
  }
  if (img2) {
    imageCount += 1;
  }
  if (img3) {
    imageCount += 1;
  }
  let firstImageSection;
  let secondImageSection;
  let thirdImageSection;

  if (imageCount === 1) {
    firstImageSection = (
      <View
        style={[
          styles.section,
          {
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          },
        ]}
      >
        <Image
          style={{
            width: "560",
            height: "420",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
            borderStyle: "solid",
            borderWidth: 1,
          }}
          src={img1}
        />
      </View>
    );

    secondImageSection = null;
  }
  if (imageCount === 3) {
    firstImageSection = (
      <View
        style={
          ([styles.section], { justifyContent: "center", alignItems: "center" })
        }
      >
        <Image
          style={{
            width: "560",
            height: "420",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
            borderStyle: "solid",
            borderWidth: 1,
            margin: 10,
          }}
          src={img1}
        />
      </View>
    );

    secondImageSection = (
      <View
        style={[
          styles.section,
          { alignItems: "center", height: "50%" },
          //{ flexDirection: "column", justifyContent: "space-around" },
        ]}
      >
        {formatRiskWeekInfo(downloadInfo)}
        <Image
          style={{
            width: "510",
            height: "200",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
            borderStyle: "solid",
            borderWidth: 1,
          }}
          src={img2 || img1}
        />
      </View>
    );

    thirdImageSection = (
      <View
        style={[
          styles.section,
          { alignItems: "center", height: "50%" },
          //{ flexDirection: "column", justifyContent: "space-around" },
        ]}
      >
        <Image
          style={{
            width: "510",
            height: "200",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
            borderStyle: "solid",
            borderWidth: 1,
          }}
          src={img3 || img2 || img1}
        />
      </View>
    );
  }

  const imagePage1 = (
    <Page size="A4" orientation="portrait" style={styles.dataPage}>
      {formatRiskMapInfo(downloadInfo)}
      {firstImageSection}
    </Page>
  );

  const riskWeekInfo = formatRiskWeekInfo(downloadInfo);

  const year = +downloadInfo["Date"].slice(-4);
  const imagePage2 = riskWeekInfo ? (
    <Page size="A4" orientation="portrait" style={styles.dataPage}>
      {secondImageSection}
      <View>
        <View style={[styles.table, {}]}>
          <View style={styles.tableRow}>
            <Text key="riskHeader" style={styles.header}>
              {downloadInfo["Surface Type"] === "Risk"
                ? "Overall Risk"
                : downloadInfo["Surface Type"]}{" "}
              time series for {year}
            </Text>
          </View>
        </View>
        {thirdImageSection}
      </View>
    </Page>
  ) : null;

  return (
    <Document>
      {imagePage1}
      {imagePage2}
      {formatRiskChartInfo(downloadInfo, thirdImageSection)}
    </Document>
  );
};

const generatePDFByImageCount = (
  downloadInfo,
  currentMap,
  img1,
  img2,
  img3
) => {
  if (maxImageCount[currentMap] === 2) {
    return MyDocument(downloadInfo, img1, img2);
  } else if (maxImageCount[currentMap] === 3) {
    if (currentMap === "arbo") {
      return MyDocument(downloadInfo, img1, img2);
    }
    if (currentMap === "risk") {
      return MyDocumentThree(downloadInfo, img1, img2, img3);
    }
  }
};

const maxImageCount = {
  abundance: 3,
  arbo: 2,
  invasive: 3,
  resistance: 2,
  risk: 3,
  zika: 3,
};

// func to actually download the pdf
// ultimately there should just be one func to hand the pdf, the functionalities are so similar
export const downloadPDF = (downloadInfo = {}, map, currentMap) => {
  map.getCanvas().toBlob((blob) => {
    // If there's a point, add a grey x in the appropriate place
    if (
      downloadInfo["Chart Type"] === "point" &&
      downloadInfo["mapDimensions"] &&
      downloadInfo["projectedCoordinates"] &&
      downloadInfo["projectedCoordinates"]["x"] >= 0 &&
      downloadInfo["projectedCoordinates"]["y"] >= 0 &&
      downloadInfo["projectedCoordinates"]["x"] <=
        downloadInfo["mapDimensions"]["width"] &&
      downloadInfo["projectedCoordinates"]["y"] <=
        downloadInfo["mapDimensions"]["height"]
    ) {
      // For some reason the canvas has a different idea of its dimensions than does the browser or mapbox
      const getXPos = (mapCanvas) => {
        const ratio = mapCanvas.width / downloadInfo["mapDimensions"]["width"];
        return +downloadInfo["projectedCoordinates"]["x"] * ratio;
      };

      const getYPos = (mapCanvas) => {
        const ratio =
          mapCanvas.height / downloadInfo["mapDimensions"]["height"];
        return +downloadInfo["projectedCoordinates"]["y"] * ratio;
      };

      watermark([blob, smallGreyX])
        .blob(watermark.image.atPos(getXPos, getYPos, 1))
        .render()
        .blob(
          watermark.text.lowerLeft(
            "maps.VectorSurv.org",
            "60px Roboto",
            colors["vectorsurv-navy"],
            0.2
          )
        )
        .then(async (blob) => {
          const url1 = URL.createObjectURL(blob);
          const svg = document.querySelector("#chartContainer svg");
          if (!svg) {
            savePDF(
              generatePDFByImageCount(downloadInfo, currentMap, url1),
              `vectorsurv-${currentMap}.pdf`
            );
            URL.revokeObjectURL(url1);
          } else {
            const svgString = translateSVGToString(svg).replace(
              "</style>",
              ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
            );
            convertSVGtoImage(svgString, 1000, 750, (blob2) => {
              const timeseriesSvg = document.querySelector(
                "#seriesContainer svg"
              );
              const url2 = URL.createObjectURL(blob2);
              if (!timeseriesSvg) {
                savePDF(
                  generatePDFByImageCount(downloadInfo, currentMap, url1, url2),
                  `vectorsurv-${currentMap}.pdf`
                );
                URL.revokeObjectURL(url2);
                URL.revokeObjectURL(url1);
              } else {
                const seriesSvgString = translateSVGToString(
                  timeseriesSvg
                ).replace(
                  "</style>",
                  ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
                );
                convertSVGtoImage(seriesSvgString, 1000, 750, (blob3) => {
                  const url3 = URL.createObjectURL(blob3);
                  savePDF(
                    generatePDFByImageCount(
                      downloadInfo,
                      currentMap,
                      url1,
                      url2,
                      url3
                    ),
                    `vectorsurv-${currentMap}.pdf`
                  );
                  URL.revokeObjectURL(url3);
                  URL.revokeObjectURL(url2);
                  URL.revokeObjectURL(url1);
                });
              }
            });
          }
        });
    } else {
      watermark([blob])
        .blob(
          watermark.text.lowerLeft(
            "maps.VectorSurv.org",
            "60px Roboto",
            colors["vectorsurv-navy"],
            0.2
          )
        )
        .then(async (blob) => {
          const url1 = URL.createObjectURL(blob);
          const svg = document.querySelector("#chartContainer svg");
          if (!svg) {
            savePDF(
              generatePDFByImageCount(downloadInfo, currentMap, url1),
              `vectorsurv-${currentMap}.pdf`
            );
            URL.revokeObjectURL(url1);
          } else {
            const svgString = translateSVGToString(svg).replace(
              "</style>",
              ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
            );
            convertSVGtoImage(svgString, 1000, 750, (blob2) => {
              const timeseriesSvg = document.querySelector(
                "#seriesContainer svg"
              );
              const url2 = URL.createObjectURL(blob2);
              if (!timeseriesSvg) {
                savePDF(
                  generatePDFByImageCount(downloadInfo, currentMap, url1, url2),
                  `vectorsurv-${currentMap}.pdf`
                );
                URL.revokeObjectURL(url2);
                URL.revokeObjectURL(url1);
              } else {
                const seriesSvgString = translateSVGToString(
                  timeseriesSvg
                ).replace(
                  "</style>",
                  ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
                );
                convertSVGtoImage(seriesSvgString, 1000, 750, (blob3) => {
                  const url3 = URL.createObjectURL(blob3);
                  savePDF(
                    generatePDFByImageCount(
                      downloadInfo,
                      currentMap,
                      url1,
                      url2,
                      url3
                    ),
                    `vectorsurv-${currentMap}.pdf`
                  );
                  URL.revokeObjectURL(url3);
                  URL.revokeObjectURL(url2);
                  URL.revokeObjectURL(url1);
                });
              }
            });
          }
        });
    }
  });
};

/**
 * open a preview of the overall PDF in a new browser tab
 *
 * @param {Object} downloadInfo - Information about the dimensions and settings for the PDF output
 * @param {Ref} map - Reference to the Mapbox element
 * @param {string} currentMap - Identifier for the type of map, basically "risk", for naming figures in the PDF
 *
 * @example
 * // Usage in development to preview PDF
 * {process.env.NODE_ENV === "development" && (
 *   <ButtonWithIcon
 *     color={"salmon"}
 *     onClick={() => previewPdf(downloadInfo[currentMap], map, currentMap)}
 *   >
 *     <Link color='white' />
 *     <sub>BETA</sub> PDF PREVIEW BUTTON <sub>BETA</sub>
 *   </ButtonWithIcon>
 * )}
 */

export const previewPdf = (downloadInfo = {}, map, currentMap) => {
  map.getCanvas().toBlob((blob) => {
    // If there's a point, add a grey x in the appropriate place
    if (
      downloadInfo["Chart Type"] === "point" &&
      downloadInfo["mapDimensions"] &&
      downloadInfo["projectedCoordinates"] &&
      downloadInfo["projectedCoordinates"]["x"] >= 0 &&
      downloadInfo["projectedCoordinates"]["y"] >= 0 &&
      downloadInfo["projectedCoordinates"]["x"] <=
        downloadInfo["mapDimensions"]["width"] &&
      downloadInfo["projectedCoordinates"]["y"] <=
        downloadInfo["mapDimensions"]["height"]
    ) {
      // For some reason the canvas has a different idea of its dimensions than does the browser or mapbox
      const getXPos = (mapCanvas) => {
        const ratio = mapCanvas.width / downloadInfo["mapDimensions"]["width"];
        return +downloadInfo["projectedCoordinates"]["x"] * ratio;
      };

      const getYPos = (mapCanvas) => {
        const ratio =
          mapCanvas.height / downloadInfo["mapDimensions"]["height"];
        return +downloadInfo["projectedCoordinates"]["y"] * ratio;
      };

      watermark([blob, smallGreyX])
        .blob(watermark.image.atPos(getXPos, getYPos, 1))
        .render()
        .blob(
          watermark.text.lowerLeft(
            "maps.VectorSurv.org",
            "60px Roboto",
            colors["vectorsurv-navy"],
            0.2
          )
        )
        .then(async (blob) => {
          const url1 = URL.createObjectURL(blob);
          const svg = document.querySelector("#chartContainer svg");
          if (!svg) {
            openPDF(
              generatePDFByImageCount(downloadInfo, currentMap, url1),
              `vectorsurv-${currentMap}.pdf`
            );
            URL.revokeObjectURL(url1);
          } else {
            const svgString = translateSVGToString(svg).replace(
              "</style>",
              ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
            );
            convertSVGtoImage(svgString, 1000, 750, (blob2) => {
              const timeseriesSvg = document.querySelector(
                "#seriesContainer svg"
              );
              const url2 = URL.createObjectURL(blob2);
              if (!timeseriesSvg) {
                openPDF(
                  generatePDFByImageCount(downloadInfo, currentMap, url1, url2),
                  `vectorsurv-${currentMap}.pdf`
                );
                URL.revokeObjectURL(url2);
                URL.revokeObjectURL(url1);
              } else {
                const seriesSvgString = translateSVGToString(
                  timeseriesSvg
                ).replace(
                  "</style>",
                  ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
                );
                convertSVGtoImage(seriesSvgString, 1000, 750, (blob3) => {
                  const url3 = URL.createObjectURL(blob3);
                  openPDF(
                    generatePDFByImageCount(
                      downloadInfo,
                      currentMap,
                      url1,
                      url2,
                      url3
                    ),
                    `vectorsurv-${currentMap}.pdf`
                  );
                  URL.revokeObjectURL(url3);
                  URL.revokeObjectURL(url2);
                  URL.revokeObjectURL(url1);
                });
              }
            });
          }
        });
    } else {
      watermark([blob])
        .blob(
          watermark.text.lowerLeft(
            "maps.VectorSurv.org",
            "60px Roboto",
            colors["vectorsurv-navy"],
            0.2
          )
        )
        .then(async (blob) => {
          const url1 = URL.createObjectURL(blob);
          const svg = document.querySelector("#chartContainer svg");
          if (!svg) {
            openPDF(
              generatePDFByImageCount(downloadInfo, currentMap, url1),
              `vectorsurv-${currentMap}.pdf`
            );
            URL.revokeObjectURL(url1);
          } else {
            const svgString = translateSVGToString(svg).replace(
              "</style>",
              ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
            );
            convertSVGtoImage(svgString, 1000, 750, (blob2) => {
              const timeseriesSvg = document.querySelector(
                "#seriesContainer svg"
              );
              const url2 = URL.createObjectURL(blob2);
              if (!timeseriesSvg) {
                openPDF(
                  generatePDFByImageCount(downloadInfo, currentMap, url1, url2),
                  `vectorsurv-${currentMap}.pdf`
                );
                URL.revokeObjectURL(url2);
                URL.revokeObjectURL(url1);
              } else {
                const seriesSvgString = translateSVGToString(
                  timeseriesSvg
                ).replace(
                  "</style>",
                  ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
                );
                convertSVGtoImage(seriesSvgString, 1000, 750, (blob3) => {
                  const url3 = URL.createObjectURL(blob3);
                  openPDF(
                    generatePDFByImageCount(
                      downloadInfo,
                      currentMap,
                      url1,
                      url2,
                      url3
                    ),
                    `vectorsurv-${currentMap}.pdf`
                  );
                  URL.revokeObjectURL(url3);
                  URL.revokeObjectURL(url2);
                  URL.revokeObjectURL(url1);
                });
              }
            });
          }
        });
    }
  });
};

// Creates a download link for a blob and clicks it
// https://github.com/diegomura/react-pdf/issues/341 the post by anisg
const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

// saves a blob
export const savePDF = async (document, filename) => {
  try {
    const pdfBlob = await pdf(document).toBlob();
    saveBlob(pdfBlob, filename);
  } catch (err) {
    console.error("When saving the PDF, there was the error", err);
  }
};

// creates a link that will open a new tab, then clicks it
const openBlob = (blob) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.target = "_blank";
  a.rel = "noreferrer noopener";
  a.click();
  window.URL.revokeObjectURL(url);
};

// async a blob into a new tab
export const openPDF = async (document) => {
  try {
    const pdfBlob = await pdf(document).toBlob();
    openBlob(pdfBlob);
  } catch (err) {
    console.error("When opening the PDF, there was the error", err);
  }
};

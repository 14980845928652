import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { AppContainer } from "react-hot-loader";
import "core-js/stable";
import "regenerator-runtime/runtime";

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
// registerServiceWorker();
unregister();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}

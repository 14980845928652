import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, handleClickOutsideModal, mapStyles } from "../helpers";
import { Card, MapControlsContainer } from "../styles";
import DiverseDropdown from "./DiverseDropdown";

const ModalContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalCard = styled(Card)`
  height: 350px;
  width: 500px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  box-shadow: 0 14px 44px rgba(0, 0, 0, 0.9);
  textarea {
    color: gray;
    font-family: Monospace;
  }
`;

const TooltipMsg = styled.div`
  width: 100%;
  color: black;
  font-size: 14px;
  text-align: center;
  font-style: italic;
`;

const defaultEmbedText = (url) =>
  `<iframe id="vectorsurvEmbedded" title="Embedded VectorSurv Visualizations" width="1000" height="700" src="${url}"> </iframe>`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 15px;
  color: ${colors["vectorsurv-navy"]};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
`;

class StyleModal extends Component {
  constructor(props) {
    super(props);
    //we do not want people sharing the print quality version of the maps, so strip out that param if present
    const url = this.props.url
      .replace("&hires=true", "")
      .replace("&hires=false", "");
    this.state = {
      embedCode: defaultEmbedText(url),
      message: `This device will use your Default Map Style the next time you visit this page`,
      url,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escToClose, true);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escToClose, true);
  }

  escToClose = (e) => {
    if (e.keyCode === 27) {
      this.props.closeModal();
    }
  };

  render() {
    const { message } = this.state;
    const {
      changeDefaultMapStyle,
      changeMapStyle,
      defaultMapStyle,
      closeModal,
      mapStyle,
    } = this.props;
    const styleDisabledValues = { "Map Styles": true };
    const styleSelectValues = Object.keys(mapStyles).slice();
    styleSelectValues.unshift("Map Styles");

    return (
      <ModalContainer
        onClick={(e) => handleClickOutsideModal(e, this.props.closeModal)}
      >
        <ModalCard className={"modal-card"}>
          <CloseButton onClick={closeModal}>Close</CloseButton>
          <h1>Change Map Settings</h1>
          <div
            style={{
              margin: "10px",
            }}
          >
            <Row>
              <div>Current Map Style:</div>
              <MapControlsContainer>
                <DiverseDropdown
                  id={"style-current"}
                  handler={(e) => changeMapStyle(e.target.value)}
                  values={styleSelectValues.map((value) => {
                    return { value, disabled: !!styleDisabledValues[value] };
                  })}
                  value={mapStyle}
                />
              </MapControlsContainer>
            </Row>
            {message && <TooltipMsg>{message}</TooltipMsg>}
            <Row>
              <div>Default Map Style:</div>
              <MapControlsContainer>
                <DiverseDropdown
                  id={"style-default"}
                  handler={(e) => changeDefaultMapStyle(e.target.value)}
                  values={styleSelectValues.map((value) => {
                    return { value, disabled: !!styleDisabledValues[value] };
                  })}
                  value={defaultMapStyle}
                />
              </MapControlsContainer>
            </Row>
          </div>
        </ModalCard>
      </ModalContainer>
    );
  }
}

StyleModal.propTypes = {
  url: PropTypes.string.isRequired,
  changeDefaultMapStyle: PropTypes.func.isRequired,
  changeMapStyle: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultMapStyle: PropTypes.string.isRequired,
  mapStyle: PropTypes.string.isRequired,
};

export default StyleModal;

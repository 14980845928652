import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { colors } from "../../helpers";
import DiverseDropdown from "../DiverseDropdown";

const selectorStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginLeft: 10,
  marginRight: 10,
  textAlign: "right",
};

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChartTitle = (props) => {
  const { action, place, disabledValues, loading, units, value, values } =
    props;
  return (
    <div
      style={{
        margin: "5px 0px 10px 30px",
        display: "flex",
        justifyContent: "space-between",
        color: colors["dark-green"],
        fontSize: 20,
      }}
    >
      <div style={{ textAlign: "center", margin: "0px 30px 0px 0px" }}>
        {units} in {place}
      </div>{" "}
      {/* actions to push to the right */}
      <Actions>
        <div
          style={{
            color: colors["dark-green"],
            cursor: "pointer",
            marginRight: 5,
            fontWeight: "light",
            fontSize: 14,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          Choose your pie chart:
          <div style={selectorStyle}>
            <DiverseDropdown
              id="change-piechart"
              handler={(e) => action(e.target.value)}
              values={values.map((value) => {
                return { value, disabled: !!disabledValues[value] };
              })}
              value={value}
              style={{ width: value.split(" ").length < 4 ? 150 : 200 }}
              loading={loading}
            />
          </div>
        </div>
      </Actions>
    </div>
  );
};

ChartTitle.propTypes = {
  action: Proptypes.func,
};

export default ChartTitle;

import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  colors,
  copyToClipboard,
  downloadMap,
  downloadSVG,
  handleClickOutsideModal,
} from "../helpers";
import { downloadPDF } from "../pdfDownload";
import { Card } from "../styles";
import { DownloadIcon, Link, Code } from "./icons";

const ModalContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalCard = styled(Card)`
  height: 350px;
  width: 500px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  box-shadow: 0 14px 44px rgba(0, 0, 0, 0.9);
  textarea {
    color: gray;
    font-family: Monospace;
  }
`;

const TooltipMsg = styled.div`
  width: 100%;
  color: black;
  font-size: 14px;
  text-align: center;
  font-style: italic;
`;

const defaultEmbedText = (url) =>
  `<iframe id="vectorsurvEmbedded" title="Embedded VectorSurv Visualizations" width="1000" height="700" src="${url}"> </iframe>`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 15px;
  color: ${colors["vectorsurv-navy"]};
  cursor: pointer;
`;

const MapSettings = styled.div`
  color: ${colors["vectorsurv-navy"]};
  cursor: pointer;
`;

const ButtonWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 50px;
  padding: 0 10px;
  background: ${(props) => (props.color ? props.color : colors["light-blue"])};
  color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
`;

class ShareModal extends Component {
  constructor(props) {
    super(props);
    //we do not want people sharing the print quality version of the maps, so strip out that param if present
    const url = this.props.url
      .replace("&hires=true", "")
      .replace("&hires=false", "");
    this.state = {
      embedCode: defaultEmbedText(url),
      message: "Please attribute VectorSurv.org for all downloaded materials",
      url,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escToClose, true);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escToClose, true);
  }

  escToClose = (e) => {
    if (e.keyCode === 27) {
      this.props.closeModal();
    }
  };

  copyURL = () => {
    copyToClipboard(this.state.url);
    this.setState({ message: "URL copied to your clipboard 😃" });
  };

  copyEmbed = () => {
    copyToClipboard(this.state.embedCode);
    this.setState({ message: "Code copied to your clipboard 👨🏻‍💻" });
  };

  changeEmbedCode = (e) => {
    this.setState({ embedCode: e.target.value });
  };

  render() {
    const { embedCode, message } = this.state;
    const {
      closeModal,
      currentMap,
      downloadInfo,
      map,
      openStyleModal,
      svgOptions,
    } = this.props;
    const chartEl = document.querySelector("#chartContainer svg");
    const urlMap = document.location.pathname.replace("/", "").replace("/", "");

    const showPdfButton = map && currentMap === urlMap && currentMap === "risk";

    // new style to ensure all buttons easy to see
    const modalStyle = showPdfButton ? { height: "415px" } : {};

    return (
      <ModalContainer
        onClick={(e) => handleClickOutsideModal(e, this.props.closeModal)}
      >
        <ModalCard className={"modal-card"} style={modalStyle}>
          <CloseButton onClick={closeModal}>Close</CloseButton>
          <h1>Share VectorSurv visualizations</h1>
          {message && <TooltipMsg>{message}</TooltipMsg>}
          <Row>
            <ButtonWithIcon color={colors["green"]} onClick={this.copyURL}>
              <Link color="white" />
              Copy link to this configured page
            </ButtonWithIcon>
          </Row>
          <Row>
            {map && (
              <ButtonWithIcon
                color={colors["light-blue"]}
                onClick={() => downloadMap(map)}
              >
                <DownloadIcon color={"white"} />
                Download Map
              </ButtonWithIcon>
            )}
            {chartEl && (
              <ButtonWithIcon
                color={colors["pink"]}
                onClick={() => downloadSVG(svgOptions)}
              >
                <DownloadIcon color={"white"} />
                Download Chart
              </ButtonWithIcon>
            )}
          </Row>

          <Row>
            <textarea value={embedCode} onChange={this.changeEmbedCode} />
            <ButtonWithIcon color={colors["gray"]} onClick={this.copyEmbed}>
              <Code color="white" />
              Copy code to embed
            </ButtonWithIcon>
          </Row>
          {showPdfButton && (
            <Row>
              <ButtonWithIcon
                color={"salmon"}
                onClick={() =>
                  downloadPDF(downloadInfo[currentMap], map, currentMap)
                }
              >
                <Link color="white" />
                PDF DOWNLOAD BUTTON <sub>BETA</sub>
              </ButtonWithIcon>
            </Row>
          )}
          <Row>
            <MapSettings onClick={openStyleModal}>Change Map Style</MapSettings>
          </Row>
        </ModalCard>
      </ModalContainer>
    );
  }
}

ShareModal.propTypes = {
  url: PropTypes.string.isRequired,
  svgOptions: PropTypes.object,
  map: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ShareModal;

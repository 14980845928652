import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { colors } from "../helpers";
import { HelpIcon } from "./icons";

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
`;

const ChartTitle = (props) => {
  return (
    <div
      style={{
        margin: "10px 0px 10px 30px",
        display: "flex",
        justifyContent: "space-between",
        color: colors["dark-blue"],
        fontSize: 20,
      }}
    >
      <div>
        {props.units} {props.preposition || "in"} {props.city}
      </div>{" "}
      {/* actions to push to the right */}
      <Actions>
        <div
          onClick={props.action}
          style={{
            cursor: "pointer",
            marginRight: 5,
            fontWeight: "light",
            fontSize: 14,
            color: colors["dark-red"],
            width: 100,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {!props.noAction && <HelpIcon color={colors["dark-red"]} />}
          {!props.noAction && (props.actionText || "Show Help")}
        </div>
      </Actions>
    </div>
  );
};

ChartTitle.propTypes = {
  city: Proptypes.string.isRequired,
  action: Proptypes.func,
  units: Proptypes.string.isRequired,
};

export default ChartTitle;

import React from "react";
import PropTypes from "prop-types";
import { colors, slugifyString } from "../helpers";
import { DropdownContainer } from "../styles";

/**
 * DiverseDropdown such that display values are different than select values
 * @param {*} props props.values such that [{ content, value, id: -1, disabled: bool, }]
 * @returns DiverseDropdown element
 */
const DiverseDropdown = (props) => {
  let { values } = props;
  if (!values || !values.length) {
    values = [
      {
        content: "No Values Available",
        value: "No Values Available",
        id: -1,
        disabled: true,
      },
    ];
  }

  const options = values.map((valueObject, index) => {
    const { content, value, id, disabled } = valueObject;
    return (
      <option
        value={value}
        key={slugifyString(value) || index}
        disabled={disabled}
        id={id || slugifyString(value) || index}
      >
        {content || value}
      </option>
    );
  });

  const dropdownStyle = { ...props.style };
  const selectId = props.id || Math.floor(Math.random() * 1000);
  if (props.loading || props.disabled) {
    dropdownStyle.color = colors["gray"];
  }
  return (
    <DropdownContainer>
      {props.label && (
        <label htmlFor={selectId} style={{ marginRight: "5px" }}>
          {props.label}
        </label>
      )}
      <div>
        <select
          id={selectId}
          onChange={props.handler}
          value={props.value}
          style={dropdownStyle}
          disabled={props.disabled || props.loading}
          multiple={!!props.multiple}
        >
          {options}
        </select>
      </div>
    </DropdownContainer>
  );
};

DiverseDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default DiverseDropdown;

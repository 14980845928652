import axios from "axios";
import { abundanceDW, getWeekNumber } from "../../helpers";

export const calculateRates = (
  intervalLength = 0,
  year,
  currentYearNumDenomData,
  prevYearNumDenomData = {},
  traps = []
) => {
  if (intervalLength === 0) {
    return calculateSingleWeekRates(currentYearNumDenomData);
  }
  const calculationTraps = traps.slice(1);
  calculationTraps.unshift("alltraps");

  const prevYear = year - 1;

  const currentYearNumberOfWeeks = Object.keys(abundanceDW[year]).length;
  let prevYearNumberOfWeeks = Object.keys(abundanceDW[prevYear]).length;
  const endPrevYearFinalWeek = new Date(
    abundanceDW[prevYear][prevYearNumberOfWeeks - 1]
  );
  if (
    endPrevYearFinalWeek.getMonth() !== 11 ||
    endPrevYearFinalWeek.getDate() !== 24
  ) {
    // The only years that don't have a hanging empty half week at the end are those that end on Saturday, Dec 31
    prevYearNumberOfWeeks -= 1;
  }
  // endingWeek to avoid extra calculations, for instance if it's currently January we're unlikely to need calculations for December
  let endingWeek = currentYearNumberOfWeeks;
  if (year === new Date().getFullYear()) {
    // if it's the current year, only calculate to the current week
    endingWeek = getWeekNumber(new Date());
  }

  const agencies = Object.keys({
    ...currentYearNumDenomData,
    ...prevYearNumDenomData,
  });

  const memo = {};
  const returnObject = {};
  agencies.forEach((agency) => {
    // we know every agency in the list has data
    returnObject[agency] = {};
    memo[agency] = {};
    for (
      let week = 1 - intervalLength - 1; // this to give a complete memo prior to week 0
      week <= endingWeek;
      week += 1
    ) {
      memo[agency][week] = {};
      // calculationPops.forEach((pop) => {
      //   memo[agency][week][pop] = {};

      calculationTraps.forEach((trap) => {
        memo[agency][week][trap] = {};
        let sumAllSpeciesInWeekAndTrapType = 0;
        const speciesPercentages = {};
        let newSpeciesList = [];
        // in this way we won't iterate through all species anymore
        let numDenomTrapsToScrapeForSpecies = {};
        if (week < 1) {
          numDenomTrapsToScrapeForSpecies =
            prevYearNumDenomData?.[agency]?.[week + prevYearNumberOfWeeks]?.[
              trap
            ];
        } else {
          numDenomTrapsToScrapeForSpecies =
            currentYearNumDenomData?.[agency]?.[week]?.[trap];
        }
        newSpeciesList = [].concat(
          Object.keys({
            ...memo?.[agency]?.[week - 1]?.[trap],
            ...numDenomTrapsToScrapeForSpecies,
          })
        );

        newSpeciesList.forEach((specie, speciesIndex) => {
          let sum = 0;
          let nights = 0;
          if (!memo?.[agency]?.[week - 1]?.[trap]?.[specie]) {
            // If there's no memo for the previous week/trap/species
            let currentWeek = week;
            if (currentWeek < 1) {
              currentWeek += prevYearNumberOfWeeks;
              sum +=
                prevYearNumDenomData?.[agency]?.[currentWeek]?.[trap]?.[
                  specie
                ]?.[0] || 0;
              nights +=
                prevYearNumDenomData?.[agency]?.[currentWeek]?.[trap]?.[
                  specie
                ]?.[1] || 0;
            } else {
              sum +=
                currentYearNumDenomData?.[agency]?.[currentWeek]?.[trap]?.[
                  specie
                ]?.[0] || 0;
              nights +=
                currentYearNumDenomData?.[agency]?.[currentWeek]?.[trap]?.[
                  specie
                ]?.[1] || 0;
              if (speciesIndex === 0) {
                // if this case is in this year, it's going to get graphed and thus needs the sum for all species graphing
                sumAllSpeciesInWeekAndTrapType +=
                  currentYearNumDenomData?.[agency]?.[currentWeek]?.[trap]?.[
                    "All Species"
                  ]?.[0];
              }
            }
          } else {
            // we can use the memo
            sum += memo?.[agency]?.[week - 1]?.[trap]?.[specie]?.[0] || 0;
            nights += memo?.[agency]?.[week - 1]?.[trap]?.[specie]?.[1];
            // add the current week from this year's data
            if (week < 1) {
              sum +=
                prevYearNumDenomData?.[agency]?.[
                  week + prevYearNumberOfWeeks
                ]?.[trap]?.[specie]?.[0] || 0;
              nights +=
                prevYearNumDenomData?.[agency]?.[
                  week + prevYearNumberOfWeeks
                ]?.[trap]?.[specie]?.[1] || 0;
            } else {
              // can use current year
              sum +=
                currentYearNumDenomData?.[agency]?.[week]?.[trap]?.[
                  specie
                ]?.[0] || 0;
              nights +=
                currentYearNumDenomData?.[agency]?.[week]?.[trap]?.[
                  specie
                ]?.[1] || 0;
            }

            // use the memo to set the sumAllSpeciesInWeekAndTrapType. Only do when it's the first species in the week to ensure doing once.
            if (speciesIndex === 0) {
              // Start with memo from previous week
              sumAllSpeciesInWeekAndTrapType +=
                memo?.[agency]?.[week - 1]?.[trap]?.["All Species"]?.[0] || 0;
              // Add All Species total from current week
              sumAllSpeciesInWeekAndTrapType +=
                currentYearNumDenomData?.[agency]?.[week]?.[trap]?.[
                  "All Species"
                ]?.[0] || 0;
            }

            if (week > 0) {
              // everything in this block only matters if we're in the current year
              let fallingOffWeek = week - intervalLength - 1; // the last week before the new interval starts will fall off
              if (fallingOffWeek < 1) {
                // the week falling off is in the previous year
                fallingOffWeek += prevYearNumberOfWeeks;
                sum -=
                  prevYearNumDenomData?.[agency]?.[fallingOffWeek]?.[trap]?.[
                    specie
                  ]?.[0] || 0;
                nights -=
                  prevYearNumDenomData?.[agency]?.[fallingOffWeek]?.[trap]?.[
                    specie
                  ]?.[1] || 0;
                if (speciesIndex === 0) {
                  // If it's the first species, subtract the All Species total from the falling off week
                  sumAllSpeciesInWeekAndTrapType -=
                    prevYearNumDenomData?.[agency]?.[fallingOffWeek]?.[trap]?.[
                      "All Species"
                    ]?.[0] || 0;
                }
              } else {
                // the week falling off is in this year
                sum -=
                  currentYearNumDenomData?.[agency]?.[fallingOffWeek]?.[trap]?.[
                    specie
                  ]?.[0] || 0;
                nights -=
                  currentYearNumDenomData?.[agency]?.[fallingOffWeek]?.[trap]?.[
                    specie
                  ]?.[1] || 0;
                if (speciesIndex === 0) {
                  // If it's the first species, subtract the All Species total from the falling off week
                  sumAllSpeciesInWeekAndTrapType -=
                    currentYearNumDenomData?.[agency]?.[fallingOffWeek]?.[
                      trap
                    ]?.["All Species"]?.[0] || 0;
                }
              }
            }
          }
          // now have the correct sums
          // memoize those sums
          memo[agency][week][trap][specie] = [];
          memo[agency][week][trap][specie][0] = sum;
          memo[agency][week][trap][specie][1] = nights;
          // then get what we're really after, the rates
          if (nights && week > 0) {
            // Only add this if there's surveillance at all, otherwise it would be green on the map
            if (!returnObject[agency][week]) {
              returnObject[agency][week] = {};
            }
            if (!returnObject[agency][week]) {
              returnObject[agency][week] = {};
            }
            if (!returnObject?.[agency]?.[week]?.[trap]) {
              returnObject[agency][week][trap] = {};
            }
            returnObject[agency][week][trap][specie] = +(sum / nights).toFixed(
              2
            );
            if (sum) {
              // this only matters if it isn't 0, only used if not 0.
              const speciePercentOfAll = sum / sumAllSpeciesInWeekAndTrapType;
              speciesPercentages[specie] = speciePercentOfAll.toFixed(2);
            }
          }
        });

        if (returnObject?.[agency]?.[week]?.[trap])
          returnObject[agency][week][trap]["speciesPercentages"] =
            speciesPercentages;
      });
    }
  });

  return returnObject;
};

// this is a different function for 2 reasons:
// 1. It is the base case (for now)
// 2. It is more optimizable - no danger of missing a species from the previous week
export const calculateSingleWeekRates = (yearNumDenomData) => {
  let newData = JSON.parse(JSON.stringify(yearNumDenomData));
  // translate the sum and nights to collections per trap night
  Object.keys(newData).forEach((agency) => {
    const agencyObj = newData[agency];
    Object.keys(agencyObj).forEach((week) => {
      const weekObj = agencyObj[week];
      Object.keys(weekObj).forEach((trap) => {
        const trapObj = weekObj[trap];
        // To add another complex, help it here
        const allSpeciesSum = newData[agency][week][trap]["All Species"][0];
        const speciesPercentages = {};
        Object.keys(trapObj).forEach((specie) => {
          const sum = +newData[agency][week][trap][specie][0];
          const nights = +newData[agency][week][trap][specie][1];
          const speciePercentOfAll = sum / allSpeciesSum;
          speciesPercentages[specie] = speciePercentOfAll.toFixed(2);
          newData[agency][week][trap][specie] = +(sum / nights).toFixed(2);
        });
        newData[agency][week][trap]["speciesPercentages"] = speciesPercentages;
      });
    });
  });
  return newData;
};

export const getStateAbundanceRates = async (
  year,
  usState,
  trap,
  authQueryString
) => {
  // const url = `http://localhost:8011/v2/pacific/stateYearTrapRates/${year}/${usState}/${trap}/${authQueryString}`;
  const url = `https://mathew.vectorsurv.org/v2/pacific/stateYearTrapRates/${year}/${usState}/${trap}/${authQueryString}`;
  let rates = [];
  try {
    const response = await axios.get(url);
    rates = response.data;
    return rates;
  } catch (err) {
    console.error(err);
    return rates;
  }
};

export const getAgencyAbundanceRates = async (
  year,
  agencyCode,
  trap,
  authQueryString
) => {
  // const url = `http://localhost:8011/v2/pacific/agencyYearTrapRates/${year}/${agencyCode}/${trap}/${authQueryString}`;
  const url = `https://mathew.vectorsurv.org/v2/pacific/agencyYearTrapRates/${year}/${agencyCode}/${trap}/${authQueryString}`;
  let rates = [];
  try {
    const response = await axios.get(url);
    rates = response.data;
    return rates;
  } catch (err) {
    console.error(err);
    return rates;
  }
};

export const getZoneAbundanceRates = async (
  year,
  zone,
  trap,
  authQueryString
) => {
  // const url = `http://localhost:8011/v2/pacific/zoneYearTrapRates/${year}/${zone}/${trap}/${authQueryString}`;
  const url = `https://mathew.vectorsurv.org/v2/pacific/zoneYearTrapRates/${year}/${zone}/${trap}/${authQueryString}`;
  let rates = [];
  try {
    const response = await axios.get(url);
    rates = response.data;
    return rates;
  } catch (err) {
    console.error(err);
    return rates;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { TutorialCard } from "../styles";
import DiverseDropdown from "./DiverseDropdown";
import { validStateAbbreviations } from "../helpers";

const selectorStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginRight: 10,
  marginTop: 10,
  textAlign: "right",
};

export default function Tutorial(props) {
  const { title, description, instructions, passedStyles } = props;
  const items = instructions.map((item) => {
    return (
      <li key={item.key || item.text}>
        {item.icon}
        {item.text}
      </li>
    );
  });
  const optionalDropdown = props.updateUsState ? (
    <div style={selectorStyle}>
      My state:
      <DiverseDropdown
        id="change-usState"
        handler={(e) => props.updateUsState(e.target.value)}
        values={validStateAbbreviations.map((value) => {
          return { value };
        })}
        value={props.usState}
        style={{ width: 60 }}
        loading={props.loading}
      />
    </div>
  ) : (
    <div />
  );
  return (
    <TutorialCard style={passedStyles}>
      <div className="tutorial-title-container">
        <div style={{ marginLeft: "25px" }}>
          <div className="tutorial-main-title">How Do I Use This?</div>
          <div className="tutorial-sub-title">{title}</div>
        </div>
        {optionalDropdown}
      </div>
      <div>
        <div className="tutorial-list-header">{description}</div>
        <ul>{items}</ul>
      </div>
    </TutorialCard>
  );
}

Tutorial.propTypes = {
  title: PropTypes.string.isRequired,
  // description can sometimes be an array, as React.children, if there are things like <i>tags
  // description: PropTypes.string.isRequired || PropTypes.array.isRequired,
  instructions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// 20190508102040
// http://localhost:8011/v2/diseaseweeks
export const diseaseWeeks = [
  {
    disease_year: 1945,
    disease_week: 1,
    start_date: "1944-12-31T07:00:00.000Z",
    end_date: "1945-01-06T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 2,
    start_date: "1945-01-07T07:00:00.000Z",
    end_date: "1945-01-13T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 3,
    start_date: "1945-01-14T07:00:00.000Z",
    end_date: "1945-01-20T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 4,
    start_date: "1945-01-21T07:00:00.000Z",
    end_date: "1945-01-27T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 5,
    start_date: "1945-01-28T07:00:00.000Z",
    end_date: "1945-02-03T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 6,
    start_date: "1945-02-04T07:00:00.000Z",
    end_date: "1945-02-10T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 7,
    start_date: "1945-02-11T07:00:00.000Z",
    end_date: "1945-02-17T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 8,
    start_date: "1945-02-18T07:00:00.000Z",
    end_date: "1945-02-24T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 9,
    start_date: "1945-02-25T07:00:00.000Z",
    end_date: "1945-03-03T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 10,
    start_date: "1945-03-04T07:00:00.000Z",
    end_date: "1945-03-10T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 11,
    start_date: "1945-03-11T07:00:00.000Z",
    end_date: "1945-03-17T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 12,
    start_date: "1945-03-18T07:00:00.000Z",
    end_date: "1945-03-24T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 13,
    start_date: "1945-03-25T07:00:00.000Z",
    end_date: "1945-03-31T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 14,
    start_date: "1945-04-01T07:00:00.000Z",
    end_date: "1945-04-07T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 15,
    start_date: "1945-04-08T07:00:00.000Z",
    end_date: "1945-04-14T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 16,
    start_date: "1945-04-15T07:00:00.000Z",
    end_date: "1945-04-21T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 17,
    start_date: "1945-04-22T07:00:00.000Z",
    end_date: "1945-04-28T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 18,
    start_date: "1945-04-29T07:00:00.000Z",
    end_date: "1945-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 19,
    start_date: "1945-05-06T07:00:00.000Z",
    end_date: "1945-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 20,
    start_date: "1945-05-13T07:00:00.000Z",
    end_date: "1945-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 21,
    start_date: "1945-05-20T07:00:00.000Z",
    end_date: "1945-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 22,
    start_date: "1945-05-27T07:00:00.000Z",
    end_date: "1945-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 23,
    start_date: "1945-06-03T07:00:00.000Z",
    end_date: "1945-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 24,
    start_date: "1945-06-10T07:00:00.000Z",
    end_date: "1945-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 25,
    start_date: "1945-06-17T07:00:00.000Z",
    end_date: "1945-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 26,
    start_date: "1945-06-24T07:00:00.000Z",
    end_date: "1945-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 27,
    start_date: "1945-07-01T07:00:00.000Z",
    end_date: "1945-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 28,
    start_date: "1945-07-08T07:00:00.000Z",
    end_date: "1945-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 29,
    start_date: "1945-07-15T07:00:00.000Z",
    end_date: "1945-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 30,
    start_date: "1945-07-22T07:00:00.000Z",
    end_date: "1945-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 31,
    start_date: "1945-07-29T07:00:00.000Z",
    end_date: "1945-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 32,
    start_date: "1945-08-05T07:00:00.000Z",
    end_date: "1945-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 33,
    start_date: "1945-08-12T07:00:00.000Z",
    end_date: "1945-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 34,
    start_date: "1945-08-19T07:00:00.000Z",
    end_date: "1945-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 35,
    start_date: "1945-08-26T07:00:00.000Z",
    end_date: "1945-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 36,
    start_date: "1945-09-02T07:00:00.000Z",
    end_date: "1945-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 37,
    start_date: "1945-09-09T07:00:00.000Z",
    end_date: "1945-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 38,
    start_date: "1945-09-16T07:00:00.000Z",
    end_date: "1945-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 39,
    start_date: "1945-09-23T07:00:00.000Z",
    end_date: "1945-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 40,
    start_date: "1945-09-30T07:00:00.000Z",
    end_date: "1945-10-06T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 41,
    start_date: "1945-10-07T08:00:00.000Z",
    end_date: "1945-10-13T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 42,
    start_date: "1945-10-14T08:00:00.000Z",
    end_date: "1945-10-20T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 43,
    start_date: "1945-10-21T08:00:00.000Z",
    end_date: "1945-10-27T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 44,
    start_date: "1945-10-28T08:00:00.000Z",
    end_date: "1945-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 45,
    start_date: "1945-11-04T08:00:00.000Z",
    end_date: "1945-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 46,
    start_date: "1945-11-11T08:00:00.000Z",
    end_date: "1945-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 47,
    start_date: "1945-11-18T08:00:00.000Z",
    end_date: "1945-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 48,
    start_date: "1945-11-25T08:00:00.000Z",
    end_date: "1945-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 49,
    start_date: "1945-12-02T08:00:00.000Z",
    end_date: "1945-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 50,
    start_date: "1945-12-09T08:00:00.000Z",
    end_date: "1945-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 51,
    start_date: "1945-12-16T08:00:00.000Z",
    end_date: "1945-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 52,
    start_date: "1945-12-23T08:00:00.000Z",
    end_date: "1945-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1945,
    disease_week: 53,
    start_date: "1945-12-30T08:00:00.000Z",
    end_date: "1946-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 1,
    start_date: "1945-12-30T08:00:00.000Z",
    end_date: "1946-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 2,
    start_date: "1946-01-06T08:00:00.000Z",
    end_date: "1946-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 3,
    start_date: "1946-01-13T08:00:00.000Z",
    end_date: "1946-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 4,
    start_date: "1946-01-20T08:00:00.000Z",
    end_date: "1946-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 5,
    start_date: "1946-01-27T08:00:00.000Z",
    end_date: "1946-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 6,
    start_date: "1946-02-03T08:00:00.000Z",
    end_date: "1946-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 7,
    start_date: "1946-02-10T08:00:00.000Z",
    end_date: "1946-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 8,
    start_date: "1946-02-17T08:00:00.000Z",
    end_date: "1946-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 9,
    start_date: "1946-02-24T08:00:00.000Z",
    end_date: "1946-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 10,
    start_date: "1946-03-03T08:00:00.000Z",
    end_date: "1946-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 11,
    start_date: "1946-03-10T08:00:00.000Z",
    end_date: "1946-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 12,
    start_date: "1946-03-17T08:00:00.000Z",
    end_date: "1946-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 13,
    start_date: "1946-03-24T08:00:00.000Z",
    end_date: "1946-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 14,
    start_date: "1946-03-31T08:00:00.000Z",
    end_date: "1946-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 15,
    start_date: "1946-04-07T08:00:00.000Z",
    end_date: "1946-04-13T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 16,
    start_date: "1946-04-14T08:00:00.000Z",
    end_date: "1946-04-20T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 17,
    start_date: "1946-04-21T08:00:00.000Z",
    end_date: "1946-04-27T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 18,
    start_date: "1946-04-28T08:00:00.000Z",
    end_date: "1946-05-04T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 19,
    start_date: "1946-05-05T08:00:00.000Z",
    end_date: "1946-05-11T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 20,
    start_date: "1946-05-12T08:00:00.000Z",
    end_date: "1946-05-18T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 21,
    start_date: "1946-05-19T08:00:00.000Z",
    end_date: "1946-05-25T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 22,
    start_date: "1946-05-26T08:00:00.000Z",
    end_date: "1946-06-01T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 23,
    start_date: "1946-06-02T08:00:00.000Z",
    end_date: "1946-06-08T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 24,
    start_date: "1946-06-09T08:00:00.000Z",
    end_date: "1946-06-15T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 25,
    start_date: "1946-06-16T08:00:00.000Z",
    end_date: "1946-06-22T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 26,
    start_date: "1946-06-23T08:00:00.000Z",
    end_date: "1946-06-29T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 27,
    start_date: "1946-06-30T08:00:00.000Z",
    end_date: "1946-07-06T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 28,
    start_date: "1946-07-07T08:00:00.000Z",
    end_date: "1946-07-13T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 29,
    start_date: "1946-07-14T08:00:00.000Z",
    end_date: "1946-07-20T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 30,
    start_date: "1946-07-21T08:00:00.000Z",
    end_date: "1946-07-27T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 31,
    start_date: "1946-07-28T08:00:00.000Z",
    end_date: "1946-08-03T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 32,
    start_date: "1946-08-04T08:00:00.000Z",
    end_date: "1946-08-10T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 33,
    start_date: "1946-08-11T08:00:00.000Z",
    end_date: "1946-08-17T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 34,
    start_date: "1946-08-18T08:00:00.000Z",
    end_date: "1946-08-24T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 35,
    start_date: "1946-08-25T08:00:00.000Z",
    end_date: "1946-08-31T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 36,
    start_date: "1946-09-01T08:00:00.000Z",
    end_date: "1946-09-07T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 37,
    start_date: "1946-09-08T08:00:00.000Z",
    end_date: "1946-09-14T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 38,
    start_date: "1946-09-15T08:00:00.000Z",
    end_date: "1946-09-21T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 39,
    start_date: "1946-09-22T08:00:00.000Z",
    end_date: "1946-09-28T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 40,
    start_date: "1946-09-29T08:00:00.000Z",
    end_date: "1946-10-05T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 41,
    start_date: "1946-10-06T08:00:00.000Z",
    end_date: "1946-10-12T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 42,
    start_date: "1946-10-13T08:00:00.000Z",
    end_date: "1946-10-19T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 43,
    start_date: "1946-10-20T08:00:00.000Z",
    end_date: "1946-10-26T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 44,
    start_date: "1946-10-27T08:00:00.000Z",
    end_date: "1946-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 45,
    start_date: "1946-11-03T08:00:00.000Z",
    end_date: "1946-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 46,
    start_date: "1946-11-10T08:00:00.000Z",
    end_date: "1946-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 47,
    start_date: "1946-11-17T08:00:00.000Z",
    end_date: "1946-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 48,
    start_date: "1946-11-24T08:00:00.000Z",
    end_date: "1946-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 49,
    start_date: "1946-12-01T08:00:00.000Z",
    end_date: "1946-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 50,
    start_date: "1946-12-08T08:00:00.000Z",
    end_date: "1946-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 51,
    start_date: "1946-12-15T08:00:00.000Z",
    end_date: "1946-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 52,
    start_date: "1946-12-22T08:00:00.000Z",
    end_date: "1946-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1946,
    disease_week: 53,
    start_date: "1946-12-29T08:00:00.000Z",
    end_date: "1947-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 1,
    start_date: "1946-12-29T08:00:00.000Z",
    end_date: "1947-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 2,
    start_date: "1947-01-05T08:00:00.000Z",
    end_date: "1947-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 3,
    start_date: "1947-01-12T08:00:00.000Z",
    end_date: "1947-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 4,
    start_date: "1947-01-19T08:00:00.000Z",
    end_date: "1947-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 5,
    start_date: "1947-01-26T08:00:00.000Z",
    end_date: "1947-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 6,
    start_date: "1947-02-02T08:00:00.000Z",
    end_date: "1947-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 7,
    start_date: "1947-02-09T08:00:00.000Z",
    end_date: "1947-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 8,
    start_date: "1947-02-16T08:00:00.000Z",
    end_date: "1947-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 9,
    start_date: "1947-02-23T08:00:00.000Z",
    end_date: "1947-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 10,
    start_date: "1947-03-02T08:00:00.000Z",
    end_date: "1947-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 11,
    start_date: "1947-03-09T08:00:00.000Z",
    end_date: "1947-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 12,
    start_date: "1947-03-16T08:00:00.000Z",
    end_date: "1947-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 13,
    start_date: "1947-03-23T08:00:00.000Z",
    end_date: "1947-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 14,
    start_date: "1947-03-30T08:00:00.000Z",
    end_date: "1947-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 15,
    start_date: "1947-04-06T08:00:00.000Z",
    end_date: "1947-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 16,
    start_date: "1947-04-13T08:00:00.000Z",
    end_date: "1947-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 17,
    start_date: "1947-04-20T08:00:00.000Z",
    end_date: "1947-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 18,
    start_date: "1947-04-27T08:00:00.000Z",
    end_date: "1947-05-03T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 19,
    start_date: "1947-05-04T08:00:00.000Z",
    end_date: "1947-05-10T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 20,
    start_date: "1947-05-11T08:00:00.000Z",
    end_date: "1947-05-17T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 21,
    start_date: "1947-05-18T08:00:00.000Z",
    end_date: "1947-05-24T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 22,
    start_date: "1947-05-25T08:00:00.000Z",
    end_date: "1947-05-31T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 23,
    start_date: "1947-06-01T08:00:00.000Z",
    end_date: "1947-06-07T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 24,
    start_date: "1947-06-08T08:00:00.000Z",
    end_date: "1947-06-14T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 25,
    start_date: "1947-06-15T08:00:00.000Z",
    end_date: "1947-06-21T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 26,
    start_date: "1947-06-22T08:00:00.000Z",
    end_date: "1947-06-28T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 27,
    start_date: "1947-06-29T08:00:00.000Z",
    end_date: "1947-07-05T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 28,
    start_date: "1947-07-06T08:00:00.000Z",
    end_date: "1947-07-12T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 29,
    start_date: "1947-07-13T08:00:00.000Z",
    end_date: "1947-07-19T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 30,
    start_date: "1947-07-20T08:00:00.000Z",
    end_date: "1947-07-26T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 31,
    start_date: "1947-07-27T08:00:00.000Z",
    end_date: "1947-08-02T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 32,
    start_date: "1947-08-03T08:00:00.000Z",
    end_date: "1947-08-09T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 33,
    start_date: "1947-08-10T08:00:00.000Z",
    end_date: "1947-08-16T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 34,
    start_date: "1947-08-17T08:00:00.000Z",
    end_date: "1947-08-23T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 35,
    start_date: "1947-08-24T08:00:00.000Z",
    end_date: "1947-08-30T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 36,
    start_date: "1947-08-31T08:00:00.000Z",
    end_date: "1947-09-06T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 37,
    start_date: "1947-09-07T08:00:00.000Z",
    end_date: "1947-09-13T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 38,
    start_date: "1947-09-14T08:00:00.000Z",
    end_date: "1947-09-20T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 39,
    start_date: "1947-09-21T08:00:00.000Z",
    end_date: "1947-09-27T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 40,
    start_date: "1947-09-28T08:00:00.000Z",
    end_date: "1947-10-04T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 41,
    start_date: "1947-10-05T08:00:00.000Z",
    end_date: "1947-10-11T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 42,
    start_date: "1947-10-12T08:00:00.000Z",
    end_date: "1947-10-18T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 43,
    start_date: "1947-10-19T08:00:00.000Z",
    end_date: "1947-10-25T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 44,
    start_date: "1947-10-26T08:00:00.000Z",
    end_date: "1947-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 45,
    start_date: "1947-11-02T08:00:00.000Z",
    end_date: "1947-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 46,
    start_date: "1947-11-09T08:00:00.000Z",
    end_date: "1947-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 47,
    start_date: "1947-11-16T08:00:00.000Z",
    end_date: "1947-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 48,
    start_date: "1947-11-23T08:00:00.000Z",
    end_date: "1947-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 49,
    start_date: "1947-11-30T08:00:00.000Z",
    end_date: "1947-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 50,
    start_date: "1947-12-07T08:00:00.000Z",
    end_date: "1947-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 51,
    start_date: "1947-12-14T08:00:00.000Z",
    end_date: "1947-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 52,
    start_date: "1947-12-21T08:00:00.000Z",
    end_date: "1947-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1947,
    disease_week: 53,
    start_date: "1947-12-28T08:00:00.000Z",
    end_date: "1948-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 1,
    start_date: "1950-01-01T08:00:00.000Z",
    end_date: "1950-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 2,
    start_date: "1950-01-08T08:00:00.000Z",
    end_date: "1950-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 3,
    start_date: "1950-01-15T08:00:00.000Z",
    end_date: "1950-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 4,
    start_date: "1950-01-22T08:00:00.000Z",
    end_date: "1950-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 5,
    start_date: "1950-01-29T08:00:00.000Z",
    end_date: "1950-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 6,
    start_date: "1950-02-05T08:00:00.000Z",
    end_date: "1950-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 7,
    start_date: "1950-02-12T08:00:00.000Z",
    end_date: "1950-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 8,
    start_date: "1950-02-19T08:00:00.000Z",
    end_date: "1950-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 9,
    start_date: "1950-02-26T08:00:00.000Z",
    end_date: "1950-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 10,
    start_date: "1950-03-05T08:00:00.000Z",
    end_date: "1950-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 11,
    start_date: "1950-03-12T08:00:00.000Z",
    end_date: "1950-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 12,
    start_date: "1950-03-19T08:00:00.000Z",
    end_date: "1950-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 13,
    start_date: "1950-03-26T08:00:00.000Z",
    end_date: "1950-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 14,
    start_date: "1950-04-02T08:00:00.000Z",
    end_date: "1950-04-08T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 15,
    start_date: "1950-04-09T08:00:00.000Z",
    end_date: "1950-04-15T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 16,
    start_date: "1950-04-16T08:00:00.000Z",
    end_date: "1950-04-22T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 17,
    start_date: "1950-04-23T08:00:00.000Z",
    end_date: "1950-04-29T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 18,
    start_date: "1950-04-30T08:00:00.000Z",
    end_date: "1950-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 19,
    start_date: "1950-05-07T07:00:00.000Z",
    end_date: "1950-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 20,
    start_date: "1950-05-14T07:00:00.000Z",
    end_date: "1950-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 21,
    start_date: "1950-05-21T07:00:00.000Z",
    end_date: "1950-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 22,
    start_date: "1950-05-28T07:00:00.000Z",
    end_date: "1950-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 23,
    start_date: "1950-06-04T07:00:00.000Z",
    end_date: "1950-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 24,
    start_date: "1950-06-11T07:00:00.000Z",
    end_date: "1950-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 25,
    start_date: "1950-06-18T07:00:00.000Z",
    end_date: "1950-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 26,
    start_date: "1950-06-25T07:00:00.000Z",
    end_date: "1950-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 27,
    start_date: "1950-07-02T07:00:00.000Z",
    end_date: "1950-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 28,
    start_date: "1950-07-09T07:00:00.000Z",
    end_date: "1950-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 29,
    start_date: "1950-07-16T07:00:00.000Z",
    end_date: "1950-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 30,
    start_date: "1950-07-23T07:00:00.000Z",
    end_date: "1950-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 31,
    start_date: "1950-07-30T07:00:00.000Z",
    end_date: "1950-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 32,
    start_date: "1950-08-06T07:00:00.000Z",
    end_date: "1950-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 33,
    start_date: "1950-08-13T07:00:00.000Z",
    end_date: "1950-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 34,
    start_date: "1950-08-20T07:00:00.000Z",
    end_date: "1950-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 35,
    start_date: "1950-08-27T07:00:00.000Z",
    end_date: "1950-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 36,
    start_date: "1950-09-03T07:00:00.000Z",
    end_date: "1950-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 37,
    start_date: "1950-09-10T07:00:00.000Z",
    end_date: "1950-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 38,
    start_date: "1950-09-17T07:00:00.000Z",
    end_date: "1950-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 39,
    start_date: "1950-09-24T07:00:00.000Z",
    end_date: "1950-09-30T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 40,
    start_date: "1950-10-01T08:00:00.000Z",
    end_date: "1950-10-07T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 41,
    start_date: "1950-10-08T08:00:00.000Z",
    end_date: "1950-10-14T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 42,
    start_date: "1950-10-15T08:00:00.000Z",
    end_date: "1950-10-21T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 43,
    start_date: "1950-10-22T08:00:00.000Z",
    end_date: "1950-10-28T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 44,
    start_date: "1950-10-29T08:00:00.000Z",
    end_date: "1950-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 45,
    start_date: "1950-11-05T08:00:00.000Z",
    end_date: "1950-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 46,
    start_date: "1950-11-12T08:00:00.000Z",
    end_date: "1950-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 47,
    start_date: "1950-11-19T08:00:00.000Z",
    end_date: "1950-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 48,
    start_date: "1950-11-26T08:00:00.000Z",
    end_date: "1950-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 49,
    start_date: "1950-12-03T08:00:00.000Z",
    end_date: "1950-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 50,
    start_date: "1950-12-10T08:00:00.000Z",
    end_date: "1950-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 51,
    start_date: "1950-12-17T08:00:00.000Z",
    end_date: "1950-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 52,
    start_date: "1950-12-24T08:00:00.000Z",
    end_date: "1950-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1950,
    disease_week: 53,
    start_date: "1950-12-31T08:00:00.000Z",
    end_date: "1951-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 1,
    start_date: "1950-12-31T08:00:00.000Z",
    end_date: "1951-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 2,
    start_date: "1951-01-07T08:00:00.000Z",
    end_date: "1951-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 3,
    start_date: "1951-01-14T08:00:00.000Z",
    end_date: "1951-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 4,
    start_date: "1951-01-21T08:00:00.000Z",
    end_date: "1951-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 5,
    start_date: "1951-01-28T08:00:00.000Z",
    end_date: "1951-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 6,
    start_date: "1951-02-04T08:00:00.000Z",
    end_date: "1951-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 7,
    start_date: "1951-02-11T08:00:00.000Z",
    end_date: "1951-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 8,
    start_date: "1951-02-18T08:00:00.000Z",
    end_date: "1951-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 9,
    start_date: "1951-02-25T08:00:00.000Z",
    end_date: "1951-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 10,
    start_date: "1951-03-04T08:00:00.000Z",
    end_date: "1951-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 11,
    start_date: "1951-03-11T08:00:00.000Z",
    end_date: "1951-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 12,
    start_date: "1951-03-18T08:00:00.000Z",
    end_date: "1951-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 13,
    start_date: "1951-03-25T08:00:00.000Z",
    end_date: "1951-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 14,
    start_date: "1951-04-01T08:00:00.000Z",
    end_date: "1951-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 15,
    start_date: "1951-04-08T08:00:00.000Z",
    end_date: "1951-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 16,
    start_date: "1951-04-15T08:00:00.000Z",
    end_date: "1951-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 17,
    start_date: "1951-04-22T08:00:00.000Z",
    end_date: "1951-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 18,
    start_date: "1951-04-29T08:00:00.000Z",
    end_date: "1951-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 19,
    start_date: "1951-05-06T07:00:00.000Z",
    end_date: "1951-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 20,
    start_date: "1951-05-13T07:00:00.000Z",
    end_date: "1951-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 21,
    start_date: "1951-05-20T07:00:00.000Z",
    end_date: "1951-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 22,
    start_date: "1951-05-27T07:00:00.000Z",
    end_date: "1951-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 23,
    start_date: "1951-06-03T07:00:00.000Z",
    end_date: "1951-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 24,
    start_date: "1951-06-10T07:00:00.000Z",
    end_date: "1951-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 25,
    start_date: "1951-06-17T07:00:00.000Z",
    end_date: "1951-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 26,
    start_date: "1951-06-24T07:00:00.000Z",
    end_date: "1951-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 27,
    start_date: "1951-07-01T07:00:00.000Z",
    end_date: "1951-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 28,
    start_date: "1951-07-08T07:00:00.000Z",
    end_date: "1951-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 29,
    start_date: "1951-07-15T07:00:00.000Z",
    end_date: "1951-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 30,
    start_date: "1951-07-22T07:00:00.000Z",
    end_date: "1951-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 31,
    start_date: "1951-07-29T07:00:00.000Z",
    end_date: "1951-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 32,
    start_date: "1951-08-05T07:00:00.000Z",
    end_date: "1951-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 33,
    start_date: "1951-08-12T07:00:00.000Z",
    end_date: "1951-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 34,
    start_date: "1951-08-19T07:00:00.000Z",
    end_date: "1951-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 35,
    start_date: "1951-08-26T07:00:00.000Z",
    end_date: "1951-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 36,
    start_date: "1951-09-02T07:00:00.000Z",
    end_date: "1951-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 37,
    start_date: "1951-09-09T07:00:00.000Z",
    end_date: "1951-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 38,
    start_date: "1951-09-16T07:00:00.000Z",
    end_date: "1951-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 39,
    start_date: "1951-09-23T07:00:00.000Z",
    end_date: "1951-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 40,
    start_date: "1951-09-30T07:00:00.000Z",
    end_date: "1951-10-06T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 41,
    start_date: "1951-10-07T08:00:00.000Z",
    end_date: "1951-10-13T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 42,
    start_date: "1951-10-14T08:00:00.000Z",
    end_date: "1951-10-20T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 43,
    start_date: "1951-10-21T08:00:00.000Z",
    end_date: "1951-10-27T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 44,
    start_date: "1951-10-28T08:00:00.000Z",
    end_date: "1951-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 45,
    start_date: "1951-11-04T08:00:00.000Z",
    end_date: "1951-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 46,
    start_date: "1951-11-11T08:00:00.000Z",
    end_date: "1951-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 47,
    start_date: "1951-11-18T08:00:00.000Z",
    end_date: "1951-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 48,
    start_date: "1951-11-25T08:00:00.000Z",
    end_date: "1951-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 49,
    start_date: "1951-12-02T08:00:00.000Z",
    end_date: "1951-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 50,
    start_date: "1951-12-09T08:00:00.000Z",
    end_date: "1951-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 51,
    start_date: "1951-12-16T08:00:00.000Z",
    end_date: "1951-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 52,
    start_date: "1951-12-23T08:00:00.000Z",
    end_date: "1951-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1951,
    disease_week: 53,
    start_date: "1951-12-30T08:00:00.000Z",
    end_date: "1952-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 1,
    start_date: "1951-12-30T08:00:00.000Z",
    end_date: "1952-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 2,
    start_date: "1952-01-06T08:00:00.000Z",
    end_date: "1952-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 3,
    start_date: "1952-01-13T08:00:00.000Z",
    end_date: "1952-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 4,
    start_date: "1952-01-20T08:00:00.000Z",
    end_date: "1952-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 5,
    start_date: "1952-01-27T08:00:00.000Z",
    end_date: "1952-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 6,
    start_date: "1952-02-03T08:00:00.000Z",
    end_date: "1952-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 7,
    start_date: "1952-02-10T08:00:00.000Z",
    end_date: "1952-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 8,
    start_date: "1952-02-17T08:00:00.000Z",
    end_date: "1952-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 9,
    start_date: "1952-02-24T08:00:00.000Z",
    end_date: "1952-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 10,
    start_date: "1952-03-02T08:00:00.000Z",
    end_date: "1952-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 11,
    start_date: "1952-03-09T08:00:00.000Z",
    end_date: "1952-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 12,
    start_date: "1952-03-16T08:00:00.000Z",
    end_date: "1952-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 13,
    start_date: "1952-03-23T08:00:00.000Z",
    end_date: "1952-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 14,
    start_date: "1952-03-30T08:00:00.000Z",
    end_date: "1952-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 15,
    start_date: "1952-04-06T08:00:00.000Z",
    end_date: "1952-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 16,
    start_date: "1952-04-13T08:00:00.000Z",
    end_date: "1952-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 17,
    start_date: "1952-04-20T08:00:00.000Z",
    end_date: "1952-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 18,
    start_date: "1952-04-27T08:00:00.000Z",
    end_date: "1952-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 19,
    start_date: "1952-05-04T07:00:00.000Z",
    end_date: "1952-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 20,
    start_date: "1952-05-11T07:00:00.000Z",
    end_date: "1952-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 21,
    start_date: "1952-05-18T07:00:00.000Z",
    end_date: "1952-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 22,
    start_date: "1952-05-25T07:00:00.000Z",
    end_date: "1952-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 23,
    start_date: "1952-06-01T07:00:00.000Z",
    end_date: "1952-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 24,
    start_date: "1952-06-08T07:00:00.000Z",
    end_date: "1952-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 25,
    start_date: "1952-06-15T07:00:00.000Z",
    end_date: "1952-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 26,
    start_date: "1952-06-22T07:00:00.000Z",
    end_date: "1952-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 27,
    start_date: "1952-06-29T07:00:00.000Z",
    end_date: "1952-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 28,
    start_date: "1952-07-06T07:00:00.000Z",
    end_date: "1952-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 29,
    start_date: "1952-07-13T07:00:00.000Z",
    end_date: "1952-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 30,
    start_date: "1952-07-20T07:00:00.000Z",
    end_date: "1952-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 31,
    start_date: "1952-07-27T07:00:00.000Z",
    end_date: "1952-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 32,
    start_date: "1952-08-03T07:00:00.000Z",
    end_date: "1952-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 33,
    start_date: "1952-08-10T07:00:00.000Z",
    end_date: "1952-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 34,
    start_date: "1952-08-17T07:00:00.000Z",
    end_date: "1952-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 35,
    start_date: "1952-08-24T07:00:00.000Z",
    end_date: "1952-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 36,
    start_date: "1952-08-31T07:00:00.000Z",
    end_date: "1952-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 37,
    start_date: "1952-09-07T07:00:00.000Z",
    end_date: "1952-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 38,
    start_date: "1952-09-14T07:00:00.000Z",
    end_date: "1952-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 39,
    start_date: "1952-09-21T07:00:00.000Z",
    end_date: "1952-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 40,
    start_date: "1952-09-28T07:00:00.000Z",
    end_date: "1952-10-04T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 41,
    start_date: "1952-10-05T08:00:00.000Z",
    end_date: "1952-10-11T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 42,
    start_date: "1952-10-12T08:00:00.000Z",
    end_date: "1952-10-18T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 43,
    start_date: "1952-10-19T08:00:00.000Z",
    end_date: "1952-10-25T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 44,
    start_date: "1952-10-26T08:00:00.000Z",
    end_date: "1952-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 45,
    start_date: "1952-11-02T08:00:00.000Z",
    end_date: "1952-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 46,
    start_date: "1952-11-09T08:00:00.000Z",
    end_date: "1952-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 47,
    start_date: "1952-11-16T08:00:00.000Z",
    end_date: "1952-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 48,
    start_date: "1952-11-23T08:00:00.000Z",
    end_date: "1952-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 49,
    start_date: "1952-11-30T08:00:00.000Z",
    end_date: "1952-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 50,
    start_date: "1952-12-07T08:00:00.000Z",
    end_date: "1952-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 51,
    start_date: "1952-12-14T08:00:00.000Z",
    end_date: "1952-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 52,
    start_date: "1952-12-21T08:00:00.000Z",
    end_date: "1952-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1952,
    disease_week: 53,
    start_date: "1952-12-28T08:00:00.000Z",
    end_date: "1953-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 1,
    start_date: "1956-01-01T08:00:00.000Z",
    end_date: "1956-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 2,
    start_date: "1956-01-08T08:00:00.000Z",
    end_date: "1956-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 3,
    start_date: "1956-01-15T08:00:00.000Z",
    end_date: "1956-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 4,
    start_date: "1956-01-22T08:00:00.000Z",
    end_date: "1956-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 5,
    start_date: "1956-01-29T08:00:00.000Z",
    end_date: "1956-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 6,
    start_date: "1956-02-05T08:00:00.000Z",
    end_date: "1956-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 7,
    start_date: "1956-02-12T08:00:00.000Z",
    end_date: "1956-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 8,
    start_date: "1956-02-19T08:00:00.000Z",
    end_date: "1956-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 9,
    start_date: "1956-02-26T08:00:00.000Z",
    end_date: "1956-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 10,
    start_date: "1956-03-04T08:00:00.000Z",
    end_date: "1956-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 11,
    start_date: "1956-03-11T08:00:00.000Z",
    end_date: "1956-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 12,
    start_date: "1956-03-18T08:00:00.000Z",
    end_date: "1956-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 13,
    start_date: "1956-03-25T08:00:00.000Z",
    end_date: "1956-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 14,
    start_date: "1956-04-01T08:00:00.000Z",
    end_date: "1956-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 15,
    start_date: "1956-04-08T08:00:00.000Z",
    end_date: "1956-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 16,
    start_date: "1956-04-15T08:00:00.000Z",
    end_date: "1956-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 17,
    start_date: "1956-04-22T08:00:00.000Z",
    end_date: "1956-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 18,
    start_date: "1956-04-29T08:00:00.000Z",
    end_date: "1956-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 19,
    start_date: "1956-05-06T07:00:00.000Z",
    end_date: "1956-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 20,
    start_date: "1956-05-13T07:00:00.000Z",
    end_date: "1956-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 21,
    start_date: "1956-05-20T07:00:00.000Z",
    end_date: "1956-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 22,
    start_date: "1956-05-27T07:00:00.000Z",
    end_date: "1956-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 23,
    start_date: "1956-06-03T07:00:00.000Z",
    end_date: "1956-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 24,
    start_date: "1956-06-10T07:00:00.000Z",
    end_date: "1956-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 25,
    start_date: "1956-06-17T07:00:00.000Z",
    end_date: "1956-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 26,
    start_date: "1956-06-24T07:00:00.000Z",
    end_date: "1956-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 27,
    start_date: "1956-07-01T07:00:00.000Z",
    end_date: "1956-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 28,
    start_date: "1956-07-08T07:00:00.000Z",
    end_date: "1956-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 29,
    start_date: "1956-07-15T07:00:00.000Z",
    end_date: "1956-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 30,
    start_date: "1956-07-22T07:00:00.000Z",
    end_date: "1956-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 31,
    start_date: "1956-07-29T07:00:00.000Z",
    end_date: "1956-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 32,
    start_date: "1956-08-05T07:00:00.000Z",
    end_date: "1956-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 33,
    start_date: "1956-08-12T07:00:00.000Z",
    end_date: "1956-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 34,
    start_date: "1956-08-19T07:00:00.000Z",
    end_date: "1956-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 35,
    start_date: "1956-08-26T07:00:00.000Z",
    end_date: "1956-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 36,
    start_date: "1956-09-02T07:00:00.000Z",
    end_date: "1956-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 37,
    start_date: "1956-09-09T07:00:00.000Z",
    end_date: "1956-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 38,
    start_date: "1956-09-16T07:00:00.000Z",
    end_date: "1956-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 39,
    start_date: "1956-09-23T07:00:00.000Z",
    end_date: "1956-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 40,
    start_date: "1956-09-30T07:00:00.000Z",
    end_date: "1956-10-06T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 41,
    start_date: "1956-10-07T08:00:00.000Z",
    end_date: "1956-10-13T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 42,
    start_date: "1956-10-14T08:00:00.000Z",
    end_date: "1956-10-20T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 43,
    start_date: "1956-10-21T08:00:00.000Z",
    end_date: "1956-10-27T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 44,
    start_date: "1956-10-28T08:00:00.000Z",
    end_date: "1956-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 45,
    start_date: "1956-11-04T08:00:00.000Z",
    end_date: "1956-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 46,
    start_date: "1956-11-11T08:00:00.000Z",
    end_date: "1956-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 47,
    start_date: "1956-11-18T08:00:00.000Z",
    end_date: "1956-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 48,
    start_date: "1956-11-25T08:00:00.000Z",
    end_date: "1956-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 49,
    start_date: "1956-12-02T08:00:00.000Z",
    end_date: "1956-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 50,
    start_date: "1956-12-09T08:00:00.000Z",
    end_date: "1956-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 51,
    start_date: "1956-12-16T08:00:00.000Z",
    end_date: "1956-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 52,
    start_date: "1956-12-23T08:00:00.000Z",
    end_date: "1956-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1956,
    disease_week: 53,
    start_date: "1956-12-30T08:00:00.000Z",
    end_date: "1957-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 1,
    start_date: "1956-12-30T08:00:00.000Z",
    end_date: "1957-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 2,
    start_date: "1957-01-06T08:00:00.000Z",
    end_date: "1957-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 3,
    start_date: "1957-01-13T08:00:00.000Z",
    end_date: "1957-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 4,
    start_date: "1957-01-20T08:00:00.000Z",
    end_date: "1957-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 5,
    start_date: "1957-01-27T08:00:00.000Z",
    end_date: "1957-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 6,
    start_date: "1957-02-03T08:00:00.000Z",
    end_date: "1957-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 7,
    start_date: "1957-02-10T08:00:00.000Z",
    end_date: "1957-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 8,
    start_date: "1957-02-17T08:00:00.000Z",
    end_date: "1957-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 9,
    start_date: "1957-02-24T08:00:00.000Z",
    end_date: "1957-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 10,
    start_date: "1957-03-03T08:00:00.000Z",
    end_date: "1957-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 11,
    start_date: "1957-03-10T08:00:00.000Z",
    end_date: "1957-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 12,
    start_date: "1957-03-17T08:00:00.000Z",
    end_date: "1957-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 13,
    start_date: "1957-03-24T08:00:00.000Z",
    end_date: "1957-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 14,
    start_date: "1957-03-31T08:00:00.000Z",
    end_date: "1957-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 15,
    start_date: "1957-04-07T08:00:00.000Z",
    end_date: "1957-04-13T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 16,
    start_date: "1957-04-14T08:00:00.000Z",
    end_date: "1957-04-20T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 17,
    start_date: "1957-04-21T08:00:00.000Z",
    end_date: "1957-04-27T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 18,
    start_date: "1957-04-28T08:00:00.000Z",
    end_date: "1957-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 19,
    start_date: "1957-05-05T07:00:00.000Z",
    end_date: "1957-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 20,
    start_date: "1957-05-12T07:00:00.000Z",
    end_date: "1957-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 21,
    start_date: "1957-05-19T07:00:00.000Z",
    end_date: "1957-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 22,
    start_date: "1957-05-26T07:00:00.000Z",
    end_date: "1957-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 23,
    start_date: "1957-06-02T07:00:00.000Z",
    end_date: "1957-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 24,
    start_date: "1957-06-09T07:00:00.000Z",
    end_date: "1957-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 25,
    start_date: "1957-06-16T07:00:00.000Z",
    end_date: "1957-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 26,
    start_date: "1957-06-23T07:00:00.000Z",
    end_date: "1957-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 27,
    start_date: "1957-06-30T07:00:00.000Z",
    end_date: "1957-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 28,
    start_date: "1957-07-07T07:00:00.000Z",
    end_date: "1957-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 29,
    start_date: "1957-07-14T07:00:00.000Z",
    end_date: "1957-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 30,
    start_date: "1957-07-21T07:00:00.000Z",
    end_date: "1957-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 31,
    start_date: "1957-07-28T07:00:00.000Z",
    end_date: "1957-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 32,
    start_date: "1957-08-04T07:00:00.000Z",
    end_date: "1957-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 33,
    start_date: "1957-08-11T07:00:00.000Z",
    end_date: "1957-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 34,
    start_date: "1957-08-18T07:00:00.000Z",
    end_date: "1957-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 35,
    start_date: "1957-08-25T07:00:00.000Z",
    end_date: "1957-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 36,
    start_date: "1957-09-01T07:00:00.000Z",
    end_date: "1957-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 37,
    start_date: "1957-09-08T07:00:00.000Z",
    end_date: "1957-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 38,
    start_date: "1957-09-15T07:00:00.000Z",
    end_date: "1957-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 39,
    start_date: "1957-09-22T07:00:00.000Z",
    end_date: "1957-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 40,
    start_date: "1957-09-29T07:00:00.000Z",
    end_date: "1957-10-05T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 41,
    start_date: "1957-10-06T08:00:00.000Z",
    end_date: "1957-10-12T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 42,
    start_date: "1957-10-13T08:00:00.000Z",
    end_date: "1957-10-19T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 43,
    start_date: "1957-10-20T08:00:00.000Z",
    end_date: "1957-10-26T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 44,
    start_date: "1957-10-27T08:00:00.000Z",
    end_date: "1957-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 45,
    start_date: "1957-11-03T08:00:00.000Z",
    end_date: "1957-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 46,
    start_date: "1957-11-10T08:00:00.000Z",
    end_date: "1957-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 47,
    start_date: "1957-11-17T08:00:00.000Z",
    end_date: "1957-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 48,
    start_date: "1957-11-24T08:00:00.000Z",
    end_date: "1957-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 49,
    start_date: "1957-12-01T08:00:00.000Z",
    end_date: "1957-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 50,
    start_date: "1957-12-08T08:00:00.000Z",
    end_date: "1957-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 51,
    start_date: "1957-12-15T08:00:00.000Z",
    end_date: "1957-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 52,
    start_date: "1957-12-22T08:00:00.000Z",
    end_date: "1957-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1957,
    disease_week: 53,
    start_date: "1957-12-29T08:00:00.000Z",
    end_date: "1958-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 1,
    start_date: "1957-12-29T08:00:00.000Z",
    end_date: "1958-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 2,
    start_date: "1958-01-05T08:00:00.000Z",
    end_date: "1958-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 3,
    start_date: "1958-01-12T08:00:00.000Z",
    end_date: "1958-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 4,
    start_date: "1958-01-19T08:00:00.000Z",
    end_date: "1958-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 5,
    start_date: "1958-01-26T08:00:00.000Z",
    end_date: "1958-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 6,
    start_date: "1958-02-02T08:00:00.000Z",
    end_date: "1958-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 7,
    start_date: "1958-02-09T08:00:00.000Z",
    end_date: "1958-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 8,
    start_date: "1958-02-16T08:00:00.000Z",
    end_date: "1958-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 9,
    start_date: "1958-02-23T08:00:00.000Z",
    end_date: "1958-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 10,
    start_date: "1958-03-02T08:00:00.000Z",
    end_date: "1958-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 11,
    start_date: "1958-03-09T08:00:00.000Z",
    end_date: "1958-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 12,
    start_date: "1958-03-16T08:00:00.000Z",
    end_date: "1958-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 13,
    start_date: "1958-03-23T08:00:00.000Z",
    end_date: "1958-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 14,
    start_date: "1958-03-30T08:00:00.000Z",
    end_date: "1958-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 15,
    start_date: "1958-04-06T08:00:00.000Z",
    end_date: "1958-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 16,
    start_date: "1958-04-13T08:00:00.000Z",
    end_date: "1958-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 17,
    start_date: "1958-04-20T08:00:00.000Z",
    end_date: "1958-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 18,
    start_date: "1958-04-27T08:00:00.000Z",
    end_date: "1958-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 19,
    start_date: "1958-05-04T07:00:00.000Z",
    end_date: "1958-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 20,
    start_date: "1958-05-11T07:00:00.000Z",
    end_date: "1958-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 21,
    start_date: "1958-05-18T07:00:00.000Z",
    end_date: "1958-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 22,
    start_date: "1958-05-25T07:00:00.000Z",
    end_date: "1958-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 23,
    start_date: "1958-06-01T07:00:00.000Z",
    end_date: "1958-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 24,
    start_date: "1958-06-08T07:00:00.000Z",
    end_date: "1958-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 25,
    start_date: "1958-06-15T07:00:00.000Z",
    end_date: "1958-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 26,
    start_date: "1958-06-22T07:00:00.000Z",
    end_date: "1958-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 27,
    start_date: "1958-06-29T07:00:00.000Z",
    end_date: "1958-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 28,
    start_date: "1958-07-06T07:00:00.000Z",
    end_date: "1958-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 29,
    start_date: "1958-07-13T07:00:00.000Z",
    end_date: "1958-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 30,
    start_date: "1958-07-20T07:00:00.000Z",
    end_date: "1958-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 31,
    start_date: "1958-07-27T07:00:00.000Z",
    end_date: "1958-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 32,
    start_date: "1958-08-03T07:00:00.000Z",
    end_date: "1958-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 33,
    start_date: "1958-08-10T07:00:00.000Z",
    end_date: "1958-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 34,
    start_date: "1958-08-17T07:00:00.000Z",
    end_date: "1958-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 35,
    start_date: "1958-08-24T07:00:00.000Z",
    end_date: "1958-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 36,
    start_date: "1958-08-31T07:00:00.000Z",
    end_date: "1958-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 37,
    start_date: "1958-09-07T07:00:00.000Z",
    end_date: "1958-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 38,
    start_date: "1958-09-14T07:00:00.000Z",
    end_date: "1958-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 39,
    start_date: "1958-09-21T07:00:00.000Z",
    end_date: "1958-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 40,
    start_date: "1958-09-28T07:00:00.000Z",
    end_date: "1958-10-04T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 41,
    start_date: "1958-10-05T08:00:00.000Z",
    end_date: "1958-10-11T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 42,
    start_date: "1958-10-12T08:00:00.000Z",
    end_date: "1958-10-18T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 43,
    start_date: "1958-10-19T08:00:00.000Z",
    end_date: "1958-10-25T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 44,
    start_date: "1958-10-26T08:00:00.000Z",
    end_date: "1958-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 45,
    start_date: "1958-11-02T08:00:00.000Z",
    end_date: "1958-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 46,
    start_date: "1958-11-09T08:00:00.000Z",
    end_date: "1958-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 47,
    start_date: "1958-11-16T08:00:00.000Z",
    end_date: "1958-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 48,
    start_date: "1958-11-23T08:00:00.000Z",
    end_date: "1958-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 49,
    start_date: "1958-11-30T08:00:00.000Z",
    end_date: "1958-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 50,
    start_date: "1958-12-07T08:00:00.000Z",
    end_date: "1958-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 51,
    start_date: "1958-12-14T08:00:00.000Z",
    end_date: "1958-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 52,
    start_date: "1958-12-21T08:00:00.000Z",
    end_date: "1958-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1958,
    disease_week: 53,
    start_date: "1958-12-28T08:00:00.000Z",
    end_date: "1959-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 1,
    start_date: "1961-01-01T08:00:00.000Z",
    end_date: "1961-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 2,
    start_date: "1961-01-08T08:00:00.000Z",
    end_date: "1961-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 3,
    start_date: "1961-01-15T08:00:00.000Z",
    end_date: "1961-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 4,
    start_date: "1961-01-22T08:00:00.000Z",
    end_date: "1961-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 5,
    start_date: "1961-01-29T08:00:00.000Z",
    end_date: "1961-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 6,
    start_date: "1961-02-05T08:00:00.000Z",
    end_date: "1961-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 7,
    start_date: "1961-02-12T08:00:00.000Z",
    end_date: "1961-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 8,
    start_date: "1961-02-19T08:00:00.000Z",
    end_date: "1961-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 9,
    start_date: "1961-02-26T08:00:00.000Z",
    end_date: "1961-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 10,
    start_date: "1961-03-05T08:00:00.000Z",
    end_date: "1961-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 11,
    start_date: "1961-03-12T08:00:00.000Z",
    end_date: "1961-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 12,
    start_date: "1961-03-19T08:00:00.000Z",
    end_date: "1961-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 13,
    start_date: "1961-03-26T08:00:00.000Z",
    end_date: "1961-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 14,
    start_date: "1961-04-02T08:00:00.000Z",
    end_date: "1961-04-08T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 15,
    start_date: "1961-04-09T08:00:00.000Z",
    end_date: "1961-04-15T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 16,
    start_date: "1961-04-16T08:00:00.000Z",
    end_date: "1961-04-22T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 17,
    start_date: "1961-04-23T08:00:00.000Z",
    end_date: "1961-04-29T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 18,
    start_date: "1961-04-30T08:00:00.000Z",
    end_date: "1961-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 19,
    start_date: "1961-05-07T07:00:00.000Z",
    end_date: "1961-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 20,
    start_date: "1961-05-14T07:00:00.000Z",
    end_date: "1961-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 21,
    start_date: "1961-05-21T07:00:00.000Z",
    end_date: "1961-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 22,
    start_date: "1961-05-28T07:00:00.000Z",
    end_date: "1961-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 23,
    start_date: "1961-06-04T07:00:00.000Z",
    end_date: "1961-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 24,
    start_date: "1961-06-11T07:00:00.000Z",
    end_date: "1961-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 25,
    start_date: "1961-06-18T07:00:00.000Z",
    end_date: "1961-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 26,
    start_date: "1961-06-25T07:00:00.000Z",
    end_date: "1961-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 27,
    start_date: "1961-07-02T07:00:00.000Z",
    end_date: "1961-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 28,
    start_date: "1961-07-09T07:00:00.000Z",
    end_date: "1961-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 29,
    start_date: "1961-07-16T07:00:00.000Z",
    end_date: "1961-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 30,
    start_date: "1961-07-23T07:00:00.000Z",
    end_date: "1961-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 31,
    start_date: "1961-07-30T07:00:00.000Z",
    end_date: "1961-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 32,
    start_date: "1961-08-06T07:00:00.000Z",
    end_date: "1961-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 33,
    start_date: "1961-08-13T07:00:00.000Z",
    end_date: "1961-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 34,
    start_date: "1961-08-20T07:00:00.000Z",
    end_date: "1961-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 35,
    start_date: "1961-08-27T07:00:00.000Z",
    end_date: "1961-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 36,
    start_date: "1961-09-03T07:00:00.000Z",
    end_date: "1961-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 37,
    start_date: "1961-09-10T07:00:00.000Z",
    end_date: "1961-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 38,
    start_date: "1961-09-17T07:00:00.000Z",
    end_date: "1961-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 39,
    start_date: "1961-09-24T07:00:00.000Z",
    end_date: "1961-09-30T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 40,
    start_date: "1961-10-01T08:00:00.000Z",
    end_date: "1961-10-07T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 41,
    start_date: "1961-10-08T08:00:00.000Z",
    end_date: "1961-10-14T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 42,
    start_date: "1961-10-15T08:00:00.000Z",
    end_date: "1961-10-21T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 43,
    start_date: "1961-10-22T08:00:00.000Z",
    end_date: "1961-10-28T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 44,
    start_date: "1961-10-29T08:00:00.000Z",
    end_date: "1961-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 45,
    start_date: "1961-11-05T08:00:00.000Z",
    end_date: "1961-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 46,
    start_date: "1961-11-12T08:00:00.000Z",
    end_date: "1961-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 47,
    start_date: "1961-11-19T08:00:00.000Z",
    end_date: "1961-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 48,
    start_date: "1961-11-26T08:00:00.000Z",
    end_date: "1961-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 49,
    start_date: "1961-12-03T08:00:00.000Z",
    end_date: "1961-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 50,
    start_date: "1961-12-10T08:00:00.000Z",
    end_date: "1961-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 51,
    start_date: "1961-12-17T08:00:00.000Z",
    end_date: "1961-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 52,
    start_date: "1961-12-24T08:00:00.000Z",
    end_date: "1961-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1961,
    disease_week: 53,
    start_date: "1961-12-31T08:00:00.000Z",
    end_date: "1962-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 1,
    start_date: "1961-12-31T08:00:00.000Z",
    end_date: "1962-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 2,
    start_date: "1962-01-07T08:00:00.000Z",
    end_date: "1962-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 3,
    start_date: "1962-01-14T08:00:00.000Z",
    end_date: "1962-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 4,
    start_date: "1962-01-21T08:00:00.000Z",
    end_date: "1962-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 5,
    start_date: "1962-01-28T08:00:00.000Z",
    end_date: "1962-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 6,
    start_date: "1962-02-04T08:00:00.000Z",
    end_date: "1962-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 7,
    start_date: "1962-02-11T08:00:00.000Z",
    end_date: "1962-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 8,
    start_date: "1962-02-18T08:00:00.000Z",
    end_date: "1962-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 9,
    start_date: "1962-02-25T08:00:00.000Z",
    end_date: "1962-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 10,
    start_date: "1962-03-04T08:00:00.000Z",
    end_date: "1962-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 11,
    start_date: "1962-03-11T08:00:00.000Z",
    end_date: "1962-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 12,
    start_date: "1962-03-18T08:00:00.000Z",
    end_date: "1962-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 13,
    start_date: "1962-03-25T08:00:00.000Z",
    end_date: "1962-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 14,
    start_date: "1962-04-01T08:00:00.000Z",
    end_date: "1962-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 15,
    start_date: "1962-04-08T08:00:00.000Z",
    end_date: "1962-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 16,
    start_date: "1962-04-15T08:00:00.000Z",
    end_date: "1962-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 17,
    start_date: "1962-04-22T08:00:00.000Z",
    end_date: "1962-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 18,
    start_date: "1962-04-29T08:00:00.000Z",
    end_date: "1962-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 19,
    start_date: "1962-05-06T07:00:00.000Z",
    end_date: "1962-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 20,
    start_date: "1962-05-13T07:00:00.000Z",
    end_date: "1962-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 21,
    start_date: "1962-05-20T07:00:00.000Z",
    end_date: "1962-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 22,
    start_date: "1962-05-27T07:00:00.000Z",
    end_date: "1962-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 23,
    start_date: "1962-06-03T07:00:00.000Z",
    end_date: "1962-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 24,
    start_date: "1962-06-10T07:00:00.000Z",
    end_date: "1962-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 25,
    start_date: "1962-06-17T07:00:00.000Z",
    end_date: "1962-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 26,
    start_date: "1962-06-24T07:00:00.000Z",
    end_date: "1962-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 27,
    start_date: "1962-07-01T07:00:00.000Z",
    end_date: "1962-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 28,
    start_date: "1962-07-08T07:00:00.000Z",
    end_date: "1962-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 29,
    start_date: "1962-07-15T07:00:00.000Z",
    end_date: "1962-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 30,
    start_date: "1962-07-22T07:00:00.000Z",
    end_date: "1962-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 31,
    start_date: "1962-07-29T07:00:00.000Z",
    end_date: "1962-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 32,
    start_date: "1962-08-05T07:00:00.000Z",
    end_date: "1962-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 33,
    start_date: "1962-08-12T07:00:00.000Z",
    end_date: "1962-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 34,
    start_date: "1962-08-19T07:00:00.000Z",
    end_date: "1962-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 35,
    start_date: "1962-08-26T07:00:00.000Z",
    end_date: "1962-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 36,
    start_date: "1962-09-02T07:00:00.000Z",
    end_date: "1962-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 37,
    start_date: "1962-09-09T07:00:00.000Z",
    end_date: "1962-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 38,
    start_date: "1962-09-16T07:00:00.000Z",
    end_date: "1962-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 39,
    start_date: "1962-09-23T07:00:00.000Z",
    end_date: "1962-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 40,
    start_date: "1962-09-30T07:00:00.000Z",
    end_date: "1962-10-06T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 41,
    start_date: "1962-10-07T07:00:00.000Z",
    end_date: "1962-10-13T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 42,
    start_date: "1962-10-14T07:00:00.000Z",
    end_date: "1962-10-20T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 43,
    start_date: "1962-10-21T07:00:00.000Z",
    end_date: "1962-10-27T07:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 44,
    start_date: "1962-10-28T07:00:00.000Z",
    end_date: "1962-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 45,
    start_date: "1962-11-04T08:00:00.000Z",
    end_date: "1962-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 46,
    start_date: "1962-11-11T08:00:00.000Z",
    end_date: "1962-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 47,
    start_date: "1962-11-18T08:00:00.000Z",
    end_date: "1962-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 48,
    start_date: "1962-11-25T08:00:00.000Z",
    end_date: "1962-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 49,
    start_date: "1962-12-02T08:00:00.000Z",
    end_date: "1962-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 50,
    start_date: "1962-12-09T08:00:00.000Z",
    end_date: "1962-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 51,
    start_date: "1962-12-16T08:00:00.000Z",
    end_date: "1962-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 52,
    start_date: "1962-12-23T08:00:00.000Z",
    end_date: "1962-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1962,
    disease_week: 53,
    start_date: "1962-12-30T08:00:00.000Z",
    end_date: "1963-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 1,
    start_date: "1962-12-30T08:00:00.000Z",
    end_date: "1963-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 2,
    start_date: "1963-01-06T08:00:00.000Z",
    end_date: "1963-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 3,
    start_date: "1963-01-13T08:00:00.000Z",
    end_date: "1963-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 4,
    start_date: "1963-01-20T08:00:00.000Z",
    end_date: "1963-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 5,
    start_date: "1963-01-27T08:00:00.000Z",
    end_date: "1963-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 6,
    start_date: "1963-02-03T08:00:00.000Z",
    end_date: "1963-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 7,
    start_date: "1963-02-10T08:00:00.000Z",
    end_date: "1963-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 8,
    start_date: "1963-02-17T08:00:00.000Z",
    end_date: "1963-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 9,
    start_date: "1963-02-24T08:00:00.000Z",
    end_date: "1963-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 10,
    start_date: "1963-03-03T08:00:00.000Z",
    end_date: "1963-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 11,
    start_date: "1963-03-10T08:00:00.000Z",
    end_date: "1963-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 12,
    start_date: "1963-03-17T08:00:00.000Z",
    end_date: "1963-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 13,
    start_date: "1963-03-24T08:00:00.000Z",
    end_date: "1963-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 14,
    start_date: "1963-03-31T08:00:00.000Z",
    end_date: "1963-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 15,
    start_date: "1963-04-07T08:00:00.000Z",
    end_date: "1963-04-13T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 16,
    start_date: "1963-04-14T08:00:00.000Z",
    end_date: "1963-04-20T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 17,
    start_date: "1963-04-21T08:00:00.000Z",
    end_date: "1963-04-27T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 18,
    start_date: "1963-04-28T08:00:00.000Z",
    end_date: "1963-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 19,
    start_date: "1963-05-05T07:00:00.000Z",
    end_date: "1963-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 20,
    start_date: "1963-05-12T07:00:00.000Z",
    end_date: "1963-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 21,
    start_date: "1963-05-19T07:00:00.000Z",
    end_date: "1963-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 22,
    start_date: "1963-05-26T07:00:00.000Z",
    end_date: "1963-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 23,
    start_date: "1963-06-02T07:00:00.000Z",
    end_date: "1963-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 24,
    start_date: "1963-06-09T07:00:00.000Z",
    end_date: "1963-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 25,
    start_date: "1963-06-16T07:00:00.000Z",
    end_date: "1963-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 26,
    start_date: "1963-06-23T07:00:00.000Z",
    end_date: "1963-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 27,
    start_date: "1963-06-30T07:00:00.000Z",
    end_date: "1963-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 28,
    start_date: "1963-07-07T07:00:00.000Z",
    end_date: "1963-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 29,
    start_date: "1963-07-14T07:00:00.000Z",
    end_date: "1963-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 30,
    start_date: "1963-07-21T07:00:00.000Z",
    end_date: "1963-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 31,
    start_date: "1963-07-28T07:00:00.000Z",
    end_date: "1963-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 32,
    start_date: "1963-08-04T07:00:00.000Z",
    end_date: "1963-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 33,
    start_date: "1963-08-11T07:00:00.000Z",
    end_date: "1963-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 34,
    start_date: "1963-08-18T07:00:00.000Z",
    end_date: "1963-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 35,
    start_date: "1963-08-25T07:00:00.000Z",
    end_date: "1963-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 36,
    start_date: "1963-09-01T07:00:00.000Z",
    end_date: "1963-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 37,
    start_date: "1963-09-08T07:00:00.000Z",
    end_date: "1963-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 38,
    start_date: "1963-09-15T07:00:00.000Z",
    end_date: "1963-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 39,
    start_date: "1963-09-22T07:00:00.000Z",
    end_date: "1963-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 40,
    start_date: "1963-09-29T07:00:00.000Z",
    end_date: "1963-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 41,
    start_date: "1963-10-06T07:00:00.000Z",
    end_date: "1963-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 42,
    start_date: "1963-10-13T07:00:00.000Z",
    end_date: "1963-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 43,
    start_date: "1963-10-20T07:00:00.000Z",
    end_date: "1963-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 44,
    start_date: "1963-10-27T07:00:00.000Z",
    end_date: "1963-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 45,
    start_date: "1963-11-03T08:00:00.000Z",
    end_date: "1963-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 46,
    start_date: "1963-11-10T08:00:00.000Z",
    end_date: "1963-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 47,
    start_date: "1963-11-17T08:00:00.000Z",
    end_date: "1963-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 48,
    start_date: "1963-11-24T08:00:00.000Z",
    end_date: "1963-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 49,
    start_date: "1963-12-01T08:00:00.000Z",
    end_date: "1963-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 50,
    start_date: "1963-12-08T08:00:00.000Z",
    end_date: "1963-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 51,
    start_date: "1963-12-15T08:00:00.000Z",
    end_date: "1963-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 52,
    start_date: "1963-12-22T08:00:00.000Z",
    end_date: "1963-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1963,
    disease_week: 53,
    start_date: "1963-12-29T08:00:00.000Z",
    end_date: "1964-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 1,
    start_date: "1963-12-29T08:00:00.000Z",
    end_date: "1964-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 2,
    start_date: "1964-01-05T08:00:00.000Z",
    end_date: "1964-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 3,
    start_date: "1964-01-12T08:00:00.000Z",
    end_date: "1964-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 4,
    start_date: "1964-01-19T08:00:00.000Z",
    end_date: "1964-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 5,
    start_date: "1964-01-26T08:00:00.000Z",
    end_date: "1964-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 6,
    start_date: "1964-02-02T08:00:00.000Z",
    end_date: "1964-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 7,
    start_date: "1964-02-09T08:00:00.000Z",
    end_date: "1964-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 8,
    start_date: "1964-02-16T08:00:00.000Z",
    end_date: "1964-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 9,
    start_date: "1964-02-23T08:00:00.000Z",
    end_date: "1964-02-29T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 10,
    start_date: "1964-03-01T08:00:00.000Z",
    end_date: "1964-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 11,
    start_date: "1964-03-08T08:00:00.000Z",
    end_date: "1964-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 12,
    start_date: "1964-03-15T08:00:00.000Z",
    end_date: "1964-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 13,
    start_date: "1964-03-22T08:00:00.000Z",
    end_date: "1964-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 14,
    start_date: "1964-03-29T08:00:00.000Z",
    end_date: "1964-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 15,
    start_date: "1964-04-05T08:00:00.000Z",
    end_date: "1964-04-11T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 16,
    start_date: "1964-04-12T08:00:00.000Z",
    end_date: "1964-04-18T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 17,
    start_date: "1964-04-19T08:00:00.000Z",
    end_date: "1964-04-25T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 18,
    start_date: "1964-04-26T08:00:00.000Z",
    end_date: "1964-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 19,
    start_date: "1964-05-03T07:00:00.000Z",
    end_date: "1964-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 20,
    start_date: "1964-05-10T07:00:00.000Z",
    end_date: "1964-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 21,
    start_date: "1964-05-17T07:00:00.000Z",
    end_date: "1964-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 22,
    start_date: "1964-05-24T07:00:00.000Z",
    end_date: "1964-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 23,
    start_date: "1964-05-31T07:00:00.000Z",
    end_date: "1964-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 24,
    start_date: "1964-06-07T07:00:00.000Z",
    end_date: "1964-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 25,
    start_date: "1964-06-14T07:00:00.000Z",
    end_date: "1964-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 26,
    start_date: "1964-06-21T07:00:00.000Z",
    end_date: "1964-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 27,
    start_date: "1964-06-28T07:00:00.000Z",
    end_date: "1964-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 28,
    start_date: "1964-07-05T07:00:00.000Z",
    end_date: "1964-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 29,
    start_date: "1964-07-12T07:00:00.000Z",
    end_date: "1964-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 30,
    start_date: "1964-07-19T07:00:00.000Z",
    end_date: "1964-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 31,
    start_date: "1964-07-26T07:00:00.000Z",
    end_date: "1964-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 32,
    start_date: "1964-08-02T07:00:00.000Z",
    end_date: "1964-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 33,
    start_date: "1964-08-09T07:00:00.000Z",
    end_date: "1964-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 34,
    start_date: "1964-08-16T07:00:00.000Z",
    end_date: "1964-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 35,
    start_date: "1964-08-23T07:00:00.000Z",
    end_date: "1964-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 36,
    start_date: "1964-08-30T07:00:00.000Z",
    end_date: "1964-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 37,
    start_date: "1964-09-06T07:00:00.000Z",
    end_date: "1964-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 38,
    start_date: "1964-09-13T07:00:00.000Z",
    end_date: "1964-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 39,
    start_date: "1964-09-20T07:00:00.000Z",
    end_date: "1964-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 40,
    start_date: "1964-09-27T07:00:00.000Z",
    end_date: "1964-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 41,
    start_date: "1964-10-04T07:00:00.000Z",
    end_date: "1964-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 42,
    start_date: "1964-10-11T07:00:00.000Z",
    end_date: "1964-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 43,
    start_date: "1964-10-18T07:00:00.000Z",
    end_date: "1964-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 44,
    start_date: "1964-10-25T07:00:00.000Z",
    end_date: "1964-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 45,
    start_date: "1964-11-01T08:00:00.000Z",
    end_date: "1964-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 46,
    start_date: "1964-11-08T08:00:00.000Z",
    end_date: "1964-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 47,
    start_date: "1964-11-15T08:00:00.000Z",
    end_date: "1964-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 48,
    start_date: "1964-11-22T08:00:00.000Z",
    end_date: "1964-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 49,
    start_date: "1964-11-29T08:00:00.000Z",
    end_date: "1964-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 50,
    start_date: "1964-12-06T08:00:00.000Z",
    end_date: "1964-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 51,
    start_date: "1964-12-13T08:00:00.000Z",
    end_date: "1964-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 52,
    start_date: "1964-12-20T08:00:00.000Z",
    end_date: "1964-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1964,
    disease_week: 53,
    start_date: "1964-12-27T08:00:00.000Z",
    end_date: "1965-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 1,
    start_date: "1967-01-01T08:00:00.000Z",
    end_date: "1967-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 2,
    start_date: "1967-01-08T08:00:00.000Z",
    end_date: "1967-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 3,
    start_date: "1967-01-15T08:00:00.000Z",
    end_date: "1967-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 4,
    start_date: "1967-01-22T08:00:00.000Z",
    end_date: "1967-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 5,
    start_date: "1967-01-29T08:00:00.000Z",
    end_date: "1967-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 6,
    start_date: "1967-02-05T08:00:00.000Z",
    end_date: "1967-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 7,
    start_date: "1967-02-12T08:00:00.000Z",
    end_date: "1967-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 8,
    start_date: "1967-02-19T08:00:00.000Z",
    end_date: "1967-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 9,
    start_date: "1967-02-26T08:00:00.000Z",
    end_date: "1967-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 10,
    start_date: "1967-03-05T08:00:00.000Z",
    end_date: "1967-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 11,
    start_date: "1967-03-12T08:00:00.000Z",
    end_date: "1967-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 12,
    start_date: "1967-03-19T08:00:00.000Z",
    end_date: "1967-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 13,
    start_date: "1967-03-26T08:00:00.000Z",
    end_date: "1967-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 14,
    start_date: "1967-04-02T08:00:00.000Z",
    end_date: "1967-04-08T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 15,
    start_date: "1967-04-09T08:00:00.000Z",
    end_date: "1967-04-15T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 16,
    start_date: "1967-04-16T08:00:00.000Z",
    end_date: "1967-04-22T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 17,
    start_date: "1967-04-23T08:00:00.000Z",
    end_date: "1967-04-29T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 18,
    start_date: "1967-04-30T08:00:00.000Z",
    end_date: "1967-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 19,
    start_date: "1967-05-07T07:00:00.000Z",
    end_date: "1967-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 20,
    start_date: "1967-05-14T07:00:00.000Z",
    end_date: "1967-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 21,
    start_date: "1967-05-21T07:00:00.000Z",
    end_date: "1967-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 22,
    start_date: "1967-05-28T07:00:00.000Z",
    end_date: "1967-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 23,
    start_date: "1967-06-04T07:00:00.000Z",
    end_date: "1967-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 24,
    start_date: "1967-06-11T07:00:00.000Z",
    end_date: "1967-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 25,
    start_date: "1967-06-18T07:00:00.000Z",
    end_date: "1967-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 26,
    start_date: "1967-06-25T07:00:00.000Z",
    end_date: "1967-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 27,
    start_date: "1967-07-02T07:00:00.000Z",
    end_date: "1967-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 28,
    start_date: "1967-07-09T07:00:00.000Z",
    end_date: "1967-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 29,
    start_date: "1967-07-16T07:00:00.000Z",
    end_date: "1967-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 30,
    start_date: "1967-07-23T07:00:00.000Z",
    end_date: "1967-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 31,
    start_date: "1967-07-30T07:00:00.000Z",
    end_date: "1967-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 32,
    start_date: "1967-08-06T07:00:00.000Z",
    end_date: "1967-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 33,
    start_date: "1967-08-13T07:00:00.000Z",
    end_date: "1967-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 34,
    start_date: "1967-08-20T07:00:00.000Z",
    end_date: "1967-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 35,
    start_date: "1967-08-27T07:00:00.000Z",
    end_date: "1967-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 36,
    start_date: "1967-09-03T07:00:00.000Z",
    end_date: "1967-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 37,
    start_date: "1967-09-10T07:00:00.000Z",
    end_date: "1967-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 38,
    start_date: "1967-09-17T07:00:00.000Z",
    end_date: "1967-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 39,
    start_date: "1967-09-24T07:00:00.000Z",
    end_date: "1967-09-30T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 40,
    start_date: "1967-10-01T07:00:00.000Z",
    end_date: "1967-10-07T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 41,
    start_date: "1967-10-08T07:00:00.000Z",
    end_date: "1967-10-14T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 42,
    start_date: "1967-10-15T07:00:00.000Z",
    end_date: "1967-10-21T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 43,
    start_date: "1967-10-22T07:00:00.000Z",
    end_date: "1967-10-28T07:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 44,
    start_date: "1967-10-29T07:00:00.000Z",
    end_date: "1967-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 45,
    start_date: "1967-11-05T08:00:00.000Z",
    end_date: "1967-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 46,
    start_date: "1967-11-12T08:00:00.000Z",
    end_date: "1967-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 47,
    start_date: "1967-11-19T08:00:00.000Z",
    end_date: "1967-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 48,
    start_date: "1967-11-26T08:00:00.000Z",
    end_date: "1967-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 49,
    start_date: "1967-12-03T08:00:00.000Z",
    end_date: "1967-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 50,
    start_date: "1967-12-10T08:00:00.000Z",
    end_date: "1967-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 51,
    start_date: "1967-12-17T08:00:00.000Z",
    end_date: "1967-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 52,
    start_date: "1967-12-24T08:00:00.000Z",
    end_date: "1967-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1967,
    disease_week: 53,
    start_date: "1967-12-31T08:00:00.000Z",
    end_date: "1968-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 1,
    start_date: "1967-12-31T08:00:00.000Z",
    end_date: "1968-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 2,
    start_date: "1968-01-07T08:00:00.000Z",
    end_date: "1968-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 3,
    start_date: "1968-01-14T08:00:00.000Z",
    end_date: "1968-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 4,
    start_date: "1968-01-21T08:00:00.000Z",
    end_date: "1968-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 5,
    start_date: "1968-01-28T08:00:00.000Z",
    end_date: "1968-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 6,
    start_date: "1968-02-04T08:00:00.000Z",
    end_date: "1968-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 7,
    start_date: "1968-02-11T08:00:00.000Z",
    end_date: "1968-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 8,
    start_date: "1968-02-18T08:00:00.000Z",
    end_date: "1968-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 9,
    start_date: "1968-02-25T08:00:00.000Z",
    end_date: "1968-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 10,
    start_date: "1968-03-03T08:00:00.000Z",
    end_date: "1968-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 11,
    start_date: "1968-03-10T08:00:00.000Z",
    end_date: "1968-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 12,
    start_date: "1968-03-17T08:00:00.000Z",
    end_date: "1968-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 13,
    start_date: "1968-03-24T08:00:00.000Z",
    end_date: "1968-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 14,
    start_date: "1968-03-31T08:00:00.000Z",
    end_date: "1968-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 15,
    start_date: "1968-04-07T08:00:00.000Z",
    end_date: "1968-04-13T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 16,
    start_date: "1968-04-14T08:00:00.000Z",
    end_date: "1968-04-20T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 17,
    start_date: "1968-04-21T08:00:00.000Z",
    end_date: "1968-04-27T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 18,
    start_date: "1968-04-28T08:00:00.000Z",
    end_date: "1968-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 19,
    start_date: "1968-05-05T07:00:00.000Z",
    end_date: "1968-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 20,
    start_date: "1968-05-12T07:00:00.000Z",
    end_date: "1968-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 21,
    start_date: "1968-05-19T07:00:00.000Z",
    end_date: "1968-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 22,
    start_date: "1968-05-26T07:00:00.000Z",
    end_date: "1968-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 23,
    start_date: "1968-06-02T07:00:00.000Z",
    end_date: "1968-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 24,
    start_date: "1968-06-09T07:00:00.000Z",
    end_date: "1968-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 25,
    start_date: "1968-06-16T07:00:00.000Z",
    end_date: "1968-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 26,
    start_date: "1968-06-23T07:00:00.000Z",
    end_date: "1968-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 27,
    start_date: "1968-06-30T07:00:00.000Z",
    end_date: "1968-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 28,
    start_date: "1968-07-07T07:00:00.000Z",
    end_date: "1968-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 29,
    start_date: "1968-07-14T07:00:00.000Z",
    end_date: "1968-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 30,
    start_date: "1968-07-21T07:00:00.000Z",
    end_date: "1968-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 31,
    start_date: "1968-07-28T07:00:00.000Z",
    end_date: "1968-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 32,
    start_date: "1968-08-04T07:00:00.000Z",
    end_date: "1968-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 33,
    start_date: "1968-08-11T07:00:00.000Z",
    end_date: "1968-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 34,
    start_date: "1968-08-18T07:00:00.000Z",
    end_date: "1968-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 35,
    start_date: "1968-08-25T07:00:00.000Z",
    end_date: "1968-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 36,
    start_date: "1968-09-01T07:00:00.000Z",
    end_date: "1968-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 37,
    start_date: "1968-09-08T07:00:00.000Z",
    end_date: "1968-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 38,
    start_date: "1968-09-15T07:00:00.000Z",
    end_date: "1968-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 39,
    start_date: "1968-09-22T07:00:00.000Z",
    end_date: "1968-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 40,
    start_date: "1968-09-29T07:00:00.000Z",
    end_date: "1968-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 41,
    start_date: "1968-10-06T07:00:00.000Z",
    end_date: "1968-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 42,
    start_date: "1968-10-13T07:00:00.000Z",
    end_date: "1968-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 43,
    start_date: "1968-10-20T07:00:00.000Z",
    end_date: "1968-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 44,
    start_date: "1968-10-27T07:00:00.000Z",
    end_date: "1968-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 45,
    start_date: "1968-11-03T08:00:00.000Z",
    end_date: "1968-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 46,
    start_date: "1968-11-10T08:00:00.000Z",
    end_date: "1968-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 47,
    start_date: "1968-11-17T08:00:00.000Z",
    end_date: "1968-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 48,
    start_date: "1968-11-24T08:00:00.000Z",
    end_date: "1968-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 49,
    start_date: "1968-12-01T08:00:00.000Z",
    end_date: "1968-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 50,
    start_date: "1968-12-08T08:00:00.000Z",
    end_date: "1968-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 51,
    start_date: "1968-12-15T08:00:00.000Z",
    end_date: "1968-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 52,
    start_date: "1968-12-22T08:00:00.000Z",
    end_date: "1968-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1968,
    disease_week: 53,
    start_date: "1968-12-29T08:00:00.000Z",
    end_date: "1969-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 1,
    start_date: "1968-12-29T08:00:00.000Z",
    end_date: "1969-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 2,
    start_date: "1969-01-05T08:00:00.000Z",
    end_date: "1969-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 3,
    start_date: "1969-01-12T08:00:00.000Z",
    end_date: "1969-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 4,
    start_date: "1969-01-19T08:00:00.000Z",
    end_date: "1969-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 5,
    start_date: "1969-01-26T08:00:00.000Z",
    end_date: "1969-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 6,
    start_date: "1969-02-02T08:00:00.000Z",
    end_date: "1969-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 7,
    start_date: "1969-02-09T08:00:00.000Z",
    end_date: "1969-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 8,
    start_date: "1969-02-16T08:00:00.000Z",
    end_date: "1969-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 9,
    start_date: "1969-02-23T08:00:00.000Z",
    end_date: "1969-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 10,
    start_date: "1969-03-02T08:00:00.000Z",
    end_date: "1969-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 11,
    start_date: "1969-03-09T08:00:00.000Z",
    end_date: "1969-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 12,
    start_date: "1969-03-16T08:00:00.000Z",
    end_date: "1969-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 13,
    start_date: "1969-03-23T08:00:00.000Z",
    end_date: "1969-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 14,
    start_date: "1969-03-30T08:00:00.000Z",
    end_date: "1969-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 15,
    start_date: "1969-04-06T08:00:00.000Z",
    end_date: "1969-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 16,
    start_date: "1969-04-13T08:00:00.000Z",
    end_date: "1969-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 17,
    start_date: "1969-04-20T08:00:00.000Z",
    end_date: "1969-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 18,
    start_date: "1969-04-27T08:00:00.000Z",
    end_date: "1969-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 19,
    start_date: "1969-05-04T07:00:00.000Z",
    end_date: "1969-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 20,
    start_date: "1969-05-11T07:00:00.000Z",
    end_date: "1969-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 21,
    start_date: "1969-05-18T07:00:00.000Z",
    end_date: "1969-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 22,
    start_date: "1969-05-25T07:00:00.000Z",
    end_date: "1969-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 23,
    start_date: "1969-06-01T07:00:00.000Z",
    end_date: "1969-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 24,
    start_date: "1969-06-08T07:00:00.000Z",
    end_date: "1969-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 25,
    start_date: "1969-06-15T07:00:00.000Z",
    end_date: "1969-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 26,
    start_date: "1969-06-22T07:00:00.000Z",
    end_date: "1969-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 27,
    start_date: "1969-06-29T07:00:00.000Z",
    end_date: "1969-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 28,
    start_date: "1969-07-06T07:00:00.000Z",
    end_date: "1969-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 29,
    start_date: "1969-07-13T07:00:00.000Z",
    end_date: "1969-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 30,
    start_date: "1969-07-20T07:00:00.000Z",
    end_date: "1969-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 31,
    start_date: "1969-07-27T07:00:00.000Z",
    end_date: "1969-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 32,
    start_date: "1969-08-03T07:00:00.000Z",
    end_date: "1969-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 33,
    start_date: "1969-08-10T07:00:00.000Z",
    end_date: "1969-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 34,
    start_date: "1969-08-17T07:00:00.000Z",
    end_date: "1969-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 35,
    start_date: "1969-08-24T07:00:00.000Z",
    end_date: "1969-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 36,
    start_date: "1969-08-31T07:00:00.000Z",
    end_date: "1969-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 37,
    start_date: "1969-09-07T07:00:00.000Z",
    end_date: "1969-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 38,
    start_date: "1969-09-14T07:00:00.000Z",
    end_date: "1969-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 39,
    start_date: "1969-09-21T07:00:00.000Z",
    end_date: "1969-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 40,
    start_date: "1969-09-28T07:00:00.000Z",
    end_date: "1969-10-04T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 41,
    start_date: "1969-10-05T07:00:00.000Z",
    end_date: "1969-10-11T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 42,
    start_date: "1969-10-12T07:00:00.000Z",
    end_date: "1969-10-18T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 43,
    start_date: "1969-10-19T07:00:00.000Z",
    end_date: "1969-10-25T07:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 44,
    start_date: "1969-10-26T07:00:00.000Z",
    end_date: "1969-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 45,
    start_date: "1969-11-02T08:00:00.000Z",
    end_date: "1969-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 46,
    start_date: "1969-11-09T08:00:00.000Z",
    end_date: "1969-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 47,
    start_date: "1969-11-16T08:00:00.000Z",
    end_date: "1969-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 48,
    start_date: "1969-11-23T08:00:00.000Z",
    end_date: "1969-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 49,
    start_date: "1969-11-30T08:00:00.000Z",
    end_date: "1969-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 50,
    start_date: "1969-12-07T08:00:00.000Z",
    end_date: "1969-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 51,
    start_date: "1969-12-14T08:00:00.000Z",
    end_date: "1969-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 52,
    start_date: "1969-12-21T08:00:00.000Z",
    end_date: "1969-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1969,
    disease_week: 53,
    start_date: "1969-12-28T08:00:00.000Z",
    end_date: "1970-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 1,
    start_date: "1972-12-31T08:00:00.000Z",
    end_date: "1973-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 2,
    start_date: "1973-01-07T08:00:00.000Z",
    end_date: "1973-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 3,
    start_date: "1973-01-14T08:00:00.000Z",
    end_date: "1973-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 4,
    start_date: "1973-01-21T08:00:00.000Z",
    end_date: "1973-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 5,
    start_date: "1973-01-28T08:00:00.000Z",
    end_date: "1973-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 6,
    start_date: "1973-02-04T08:00:00.000Z",
    end_date: "1973-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 7,
    start_date: "1973-02-11T08:00:00.000Z",
    end_date: "1973-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 8,
    start_date: "1973-02-18T08:00:00.000Z",
    end_date: "1973-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 9,
    start_date: "1973-02-25T08:00:00.000Z",
    end_date: "1973-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 10,
    start_date: "1973-03-04T08:00:00.000Z",
    end_date: "1973-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 11,
    start_date: "1973-03-11T08:00:00.000Z",
    end_date: "1973-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 12,
    start_date: "1973-03-18T08:00:00.000Z",
    end_date: "1973-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 13,
    start_date: "1973-03-25T08:00:00.000Z",
    end_date: "1973-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 14,
    start_date: "1973-04-01T08:00:00.000Z",
    end_date: "1973-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 15,
    start_date: "1973-04-08T08:00:00.000Z",
    end_date: "1973-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 16,
    start_date: "1973-04-15T08:00:00.000Z",
    end_date: "1973-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 17,
    start_date: "1973-04-22T08:00:00.000Z",
    end_date: "1973-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 18,
    start_date: "1973-04-29T08:00:00.000Z",
    end_date: "1973-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 19,
    start_date: "1973-05-06T07:00:00.000Z",
    end_date: "1973-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 20,
    start_date: "1973-05-13T07:00:00.000Z",
    end_date: "1973-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 21,
    start_date: "1973-05-20T07:00:00.000Z",
    end_date: "1973-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 22,
    start_date: "1973-05-27T07:00:00.000Z",
    end_date: "1973-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 23,
    start_date: "1973-06-03T07:00:00.000Z",
    end_date: "1973-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 24,
    start_date: "1973-06-10T07:00:00.000Z",
    end_date: "1973-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 25,
    start_date: "1973-06-17T07:00:00.000Z",
    end_date: "1973-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 26,
    start_date: "1973-06-24T07:00:00.000Z",
    end_date: "1973-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 27,
    start_date: "1973-07-01T07:00:00.000Z",
    end_date: "1973-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 28,
    start_date: "1973-07-08T07:00:00.000Z",
    end_date: "1973-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 29,
    start_date: "1973-07-15T07:00:00.000Z",
    end_date: "1973-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 30,
    start_date: "1973-07-22T07:00:00.000Z",
    end_date: "1973-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 31,
    start_date: "1973-07-29T07:00:00.000Z",
    end_date: "1973-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 32,
    start_date: "1973-08-05T07:00:00.000Z",
    end_date: "1973-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 33,
    start_date: "1973-08-12T07:00:00.000Z",
    end_date: "1973-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 34,
    start_date: "1973-08-19T07:00:00.000Z",
    end_date: "1973-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 35,
    start_date: "1973-08-26T07:00:00.000Z",
    end_date: "1973-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 36,
    start_date: "1973-09-02T07:00:00.000Z",
    end_date: "1973-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 37,
    start_date: "1973-09-09T07:00:00.000Z",
    end_date: "1973-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 38,
    start_date: "1973-09-16T07:00:00.000Z",
    end_date: "1973-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 39,
    start_date: "1973-09-23T07:00:00.000Z",
    end_date: "1973-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 40,
    start_date: "1973-09-30T07:00:00.000Z",
    end_date: "1973-10-06T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 41,
    start_date: "1973-10-07T07:00:00.000Z",
    end_date: "1973-10-13T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 42,
    start_date: "1973-10-14T07:00:00.000Z",
    end_date: "1973-10-20T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 43,
    start_date: "1973-10-21T07:00:00.000Z",
    end_date: "1973-10-27T07:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 44,
    start_date: "1973-10-28T07:00:00.000Z",
    end_date: "1973-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 45,
    start_date: "1973-11-04T08:00:00.000Z",
    end_date: "1973-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 46,
    start_date: "1973-11-11T08:00:00.000Z",
    end_date: "1973-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 47,
    start_date: "1973-11-18T08:00:00.000Z",
    end_date: "1973-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 48,
    start_date: "1973-11-25T08:00:00.000Z",
    end_date: "1973-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 49,
    start_date: "1973-12-02T08:00:00.000Z",
    end_date: "1973-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 50,
    start_date: "1973-12-09T08:00:00.000Z",
    end_date: "1973-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 51,
    start_date: "1973-12-16T08:00:00.000Z",
    end_date: "1973-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 52,
    start_date: "1973-12-23T08:00:00.000Z",
    end_date: "1973-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1973,
    disease_week: 53,
    start_date: "1973-12-30T08:00:00.000Z",
    end_date: "1974-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 1,
    start_date: "1973-12-30T08:00:00.000Z",
    end_date: "1974-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 2,
    start_date: "1974-01-06T08:00:00.000Z",
    end_date: "1974-01-12T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 3,
    start_date: "1974-01-13T07:00:00.000Z",
    end_date: "1974-01-19T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 4,
    start_date: "1974-01-20T07:00:00.000Z",
    end_date: "1974-01-26T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 5,
    start_date: "1974-01-27T07:00:00.000Z",
    end_date: "1974-02-02T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 6,
    start_date: "1974-02-03T07:00:00.000Z",
    end_date: "1974-02-09T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 7,
    start_date: "1974-02-10T07:00:00.000Z",
    end_date: "1974-02-16T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 8,
    start_date: "1974-02-17T07:00:00.000Z",
    end_date: "1974-02-23T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 9,
    start_date: "1974-02-24T07:00:00.000Z",
    end_date: "1974-03-02T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 10,
    start_date: "1974-03-03T07:00:00.000Z",
    end_date: "1974-03-09T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 11,
    start_date: "1974-03-10T07:00:00.000Z",
    end_date: "1974-03-16T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 12,
    start_date: "1974-03-17T07:00:00.000Z",
    end_date: "1974-03-23T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 13,
    start_date: "1974-03-24T07:00:00.000Z",
    end_date: "1974-03-30T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 14,
    start_date: "1974-03-31T07:00:00.000Z",
    end_date: "1974-04-06T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 15,
    start_date: "1974-04-07T07:00:00.000Z",
    end_date: "1974-04-13T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 16,
    start_date: "1974-04-14T07:00:00.000Z",
    end_date: "1974-04-20T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 17,
    start_date: "1974-04-21T07:00:00.000Z",
    end_date: "1974-04-27T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 18,
    start_date: "1974-04-28T07:00:00.000Z",
    end_date: "1974-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 19,
    start_date: "1974-05-05T07:00:00.000Z",
    end_date: "1974-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 20,
    start_date: "1974-05-12T07:00:00.000Z",
    end_date: "1974-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 21,
    start_date: "1974-05-19T07:00:00.000Z",
    end_date: "1974-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 22,
    start_date: "1974-05-26T07:00:00.000Z",
    end_date: "1974-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 23,
    start_date: "1974-06-02T07:00:00.000Z",
    end_date: "1974-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 24,
    start_date: "1974-06-09T07:00:00.000Z",
    end_date: "1974-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 25,
    start_date: "1974-06-16T07:00:00.000Z",
    end_date: "1974-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 26,
    start_date: "1974-06-23T07:00:00.000Z",
    end_date: "1974-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 27,
    start_date: "1974-06-30T07:00:00.000Z",
    end_date: "1974-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 28,
    start_date: "1974-07-07T07:00:00.000Z",
    end_date: "1974-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 29,
    start_date: "1974-07-14T07:00:00.000Z",
    end_date: "1974-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 30,
    start_date: "1974-07-21T07:00:00.000Z",
    end_date: "1974-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 31,
    start_date: "1974-07-28T07:00:00.000Z",
    end_date: "1974-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 32,
    start_date: "1974-08-04T07:00:00.000Z",
    end_date: "1974-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 33,
    start_date: "1974-08-11T07:00:00.000Z",
    end_date: "1974-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 34,
    start_date: "1974-08-18T07:00:00.000Z",
    end_date: "1974-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 35,
    start_date: "1974-08-25T07:00:00.000Z",
    end_date: "1974-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 36,
    start_date: "1974-09-01T07:00:00.000Z",
    end_date: "1974-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 37,
    start_date: "1974-09-08T07:00:00.000Z",
    end_date: "1974-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 38,
    start_date: "1974-09-15T07:00:00.000Z",
    end_date: "1974-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 39,
    start_date: "1974-09-22T07:00:00.000Z",
    end_date: "1974-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 40,
    start_date: "1974-09-29T07:00:00.000Z",
    end_date: "1974-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 41,
    start_date: "1974-10-06T07:00:00.000Z",
    end_date: "1974-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 42,
    start_date: "1974-10-13T07:00:00.000Z",
    end_date: "1974-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 43,
    start_date: "1974-10-20T07:00:00.000Z",
    end_date: "1974-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 44,
    start_date: "1974-10-27T07:00:00.000Z",
    end_date: "1974-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 45,
    start_date: "1974-11-03T08:00:00.000Z",
    end_date: "1974-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 46,
    start_date: "1974-11-10T08:00:00.000Z",
    end_date: "1974-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 47,
    start_date: "1974-11-17T08:00:00.000Z",
    end_date: "1974-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 48,
    start_date: "1974-11-24T08:00:00.000Z",
    end_date: "1974-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 49,
    start_date: "1974-12-01T08:00:00.000Z",
    end_date: "1974-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 50,
    start_date: "1974-12-08T08:00:00.000Z",
    end_date: "1974-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 51,
    start_date: "1974-12-15T08:00:00.000Z",
    end_date: "1974-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 52,
    start_date: "1974-12-22T08:00:00.000Z",
    end_date: "1974-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1974,
    disease_week: 53,
    start_date: "1974-12-29T08:00:00.000Z",
    end_date: "1975-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 1,
    start_date: "1974-12-29T08:00:00.000Z",
    end_date: "1975-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 2,
    start_date: "1975-01-05T08:00:00.000Z",
    end_date: "1975-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 3,
    start_date: "1975-01-12T08:00:00.000Z",
    end_date: "1975-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 4,
    start_date: "1975-01-19T08:00:00.000Z",
    end_date: "1975-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 5,
    start_date: "1975-01-26T08:00:00.000Z",
    end_date: "1975-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 6,
    start_date: "1975-02-02T08:00:00.000Z",
    end_date: "1975-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 7,
    start_date: "1975-02-09T08:00:00.000Z",
    end_date: "1975-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 8,
    start_date: "1975-02-16T08:00:00.000Z",
    end_date: "1975-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 9,
    start_date: "1975-02-23T08:00:00.000Z",
    end_date: "1975-03-01T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 10,
    start_date: "1975-03-02T07:00:00.000Z",
    end_date: "1975-03-08T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 11,
    start_date: "1975-03-09T07:00:00.000Z",
    end_date: "1975-03-15T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 12,
    start_date: "1975-03-16T07:00:00.000Z",
    end_date: "1975-03-22T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 13,
    start_date: "1975-03-23T07:00:00.000Z",
    end_date: "1975-03-29T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 14,
    start_date: "1975-03-30T07:00:00.000Z",
    end_date: "1975-04-05T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 15,
    start_date: "1975-04-06T07:00:00.000Z",
    end_date: "1975-04-12T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 16,
    start_date: "1975-04-13T07:00:00.000Z",
    end_date: "1975-04-19T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 17,
    start_date: "1975-04-20T07:00:00.000Z",
    end_date: "1975-04-26T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 18,
    start_date: "1975-04-27T07:00:00.000Z",
    end_date: "1975-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 19,
    start_date: "1975-05-04T07:00:00.000Z",
    end_date: "1975-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 20,
    start_date: "1975-05-11T07:00:00.000Z",
    end_date: "1975-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 21,
    start_date: "1975-05-18T07:00:00.000Z",
    end_date: "1975-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 22,
    start_date: "1975-05-25T07:00:00.000Z",
    end_date: "1975-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 23,
    start_date: "1975-06-01T07:00:00.000Z",
    end_date: "1975-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 24,
    start_date: "1975-06-08T07:00:00.000Z",
    end_date: "1975-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 25,
    start_date: "1975-06-15T07:00:00.000Z",
    end_date: "1975-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 26,
    start_date: "1975-06-22T07:00:00.000Z",
    end_date: "1975-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 27,
    start_date: "1975-06-29T07:00:00.000Z",
    end_date: "1975-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 28,
    start_date: "1975-07-06T07:00:00.000Z",
    end_date: "1975-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 29,
    start_date: "1975-07-13T07:00:00.000Z",
    end_date: "1975-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 30,
    start_date: "1975-07-20T07:00:00.000Z",
    end_date: "1975-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 31,
    start_date: "1975-07-27T07:00:00.000Z",
    end_date: "1975-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 32,
    start_date: "1975-08-03T07:00:00.000Z",
    end_date: "1975-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 33,
    start_date: "1975-08-10T07:00:00.000Z",
    end_date: "1975-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 34,
    start_date: "1975-08-17T07:00:00.000Z",
    end_date: "1975-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 35,
    start_date: "1975-08-24T07:00:00.000Z",
    end_date: "1975-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 36,
    start_date: "1975-08-31T07:00:00.000Z",
    end_date: "1975-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 37,
    start_date: "1975-09-07T07:00:00.000Z",
    end_date: "1975-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 38,
    start_date: "1975-09-14T07:00:00.000Z",
    end_date: "1975-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 39,
    start_date: "1975-09-21T07:00:00.000Z",
    end_date: "1975-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 40,
    start_date: "1975-09-28T07:00:00.000Z",
    end_date: "1975-10-04T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 41,
    start_date: "1975-10-05T07:00:00.000Z",
    end_date: "1975-10-11T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 42,
    start_date: "1975-10-12T07:00:00.000Z",
    end_date: "1975-10-18T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 43,
    start_date: "1975-10-19T07:00:00.000Z",
    end_date: "1975-10-25T07:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 44,
    start_date: "1975-10-26T07:00:00.000Z",
    end_date: "1975-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 45,
    start_date: "1975-11-02T08:00:00.000Z",
    end_date: "1975-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 46,
    start_date: "1975-11-09T08:00:00.000Z",
    end_date: "1975-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 47,
    start_date: "1975-11-16T08:00:00.000Z",
    end_date: "1975-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 48,
    start_date: "1975-11-23T08:00:00.000Z",
    end_date: "1975-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 49,
    start_date: "1975-11-30T08:00:00.000Z",
    end_date: "1975-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 50,
    start_date: "1975-12-07T08:00:00.000Z",
    end_date: "1975-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 51,
    start_date: "1975-12-14T08:00:00.000Z",
    end_date: "1975-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 52,
    start_date: "1975-12-21T08:00:00.000Z",
    end_date: "1975-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1975,
    disease_week: 53,
    start_date: "1975-12-28T08:00:00.000Z",
    end_date: "1976-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 1,
    start_date: "1978-01-01T08:00:00.000Z",
    end_date: "1978-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 2,
    start_date: "1978-01-08T08:00:00.000Z",
    end_date: "1978-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 3,
    start_date: "1978-01-15T08:00:00.000Z",
    end_date: "1978-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 4,
    start_date: "1978-01-22T08:00:00.000Z",
    end_date: "1978-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 5,
    start_date: "1978-01-29T08:00:00.000Z",
    end_date: "1978-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 6,
    start_date: "1978-02-05T08:00:00.000Z",
    end_date: "1978-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 7,
    start_date: "1978-02-12T08:00:00.000Z",
    end_date: "1978-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 8,
    start_date: "1978-02-19T08:00:00.000Z",
    end_date: "1978-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 9,
    start_date: "1978-02-26T08:00:00.000Z",
    end_date: "1978-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 10,
    start_date: "1978-03-05T08:00:00.000Z",
    end_date: "1978-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 11,
    start_date: "1978-03-12T08:00:00.000Z",
    end_date: "1978-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 12,
    start_date: "1978-03-19T08:00:00.000Z",
    end_date: "1978-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 13,
    start_date: "1978-03-26T08:00:00.000Z",
    end_date: "1978-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 14,
    start_date: "1978-04-02T08:00:00.000Z",
    end_date: "1978-04-08T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 15,
    start_date: "1978-04-09T08:00:00.000Z",
    end_date: "1978-04-15T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 16,
    start_date: "1978-04-16T08:00:00.000Z",
    end_date: "1978-04-22T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 17,
    start_date: "1978-04-23T08:00:00.000Z",
    end_date: "1978-04-29T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 18,
    start_date: "1978-04-30T08:00:00.000Z",
    end_date: "1978-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 19,
    start_date: "1978-05-07T07:00:00.000Z",
    end_date: "1978-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 20,
    start_date: "1978-05-14T07:00:00.000Z",
    end_date: "1978-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 21,
    start_date: "1978-05-21T07:00:00.000Z",
    end_date: "1978-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 22,
    start_date: "1978-05-28T07:00:00.000Z",
    end_date: "1978-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 23,
    start_date: "1978-06-04T07:00:00.000Z",
    end_date: "1978-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 24,
    start_date: "1978-06-11T07:00:00.000Z",
    end_date: "1978-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 25,
    start_date: "1978-06-18T07:00:00.000Z",
    end_date: "1978-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 26,
    start_date: "1978-06-25T07:00:00.000Z",
    end_date: "1978-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 27,
    start_date: "1978-07-02T07:00:00.000Z",
    end_date: "1978-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 28,
    start_date: "1978-07-09T07:00:00.000Z",
    end_date: "1978-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 29,
    start_date: "1978-07-16T07:00:00.000Z",
    end_date: "1978-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 30,
    start_date: "1978-07-23T07:00:00.000Z",
    end_date: "1978-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 31,
    start_date: "1978-07-30T07:00:00.000Z",
    end_date: "1978-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 32,
    start_date: "1978-08-06T07:00:00.000Z",
    end_date: "1978-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 33,
    start_date: "1978-08-13T07:00:00.000Z",
    end_date: "1978-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 34,
    start_date: "1978-08-20T07:00:00.000Z",
    end_date: "1978-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 35,
    start_date: "1978-08-27T07:00:00.000Z",
    end_date: "1978-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 36,
    start_date: "1978-09-03T07:00:00.000Z",
    end_date: "1978-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 37,
    start_date: "1978-09-10T07:00:00.000Z",
    end_date: "1978-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 38,
    start_date: "1978-09-17T07:00:00.000Z",
    end_date: "1978-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 39,
    start_date: "1978-09-24T07:00:00.000Z",
    end_date: "1978-09-30T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 40,
    start_date: "1978-10-01T07:00:00.000Z",
    end_date: "1978-10-07T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 41,
    start_date: "1978-10-08T07:00:00.000Z",
    end_date: "1978-10-14T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 42,
    start_date: "1978-10-15T07:00:00.000Z",
    end_date: "1978-10-21T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 43,
    start_date: "1978-10-22T07:00:00.000Z",
    end_date: "1978-10-28T07:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 44,
    start_date: "1978-10-29T07:00:00.000Z",
    end_date: "1978-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 45,
    start_date: "1978-11-05T08:00:00.000Z",
    end_date: "1978-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 46,
    start_date: "1978-11-12T08:00:00.000Z",
    end_date: "1978-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 47,
    start_date: "1978-11-19T08:00:00.000Z",
    end_date: "1978-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 48,
    start_date: "1978-11-26T08:00:00.000Z",
    end_date: "1978-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 49,
    start_date: "1978-12-03T08:00:00.000Z",
    end_date: "1978-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 50,
    start_date: "1978-12-10T08:00:00.000Z",
    end_date: "1978-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 51,
    start_date: "1978-12-17T08:00:00.000Z",
    end_date: "1978-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 52,
    start_date: "1978-12-24T08:00:00.000Z",
    end_date: "1978-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1978,
    disease_week: 53,
    start_date: "1978-12-31T08:00:00.000Z",
    end_date: "1979-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 1,
    start_date: "1978-12-31T08:00:00.000Z",
    end_date: "1979-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 2,
    start_date: "1979-01-07T08:00:00.000Z",
    end_date: "1979-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 3,
    start_date: "1979-01-14T08:00:00.000Z",
    end_date: "1979-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 4,
    start_date: "1979-01-21T08:00:00.000Z",
    end_date: "1979-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 5,
    start_date: "1979-01-28T08:00:00.000Z",
    end_date: "1979-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 6,
    start_date: "1979-02-04T08:00:00.000Z",
    end_date: "1979-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 7,
    start_date: "1979-02-11T08:00:00.000Z",
    end_date: "1979-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 8,
    start_date: "1979-02-18T08:00:00.000Z",
    end_date: "1979-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 9,
    start_date: "1979-02-25T08:00:00.000Z",
    end_date: "1979-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 10,
    start_date: "1979-03-04T08:00:00.000Z",
    end_date: "1979-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 11,
    start_date: "1979-03-11T08:00:00.000Z",
    end_date: "1979-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 12,
    start_date: "1979-03-18T08:00:00.000Z",
    end_date: "1979-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 13,
    start_date: "1979-03-25T08:00:00.000Z",
    end_date: "1979-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 14,
    start_date: "1979-04-01T08:00:00.000Z",
    end_date: "1979-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 15,
    start_date: "1979-04-08T08:00:00.000Z",
    end_date: "1979-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 16,
    start_date: "1979-04-15T08:00:00.000Z",
    end_date: "1979-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 17,
    start_date: "1979-04-22T08:00:00.000Z",
    end_date: "1979-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 18,
    start_date: "1979-04-29T08:00:00.000Z",
    end_date: "1979-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 19,
    start_date: "1979-05-06T07:00:00.000Z",
    end_date: "1979-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 20,
    start_date: "1979-05-13T07:00:00.000Z",
    end_date: "1979-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 21,
    start_date: "1979-05-20T07:00:00.000Z",
    end_date: "1979-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 22,
    start_date: "1979-05-27T07:00:00.000Z",
    end_date: "1979-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 23,
    start_date: "1979-06-03T07:00:00.000Z",
    end_date: "1979-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 24,
    start_date: "1979-06-10T07:00:00.000Z",
    end_date: "1979-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 25,
    start_date: "1979-06-17T07:00:00.000Z",
    end_date: "1979-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 26,
    start_date: "1979-06-24T07:00:00.000Z",
    end_date: "1979-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 27,
    start_date: "1979-07-01T07:00:00.000Z",
    end_date: "1979-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 28,
    start_date: "1979-07-08T07:00:00.000Z",
    end_date: "1979-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 29,
    start_date: "1979-07-15T07:00:00.000Z",
    end_date: "1979-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 30,
    start_date: "1979-07-22T07:00:00.000Z",
    end_date: "1979-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 31,
    start_date: "1979-07-29T07:00:00.000Z",
    end_date: "1979-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 32,
    start_date: "1979-08-05T07:00:00.000Z",
    end_date: "1979-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 33,
    start_date: "1979-08-12T07:00:00.000Z",
    end_date: "1979-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 34,
    start_date: "1979-08-19T07:00:00.000Z",
    end_date: "1979-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 35,
    start_date: "1979-08-26T07:00:00.000Z",
    end_date: "1979-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 36,
    start_date: "1979-09-02T07:00:00.000Z",
    end_date: "1979-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 37,
    start_date: "1979-09-09T07:00:00.000Z",
    end_date: "1979-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 38,
    start_date: "1979-09-16T07:00:00.000Z",
    end_date: "1979-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 39,
    start_date: "1979-09-23T07:00:00.000Z",
    end_date: "1979-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 40,
    start_date: "1979-09-30T07:00:00.000Z",
    end_date: "1979-10-06T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 41,
    start_date: "1979-10-07T07:00:00.000Z",
    end_date: "1979-10-13T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 42,
    start_date: "1979-10-14T07:00:00.000Z",
    end_date: "1979-10-20T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 43,
    start_date: "1979-10-21T07:00:00.000Z",
    end_date: "1979-10-27T07:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 44,
    start_date: "1979-10-28T07:00:00.000Z",
    end_date: "1979-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 45,
    start_date: "1979-11-04T08:00:00.000Z",
    end_date: "1979-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 46,
    start_date: "1979-11-11T08:00:00.000Z",
    end_date: "1979-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 47,
    start_date: "1979-11-18T08:00:00.000Z",
    end_date: "1979-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 48,
    start_date: "1979-11-25T08:00:00.000Z",
    end_date: "1979-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 49,
    start_date: "1979-12-02T08:00:00.000Z",
    end_date: "1979-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 50,
    start_date: "1979-12-09T08:00:00.000Z",
    end_date: "1979-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 51,
    start_date: "1979-12-16T08:00:00.000Z",
    end_date: "1979-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 52,
    start_date: "1979-12-23T08:00:00.000Z",
    end_date: "1979-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1979,
    disease_week: 53,
    start_date: "1979-12-30T08:00:00.000Z",
    end_date: "1980-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 1,
    start_date: "1979-12-30T08:00:00.000Z",
    end_date: "1980-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 2,
    start_date: "1980-01-06T08:00:00.000Z",
    end_date: "1980-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 3,
    start_date: "1980-01-13T08:00:00.000Z",
    end_date: "1980-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 4,
    start_date: "1980-01-20T08:00:00.000Z",
    end_date: "1980-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 5,
    start_date: "1980-01-27T08:00:00.000Z",
    end_date: "1980-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 6,
    start_date: "1980-02-03T08:00:00.000Z",
    end_date: "1980-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 7,
    start_date: "1980-02-10T08:00:00.000Z",
    end_date: "1980-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 8,
    start_date: "1980-02-17T08:00:00.000Z",
    end_date: "1980-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 9,
    start_date: "1980-02-24T08:00:00.000Z",
    end_date: "1980-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 10,
    start_date: "1980-03-02T08:00:00.000Z",
    end_date: "1980-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 11,
    start_date: "1980-03-09T08:00:00.000Z",
    end_date: "1980-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 12,
    start_date: "1980-03-16T08:00:00.000Z",
    end_date: "1980-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 13,
    start_date: "1980-03-23T08:00:00.000Z",
    end_date: "1980-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 14,
    start_date: "1980-03-30T08:00:00.000Z",
    end_date: "1980-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 15,
    start_date: "1980-04-06T08:00:00.000Z",
    end_date: "1980-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 16,
    start_date: "1980-04-13T08:00:00.000Z",
    end_date: "1980-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 17,
    start_date: "1980-04-20T08:00:00.000Z",
    end_date: "1980-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 18,
    start_date: "1980-04-27T08:00:00.000Z",
    end_date: "1980-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 19,
    start_date: "1980-05-04T07:00:00.000Z",
    end_date: "1980-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 20,
    start_date: "1980-05-11T07:00:00.000Z",
    end_date: "1980-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 21,
    start_date: "1980-05-18T07:00:00.000Z",
    end_date: "1980-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 22,
    start_date: "1980-05-25T07:00:00.000Z",
    end_date: "1980-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 23,
    start_date: "1980-06-01T07:00:00.000Z",
    end_date: "1980-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 24,
    start_date: "1980-06-08T07:00:00.000Z",
    end_date: "1980-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 25,
    start_date: "1980-06-15T07:00:00.000Z",
    end_date: "1980-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 26,
    start_date: "1980-06-22T07:00:00.000Z",
    end_date: "1980-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 27,
    start_date: "1980-06-29T07:00:00.000Z",
    end_date: "1980-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 28,
    start_date: "1980-07-06T07:00:00.000Z",
    end_date: "1980-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 29,
    start_date: "1980-07-13T07:00:00.000Z",
    end_date: "1980-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 30,
    start_date: "1980-07-20T07:00:00.000Z",
    end_date: "1980-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 31,
    start_date: "1980-07-27T07:00:00.000Z",
    end_date: "1980-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 32,
    start_date: "1980-08-03T07:00:00.000Z",
    end_date: "1980-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 33,
    start_date: "1980-08-10T07:00:00.000Z",
    end_date: "1980-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 34,
    start_date: "1980-08-17T07:00:00.000Z",
    end_date: "1980-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 35,
    start_date: "1980-08-24T07:00:00.000Z",
    end_date: "1980-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 36,
    start_date: "1980-08-31T07:00:00.000Z",
    end_date: "1980-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 37,
    start_date: "1980-09-07T07:00:00.000Z",
    end_date: "1980-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 38,
    start_date: "1980-09-14T07:00:00.000Z",
    end_date: "1980-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 39,
    start_date: "1980-09-21T07:00:00.000Z",
    end_date: "1980-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 40,
    start_date: "1980-09-28T07:00:00.000Z",
    end_date: "1980-10-04T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 41,
    start_date: "1980-10-05T07:00:00.000Z",
    end_date: "1980-10-11T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 42,
    start_date: "1980-10-12T07:00:00.000Z",
    end_date: "1980-10-18T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 43,
    start_date: "1980-10-19T07:00:00.000Z",
    end_date: "1980-10-25T07:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 44,
    start_date: "1980-10-26T07:00:00.000Z",
    end_date: "1980-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 45,
    start_date: "1980-11-02T08:00:00.000Z",
    end_date: "1980-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 46,
    start_date: "1980-11-09T08:00:00.000Z",
    end_date: "1980-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 47,
    start_date: "1980-11-16T08:00:00.000Z",
    end_date: "1980-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 48,
    start_date: "1980-11-23T08:00:00.000Z",
    end_date: "1980-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 49,
    start_date: "1980-11-30T08:00:00.000Z",
    end_date: "1980-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 50,
    start_date: "1980-12-07T08:00:00.000Z",
    end_date: "1980-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 51,
    start_date: "1980-12-14T08:00:00.000Z",
    end_date: "1980-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 52,
    start_date: "1980-12-21T08:00:00.000Z",
    end_date: "1980-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1980,
    disease_week: 53,
    start_date: "1980-12-28T08:00:00.000Z",
    end_date: "1981-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 1,
    start_date: "1984-01-01T08:00:00.000Z",
    end_date: "1984-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 2,
    start_date: "1984-01-08T08:00:00.000Z",
    end_date: "1984-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 3,
    start_date: "1984-01-15T08:00:00.000Z",
    end_date: "1984-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 4,
    start_date: "1984-01-22T08:00:00.000Z",
    end_date: "1984-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 5,
    start_date: "1984-01-29T08:00:00.000Z",
    end_date: "1984-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 6,
    start_date: "1984-02-05T08:00:00.000Z",
    end_date: "1984-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 7,
    start_date: "1984-02-12T08:00:00.000Z",
    end_date: "1984-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 8,
    start_date: "1984-02-19T08:00:00.000Z",
    end_date: "1984-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 9,
    start_date: "1984-02-26T08:00:00.000Z",
    end_date: "1984-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 10,
    start_date: "1984-03-04T08:00:00.000Z",
    end_date: "1984-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 11,
    start_date: "1984-03-11T08:00:00.000Z",
    end_date: "1984-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 12,
    start_date: "1984-03-18T08:00:00.000Z",
    end_date: "1984-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 13,
    start_date: "1984-03-25T08:00:00.000Z",
    end_date: "1984-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 14,
    start_date: "1984-04-01T08:00:00.000Z",
    end_date: "1984-04-07T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 15,
    start_date: "1984-04-08T08:00:00.000Z",
    end_date: "1984-04-14T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 16,
    start_date: "1984-04-15T08:00:00.000Z",
    end_date: "1984-04-21T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 17,
    start_date: "1984-04-22T08:00:00.000Z",
    end_date: "1984-04-28T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 18,
    start_date: "1984-04-29T08:00:00.000Z",
    end_date: "1984-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 19,
    start_date: "1984-05-06T07:00:00.000Z",
    end_date: "1984-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 20,
    start_date: "1984-05-13T07:00:00.000Z",
    end_date: "1984-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 21,
    start_date: "1984-05-20T07:00:00.000Z",
    end_date: "1984-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 22,
    start_date: "1984-05-27T07:00:00.000Z",
    end_date: "1984-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 23,
    start_date: "1984-06-03T07:00:00.000Z",
    end_date: "1984-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 24,
    start_date: "1984-06-10T07:00:00.000Z",
    end_date: "1984-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 25,
    start_date: "1984-06-17T07:00:00.000Z",
    end_date: "1984-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 26,
    start_date: "1984-06-24T07:00:00.000Z",
    end_date: "1984-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 27,
    start_date: "1984-07-01T07:00:00.000Z",
    end_date: "1984-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 28,
    start_date: "1984-07-08T07:00:00.000Z",
    end_date: "1984-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 29,
    start_date: "1984-07-15T07:00:00.000Z",
    end_date: "1984-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 30,
    start_date: "1984-07-22T07:00:00.000Z",
    end_date: "1984-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 31,
    start_date: "1984-07-29T07:00:00.000Z",
    end_date: "1984-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 32,
    start_date: "1984-08-05T07:00:00.000Z",
    end_date: "1984-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 33,
    start_date: "1984-08-12T07:00:00.000Z",
    end_date: "1984-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 34,
    start_date: "1984-08-19T07:00:00.000Z",
    end_date: "1984-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 35,
    start_date: "1984-08-26T07:00:00.000Z",
    end_date: "1984-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 36,
    start_date: "1984-09-02T07:00:00.000Z",
    end_date: "1984-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 37,
    start_date: "1984-09-09T07:00:00.000Z",
    end_date: "1984-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 38,
    start_date: "1984-09-16T07:00:00.000Z",
    end_date: "1984-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 39,
    start_date: "1984-09-23T07:00:00.000Z",
    end_date: "1984-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 40,
    start_date: "1984-09-30T07:00:00.000Z",
    end_date: "1984-10-06T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 41,
    start_date: "1984-10-07T07:00:00.000Z",
    end_date: "1984-10-13T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 42,
    start_date: "1984-10-14T07:00:00.000Z",
    end_date: "1984-10-20T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 43,
    start_date: "1984-10-21T07:00:00.000Z",
    end_date: "1984-10-27T07:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 44,
    start_date: "1984-10-28T07:00:00.000Z",
    end_date: "1984-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 45,
    start_date: "1984-11-04T08:00:00.000Z",
    end_date: "1984-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 46,
    start_date: "1984-11-11T08:00:00.000Z",
    end_date: "1984-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 47,
    start_date: "1984-11-18T08:00:00.000Z",
    end_date: "1984-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 48,
    start_date: "1984-11-25T08:00:00.000Z",
    end_date: "1984-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 49,
    start_date: "1984-12-02T08:00:00.000Z",
    end_date: "1984-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 50,
    start_date: "1984-12-09T08:00:00.000Z",
    end_date: "1984-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 51,
    start_date: "1984-12-16T08:00:00.000Z",
    end_date: "1984-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 52,
    start_date: "1984-12-23T08:00:00.000Z",
    end_date: "1984-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1984,
    disease_week: 53,
    start_date: "1984-12-30T08:00:00.000Z",
    end_date: "1985-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 1,
    start_date: "1984-12-30T08:00:00.000Z",
    end_date: "1985-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 2,
    start_date: "1985-01-06T08:00:00.000Z",
    end_date: "1985-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 3,
    start_date: "1985-01-13T08:00:00.000Z",
    end_date: "1985-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 4,
    start_date: "1985-01-20T08:00:00.000Z",
    end_date: "1985-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 5,
    start_date: "1985-01-27T08:00:00.000Z",
    end_date: "1985-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 6,
    start_date: "1985-02-03T08:00:00.000Z",
    end_date: "1985-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 7,
    start_date: "1985-02-10T08:00:00.000Z",
    end_date: "1985-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 8,
    start_date: "1985-02-17T08:00:00.000Z",
    end_date: "1985-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 9,
    start_date: "1985-02-24T08:00:00.000Z",
    end_date: "1985-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 10,
    start_date: "1985-03-03T08:00:00.000Z",
    end_date: "1985-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 11,
    start_date: "1985-03-10T08:00:00.000Z",
    end_date: "1985-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 12,
    start_date: "1985-03-17T08:00:00.000Z",
    end_date: "1985-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 13,
    start_date: "1985-03-24T08:00:00.000Z",
    end_date: "1985-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 14,
    start_date: "1985-03-31T08:00:00.000Z",
    end_date: "1985-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 15,
    start_date: "1985-04-07T08:00:00.000Z",
    end_date: "1985-04-13T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 16,
    start_date: "1985-04-14T08:00:00.000Z",
    end_date: "1985-04-20T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 17,
    start_date: "1985-04-21T08:00:00.000Z",
    end_date: "1985-04-27T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 18,
    start_date: "1985-04-28T08:00:00.000Z",
    end_date: "1985-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 19,
    start_date: "1985-05-05T07:00:00.000Z",
    end_date: "1985-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 20,
    start_date: "1985-05-12T07:00:00.000Z",
    end_date: "1985-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 21,
    start_date: "1985-05-19T07:00:00.000Z",
    end_date: "1985-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 22,
    start_date: "1985-05-26T07:00:00.000Z",
    end_date: "1985-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 23,
    start_date: "1985-06-02T07:00:00.000Z",
    end_date: "1985-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 24,
    start_date: "1985-06-09T07:00:00.000Z",
    end_date: "1985-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 25,
    start_date: "1985-06-16T07:00:00.000Z",
    end_date: "1985-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 26,
    start_date: "1985-06-23T07:00:00.000Z",
    end_date: "1985-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 27,
    start_date: "1985-06-30T07:00:00.000Z",
    end_date: "1985-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 28,
    start_date: "1985-07-07T07:00:00.000Z",
    end_date: "1985-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 29,
    start_date: "1985-07-14T07:00:00.000Z",
    end_date: "1985-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 30,
    start_date: "1985-07-21T07:00:00.000Z",
    end_date: "1985-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 31,
    start_date: "1985-07-28T07:00:00.000Z",
    end_date: "1985-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 32,
    start_date: "1985-08-04T07:00:00.000Z",
    end_date: "1985-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 33,
    start_date: "1985-08-11T07:00:00.000Z",
    end_date: "1985-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 34,
    start_date: "1985-08-18T07:00:00.000Z",
    end_date: "1985-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 35,
    start_date: "1985-08-25T07:00:00.000Z",
    end_date: "1985-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 36,
    start_date: "1985-09-01T07:00:00.000Z",
    end_date: "1985-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 37,
    start_date: "1985-09-08T07:00:00.000Z",
    end_date: "1985-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 38,
    start_date: "1985-09-15T07:00:00.000Z",
    end_date: "1985-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 39,
    start_date: "1985-09-22T07:00:00.000Z",
    end_date: "1985-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 40,
    start_date: "1985-09-29T07:00:00.000Z",
    end_date: "1985-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 41,
    start_date: "1985-10-06T07:00:00.000Z",
    end_date: "1985-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 42,
    start_date: "1985-10-13T07:00:00.000Z",
    end_date: "1985-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 43,
    start_date: "1985-10-20T07:00:00.000Z",
    end_date: "1985-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 44,
    start_date: "1985-10-27T07:00:00.000Z",
    end_date: "1985-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 45,
    start_date: "1985-11-03T08:00:00.000Z",
    end_date: "1985-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 46,
    start_date: "1985-11-10T08:00:00.000Z",
    end_date: "1985-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 47,
    start_date: "1985-11-17T08:00:00.000Z",
    end_date: "1985-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 48,
    start_date: "1985-11-24T08:00:00.000Z",
    end_date: "1985-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 49,
    start_date: "1985-12-01T08:00:00.000Z",
    end_date: "1985-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 50,
    start_date: "1985-12-08T08:00:00.000Z",
    end_date: "1985-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 51,
    start_date: "1985-12-15T08:00:00.000Z",
    end_date: "1985-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 52,
    start_date: "1985-12-22T08:00:00.000Z",
    end_date: "1985-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1985,
    disease_week: 53,
    start_date: "1985-12-29T08:00:00.000Z",
    end_date: "1986-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 1,
    start_date: "1985-12-29T08:00:00.000Z",
    end_date: "1986-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 2,
    start_date: "1986-01-05T08:00:00.000Z",
    end_date: "1986-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 3,
    start_date: "1986-01-12T08:00:00.000Z",
    end_date: "1986-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 4,
    start_date: "1986-01-19T08:00:00.000Z",
    end_date: "1986-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 5,
    start_date: "1986-01-26T08:00:00.000Z",
    end_date: "1986-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 6,
    start_date: "1986-02-02T08:00:00.000Z",
    end_date: "1986-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 7,
    start_date: "1986-02-09T08:00:00.000Z",
    end_date: "1986-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 8,
    start_date: "1986-02-16T08:00:00.000Z",
    end_date: "1986-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 9,
    start_date: "1986-02-23T08:00:00.000Z",
    end_date: "1986-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 10,
    start_date: "1986-03-02T08:00:00.000Z",
    end_date: "1986-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 11,
    start_date: "1986-03-09T08:00:00.000Z",
    end_date: "1986-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 12,
    start_date: "1986-03-16T08:00:00.000Z",
    end_date: "1986-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 13,
    start_date: "1986-03-23T08:00:00.000Z",
    end_date: "1986-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 14,
    start_date: "1986-03-30T08:00:00.000Z",
    end_date: "1986-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 15,
    start_date: "1986-04-06T08:00:00.000Z",
    end_date: "1986-04-12T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 16,
    start_date: "1986-04-13T08:00:00.000Z",
    end_date: "1986-04-19T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 17,
    start_date: "1986-04-20T08:00:00.000Z",
    end_date: "1986-04-26T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 18,
    start_date: "1986-04-27T08:00:00.000Z",
    end_date: "1986-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 19,
    start_date: "1986-05-04T07:00:00.000Z",
    end_date: "1986-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 20,
    start_date: "1986-05-11T07:00:00.000Z",
    end_date: "1986-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 21,
    start_date: "1986-05-18T07:00:00.000Z",
    end_date: "1986-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 22,
    start_date: "1986-05-25T07:00:00.000Z",
    end_date: "1986-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 23,
    start_date: "1986-06-01T07:00:00.000Z",
    end_date: "1986-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 24,
    start_date: "1986-06-08T07:00:00.000Z",
    end_date: "1986-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 25,
    start_date: "1986-06-15T07:00:00.000Z",
    end_date: "1986-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 26,
    start_date: "1986-06-22T07:00:00.000Z",
    end_date: "1986-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 27,
    start_date: "1986-06-29T07:00:00.000Z",
    end_date: "1986-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 28,
    start_date: "1986-07-06T07:00:00.000Z",
    end_date: "1986-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 29,
    start_date: "1986-07-13T07:00:00.000Z",
    end_date: "1986-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 30,
    start_date: "1986-07-20T07:00:00.000Z",
    end_date: "1986-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 31,
    start_date: "1986-07-27T07:00:00.000Z",
    end_date: "1986-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 32,
    start_date: "1986-08-03T07:00:00.000Z",
    end_date: "1986-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 33,
    start_date: "1986-08-10T07:00:00.000Z",
    end_date: "1986-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 34,
    start_date: "1986-08-17T07:00:00.000Z",
    end_date: "1986-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 35,
    start_date: "1986-08-24T07:00:00.000Z",
    end_date: "1986-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 36,
    start_date: "1986-08-31T07:00:00.000Z",
    end_date: "1986-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 37,
    start_date: "1986-09-07T07:00:00.000Z",
    end_date: "1986-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 38,
    start_date: "1986-09-14T07:00:00.000Z",
    end_date: "1986-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 39,
    start_date: "1986-09-21T07:00:00.000Z",
    end_date: "1986-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 40,
    start_date: "1986-09-28T07:00:00.000Z",
    end_date: "1986-10-04T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 41,
    start_date: "1986-10-05T07:00:00.000Z",
    end_date: "1986-10-11T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 42,
    start_date: "1986-10-12T07:00:00.000Z",
    end_date: "1986-10-18T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 43,
    start_date: "1986-10-19T07:00:00.000Z",
    end_date: "1986-10-25T07:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 44,
    start_date: "1986-10-26T07:00:00.000Z",
    end_date: "1986-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 45,
    start_date: "1986-11-02T08:00:00.000Z",
    end_date: "1986-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 46,
    start_date: "1986-11-09T08:00:00.000Z",
    end_date: "1986-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 47,
    start_date: "1986-11-16T08:00:00.000Z",
    end_date: "1986-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 48,
    start_date: "1986-11-23T08:00:00.000Z",
    end_date: "1986-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 49,
    start_date: "1986-11-30T08:00:00.000Z",
    end_date: "1986-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 50,
    start_date: "1986-12-07T08:00:00.000Z",
    end_date: "1986-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 51,
    start_date: "1986-12-14T08:00:00.000Z",
    end_date: "1986-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 52,
    start_date: "1986-12-21T08:00:00.000Z",
    end_date: "1986-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1986,
    disease_week: 53,
    start_date: "1986-12-28T08:00:00.000Z",
    end_date: "1987-01-03T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 1,
    start_date: "1989-01-01T08:00:00.000Z",
    end_date: "1989-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 2,
    start_date: "1989-01-08T08:00:00.000Z",
    end_date: "1989-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 3,
    start_date: "1989-01-15T08:00:00.000Z",
    end_date: "1989-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 4,
    start_date: "1989-01-22T08:00:00.000Z",
    end_date: "1989-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 5,
    start_date: "1989-01-29T08:00:00.000Z",
    end_date: "1989-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 6,
    start_date: "1989-02-05T08:00:00.000Z",
    end_date: "1989-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 7,
    start_date: "1989-02-12T08:00:00.000Z",
    end_date: "1989-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 8,
    start_date: "1989-02-19T08:00:00.000Z",
    end_date: "1989-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 9,
    start_date: "1989-02-26T08:00:00.000Z",
    end_date: "1989-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 10,
    start_date: "1989-03-05T08:00:00.000Z",
    end_date: "1989-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 11,
    start_date: "1989-03-12T08:00:00.000Z",
    end_date: "1989-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 12,
    start_date: "1989-03-19T08:00:00.000Z",
    end_date: "1989-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 13,
    start_date: "1989-03-26T08:00:00.000Z",
    end_date: "1989-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 14,
    start_date: "1989-04-02T08:00:00.000Z",
    end_date: "1989-04-08T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 15,
    start_date: "1989-04-09T07:00:00.000Z",
    end_date: "1989-04-15T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 16,
    start_date: "1989-04-16T07:00:00.000Z",
    end_date: "1989-04-22T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 17,
    start_date: "1989-04-23T07:00:00.000Z",
    end_date: "1989-04-29T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 18,
    start_date: "1989-04-30T07:00:00.000Z",
    end_date: "1989-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 19,
    start_date: "1989-05-07T07:00:00.000Z",
    end_date: "1989-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 20,
    start_date: "1989-05-14T07:00:00.000Z",
    end_date: "1989-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 21,
    start_date: "1989-05-21T07:00:00.000Z",
    end_date: "1989-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 22,
    start_date: "1989-05-28T07:00:00.000Z",
    end_date: "1989-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 23,
    start_date: "1989-06-04T07:00:00.000Z",
    end_date: "1989-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 24,
    start_date: "1989-06-11T07:00:00.000Z",
    end_date: "1989-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 25,
    start_date: "1989-06-18T07:00:00.000Z",
    end_date: "1989-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 26,
    start_date: "1989-06-25T07:00:00.000Z",
    end_date: "1989-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 27,
    start_date: "1989-07-02T07:00:00.000Z",
    end_date: "1989-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 28,
    start_date: "1989-07-09T07:00:00.000Z",
    end_date: "1989-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 29,
    start_date: "1989-07-16T07:00:00.000Z",
    end_date: "1989-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 30,
    start_date: "1989-07-23T07:00:00.000Z",
    end_date: "1989-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 31,
    start_date: "1989-07-30T07:00:00.000Z",
    end_date: "1989-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 32,
    start_date: "1989-08-06T07:00:00.000Z",
    end_date: "1989-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 33,
    start_date: "1989-08-13T07:00:00.000Z",
    end_date: "1989-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 34,
    start_date: "1989-08-20T07:00:00.000Z",
    end_date: "1989-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 35,
    start_date: "1989-08-27T07:00:00.000Z",
    end_date: "1989-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 36,
    start_date: "1989-09-03T07:00:00.000Z",
    end_date: "1989-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 37,
    start_date: "1989-09-10T07:00:00.000Z",
    end_date: "1989-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 38,
    start_date: "1989-09-17T07:00:00.000Z",
    end_date: "1989-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 39,
    start_date: "1989-09-24T07:00:00.000Z",
    end_date: "1989-09-30T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 40,
    start_date: "1989-10-01T07:00:00.000Z",
    end_date: "1989-10-07T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 41,
    start_date: "1989-10-08T07:00:00.000Z",
    end_date: "1989-10-14T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 42,
    start_date: "1989-10-15T07:00:00.000Z",
    end_date: "1989-10-21T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 43,
    start_date: "1989-10-22T07:00:00.000Z",
    end_date: "1989-10-28T07:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 44,
    start_date: "1989-10-29T07:00:00.000Z",
    end_date: "1989-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 45,
    start_date: "1989-11-05T08:00:00.000Z",
    end_date: "1989-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 46,
    start_date: "1989-11-12T08:00:00.000Z",
    end_date: "1989-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 47,
    start_date: "1989-11-19T08:00:00.000Z",
    end_date: "1989-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 48,
    start_date: "1989-11-26T08:00:00.000Z",
    end_date: "1989-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 49,
    start_date: "1989-12-03T08:00:00.000Z",
    end_date: "1989-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 50,
    start_date: "1989-12-10T08:00:00.000Z",
    end_date: "1989-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 51,
    start_date: "1989-12-17T08:00:00.000Z",
    end_date: "1989-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 52,
    start_date: "1989-12-24T08:00:00.000Z",
    end_date: "1989-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1989,
    disease_week: 53,
    start_date: "1989-12-31T08:00:00.000Z",
    end_date: "1990-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 1,
    start_date: "1989-12-31T08:00:00.000Z",
    end_date: "1990-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 2,
    start_date: "1990-01-07T08:00:00.000Z",
    end_date: "1990-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 3,
    start_date: "1990-01-14T08:00:00.000Z",
    end_date: "1990-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 4,
    start_date: "1990-01-21T08:00:00.000Z",
    end_date: "1990-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 5,
    start_date: "1990-01-28T08:00:00.000Z",
    end_date: "1990-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 6,
    start_date: "1990-02-04T08:00:00.000Z",
    end_date: "1990-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 7,
    start_date: "1990-02-11T08:00:00.000Z",
    end_date: "1990-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 8,
    start_date: "1990-02-18T08:00:00.000Z",
    end_date: "1990-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 9,
    start_date: "1990-02-25T08:00:00.000Z",
    end_date: "1990-03-03T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 10,
    start_date: "1990-03-04T08:00:00.000Z",
    end_date: "1990-03-10T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 11,
    start_date: "1990-03-11T08:00:00.000Z",
    end_date: "1990-03-17T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 12,
    start_date: "1990-03-18T08:00:00.000Z",
    end_date: "1990-03-24T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 13,
    start_date: "1990-03-25T08:00:00.000Z",
    end_date: "1990-03-31T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 14,
    start_date: "1990-04-01T08:00:00.000Z",
    end_date: "1990-04-07T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 15,
    start_date: "1990-04-08T07:00:00.000Z",
    end_date: "1990-04-14T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 16,
    start_date: "1990-04-15T07:00:00.000Z",
    end_date: "1990-04-21T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 17,
    start_date: "1990-04-22T07:00:00.000Z",
    end_date: "1990-04-28T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 18,
    start_date: "1990-04-29T07:00:00.000Z",
    end_date: "1990-05-05T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 19,
    start_date: "1990-05-06T07:00:00.000Z",
    end_date: "1990-05-12T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 20,
    start_date: "1990-05-13T07:00:00.000Z",
    end_date: "1990-05-19T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 21,
    start_date: "1990-05-20T07:00:00.000Z",
    end_date: "1990-05-26T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 22,
    start_date: "1990-05-27T07:00:00.000Z",
    end_date: "1990-06-02T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 23,
    start_date: "1990-06-03T07:00:00.000Z",
    end_date: "1990-06-09T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 24,
    start_date: "1990-06-10T07:00:00.000Z",
    end_date: "1990-06-16T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 25,
    start_date: "1990-06-17T07:00:00.000Z",
    end_date: "1990-06-23T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 26,
    start_date: "1990-06-24T07:00:00.000Z",
    end_date: "1990-06-30T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 27,
    start_date: "1990-07-01T07:00:00.000Z",
    end_date: "1990-07-07T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 28,
    start_date: "1990-07-08T07:00:00.000Z",
    end_date: "1990-07-14T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 29,
    start_date: "1990-07-15T07:00:00.000Z",
    end_date: "1990-07-21T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 30,
    start_date: "1990-07-22T07:00:00.000Z",
    end_date: "1990-07-28T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 31,
    start_date: "1990-07-29T07:00:00.000Z",
    end_date: "1990-08-04T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 32,
    start_date: "1990-08-05T07:00:00.000Z",
    end_date: "1990-08-11T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 33,
    start_date: "1990-08-12T07:00:00.000Z",
    end_date: "1990-08-18T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 34,
    start_date: "1990-08-19T07:00:00.000Z",
    end_date: "1990-08-25T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 35,
    start_date: "1990-08-26T07:00:00.000Z",
    end_date: "1990-09-01T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 36,
    start_date: "1990-09-02T07:00:00.000Z",
    end_date: "1990-09-08T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 37,
    start_date: "1990-09-09T07:00:00.000Z",
    end_date: "1990-09-15T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 38,
    start_date: "1990-09-16T07:00:00.000Z",
    end_date: "1990-09-22T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 39,
    start_date: "1990-09-23T07:00:00.000Z",
    end_date: "1990-09-29T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 40,
    start_date: "1990-09-30T07:00:00.000Z",
    end_date: "1990-10-06T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 41,
    start_date: "1990-10-07T07:00:00.000Z",
    end_date: "1990-10-13T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 42,
    start_date: "1990-10-14T07:00:00.000Z",
    end_date: "1990-10-20T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 43,
    start_date: "1990-10-21T07:00:00.000Z",
    end_date: "1990-10-27T07:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 44,
    start_date: "1990-10-28T07:00:00.000Z",
    end_date: "1990-11-03T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 45,
    start_date: "1990-11-04T08:00:00.000Z",
    end_date: "1990-11-10T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 46,
    start_date: "1990-11-11T08:00:00.000Z",
    end_date: "1990-11-17T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 47,
    start_date: "1990-11-18T08:00:00.000Z",
    end_date: "1990-11-24T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 48,
    start_date: "1990-11-25T08:00:00.000Z",
    end_date: "1990-12-01T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 49,
    start_date: "1990-12-02T08:00:00.000Z",
    end_date: "1990-12-08T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 50,
    start_date: "1990-12-09T08:00:00.000Z",
    end_date: "1990-12-15T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 51,
    start_date: "1990-12-16T08:00:00.000Z",
    end_date: "1990-12-22T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 52,
    start_date: "1990-12-23T08:00:00.000Z",
    end_date: "1990-12-29T08:00:00.000Z",
  },
  {
    disease_year: 1990,
    disease_week: 53,
    start_date: "1990-12-30T08:00:00.000Z",
    end_date: "1991-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 1,
    start_date: "1990-12-30T08:00:00.000Z",
    end_date: "1991-01-05T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 2,
    start_date: "1991-01-06T08:00:00.000Z",
    end_date: "1991-01-12T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 3,
    start_date: "1991-01-13T08:00:00.000Z",
    end_date: "1991-01-19T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 4,
    start_date: "1991-01-20T08:00:00.000Z",
    end_date: "1991-01-26T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 5,
    start_date: "1991-01-27T08:00:00.000Z",
    end_date: "1991-02-02T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 6,
    start_date: "1991-02-03T08:00:00.000Z",
    end_date: "1991-02-09T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 7,
    start_date: "1991-02-10T08:00:00.000Z",
    end_date: "1991-02-16T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 8,
    start_date: "1991-02-17T08:00:00.000Z",
    end_date: "1991-02-23T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 9,
    start_date: "1991-02-24T08:00:00.000Z",
    end_date: "1991-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 10,
    start_date: "1991-03-03T08:00:00.000Z",
    end_date: "1991-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 11,
    start_date: "1991-03-10T08:00:00.000Z",
    end_date: "1991-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 12,
    start_date: "1991-03-17T08:00:00.000Z",
    end_date: "1991-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 13,
    start_date: "1991-03-24T08:00:00.000Z",
    end_date: "1991-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 14,
    start_date: "1991-03-31T08:00:00.000Z",
    end_date: "1991-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 15,
    start_date: "1991-04-07T08:00:00.000Z",
    end_date: "1991-04-13T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 16,
    start_date: "1991-04-14T07:00:00.000Z",
    end_date: "1991-04-20T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 17,
    start_date: "1991-04-21T07:00:00.000Z",
    end_date: "1991-04-27T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 18,
    start_date: "1991-04-28T07:00:00.000Z",
    end_date: "1991-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 19,
    start_date: "1991-05-05T07:00:00.000Z",
    end_date: "1991-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 20,
    start_date: "1991-05-12T07:00:00.000Z",
    end_date: "1991-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 21,
    start_date: "1991-05-19T07:00:00.000Z",
    end_date: "1991-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 22,
    start_date: "1991-05-26T07:00:00.000Z",
    end_date: "1991-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 23,
    start_date: "1991-06-02T07:00:00.000Z",
    end_date: "1991-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 24,
    start_date: "1991-06-09T07:00:00.000Z",
    end_date: "1991-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 25,
    start_date: "1991-06-16T07:00:00.000Z",
    end_date: "1991-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 26,
    start_date: "1991-06-23T07:00:00.000Z",
    end_date: "1991-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 27,
    start_date: "1991-06-30T07:00:00.000Z",
    end_date: "1991-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 28,
    start_date: "1991-07-07T07:00:00.000Z",
    end_date: "1991-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 29,
    start_date: "1991-07-14T07:00:00.000Z",
    end_date: "1991-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 30,
    start_date: "1991-07-21T07:00:00.000Z",
    end_date: "1991-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 31,
    start_date: "1991-07-28T07:00:00.000Z",
    end_date: "1991-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 32,
    start_date: "1991-08-04T07:00:00.000Z",
    end_date: "1991-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 33,
    start_date: "1991-08-11T07:00:00.000Z",
    end_date: "1991-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 34,
    start_date: "1991-08-18T07:00:00.000Z",
    end_date: "1991-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 35,
    start_date: "1991-08-25T07:00:00.000Z",
    end_date: "1991-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 36,
    start_date: "1991-09-01T07:00:00.000Z",
    end_date: "1991-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 37,
    start_date: "1991-09-08T07:00:00.000Z",
    end_date: "1991-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 38,
    start_date: "1991-09-15T07:00:00.000Z",
    end_date: "1991-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 39,
    start_date: "1991-09-22T07:00:00.000Z",
    end_date: "1991-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 40,
    start_date: "1991-09-29T07:00:00.000Z",
    end_date: "1991-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 41,
    start_date: "1991-10-06T07:00:00.000Z",
    end_date: "1991-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 42,
    start_date: "1991-10-13T07:00:00.000Z",
    end_date: "1991-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 43,
    start_date: "1991-10-20T07:00:00.000Z",
    end_date: "1991-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 44,
    start_date: "1991-10-27T07:00:00.000Z",
    end_date: "1991-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 45,
    start_date: "1991-11-03T08:00:00.000Z",
    end_date: "1991-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 46,
    start_date: "1991-11-10T08:00:00.000Z",
    end_date: "1991-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 47,
    start_date: "1991-11-17T08:00:00.000Z",
    end_date: "1991-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 48,
    start_date: "1991-11-24T08:00:00.000Z",
    end_date: "1991-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 49,
    start_date: "1991-12-01T08:00:00.000Z",
    end_date: "1991-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 50,
    start_date: "1991-12-08T08:00:00.000Z",
    end_date: "1991-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 51,
    start_date: "1991-12-15T08:00:00.000Z",
    end_date: "1991-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 52,
    start_date: "1991-12-22T08:00:00.000Z",
    end_date: "1991-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1991,
    disease_week: 53,
    start_date: "1991-12-29T08:00:00.000Z",
    end_date: "1992-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 1,
    start_date: "1991-12-29T08:00:00.000Z",
    end_date: "1992-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 2,
    start_date: "1992-01-05T08:00:00.000Z",
    end_date: "1992-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 3,
    start_date: "1992-01-12T08:00:00.000Z",
    end_date: "1992-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 4,
    start_date: "1992-01-19T08:00:00.000Z",
    end_date: "1992-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 5,
    start_date: "1992-01-26T08:00:00.000Z",
    end_date: "1992-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 6,
    start_date: "1992-02-02T08:00:00.000Z",
    end_date: "1992-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 7,
    start_date: "1992-02-09T08:00:00.000Z",
    end_date: "1992-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 8,
    start_date: "1992-02-16T08:00:00.000Z",
    end_date: "1992-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 9,
    start_date: "1992-02-23T08:00:00.000Z",
    end_date: "1992-02-29T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 10,
    start_date: "1992-03-01T08:00:00.000Z",
    end_date: "1992-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 11,
    start_date: "1992-03-08T08:00:00.000Z",
    end_date: "1992-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 12,
    start_date: "1992-03-15T08:00:00.000Z",
    end_date: "1992-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 13,
    start_date: "1992-03-22T08:00:00.000Z",
    end_date: "1992-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 14,
    start_date: "1992-03-29T08:00:00.000Z",
    end_date: "1992-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 15,
    start_date: "1992-04-05T08:00:00.000Z",
    end_date: "1992-04-11T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 16,
    start_date: "1992-04-12T07:00:00.000Z",
    end_date: "1992-04-18T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 17,
    start_date: "1992-04-19T07:00:00.000Z",
    end_date: "1992-04-25T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 18,
    start_date: "1992-04-26T07:00:00.000Z",
    end_date: "1992-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 19,
    start_date: "1992-05-03T07:00:00.000Z",
    end_date: "1992-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 20,
    start_date: "1992-05-10T07:00:00.000Z",
    end_date: "1992-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 21,
    start_date: "1992-05-17T07:00:00.000Z",
    end_date: "1992-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 22,
    start_date: "1992-05-24T07:00:00.000Z",
    end_date: "1992-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 23,
    start_date: "1992-05-31T07:00:00.000Z",
    end_date: "1992-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 24,
    start_date: "1992-06-07T07:00:00.000Z",
    end_date: "1992-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 25,
    start_date: "1992-06-14T07:00:00.000Z",
    end_date: "1992-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 26,
    start_date: "1992-06-21T07:00:00.000Z",
    end_date: "1992-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 27,
    start_date: "1992-06-28T07:00:00.000Z",
    end_date: "1992-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 28,
    start_date: "1992-07-05T07:00:00.000Z",
    end_date: "1992-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 29,
    start_date: "1992-07-12T07:00:00.000Z",
    end_date: "1992-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 30,
    start_date: "1992-07-19T07:00:00.000Z",
    end_date: "1992-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 31,
    start_date: "1992-07-26T07:00:00.000Z",
    end_date: "1992-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 32,
    start_date: "1992-08-02T07:00:00.000Z",
    end_date: "1992-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 33,
    start_date: "1992-08-09T07:00:00.000Z",
    end_date: "1992-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 34,
    start_date: "1992-08-16T07:00:00.000Z",
    end_date: "1992-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 35,
    start_date: "1992-08-23T07:00:00.000Z",
    end_date: "1992-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 36,
    start_date: "1992-08-30T07:00:00.000Z",
    end_date: "1992-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 37,
    start_date: "1992-09-06T07:00:00.000Z",
    end_date: "1992-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 38,
    start_date: "1992-09-13T07:00:00.000Z",
    end_date: "1992-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 39,
    start_date: "1992-09-20T07:00:00.000Z",
    end_date: "1992-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 40,
    start_date: "1992-09-27T07:00:00.000Z",
    end_date: "1992-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 41,
    start_date: "1992-10-04T07:00:00.000Z",
    end_date: "1992-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 42,
    start_date: "1992-10-11T07:00:00.000Z",
    end_date: "1992-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 43,
    start_date: "1992-10-18T07:00:00.000Z",
    end_date: "1992-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 44,
    start_date: "1992-10-25T07:00:00.000Z",
    end_date: "1992-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 45,
    start_date: "1992-11-01T08:00:00.000Z",
    end_date: "1992-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 46,
    start_date: "1992-11-08T08:00:00.000Z",
    end_date: "1992-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 47,
    start_date: "1992-11-15T08:00:00.000Z",
    end_date: "1992-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 48,
    start_date: "1992-11-22T08:00:00.000Z",
    end_date: "1992-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 49,
    start_date: "1992-11-29T08:00:00.000Z",
    end_date: "1992-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 50,
    start_date: "1992-12-06T08:00:00.000Z",
    end_date: "1992-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 51,
    start_date: "1992-12-13T08:00:00.000Z",
    end_date: "1992-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 52,
    start_date: "1992-12-20T08:00:00.000Z",
    end_date: "1992-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1992,
    disease_week: 53,
    start_date: "1992-12-27T08:00:00.000Z",
    end_date: "1993-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 1,
    start_date: "1995-01-01T08:00:00.000Z",
    end_date: "1995-01-07T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 2,
    start_date: "1995-01-08T08:00:00.000Z",
    end_date: "1995-01-14T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 3,
    start_date: "1995-01-15T08:00:00.000Z",
    end_date: "1995-01-21T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 4,
    start_date: "1995-01-22T08:00:00.000Z",
    end_date: "1995-01-28T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 5,
    start_date: "1995-01-29T08:00:00.000Z",
    end_date: "1995-02-04T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 6,
    start_date: "1995-02-05T08:00:00.000Z",
    end_date: "1995-02-11T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 7,
    start_date: "1995-02-12T08:00:00.000Z",
    end_date: "1995-02-18T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 8,
    start_date: "1995-02-19T08:00:00.000Z",
    end_date: "1995-02-25T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 9,
    start_date: "1995-02-26T08:00:00.000Z",
    end_date: "1995-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 10,
    start_date: "1995-03-05T08:00:00.000Z",
    end_date: "1995-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 11,
    start_date: "1995-03-12T08:00:00.000Z",
    end_date: "1995-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 12,
    start_date: "1995-03-19T08:00:00.000Z",
    end_date: "1995-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 13,
    start_date: "1995-03-26T08:00:00.000Z",
    end_date: "1995-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 14,
    start_date: "1995-04-02T08:00:00.000Z",
    end_date: "1995-04-08T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 15,
    start_date: "1995-04-09T07:00:00.000Z",
    end_date: "1995-04-15T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 16,
    start_date: "1995-04-16T07:00:00.000Z",
    end_date: "1995-04-22T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 17,
    start_date: "1995-04-23T07:00:00.000Z",
    end_date: "1995-04-29T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 18,
    start_date: "1995-04-30T07:00:00.000Z",
    end_date: "1995-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 19,
    start_date: "1995-05-07T07:00:00.000Z",
    end_date: "1995-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 20,
    start_date: "1995-05-14T07:00:00.000Z",
    end_date: "1995-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 21,
    start_date: "1995-05-21T07:00:00.000Z",
    end_date: "1995-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 22,
    start_date: "1995-05-28T07:00:00.000Z",
    end_date: "1995-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 23,
    start_date: "1995-06-04T07:00:00.000Z",
    end_date: "1995-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 24,
    start_date: "1995-06-11T07:00:00.000Z",
    end_date: "1995-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 25,
    start_date: "1995-06-18T07:00:00.000Z",
    end_date: "1995-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 26,
    start_date: "1995-06-25T07:00:00.000Z",
    end_date: "1995-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 27,
    start_date: "1995-07-02T07:00:00.000Z",
    end_date: "1995-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 28,
    start_date: "1995-07-09T07:00:00.000Z",
    end_date: "1995-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 29,
    start_date: "1995-07-16T07:00:00.000Z",
    end_date: "1995-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 30,
    start_date: "1995-07-23T07:00:00.000Z",
    end_date: "1995-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 31,
    start_date: "1995-07-30T07:00:00.000Z",
    end_date: "1995-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 32,
    start_date: "1995-08-06T07:00:00.000Z",
    end_date: "1995-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 33,
    start_date: "1995-08-13T07:00:00.000Z",
    end_date: "1995-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 34,
    start_date: "1995-08-20T07:00:00.000Z",
    end_date: "1995-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 35,
    start_date: "1995-08-27T07:00:00.000Z",
    end_date: "1995-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 36,
    start_date: "1995-09-03T07:00:00.000Z",
    end_date: "1995-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 37,
    start_date: "1995-09-10T07:00:00.000Z",
    end_date: "1995-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 38,
    start_date: "1995-09-17T07:00:00.000Z",
    end_date: "1995-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 39,
    start_date: "1995-09-24T07:00:00.000Z",
    end_date: "1995-09-30T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 40,
    start_date: "1995-10-01T07:00:00.000Z",
    end_date: "1995-10-07T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 41,
    start_date: "1995-10-08T07:00:00.000Z",
    end_date: "1995-10-14T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 42,
    start_date: "1995-10-15T07:00:00.000Z",
    end_date: "1995-10-21T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 43,
    start_date: "1995-10-22T07:00:00.000Z",
    end_date: "1995-10-28T07:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 44,
    start_date: "1995-10-29T07:00:00.000Z",
    end_date: "1995-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 45,
    start_date: "1995-11-05T08:00:00.000Z",
    end_date: "1995-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 46,
    start_date: "1995-11-12T08:00:00.000Z",
    end_date: "1995-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 47,
    start_date: "1995-11-19T08:00:00.000Z",
    end_date: "1995-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 48,
    start_date: "1995-11-26T08:00:00.000Z",
    end_date: "1995-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 49,
    start_date: "1995-12-03T08:00:00.000Z",
    end_date: "1995-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 50,
    start_date: "1995-12-10T08:00:00.000Z",
    end_date: "1995-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 51,
    start_date: "1995-12-17T08:00:00.000Z",
    end_date: "1995-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 52,
    start_date: "1995-12-24T08:00:00.000Z",
    end_date: "1995-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1995,
    disease_week: 53,
    start_date: "1995-12-31T08:00:00.000Z",
    end_date: "1996-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 1,
    start_date: "1995-12-31T08:00:00.000Z",
    end_date: "1996-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 2,
    start_date: "1996-01-07T08:00:00.000Z",
    end_date: "1996-01-13T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 3,
    start_date: "1996-01-14T08:00:00.000Z",
    end_date: "1996-01-20T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 4,
    start_date: "1996-01-21T08:00:00.000Z",
    end_date: "1996-01-27T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 5,
    start_date: "1996-01-28T08:00:00.000Z",
    end_date: "1996-02-03T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 6,
    start_date: "1996-02-04T08:00:00.000Z",
    end_date: "1996-02-10T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 7,
    start_date: "1996-02-11T08:00:00.000Z",
    end_date: "1996-02-17T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 8,
    start_date: "1996-02-18T08:00:00.000Z",
    end_date: "1996-02-24T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 9,
    start_date: "1996-02-25T08:00:00.000Z",
    end_date: "1996-03-02T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 10,
    start_date: "1996-03-03T08:00:00.000Z",
    end_date: "1996-03-09T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 11,
    start_date: "1996-03-10T08:00:00.000Z",
    end_date: "1996-03-16T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 12,
    start_date: "1996-03-17T08:00:00.000Z",
    end_date: "1996-03-23T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 13,
    start_date: "1996-03-24T08:00:00.000Z",
    end_date: "1996-03-30T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 14,
    start_date: "1996-03-31T08:00:00.000Z",
    end_date: "1996-04-06T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 15,
    start_date: "1996-04-07T08:00:00.000Z",
    end_date: "1996-04-13T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 16,
    start_date: "1996-04-14T07:00:00.000Z",
    end_date: "1996-04-20T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 17,
    start_date: "1996-04-21T07:00:00.000Z",
    end_date: "1996-04-27T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 18,
    start_date: "1996-04-28T07:00:00.000Z",
    end_date: "1996-05-04T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 19,
    start_date: "1996-05-05T07:00:00.000Z",
    end_date: "1996-05-11T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 20,
    start_date: "1996-05-12T07:00:00.000Z",
    end_date: "1996-05-18T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 21,
    start_date: "1996-05-19T07:00:00.000Z",
    end_date: "1996-05-25T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 22,
    start_date: "1996-05-26T07:00:00.000Z",
    end_date: "1996-06-01T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 23,
    start_date: "1996-06-02T07:00:00.000Z",
    end_date: "1996-06-08T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 24,
    start_date: "1996-06-09T07:00:00.000Z",
    end_date: "1996-06-15T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 25,
    start_date: "1996-06-16T07:00:00.000Z",
    end_date: "1996-06-22T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 26,
    start_date: "1996-06-23T07:00:00.000Z",
    end_date: "1996-06-29T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 27,
    start_date: "1996-06-30T07:00:00.000Z",
    end_date: "1996-07-06T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 28,
    start_date: "1996-07-07T07:00:00.000Z",
    end_date: "1996-07-13T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 29,
    start_date: "1996-07-14T07:00:00.000Z",
    end_date: "1996-07-20T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 30,
    start_date: "1996-07-21T07:00:00.000Z",
    end_date: "1996-07-27T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 31,
    start_date: "1996-07-28T07:00:00.000Z",
    end_date: "1996-08-03T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 32,
    start_date: "1996-08-04T07:00:00.000Z",
    end_date: "1996-08-10T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 33,
    start_date: "1996-08-11T07:00:00.000Z",
    end_date: "1996-08-17T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 34,
    start_date: "1996-08-18T07:00:00.000Z",
    end_date: "1996-08-24T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 35,
    start_date: "1996-08-25T07:00:00.000Z",
    end_date: "1996-08-31T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 36,
    start_date: "1996-09-01T07:00:00.000Z",
    end_date: "1996-09-07T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 37,
    start_date: "1996-09-08T07:00:00.000Z",
    end_date: "1996-09-14T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 38,
    start_date: "1996-09-15T07:00:00.000Z",
    end_date: "1996-09-21T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 39,
    start_date: "1996-09-22T07:00:00.000Z",
    end_date: "1996-09-28T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 40,
    start_date: "1996-09-29T07:00:00.000Z",
    end_date: "1996-10-05T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 41,
    start_date: "1996-10-06T07:00:00.000Z",
    end_date: "1996-10-12T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 42,
    start_date: "1996-10-13T07:00:00.000Z",
    end_date: "1996-10-19T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 43,
    start_date: "1996-10-20T07:00:00.000Z",
    end_date: "1996-10-26T07:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 44,
    start_date: "1996-10-27T07:00:00.000Z",
    end_date: "1996-11-02T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 45,
    start_date: "1996-11-03T08:00:00.000Z",
    end_date: "1996-11-09T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 46,
    start_date: "1996-11-10T08:00:00.000Z",
    end_date: "1996-11-16T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 47,
    start_date: "1996-11-17T08:00:00.000Z",
    end_date: "1996-11-23T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 48,
    start_date: "1996-11-24T08:00:00.000Z",
    end_date: "1996-11-30T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 49,
    start_date: "1996-12-01T08:00:00.000Z",
    end_date: "1996-12-07T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 50,
    start_date: "1996-12-08T08:00:00.000Z",
    end_date: "1996-12-14T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 51,
    start_date: "1996-12-15T08:00:00.000Z",
    end_date: "1996-12-21T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 52,
    start_date: "1996-12-22T08:00:00.000Z",
    end_date: "1996-12-28T08:00:00.000Z",
  },
  {
    disease_year: 1996,
    disease_week: 53,
    start_date: "1996-12-29T08:00:00.000Z",
    end_date: "1997-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 1,
    start_date: "1996-12-29T08:00:00.000Z",
    end_date: "1997-01-04T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 2,
    start_date: "1997-01-05T08:00:00.000Z",
    end_date: "1997-01-11T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 3,
    start_date: "1997-01-12T08:00:00.000Z",
    end_date: "1997-01-18T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 4,
    start_date: "1997-01-19T08:00:00.000Z",
    end_date: "1997-01-25T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 5,
    start_date: "1997-01-26T08:00:00.000Z",
    end_date: "1997-02-01T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 6,
    start_date: "1997-02-02T08:00:00.000Z",
    end_date: "1997-02-08T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 7,
    start_date: "1997-02-09T08:00:00.000Z",
    end_date: "1997-02-15T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 8,
    start_date: "1997-02-16T08:00:00.000Z",
    end_date: "1997-02-22T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 9,
    start_date: "1997-02-23T08:00:00.000Z",
    end_date: "1997-03-01T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 10,
    start_date: "1997-03-02T08:00:00.000Z",
    end_date: "1997-03-08T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 11,
    start_date: "1997-03-09T08:00:00.000Z",
    end_date: "1997-03-15T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 12,
    start_date: "1997-03-16T08:00:00.000Z",
    end_date: "1997-03-22T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 13,
    start_date: "1997-03-23T08:00:00.000Z",
    end_date: "1997-03-29T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 14,
    start_date: "1997-03-30T08:00:00.000Z",
    end_date: "1997-04-05T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 15,
    start_date: "1997-04-06T08:00:00.000Z",
    end_date: "1997-04-12T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 16,
    start_date: "1997-04-13T07:00:00.000Z",
    end_date: "1997-04-19T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 17,
    start_date: "1997-04-20T07:00:00.000Z",
    end_date: "1997-04-26T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 18,
    start_date: "1997-04-27T07:00:00.000Z",
    end_date: "1997-05-03T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 19,
    start_date: "1997-05-04T07:00:00.000Z",
    end_date: "1997-05-10T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 20,
    start_date: "1997-05-11T07:00:00.000Z",
    end_date: "1997-05-17T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 21,
    start_date: "1997-05-18T07:00:00.000Z",
    end_date: "1997-05-24T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 22,
    start_date: "1997-05-25T07:00:00.000Z",
    end_date: "1997-05-31T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 23,
    start_date: "1997-06-01T07:00:00.000Z",
    end_date: "1997-06-07T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 24,
    start_date: "1997-06-08T07:00:00.000Z",
    end_date: "1997-06-14T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 25,
    start_date: "1997-06-15T07:00:00.000Z",
    end_date: "1997-06-21T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 26,
    start_date: "1997-06-22T07:00:00.000Z",
    end_date: "1997-06-28T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 27,
    start_date: "1997-06-29T07:00:00.000Z",
    end_date: "1997-07-05T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 28,
    start_date: "1997-07-06T07:00:00.000Z",
    end_date: "1997-07-12T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 29,
    start_date: "1997-07-13T07:00:00.000Z",
    end_date: "1997-07-19T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 30,
    start_date: "1997-07-20T07:00:00.000Z",
    end_date: "1997-07-26T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 31,
    start_date: "1997-07-27T07:00:00.000Z",
    end_date: "1997-08-02T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 32,
    start_date: "1997-08-03T07:00:00.000Z",
    end_date: "1997-08-09T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 33,
    start_date: "1997-08-10T07:00:00.000Z",
    end_date: "1997-08-16T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 34,
    start_date: "1997-08-17T07:00:00.000Z",
    end_date: "1997-08-23T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 35,
    start_date: "1997-08-24T07:00:00.000Z",
    end_date: "1997-08-30T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 36,
    start_date: "1997-08-31T07:00:00.000Z",
    end_date: "1997-09-06T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 37,
    start_date: "1997-09-07T07:00:00.000Z",
    end_date: "1997-09-13T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 38,
    start_date: "1997-09-14T07:00:00.000Z",
    end_date: "1997-09-20T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 39,
    start_date: "1997-09-21T07:00:00.000Z",
    end_date: "1997-09-27T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 40,
    start_date: "1997-09-28T07:00:00.000Z",
    end_date: "1997-10-04T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 41,
    start_date: "1997-10-05T07:00:00.000Z",
    end_date: "1997-10-11T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 42,
    start_date: "1997-10-12T07:00:00.000Z",
    end_date: "1997-10-18T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 43,
    start_date: "1997-10-19T07:00:00.000Z",
    end_date: "1997-10-25T07:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 44,
    start_date: "1997-10-26T07:00:00.000Z",
    end_date: "1997-11-01T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 45,
    start_date: "1997-11-02T08:00:00.000Z",
    end_date: "1997-11-08T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 46,
    start_date: "1997-11-09T08:00:00.000Z",
    end_date: "1997-11-15T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 47,
    start_date: "1997-11-16T08:00:00.000Z",
    end_date: "1997-11-22T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 48,
    start_date: "1997-11-23T08:00:00.000Z",
    end_date: "1997-11-29T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 49,
    start_date: "1997-11-30T08:00:00.000Z",
    end_date: "1997-12-06T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 50,
    start_date: "1997-12-07T08:00:00.000Z",
    end_date: "1997-12-13T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 51,
    start_date: "1997-12-14T08:00:00.000Z",
    end_date: "1997-12-20T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 52,
    start_date: "1997-12-21T08:00:00.000Z",
    end_date: "1997-12-27T08:00:00.000Z",
  },
  {
    disease_year: 1997,
    disease_week: 53,
    start_date: "1997-12-28T08:00:00.000Z",
    end_date: "1998-01-03T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 1,
    start_date: "2000-12-31T08:00:00.000Z",
    end_date: "2001-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 2,
    start_date: "2001-01-07T08:00:00.000Z",
    end_date: "2001-01-13T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 3,
    start_date: "2001-01-14T08:00:00.000Z",
    end_date: "2001-01-20T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 4,
    start_date: "2001-01-21T08:00:00.000Z",
    end_date: "2001-01-27T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 5,
    start_date: "2001-01-28T08:00:00.000Z",
    end_date: "2001-02-03T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 6,
    start_date: "2001-02-04T08:00:00.000Z",
    end_date: "2001-02-10T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 7,
    start_date: "2001-02-11T08:00:00.000Z",
    end_date: "2001-02-17T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 8,
    start_date: "2001-02-18T08:00:00.000Z",
    end_date: "2001-02-24T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 9,
    start_date: "2001-02-25T08:00:00.000Z",
    end_date: "2001-03-03T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 10,
    start_date: "2001-03-04T08:00:00.000Z",
    end_date: "2001-03-10T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 11,
    start_date: "2001-03-11T08:00:00.000Z",
    end_date: "2001-03-17T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 12,
    start_date: "2001-03-18T08:00:00.000Z",
    end_date: "2001-03-24T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 13,
    start_date: "2001-03-25T08:00:00.000Z",
    end_date: "2001-03-31T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 14,
    start_date: "2001-04-01T08:00:00.000Z",
    end_date: "2001-04-07T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 15,
    start_date: "2001-04-08T07:00:00.000Z",
    end_date: "2001-04-14T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 16,
    start_date: "2001-04-15T07:00:00.000Z",
    end_date: "2001-04-21T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 17,
    start_date: "2001-04-22T07:00:00.000Z",
    end_date: "2001-04-28T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 18,
    start_date: "2001-04-29T07:00:00.000Z",
    end_date: "2001-05-05T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 19,
    start_date: "2001-05-06T07:00:00.000Z",
    end_date: "2001-05-12T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 20,
    start_date: "2001-05-13T07:00:00.000Z",
    end_date: "2001-05-19T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 21,
    start_date: "2001-05-20T07:00:00.000Z",
    end_date: "2001-05-26T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 22,
    start_date: "2001-05-27T07:00:00.000Z",
    end_date: "2001-06-02T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 23,
    start_date: "2001-06-03T07:00:00.000Z",
    end_date: "2001-06-09T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 24,
    start_date: "2001-06-10T07:00:00.000Z",
    end_date: "2001-06-16T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 25,
    start_date: "2001-06-17T07:00:00.000Z",
    end_date: "2001-06-23T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 26,
    start_date: "2001-06-24T07:00:00.000Z",
    end_date: "2001-06-30T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 27,
    start_date: "2001-07-01T07:00:00.000Z",
    end_date: "2001-07-07T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 28,
    start_date: "2001-07-08T07:00:00.000Z",
    end_date: "2001-07-14T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 29,
    start_date: "2001-07-15T07:00:00.000Z",
    end_date: "2001-07-21T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 30,
    start_date: "2001-07-22T07:00:00.000Z",
    end_date: "2001-07-28T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 31,
    start_date: "2001-07-29T07:00:00.000Z",
    end_date: "2001-08-04T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 32,
    start_date: "2001-08-05T07:00:00.000Z",
    end_date: "2001-08-11T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 33,
    start_date: "2001-08-12T07:00:00.000Z",
    end_date: "2001-08-18T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 34,
    start_date: "2001-08-19T07:00:00.000Z",
    end_date: "2001-08-25T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 35,
    start_date: "2001-08-26T07:00:00.000Z",
    end_date: "2001-09-01T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 36,
    start_date: "2001-09-02T07:00:00.000Z",
    end_date: "2001-09-08T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 37,
    start_date: "2001-09-09T07:00:00.000Z",
    end_date: "2001-09-15T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 38,
    start_date: "2001-09-16T07:00:00.000Z",
    end_date: "2001-09-22T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 39,
    start_date: "2001-09-23T07:00:00.000Z",
    end_date: "2001-09-29T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 40,
    start_date: "2001-09-30T07:00:00.000Z",
    end_date: "2001-10-06T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 41,
    start_date: "2001-10-07T07:00:00.000Z",
    end_date: "2001-10-13T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 42,
    start_date: "2001-10-14T07:00:00.000Z",
    end_date: "2001-10-20T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 43,
    start_date: "2001-10-21T07:00:00.000Z",
    end_date: "2001-10-27T07:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 44,
    start_date: "2001-10-28T07:00:00.000Z",
    end_date: "2001-11-03T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 45,
    start_date: "2001-11-04T08:00:00.000Z",
    end_date: "2001-11-10T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 46,
    start_date: "2001-11-11T08:00:00.000Z",
    end_date: "2001-11-17T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 47,
    start_date: "2001-11-18T08:00:00.000Z",
    end_date: "2001-11-24T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 48,
    start_date: "2001-11-25T08:00:00.000Z",
    end_date: "2001-12-01T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 49,
    start_date: "2001-12-02T08:00:00.000Z",
    end_date: "2001-12-08T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 50,
    start_date: "2001-12-09T08:00:00.000Z",
    end_date: "2001-12-15T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 51,
    start_date: "2001-12-16T08:00:00.000Z",
    end_date: "2001-12-22T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 52,
    start_date: "2001-12-23T08:00:00.000Z",
    end_date: "2001-12-29T08:00:00.000Z",
  },
  {
    disease_year: 2001,
    disease_week: 53,
    start_date: "2001-12-30T08:00:00.000Z",
    end_date: "2002-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 1,
    start_date: "2001-12-30T08:00:00.000Z",
    end_date: "2002-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 2,
    start_date: "2002-01-06T08:00:00.000Z",
    end_date: "2002-01-12T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 3,
    start_date: "2002-01-13T08:00:00.000Z",
    end_date: "2002-01-19T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 4,
    start_date: "2002-01-20T08:00:00.000Z",
    end_date: "2002-01-26T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 5,
    start_date: "2002-01-27T08:00:00.000Z",
    end_date: "2002-02-02T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 6,
    start_date: "2002-02-03T08:00:00.000Z",
    end_date: "2002-02-09T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 7,
    start_date: "2002-02-10T08:00:00.000Z",
    end_date: "2002-02-16T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 8,
    start_date: "2002-02-17T08:00:00.000Z",
    end_date: "2002-02-23T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 9,
    start_date: "2002-02-24T08:00:00.000Z",
    end_date: "2002-03-02T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 10,
    start_date: "2002-03-03T08:00:00.000Z",
    end_date: "2002-03-09T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 11,
    start_date: "2002-03-10T08:00:00.000Z",
    end_date: "2002-03-16T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 12,
    start_date: "2002-03-17T08:00:00.000Z",
    end_date: "2002-03-23T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 13,
    start_date: "2002-03-24T08:00:00.000Z",
    end_date: "2002-03-30T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 14,
    start_date: "2002-03-31T08:00:00.000Z",
    end_date: "2002-04-06T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 15,
    start_date: "2002-04-07T08:00:00.000Z",
    end_date: "2002-04-13T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 16,
    start_date: "2002-04-14T07:00:00.000Z",
    end_date: "2002-04-20T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 17,
    start_date: "2002-04-21T07:00:00.000Z",
    end_date: "2002-04-27T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 18,
    start_date: "2002-04-28T07:00:00.000Z",
    end_date: "2002-05-04T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 19,
    start_date: "2002-05-05T07:00:00.000Z",
    end_date: "2002-05-11T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 20,
    start_date: "2002-05-12T07:00:00.000Z",
    end_date: "2002-05-18T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 21,
    start_date: "2002-05-19T07:00:00.000Z",
    end_date: "2002-05-25T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 22,
    start_date: "2002-05-26T07:00:00.000Z",
    end_date: "2002-06-01T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 23,
    start_date: "2002-06-02T07:00:00.000Z",
    end_date: "2002-06-08T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 24,
    start_date: "2002-06-09T07:00:00.000Z",
    end_date: "2002-06-15T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 25,
    start_date: "2002-06-16T07:00:00.000Z",
    end_date: "2002-06-22T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 26,
    start_date: "2002-06-23T07:00:00.000Z",
    end_date: "2002-06-29T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 27,
    start_date: "2002-06-30T07:00:00.000Z",
    end_date: "2002-07-06T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 28,
    start_date: "2002-07-07T07:00:00.000Z",
    end_date: "2002-07-13T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 29,
    start_date: "2002-07-14T07:00:00.000Z",
    end_date: "2002-07-20T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 30,
    start_date: "2002-07-21T07:00:00.000Z",
    end_date: "2002-07-27T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 31,
    start_date: "2002-07-28T07:00:00.000Z",
    end_date: "2002-08-03T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 32,
    start_date: "2002-08-04T07:00:00.000Z",
    end_date: "2002-08-10T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 33,
    start_date: "2002-08-11T07:00:00.000Z",
    end_date: "2002-08-17T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 34,
    start_date: "2002-08-18T07:00:00.000Z",
    end_date: "2002-08-24T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 35,
    start_date: "2002-08-25T07:00:00.000Z",
    end_date: "2002-08-31T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 36,
    start_date: "2002-09-01T07:00:00.000Z",
    end_date: "2002-09-07T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 37,
    start_date: "2002-09-08T07:00:00.000Z",
    end_date: "2002-09-14T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 38,
    start_date: "2002-09-15T07:00:00.000Z",
    end_date: "2002-09-21T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 39,
    start_date: "2002-09-22T07:00:00.000Z",
    end_date: "2002-09-28T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 40,
    start_date: "2002-09-29T07:00:00.000Z",
    end_date: "2002-10-05T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 41,
    start_date: "2002-10-06T07:00:00.000Z",
    end_date: "2002-10-12T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 42,
    start_date: "2002-10-13T07:00:00.000Z",
    end_date: "2002-10-19T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 43,
    start_date: "2002-10-20T07:00:00.000Z",
    end_date: "2002-10-26T07:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 44,
    start_date: "2002-10-27T07:00:00.000Z",
    end_date: "2002-11-02T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 45,
    start_date: "2002-11-03T08:00:00.000Z",
    end_date: "2002-11-09T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 46,
    start_date: "2002-11-10T08:00:00.000Z",
    end_date: "2002-11-16T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 47,
    start_date: "2002-11-17T08:00:00.000Z",
    end_date: "2002-11-23T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 48,
    start_date: "2002-11-24T08:00:00.000Z",
    end_date: "2002-11-30T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 49,
    start_date: "2002-12-01T08:00:00.000Z",
    end_date: "2002-12-07T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 50,
    start_date: "2002-12-08T08:00:00.000Z",
    end_date: "2002-12-14T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 51,
    start_date: "2002-12-15T08:00:00.000Z",
    end_date: "2002-12-21T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 52,
    start_date: "2002-12-22T08:00:00.000Z",
    end_date: "2002-12-28T08:00:00.000Z",
  },
  {
    disease_year: 2002,
    disease_week: 53,
    start_date: "2002-12-29T08:00:00.000Z",
    end_date: "2003-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 1,
    start_date: "2002-12-29T08:00:00.000Z",
    end_date: "2003-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 2,
    start_date: "2003-01-05T08:00:00.000Z",
    end_date: "2003-01-11T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 3,
    start_date: "2003-01-12T08:00:00.000Z",
    end_date: "2003-01-18T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 4,
    start_date: "2003-01-19T08:00:00.000Z",
    end_date: "2003-01-25T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 5,
    start_date: "2003-01-26T08:00:00.000Z",
    end_date: "2003-02-01T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 6,
    start_date: "2003-02-02T08:00:00.000Z",
    end_date: "2003-02-08T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 7,
    start_date: "2003-02-09T08:00:00.000Z",
    end_date: "2003-02-15T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 8,
    start_date: "2003-02-16T08:00:00.000Z",
    end_date: "2003-02-22T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 9,
    start_date: "2003-02-23T08:00:00.000Z",
    end_date: "2003-03-01T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 10,
    start_date: "2003-03-02T08:00:00.000Z",
    end_date: "2003-03-08T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 11,
    start_date: "2003-03-09T08:00:00.000Z",
    end_date: "2003-03-15T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 12,
    start_date: "2003-03-16T08:00:00.000Z",
    end_date: "2003-03-22T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 13,
    start_date: "2003-03-23T08:00:00.000Z",
    end_date: "2003-03-29T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 14,
    start_date: "2003-03-30T08:00:00.000Z",
    end_date: "2003-04-05T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 15,
    start_date: "2003-04-06T08:00:00.000Z",
    end_date: "2003-04-12T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 16,
    start_date: "2003-04-13T07:00:00.000Z",
    end_date: "2003-04-19T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 17,
    start_date: "2003-04-20T07:00:00.000Z",
    end_date: "2003-04-26T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 18,
    start_date: "2003-04-27T07:00:00.000Z",
    end_date: "2003-05-03T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 19,
    start_date: "2003-05-04T07:00:00.000Z",
    end_date: "2003-05-10T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 20,
    start_date: "2003-05-11T07:00:00.000Z",
    end_date: "2003-05-17T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 21,
    start_date: "2003-05-18T07:00:00.000Z",
    end_date: "2003-05-24T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 22,
    start_date: "2003-05-25T07:00:00.000Z",
    end_date: "2003-05-31T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 23,
    start_date: "2003-06-01T07:00:00.000Z",
    end_date: "2003-06-07T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 24,
    start_date: "2003-06-08T07:00:00.000Z",
    end_date: "2003-06-14T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 25,
    start_date: "2003-06-15T07:00:00.000Z",
    end_date: "2003-06-21T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 26,
    start_date: "2003-06-22T07:00:00.000Z",
    end_date: "2003-06-28T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 27,
    start_date: "2003-06-29T07:00:00.000Z",
    end_date: "2003-07-05T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 28,
    start_date: "2003-07-06T07:00:00.000Z",
    end_date: "2003-07-12T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 29,
    start_date: "2003-07-13T07:00:00.000Z",
    end_date: "2003-07-19T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 30,
    start_date: "2003-07-20T07:00:00.000Z",
    end_date: "2003-07-26T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 31,
    start_date: "2003-07-27T07:00:00.000Z",
    end_date: "2003-08-02T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 32,
    start_date: "2003-08-03T07:00:00.000Z",
    end_date: "2003-08-09T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 33,
    start_date: "2003-08-10T07:00:00.000Z",
    end_date: "2003-08-16T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 34,
    start_date: "2003-08-17T07:00:00.000Z",
    end_date: "2003-08-23T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 35,
    start_date: "2003-08-24T07:00:00.000Z",
    end_date: "2003-08-30T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 36,
    start_date: "2003-08-31T07:00:00.000Z",
    end_date: "2003-09-06T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 37,
    start_date: "2003-09-07T07:00:00.000Z",
    end_date: "2003-09-13T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 38,
    start_date: "2003-09-14T07:00:00.000Z",
    end_date: "2003-09-20T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 39,
    start_date: "2003-09-21T07:00:00.000Z",
    end_date: "2003-09-27T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 40,
    start_date: "2003-09-28T07:00:00.000Z",
    end_date: "2003-10-04T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 41,
    start_date: "2003-10-05T07:00:00.000Z",
    end_date: "2003-10-11T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 42,
    start_date: "2003-10-12T07:00:00.000Z",
    end_date: "2003-10-18T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 43,
    start_date: "2003-10-19T07:00:00.000Z",
    end_date: "2003-10-25T07:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 44,
    start_date: "2003-10-26T07:00:00.000Z",
    end_date: "2003-11-01T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 45,
    start_date: "2003-11-02T08:00:00.000Z",
    end_date: "2003-11-08T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 46,
    start_date: "2003-11-09T08:00:00.000Z",
    end_date: "2003-11-15T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 47,
    start_date: "2003-11-16T08:00:00.000Z",
    end_date: "2003-11-22T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 48,
    start_date: "2003-11-23T08:00:00.000Z",
    end_date: "2003-11-29T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 49,
    start_date: "2003-11-30T08:00:00.000Z",
    end_date: "2003-12-06T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 50,
    start_date: "2003-12-07T08:00:00.000Z",
    end_date: "2003-12-13T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 51,
    start_date: "2003-12-14T08:00:00.000Z",
    end_date: "2003-12-20T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 52,
    start_date: "2003-12-21T08:00:00.000Z",
    end_date: "2003-12-27T08:00:00.000Z",
  },
  {
    disease_year: 2003,
    disease_week: 53,
    start_date: "2003-12-28T08:00:00.000Z",
    end_date: "2004-01-03T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 1,
    start_date: "2004-01-04T08:00:00.000Z",
    end_date: "2004-01-10T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 44,
    start_date: "2000-10-29T07:00:00.000Z",
    end_date: "2000-11-04T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 45,
    start_date: "2000-11-05T08:00:00.000Z",
    end_date: "2000-11-11T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 46,
    start_date: "2000-11-12T08:00:00.000Z",
    end_date: "2000-11-18T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 47,
    start_date: "2000-11-19T08:00:00.000Z",
    end_date: "2000-11-25T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 48,
    start_date: "2000-11-26T08:00:00.000Z",
    end_date: "2000-12-02T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 49,
    start_date: "2000-12-03T08:00:00.000Z",
    end_date: "2000-12-09T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 50,
    start_date: "2000-12-10T08:00:00.000Z",
    end_date: "2000-12-16T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 51,
    start_date: "2000-12-17T08:00:00.000Z",
    end_date: "2000-12-23T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 52,
    start_date: "2000-12-24T08:00:00.000Z",
    end_date: "2000-12-30T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 53,
    start_date: "2000-12-31T08:00:00.000Z",
    end_date: "2001-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 1,
    start_date: "2006-01-01T08:00:00.000Z",
    end_date: "2006-01-07T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 2,
    start_date: "2006-01-08T08:00:00.000Z",
    end_date: "2006-01-14T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 3,
    start_date: "2006-01-15T08:00:00.000Z",
    end_date: "2006-01-21T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 4,
    start_date: "2006-01-22T08:00:00.000Z",
    end_date: "2006-01-28T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 5,
    start_date: "2006-01-29T08:00:00.000Z",
    end_date: "2006-02-04T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 6,
    start_date: "2006-02-05T08:00:00.000Z",
    end_date: "2006-02-11T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 7,
    start_date: "2006-02-12T08:00:00.000Z",
    end_date: "2006-02-18T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 8,
    start_date: "2006-02-19T08:00:00.000Z",
    end_date: "2006-02-25T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 9,
    start_date: "2006-02-26T08:00:00.000Z",
    end_date: "2006-03-04T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 10,
    start_date: "2006-03-05T08:00:00.000Z",
    end_date: "2006-03-11T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 11,
    start_date: "2006-03-12T08:00:00.000Z",
    end_date: "2006-03-18T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 12,
    start_date: "2006-03-19T08:00:00.000Z",
    end_date: "2006-03-25T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 13,
    start_date: "2006-03-26T08:00:00.000Z",
    end_date: "2006-04-01T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 14,
    start_date: "2006-04-02T08:00:00.000Z",
    end_date: "2006-04-08T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 15,
    start_date: "2006-04-09T07:00:00.000Z",
    end_date: "2006-04-15T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 16,
    start_date: "2006-04-16T07:00:00.000Z",
    end_date: "2006-04-22T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 17,
    start_date: "2006-04-23T07:00:00.000Z",
    end_date: "2006-04-29T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 18,
    start_date: "2006-04-30T07:00:00.000Z",
    end_date: "2006-05-06T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 19,
    start_date: "2006-05-07T07:00:00.000Z",
    end_date: "2006-05-13T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 20,
    start_date: "2006-05-14T07:00:00.000Z",
    end_date: "2006-05-20T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 21,
    start_date: "2006-05-21T07:00:00.000Z",
    end_date: "2006-05-27T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 22,
    start_date: "2006-05-28T07:00:00.000Z",
    end_date: "2006-06-03T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 23,
    start_date: "2006-06-04T07:00:00.000Z",
    end_date: "2006-06-10T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 24,
    start_date: "2006-06-11T07:00:00.000Z",
    end_date: "2006-06-17T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 25,
    start_date: "2006-06-18T07:00:00.000Z",
    end_date: "2006-06-24T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 26,
    start_date: "2006-06-25T07:00:00.000Z",
    end_date: "2006-07-01T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 27,
    start_date: "2006-07-02T07:00:00.000Z",
    end_date: "2006-07-08T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 28,
    start_date: "2006-07-09T07:00:00.000Z",
    end_date: "2006-07-15T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 29,
    start_date: "2006-07-16T07:00:00.000Z",
    end_date: "2006-07-22T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 30,
    start_date: "2006-07-23T07:00:00.000Z",
    end_date: "2006-07-29T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 31,
    start_date: "2006-07-30T07:00:00.000Z",
    end_date: "2006-08-05T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 32,
    start_date: "2006-08-06T07:00:00.000Z",
    end_date: "2006-08-12T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 33,
    start_date: "2006-08-13T07:00:00.000Z",
    end_date: "2006-08-19T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 34,
    start_date: "2006-08-20T07:00:00.000Z",
    end_date: "2006-08-26T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 35,
    start_date: "2006-08-27T07:00:00.000Z",
    end_date: "2006-09-02T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 36,
    start_date: "2006-09-03T07:00:00.000Z",
    end_date: "2006-09-09T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 37,
    start_date: "2006-09-10T07:00:00.000Z",
    end_date: "2006-09-16T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 38,
    start_date: "2006-09-17T07:00:00.000Z",
    end_date: "2006-09-23T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 39,
    start_date: "2006-09-24T07:00:00.000Z",
    end_date: "2006-09-30T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 40,
    start_date: "2006-10-01T07:00:00.000Z",
    end_date: "2006-10-07T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 41,
    start_date: "2006-10-08T07:00:00.000Z",
    end_date: "2006-10-14T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 42,
    start_date: "2006-10-15T07:00:00.000Z",
    end_date: "2006-10-21T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 43,
    start_date: "2006-10-22T07:00:00.000Z",
    end_date: "2006-10-28T07:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 44,
    start_date: "2006-10-29T07:00:00.000Z",
    end_date: "2006-11-04T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 45,
    start_date: "2006-11-05T08:00:00.000Z",
    end_date: "2006-11-11T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 46,
    start_date: "2006-11-12T08:00:00.000Z",
    end_date: "2006-11-18T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 47,
    start_date: "2006-11-19T08:00:00.000Z",
    end_date: "2006-11-25T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 48,
    start_date: "2006-11-26T08:00:00.000Z",
    end_date: "2006-12-02T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 49,
    start_date: "2006-12-03T08:00:00.000Z",
    end_date: "2006-12-09T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 50,
    start_date: "2006-12-10T08:00:00.000Z",
    end_date: "2006-12-16T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 51,
    start_date: "2006-12-17T08:00:00.000Z",
    end_date: "2006-12-23T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 52,
    start_date: "2006-12-24T08:00:00.000Z",
    end_date: "2006-12-30T08:00:00.000Z",
  },
  {
    disease_year: 2006,
    disease_week: 53,
    start_date: "2006-12-31T08:00:00.000Z",
    end_date: "2007-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 1,
    start_date: "2006-12-31T08:00:00.000Z",
    end_date: "2007-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 2,
    start_date: "2007-01-07T08:00:00.000Z",
    end_date: "2007-01-13T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 3,
    start_date: "2007-01-14T08:00:00.000Z",
    end_date: "2007-01-20T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 4,
    start_date: "2007-01-21T08:00:00.000Z",
    end_date: "2007-01-27T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 5,
    start_date: "2007-01-28T08:00:00.000Z",
    end_date: "2007-02-03T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 6,
    start_date: "2007-02-04T08:00:00.000Z",
    end_date: "2007-02-10T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 7,
    start_date: "2007-02-11T08:00:00.000Z",
    end_date: "2007-02-17T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 8,
    start_date: "2007-02-18T08:00:00.000Z",
    end_date: "2007-02-24T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 9,
    start_date: "2007-02-25T08:00:00.000Z",
    end_date: "2007-03-03T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 10,
    start_date: "2007-03-04T08:00:00.000Z",
    end_date: "2007-03-10T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 11,
    start_date: "2007-03-11T08:00:00.000Z",
    end_date: "2007-03-17T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 12,
    start_date: "2007-03-18T07:00:00.000Z",
    end_date: "2007-03-24T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 13,
    start_date: "2007-03-25T07:00:00.000Z",
    end_date: "2007-03-31T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 14,
    start_date: "2007-04-01T07:00:00.000Z",
    end_date: "2007-04-07T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 15,
    start_date: "2007-04-08T07:00:00.000Z",
    end_date: "2007-04-14T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 16,
    start_date: "2007-04-15T07:00:00.000Z",
    end_date: "2007-04-21T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 17,
    start_date: "2007-04-22T07:00:00.000Z",
    end_date: "2007-04-28T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 18,
    start_date: "2007-04-29T07:00:00.000Z",
    end_date: "2007-05-05T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 19,
    start_date: "2007-05-06T07:00:00.000Z",
    end_date: "2007-05-12T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 20,
    start_date: "2007-05-13T07:00:00.000Z",
    end_date: "2007-05-19T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 21,
    start_date: "2007-05-20T07:00:00.000Z",
    end_date: "2007-05-26T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 22,
    start_date: "2007-05-27T07:00:00.000Z",
    end_date: "2007-06-02T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 23,
    start_date: "2007-06-03T07:00:00.000Z",
    end_date: "2007-06-09T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 24,
    start_date: "2007-06-10T07:00:00.000Z",
    end_date: "2007-06-16T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 25,
    start_date: "2007-06-17T07:00:00.000Z",
    end_date: "2007-06-23T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 26,
    start_date: "2007-06-24T07:00:00.000Z",
    end_date: "2007-06-30T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 27,
    start_date: "2007-07-01T07:00:00.000Z",
    end_date: "2007-07-07T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 28,
    start_date: "2007-07-08T07:00:00.000Z",
    end_date: "2007-07-14T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 29,
    start_date: "2007-07-15T07:00:00.000Z",
    end_date: "2007-07-21T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 30,
    start_date: "2007-07-22T07:00:00.000Z",
    end_date: "2007-07-28T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 31,
    start_date: "2007-07-29T07:00:00.000Z",
    end_date: "2007-08-04T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 32,
    start_date: "2007-08-05T07:00:00.000Z",
    end_date: "2007-08-11T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 33,
    start_date: "2007-08-12T07:00:00.000Z",
    end_date: "2007-08-18T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 34,
    start_date: "2007-08-19T07:00:00.000Z",
    end_date: "2007-08-25T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 35,
    start_date: "2007-08-26T07:00:00.000Z",
    end_date: "2007-09-01T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 36,
    start_date: "2007-09-02T07:00:00.000Z",
    end_date: "2007-09-08T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 37,
    start_date: "2007-09-09T07:00:00.000Z",
    end_date: "2007-09-15T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 38,
    start_date: "2007-09-16T07:00:00.000Z",
    end_date: "2007-09-22T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 39,
    start_date: "2007-09-23T07:00:00.000Z",
    end_date: "2007-09-29T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 40,
    start_date: "2007-09-30T07:00:00.000Z",
    end_date: "2007-10-06T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 41,
    start_date: "2007-10-07T07:00:00.000Z",
    end_date: "2007-10-13T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 42,
    start_date: "2007-10-14T07:00:00.000Z",
    end_date: "2007-10-20T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 1,
    start_date: "2005-01-02T08:00:00.000Z",
    end_date: "2005-01-08T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 2,
    start_date: "2005-01-09T08:00:00.000Z",
    end_date: "2005-01-15T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 3,
    start_date: "2005-01-16T08:00:00.000Z",
    end_date: "2005-01-22T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 4,
    start_date: "2005-01-23T08:00:00.000Z",
    end_date: "2005-01-29T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 5,
    start_date: "2005-01-30T08:00:00.000Z",
    end_date: "2005-02-05T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 6,
    start_date: "2005-02-06T08:00:00.000Z",
    end_date: "2005-02-12T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 7,
    start_date: "2005-02-13T08:00:00.000Z",
    end_date: "2005-02-19T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 8,
    start_date: "2005-02-20T08:00:00.000Z",
    end_date: "2005-02-26T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 9,
    start_date: "2005-02-27T08:00:00.000Z",
    end_date: "2005-03-05T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 10,
    start_date: "2005-03-06T08:00:00.000Z",
    end_date: "2005-03-12T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 11,
    start_date: "2005-03-13T08:00:00.000Z",
    end_date: "2005-03-19T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 12,
    start_date: "2005-03-20T08:00:00.000Z",
    end_date: "2005-03-26T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 13,
    start_date: "2005-03-27T08:00:00.000Z",
    end_date: "2005-04-02T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 14,
    start_date: "2005-04-03T08:00:00.000Z",
    end_date: "2005-04-09T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 15,
    start_date: "2005-04-10T07:00:00.000Z",
    end_date: "2005-04-16T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 16,
    start_date: "2005-04-17T07:00:00.000Z",
    end_date: "2005-04-23T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 17,
    start_date: "2005-04-24T07:00:00.000Z",
    end_date: "2005-04-30T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 18,
    start_date: "2005-05-01T07:00:00.000Z",
    end_date: "2005-05-07T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 19,
    start_date: "2005-05-08T07:00:00.000Z",
    end_date: "2005-05-14T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 20,
    start_date: "2005-05-15T07:00:00.000Z",
    end_date: "2005-05-21T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 21,
    start_date: "2005-05-22T07:00:00.000Z",
    end_date: "2005-05-28T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 22,
    start_date: "2005-05-29T07:00:00.000Z",
    end_date: "2005-06-04T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 23,
    start_date: "2005-06-05T07:00:00.000Z",
    end_date: "2005-06-11T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 24,
    start_date: "2005-06-12T07:00:00.000Z",
    end_date: "2005-06-18T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 25,
    start_date: "2005-06-19T07:00:00.000Z",
    end_date: "2005-06-25T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 26,
    start_date: "2005-06-26T07:00:00.000Z",
    end_date: "2005-07-02T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 27,
    start_date: "2005-07-03T07:00:00.000Z",
    end_date: "2005-07-09T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 28,
    start_date: "2005-07-10T07:00:00.000Z",
    end_date: "2005-07-16T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 29,
    start_date: "2005-07-17T07:00:00.000Z",
    end_date: "2005-07-23T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 30,
    start_date: "2005-07-24T07:00:00.000Z",
    end_date: "2005-07-30T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 31,
    start_date: "2005-07-31T07:00:00.000Z",
    end_date: "2005-08-06T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 32,
    start_date: "2005-08-07T07:00:00.000Z",
    end_date: "2005-08-13T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 33,
    start_date: "2005-08-14T07:00:00.000Z",
    end_date: "2005-08-20T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 34,
    start_date: "2005-08-21T07:00:00.000Z",
    end_date: "2005-08-27T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 43,
    start_date: "2007-10-21T07:00:00.000Z",
    end_date: "2007-10-27T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 44,
    start_date: "2007-10-28T07:00:00.000Z",
    end_date: "2007-11-03T07:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 45,
    start_date: "2007-11-04T07:00:00.000Z",
    end_date: "2007-11-10T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 46,
    start_date: "2007-11-11T08:00:00.000Z",
    end_date: "2007-11-17T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 47,
    start_date: "2007-11-18T08:00:00.000Z",
    end_date: "2007-11-24T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 48,
    start_date: "2007-11-25T08:00:00.000Z",
    end_date: "2007-12-01T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 49,
    start_date: "2007-12-02T08:00:00.000Z",
    end_date: "2007-12-08T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 50,
    start_date: "2007-12-09T08:00:00.000Z",
    end_date: "2007-12-15T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 51,
    start_date: "2007-12-16T08:00:00.000Z",
    end_date: "2007-12-22T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 52,
    start_date: "2007-12-23T08:00:00.000Z",
    end_date: "2007-12-29T08:00:00.000Z",
  },
  {
    disease_year: 2007,
    disease_week: 53,
    start_date: "2007-12-30T08:00:00.000Z",
    end_date: "2008-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 1,
    start_date: "2007-12-30T08:00:00.000Z",
    end_date: "2008-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 2,
    start_date: "2008-01-06T08:00:00.000Z",
    end_date: "2008-01-12T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 3,
    start_date: "2008-01-13T08:00:00.000Z",
    end_date: "2008-01-19T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 4,
    start_date: "2008-01-20T08:00:00.000Z",
    end_date: "2008-01-26T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 5,
    start_date: "2008-01-27T08:00:00.000Z",
    end_date: "2008-02-02T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 6,
    start_date: "2008-02-03T08:00:00.000Z",
    end_date: "2008-02-09T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 7,
    start_date: "2008-02-10T08:00:00.000Z",
    end_date: "2008-02-16T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 8,
    start_date: "2008-02-17T08:00:00.000Z",
    end_date: "2008-02-23T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 9,
    start_date: "2008-02-24T08:00:00.000Z",
    end_date: "2008-03-01T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 10,
    start_date: "2008-03-02T08:00:00.000Z",
    end_date: "2008-03-08T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 11,
    start_date: "2008-03-09T08:00:00.000Z",
    end_date: "2008-03-15T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 12,
    start_date: "2008-03-16T07:00:00.000Z",
    end_date: "2008-03-22T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 13,
    start_date: "2008-03-23T07:00:00.000Z",
    end_date: "2008-03-29T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 14,
    start_date: "2008-03-30T07:00:00.000Z",
    end_date: "2008-04-05T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 15,
    start_date: "2008-04-06T07:00:00.000Z",
    end_date: "2008-04-12T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 16,
    start_date: "2008-04-13T07:00:00.000Z",
    end_date: "2008-04-19T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 17,
    start_date: "2008-04-20T07:00:00.000Z",
    end_date: "2008-04-26T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 18,
    start_date: "2008-04-27T07:00:00.000Z",
    end_date: "2008-05-03T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 19,
    start_date: "2008-05-04T07:00:00.000Z",
    end_date: "2008-05-10T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 20,
    start_date: "2008-05-11T07:00:00.000Z",
    end_date: "2008-05-17T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 21,
    start_date: "2008-05-18T07:00:00.000Z",
    end_date: "2008-05-24T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 22,
    start_date: "2008-05-25T07:00:00.000Z",
    end_date: "2008-05-31T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 23,
    start_date: "2008-06-01T07:00:00.000Z",
    end_date: "2008-06-07T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 24,
    start_date: "2008-06-08T07:00:00.000Z",
    end_date: "2008-06-14T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 25,
    start_date: "2008-06-15T07:00:00.000Z",
    end_date: "2008-06-21T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 26,
    start_date: "2008-06-22T07:00:00.000Z",
    end_date: "2008-06-28T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 27,
    start_date: "2008-06-29T07:00:00.000Z",
    end_date: "2008-07-05T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 28,
    start_date: "2008-07-06T07:00:00.000Z",
    end_date: "2008-07-12T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 29,
    start_date: "2008-07-13T07:00:00.000Z",
    end_date: "2008-07-19T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 30,
    start_date: "2008-07-20T07:00:00.000Z",
    end_date: "2008-07-26T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 31,
    start_date: "2008-07-27T07:00:00.000Z",
    end_date: "2008-08-02T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 32,
    start_date: "2008-08-03T07:00:00.000Z",
    end_date: "2008-08-09T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 33,
    start_date: "2008-08-10T07:00:00.000Z",
    end_date: "2008-08-16T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 34,
    start_date: "2008-08-17T07:00:00.000Z",
    end_date: "2008-08-23T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 35,
    start_date: "2008-08-24T07:00:00.000Z",
    end_date: "2008-08-30T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 36,
    start_date: "2008-08-31T07:00:00.000Z",
    end_date: "2008-09-06T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 37,
    start_date: "2008-09-07T07:00:00.000Z",
    end_date: "2008-09-13T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 38,
    start_date: "2008-09-14T07:00:00.000Z",
    end_date: "2008-09-20T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 39,
    start_date: "2008-09-21T07:00:00.000Z",
    end_date: "2008-09-27T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 40,
    start_date: "2008-09-28T07:00:00.000Z",
    end_date: "2008-10-04T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 41,
    start_date: "2008-10-05T07:00:00.000Z",
    end_date: "2008-10-11T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 42,
    start_date: "2008-10-12T07:00:00.000Z",
    end_date: "2008-10-18T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 43,
    start_date: "2008-10-19T07:00:00.000Z",
    end_date: "2008-10-25T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 44,
    start_date: "2008-10-26T07:00:00.000Z",
    end_date: "2008-11-01T07:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 45,
    start_date: "2008-11-02T07:00:00.000Z",
    end_date: "2008-11-08T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 46,
    start_date: "2008-11-09T08:00:00.000Z",
    end_date: "2008-11-15T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 47,
    start_date: "2008-11-16T08:00:00.000Z",
    end_date: "2008-11-22T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 48,
    start_date: "2008-11-23T08:00:00.000Z",
    end_date: "2008-11-29T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 49,
    start_date: "2008-11-30T08:00:00.000Z",
    end_date: "2008-12-06T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 50,
    start_date: "2008-12-07T08:00:00.000Z",
    end_date: "2008-12-13T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 51,
    start_date: "2008-12-14T08:00:00.000Z",
    end_date: "2008-12-20T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 52,
    start_date: "2008-12-21T08:00:00.000Z",
    end_date: "2008-12-27T08:00:00.000Z",
  },
  {
    disease_year: 2008,
    disease_week: 53,
    start_date: "2008-12-28T08:00:00.000Z",
    end_date: "2009-01-03T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 1,
    start_date: "2012-01-01T08:00:00.000Z",
    end_date: "2012-01-07T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 2,
    start_date: "2012-01-08T08:00:00.000Z",
    end_date: "2012-01-14T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 3,
    start_date: "2012-01-15T08:00:00.000Z",
    end_date: "2012-01-21T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 4,
    start_date: "2012-01-22T08:00:00.000Z",
    end_date: "2012-01-28T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 5,
    start_date: "2012-01-29T08:00:00.000Z",
    end_date: "2012-02-04T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 6,
    start_date: "2012-02-05T08:00:00.000Z",
    end_date: "2012-02-11T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 7,
    start_date: "2012-02-12T08:00:00.000Z",
    end_date: "2012-02-18T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 8,
    start_date: "2012-02-19T08:00:00.000Z",
    end_date: "2012-02-25T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 9,
    start_date: "2012-02-26T08:00:00.000Z",
    end_date: "2012-03-03T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 10,
    start_date: "2012-03-04T08:00:00.000Z",
    end_date: "2012-03-10T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 11,
    start_date: "2012-03-11T08:00:00.000Z",
    end_date: "2012-03-17T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 12,
    start_date: "2012-03-18T07:00:00.000Z",
    end_date: "2012-03-24T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 13,
    start_date: "2012-03-25T07:00:00.000Z",
    end_date: "2012-03-31T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 14,
    start_date: "2012-04-01T07:00:00.000Z",
    end_date: "2012-04-07T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 15,
    start_date: "2012-04-08T07:00:00.000Z",
    end_date: "2012-04-14T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 16,
    start_date: "2012-04-15T07:00:00.000Z",
    end_date: "2012-04-21T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 17,
    start_date: "2012-04-22T07:00:00.000Z",
    end_date: "2012-04-28T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 18,
    start_date: "2012-04-29T07:00:00.000Z",
    end_date: "2012-05-05T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 19,
    start_date: "2012-05-06T07:00:00.000Z",
    end_date: "2012-05-12T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 20,
    start_date: "2012-05-13T07:00:00.000Z",
    end_date: "2012-05-19T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 21,
    start_date: "2012-05-20T07:00:00.000Z",
    end_date: "2012-05-26T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 22,
    start_date: "2012-05-27T07:00:00.000Z",
    end_date: "2012-06-02T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 23,
    start_date: "2012-06-03T07:00:00.000Z",
    end_date: "2012-06-09T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 24,
    start_date: "2012-06-10T07:00:00.000Z",
    end_date: "2012-06-16T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 25,
    start_date: "2012-06-17T07:00:00.000Z",
    end_date: "2012-06-23T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 26,
    start_date: "2012-06-24T07:00:00.000Z",
    end_date: "2012-06-30T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 27,
    start_date: "2012-07-01T07:00:00.000Z",
    end_date: "2012-07-07T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 28,
    start_date: "2012-07-08T07:00:00.000Z",
    end_date: "2012-07-14T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 29,
    start_date: "2012-07-15T07:00:00.000Z",
    end_date: "2012-07-21T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 30,
    start_date: "2012-07-22T07:00:00.000Z",
    end_date: "2012-07-28T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 31,
    start_date: "2012-07-29T07:00:00.000Z",
    end_date: "2012-08-04T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 32,
    start_date: "2012-08-05T07:00:00.000Z",
    end_date: "2012-08-11T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 33,
    start_date: "2012-08-12T07:00:00.000Z",
    end_date: "2012-08-18T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 34,
    start_date: "2012-08-19T07:00:00.000Z",
    end_date: "2012-08-25T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 35,
    start_date: "2012-08-26T07:00:00.000Z",
    end_date: "2012-09-01T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 36,
    start_date: "2012-09-02T07:00:00.000Z",
    end_date: "2012-09-08T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 37,
    start_date: "2012-09-09T07:00:00.000Z",
    end_date: "2012-09-15T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 38,
    start_date: "2012-09-16T07:00:00.000Z",
    end_date: "2012-09-22T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 39,
    start_date: "2012-09-23T07:00:00.000Z",
    end_date: "2012-09-29T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 40,
    start_date: "2012-09-30T07:00:00.000Z",
    end_date: "2012-10-06T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 41,
    start_date: "2012-10-07T07:00:00.000Z",
    end_date: "2012-10-13T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 42,
    start_date: "2012-10-14T07:00:00.000Z",
    end_date: "2012-10-20T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 43,
    start_date: "2012-10-21T07:00:00.000Z",
    end_date: "2012-10-27T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 44,
    start_date: "2012-10-28T07:00:00.000Z",
    end_date: "2012-11-03T07:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 45,
    start_date: "2012-11-04T07:00:00.000Z",
    end_date: "2012-11-10T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 46,
    start_date: "2012-11-11T08:00:00.000Z",
    end_date: "2012-11-17T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 47,
    start_date: "2012-11-18T08:00:00.000Z",
    end_date: "2012-11-24T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 48,
    start_date: "2012-11-25T08:00:00.000Z",
    end_date: "2012-12-01T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 49,
    start_date: "2012-12-02T08:00:00.000Z",
    end_date: "2012-12-08T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 50,
    start_date: "2012-12-09T08:00:00.000Z",
    end_date: "2012-12-15T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 51,
    start_date: "2012-12-16T08:00:00.000Z",
    end_date: "2012-12-22T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 52,
    start_date: "2012-12-23T08:00:00.000Z",
    end_date: "2012-12-29T08:00:00.000Z",
  },
  {
    disease_year: 2012,
    disease_week: 53,
    start_date: "2012-12-30T08:00:00.000Z",
    end_date: "2013-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 1,
    start_date: "2012-12-30T08:00:00.000Z",
    end_date: "2013-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 2,
    start_date: "2013-01-06T08:00:00.000Z",
    end_date: "2013-01-12T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 3,
    start_date: "2013-01-13T08:00:00.000Z",
    end_date: "2013-01-19T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 4,
    start_date: "2013-01-20T08:00:00.000Z",
    end_date: "2013-01-26T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 5,
    start_date: "2013-01-27T08:00:00.000Z",
    end_date: "2013-02-02T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 6,
    start_date: "2013-02-03T08:00:00.000Z",
    end_date: "2013-02-09T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 7,
    start_date: "2013-02-10T08:00:00.000Z",
    end_date: "2013-02-16T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 8,
    start_date: "2013-02-17T08:00:00.000Z",
    end_date: "2013-02-23T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 9,
    start_date: "2013-02-24T08:00:00.000Z",
    end_date: "2013-03-02T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 10,
    start_date: "2013-03-03T08:00:00.000Z",
    end_date: "2013-03-09T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 11,
    start_date: "2013-03-10T08:00:00.000Z",
    end_date: "2013-03-16T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 12,
    start_date: "2013-03-17T07:00:00.000Z",
    end_date: "2013-03-23T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 13,
    start_date: "2013-03-24T07:00:00.000Z",
    end_date: "2013-03-30T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 14,
    start_date: "2013-03-31T07:00:00.000Z",
    end_date: "2013-04-06T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 15,
    start_date: "2013-04-07T07:00:00.000Z",
    end_date: "2013-04-13T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 16,
    start_date: "2013-04-14T07:00:00.000Z",
    end_date: "2013-04-20T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 17,
    start_date: "2013-04-21T07:00:00.000Z",
    end_date: "2013-04-27T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 18,
    start_date: "2013-04-28T07:00:00.000Z",
    end_date: "2013-05-04T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 19,
    start_date: "2013-05-05T07:00:00.000Z",
    end_date: "2013-05-11T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 20,
    start_date: "2013-05-12T07:00:00.000Z",
    end_date: "2013-05-18T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 21,
    start_date: "2013-05-19T07:00:00.000Z",
    end_date: "2013-05-25T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 22,
    start_date: "2013-05-26T07:00:00.000Z",
    end_date: "2013-06-01T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 23,
    start_date: "2013-06-02T07:00:00.000Z",
    end_date: "2013-06-08T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 24,
    start_date: "2013-06-09T07:00:00.000Z",
    end_date: "2013-06-15T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 25,
    start_date: "2013-06-16T07:00:00.000Z",
    end_date: "2013-06-22T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 26,
    start_date: "2013-06-23T07:00:00.000Z",
    end_date: "2013-06-29T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 27,
    start_date: "2013-06-30T07:00:00.000Z",
    end_date: "2013-07-06T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 28,
    start_date: "2013-07-07T07:00:00.000Z",
    end_date: "2013-07-13T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 29,
    start_date: "2013-07-14T07:00:00.000Z",
    end_date: "2013-07-20T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 30,
    start_date: "2013-07-21T07:00:00.000Z",
    end_date: "2013-07-27T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 31,
    start_date: "2013-07-28T07:00:00.000Z",
    end_date: "2013-08-03T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 32,
    start_date: "2013-08-04T07:00:00.000Z",
    end_date: "2013-08-10T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 33,
    start_date: "2013-08-11T07:00:00.000Z",
    end_date: "2013-08-17T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 34,
    start_date: "2013-08-18T07:00:00.000Z",
    end_date: "2013-08-24T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 35,
    start_date: "2013-08-25T07:00:00.000Z",
    end_date: "2013-08-31T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 36,
    start_date: "2013-09-01T07:00:00.000Z",
    end_date: "2013-09-07T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 37,
    start_date: "2013-09-08T07:00:00.000Z",
    end_date: "2013-09-14T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 38,
    start_date: "2013-09-15T07:00:00.000Z",
    end_date: "2013-09-21T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 39,
    start_date: "2013-09-22T07:00:00.000Z",
    end_date: "2013-09-28T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 40,
    start_date: "2013-09-29T07:00:00.000Z",
    end_date: "2013-10-05T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 41,
    start_date: "2013-10-06T07:00:00.000Z",
    end_date: "2013-10-12T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 42,
    start_date: "2013-10-13T07:00:00.000Z",
    end_date: "2013-10-19T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 43,
    start_date: "2013-10-20T07:00:00.000Z",
    end_date: "2013-10-26T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 44,
    start_date: "2013-10-27T07:00:00.000Z",
    end_date: "2013-11-02T07:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 45,
    start_date: "2013-11-03T07:00:00.000Z",
    end_date: "2013-11-09T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 46,
    start_date: "2013-11-10T08:00:00.000Z",
    end_date: "2013-11-16T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 47,
    start_date: "2013-11-17T08:00:00.000Z",
    end_date: "2013-11-23T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 48,
    start_date: "2013-11-24T08:00:00.000Z",
    end_date: "2013-11-30T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 49,
    start_date: "2013-12-01T08:00:00.000Z",
    end_date: "2013-12-07T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 50,
    start_date: "2013-12-08T08:00:00.000Z",
    end_date: "2013-12-14T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 51,
    start_date: "2013-12-15T08:00:00.000Z",
    end_date: "2013-12-21T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 52,
    start_date: "2013-12-22T08:00:00.000Z",
    end_date: "2013-12-28T08:00:00.000Z",
  },
  {
    disease_year: 2013,
    disease_week: 53,
    start_date: "2013-12-29T08:00:00.000Z",
    end_date: "2014-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 1,
    start_date: "2013-12-29T08:00:00.000Z",
    end_date: "2014-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 2,
    start_date: "2014-01-05T08:00:00.000Z",
    end_date: "2014-01-11T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 3,
    start_date: "2014-01-12T08:00:00.000Z",
    end_date: "2014-01-18T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 4,
    start_date: "2014-01-19T08:00:00.000Z",
    end_date: "2014-01-25T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 5,
    start_date: "2014-01-26T08:00:00.000Z",
    end_date: "2014-02-01T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 6,
    start_date: "2014-02-02T08:00:00.000Z",
    end_date: "2014-02-08T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 7,
    start_date: "2014-02-09T08:00:00.000Z",
    end_date: "2014-02-15T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 8,
    start_date: "2014-02-16T08:00:00.000Z",
    end_date: "2014-02-22T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 9,
    start_date: "2014-02-23T08:00:00.000Z",
    end_date: "2014-03-01T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 10,
    start_date: "2014-03-02T08:00:00.000Z",
    end_date: "2014-03-08T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 11,
    start_date: "2014-03-09T08:00:00.000Z",
    end_date: "2014-03-15T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 12,
    start_date: "2014-03-16T07:00:00.000Z",
    end_date: "2014-03-22T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 13,
    start_date: "2014-03-23T07:00:00.000Z",
    end_date: "2014-03-29T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 14,
    start_date: "2014-03-30T07:00:00.000Z",
    end_date: "2014-04-05T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 15,
    start_date: "2014-04-06T07:00:00.000Z",
    end_date: "2014-04-12T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 16,
    start_date: "2014-04-13T07:00:00.000Z",
    end_date: "2014-04-19T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 17,
    start_date: "2014-04-20T07:00:00.000Z",
    end_date: "2014-04-26T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 18,
    start_date: "2014-04-27T07:00:00.000Z",
    end_date: "2014-05-03T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 19,
    start_date: "2014-05-04T07:00:00.000Z",
    end_date: "2014-05-10T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 20,
    start_date: "2014-05-11T07:00:00.000Z",
    end_date: "2014-05-17T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 21,
    start_date: "2014-05-18T07:00:00.000Z",
    end_date: "2014-05-24T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 22,
    start_date: "2014-05-25T07:00:00.000Z",
    end_date: "2014-05-31T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 23,
    start_date: "2014-06-01T07:00:00.000Z",
    end_date: "2014-06-07T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 24,
    start_date: "2014-06-08T07:00:00.000Z",
    end_date: "2014-06-14T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 25,
    start_date: "2014-06-15T07:00:00.000Z",
    end_date: "2014-06-21T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 26,
    start_date: "2014-06-22T07:00:00.000Z",
    end_date: "2014-06-28T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 27,
    start_date: "2014-06-29T07:00:00.000Z",
    end_date: "2014-07-05T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 28,
    start_date: "2014-07-06T07:00:00.000Z",
    end_date: "2014-07-12T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 29,
    start_date: "2014-07-13T07:00:00.000Z",
    end_date: "2014-07-19T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 30,
    start_date: "2014-07-20T07:00:00.000Z",
    end_date: "2014-07-26T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 31,
    start_date: "2014-07-27T07:00:00.000Z",
    end_date: "2014-08-02T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 32,
    start_date: "2014-08-03T07:00:00.000Z",
    end_date: "2014-08-09T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 33,
    start_date: "2014-08-10T07:00:00.000Z",
    end_date: "2014-08-16T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 34,
    start_date: "2014-08-17T07:00:00.000Z",
    end_date: "2014-08-23T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 35,
    start_date: "2014-08-24T07:00:00.000Z",
    end_date: "2014-08-30T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 36,
    start_date: "2014-08-31T07:00:00.000Z",
    end_date: "2014-09-06T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 37,
    start_date: "2014-09-07T07:00:00.000Z",
    end_date: "2014-09-13T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 38,
    start_date: "2014-09-14T07:00:00.000Z",
    end_date: "2014-09-20T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 39,
    start_date: "2014-09-21T07:00:00.000Z",
    end_date: "2014-09-27T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 40,
    start_date: "2014-09-28T07:00:00.000Z",
    end_date: "2014-10-04T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 41,
    start_date: "2014-10-05T07:00:00.000Z",
    end_date: "2014-10-11T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 42,
    start_date: "2014-10-12T07:00:00.000Z",
    end_date: "2014-10-18T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 43,
    start_date: "2014-10-19T07:00:00.000Z",
    end_date: "2014-10-25T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 44,
    start_date: "2014-10-26T07:00:00.000Z",
    end_date: "2014-11-01T07:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 45,
    start_date: "2014-11-02T07:00:00.000Z",
    end_date: "2014-11-08T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 46,
    start_date: "2014-11-09T08:00:00.000Z",
    end_date: "2014-11-15T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 47,
    start_date: "2014-11-16T08:00:00.000Z",
    end_date: "2014-11-22T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 48,
    start_date: "2014-11-23T08:00:00.000Z",
    end_date: "2014-11-29T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 49,
    start_date: "2014-11-30T08:00:00.000Z",
    end_date: "2014-12-06T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 50,
    start_date: "2014-12-07T08:00:00.000Z",
    end_date: "2014-12-13T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 51,
    start_date: "2014-12-14T08:00:00.000Z",
    end_date: "2014-12-20T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 52,
    start_date: "2014-12-21T08:00:00.000Z",
    end_date: "2014-12-27T08:00:00.000Z",
  },
  {
    disease_year: 2014,
    disease_week: 53,
    start_date: "2014-12-28T08:00:00.000Z",
    end_date: "2015-01-03T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 1,
    start_date: "2017-01-01T08:00:00.000Z",
    end_date: "2017-01-07T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 2,
    start_date: "2017-01-08T08:00:00.000Z",
    end_date: "2017-01-14T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 3,
    start_date: "2017-01-15T08:00:00.000Z",
    end_date: "2017-01-21T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 4,
    start_date: "2017-01-22T08:00:00.000Z",
    end_date: "2017-01-28T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 5,
    start_date: "2017-01-29T08:00:00.000Z",
    end_date: "2017-02-04T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 6,
    start_date: "2017-02-05T08:00:00.000Z",
    end_date: "2017-02-11T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 7,
    start_date: "2017-02-12T08:00:00.000Z",
    end_date: "2017-02-18T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 8,
    start_date: "2017-02-19T08:00:00.000Z",
    end_date: "2017-02-25T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 9,
    start_date: "2017-02-26T08:00:00.000Z",
    end_date: "2017-03-04T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 10,
    start_date: "2017-03-05T08:00:00.000Z",
    end_date: "2017-03-11T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 11,
    start_date: "2017-03-12T08:00:00.000Z",
    end_date: "2017-03-18T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 12,
    start_date: "2017-03-19T07:00:00.000Z",
    end_date: "2017-03-25T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 13,
    start_date: "2017-03-26T07:00:00.000Z",
    end_date: "2017-04-01T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 14,
    start_date: "2017-04-02T07:00:00.000Z",
    end_date: "2017-04-08T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 15,
    start_date: "2017-04-09T07:00:00.000Z",
    end_date: "2017-04-15T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 16,
    start_date: "2017-04-16T07:00:00.000Z",
    end_date: "2017-04-22T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 17,
    start_date: "2017-04-23T07:00:00.000Z",
    end_date: "2017-04-29T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 18,
    start_date: "2017-04-30T07:00:00.000Z",
    end_date: "2017-05-06T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 19,
    start_date: "2017-05-07T07:00:00.000Z",
    end_date: "2017-05-13T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 20,
    start_date: "2017-05-14T07:00:00.000Z",
    end_date: "2017-05-20T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 21,
    start_date: "2017-05-21T07:00:00.000Z",
    end_date: "2017-05-27T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 22,
    start_date: "2017-05-28T07:00:00.000Z",
    end_date: "2017-06-03T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 23,
    start_date: "2017-06-04T07:00:00.000Z",
    end_date: "2017-06-10T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 24,
    start_date: "2017-06-11T07:00:00.000Z",
    end_date: "2017-06-17T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 25,
    start_date: "2017-06-18T07:00:00.000Z",
    end_date: "2017-06-24T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 26,
    start_date: "2017-06-25T07:00:00.000Z",
    end_date: "2017-07-01T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 27,
    start_date: "2017-07-02T07:00:00.000Z",
    end_date: "2017-07-08T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 28,
    start_date: "2017-07-09T07:00:00.000Z",
    end_date: "2017-07-15T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 29,
    start_date: "2017-07-16T07:00:00.000Z",
    end_date: "2017-07-22T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 30,
    start_date: "2017-07-23T07:00:00.000Z",
    end_date: "2017-07-29T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 31,
    start_date: "2017-07-30T07:00:00.000Z",
    end_date: "2017-08-05T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 32,
    start_date: "2017-08-06T07:00:00.000Z",
    end_date: "2017-08-12T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 33,
    start_date: "2017-08-13T07:00:00.000Z",
    end_date: "2017-08-19T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 34,
    start_date: "2017-08-20T07:00:00.000Z",
    end_date: "2017-08-26T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 35,
    start_date: "2017-08-27T07:00:00.000Z",
    end_date: "2017-09-02T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 36,
    start_date: "2017-09-03T07:00:00.000Z",
    end_date: "2017-09-09T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 37,
    start_date: "2017-09-10T07:00:00.000Z",
    end_date: "2017-09-16T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 38,
    start_date: "2017-09-17T07:00:00.000Z",
    end_date: "2017-09-23T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 39,
    start_date: "2017-09-24T07:00:00.000Z",
    end_date: "2017-09-30T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 40,
    start_date: "2017-10-01T07:00:00.000Z",
    end_date: "2017-10-07T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 41,
    start_date: "2017-10-08T07:00:00.000Z",
    end_date: "2017-10-14T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 42,
    start_date: "2017-10-15T07:00:00.000Z",
    end_date: "2017-10-21T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 43,
    start_date: "2017-10-22T07:00:00.000Z",
    end_date: "2017-10-28T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 44,
    start_date: "2017-10-29T07:00:00.000Z",
    end_date: "2017-11-04T07:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 45,
    start_date: "2017-11-05T07:00:00.000Z",
    end_date: "2017-11-11T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 46,
    start_date: "2017-11-12T08:00:00.000Z",
    end_date: "2017-11-18T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 47,
    start_date: "2017-11-19T08:00:00.000Z",
    end_date: "2017-11-25T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 48,
    start_date: "2017-11-26T08:00:00.000Z",
    end_date: "2017-12-02T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 49,
    start_date: "2017-12-03T08:00:00.000Z",
    end_date: "2017-12-09T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 50,
    start_date: "2017-12-10T08:00:00.000Z",
    end_date: "2017-12-16T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 51,
    start_date: "2017-12-17T08:00:00.000Z",
    end_date: "2017-12-23T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 52,
    start_date: "2017-12-24T08:00:00.000Z",
    end_date: "2017-12-30T08:00:00.000Z",
  },
  {
    disease_year: 2017,
    disease_week: 53,
    start_date: "2017-12-31T08:00:00.000Z",
    end_date: "2018-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 1,
    start_date: "2017-12-31T08:00:00.000Z",
    end_date: "2018-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 2,
    start_date: "2018-01-07T08:00:00.000Z",
    end_date: "2018-01-13T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 3,
    start_date: "2018-01-14T08:00:00.000Z",
    end_date: "2018-01-20T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 4,
    start_date: "2018-01-21T08:00:00.000Z",
    end_date: "2018-01-27T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 5,
    start_date: "2018-01-28T08:00:00.000Z",
    end_date: "2018-02-03T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 6,
    start_date: "2018-02-04T08:00:00.000Z",
    end_date: "2018-02-10T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 7,
    start_date: "2018-02-11T08:00:00.000Z",
    end_date: "2018-02-17T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 8,
    start_date: "2018-02-18T08:00:00.000Z",
    end_date: "2018-02-24T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 9,
    start_date: "2018-02-25T08:00:00.000Z",
    end_date: "2018-03-03T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 10,
    start_date: "2018-03-04T08:00:00.000Z",
    end_date: "2018-03-10T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 11,
    start_date: "2018-03-11T08:00:00.000Z",
    end_date: "2018-03-17T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 12,
    start_date: "2018-03-18T07:00:00.000Z",
    end_date: "2018-03-24T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 13,
    start_date: "2018-03-25T07:00:00.000Z",
    end_date: "2018-03-31T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 14,
    start_date: "2018-04-01T07:00:00.000Z",
    end_date: "2018-04-07T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 15,
    start_date: "2018-04-08T07:00:00.000Z",
    end_date: "2018-04-14T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 16,
    start_date: "2018-04-15T07:00:00.000Z",
    end_date: "2018-04-21T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 17,
    start_date: "2018-04-22T07:00:00.000Z",
    end_date: "2018-04-28T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 18,
    start_date: "2018-04-29T07:00:00.000Z",
    end_date: "2018-05-05T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 19,
    start_date: "2018-05-06T07:00:00.000Z",
    end_date: "2018-05-12T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 20,
    start_date: "2018-05-13T07:00:00.000Z",
    end_date: "2018-05-19T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 21,
    start_date: "2018-05-20T07:00:00.000Z",
    end_date: "2018-05-26T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 22,
    start_date: "2018-05-27T07:00:00.000Z",
    end_date: "2018-06-02T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 23,
    start_date: "2018-06-03T07:00:00.000Z",
    end_date: "2018-06-09T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 24,
    start_date: "2018-06-10T07:00:00.000Z",
    end_date: "2018-06-16T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 25,
    start_date: "2018-06-17T07:00:00.000Z",
    end_date: "2018-06-23T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 26,
    start_date: "2018-06-24T07:00:00.000Z",
    end_date: "2018-06-30T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 27,
    start_date: "2018-07-01T07:00:00.000Z",
    end_date: "2018-07-07T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 28,
    start_date: "2018-07-08T07:00:00.000Z",
    end_date: "2018-07-14T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 29,
    start_date: "2018-07-15T07:00:00.000Z",
    end_date: "2018-07-21T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 30,
    start_date: "2018-07-22T07:00:00.000Z",
    end_date: "2018-07-28T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 31,
    start_date: "2018-07-29T07:00:00.000Z",
    end_date: "2018-08-04T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 32,
    start_date: "2018-08-05T07:00:00.000Z",
    end_date: "2018-08-11T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 33,
    start_date: "2018-08-12T07:00:00.000Z",
    end_date: "2018-08-18T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 34,
    start_date: "2018-08-19T07:00:00.000Z",
    end_date: "2018-08-25T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 35,
    start_date: "2018-08-26T07:00:00.000Z",
    end_date: "2018-09-01T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 36,
    start_date: "2018-09-02T07:00:00.000Z",
    end_date: "2018-09-08T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 37,
    start_date: "2018-09-09T07:00:00.000Z",
    end_date: "2018-09-15T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 38,
    start_date: "2018-09-16T07:00:00.000Z",
    end_date: "2018-09-22T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 39,
    start_date: "2018-09-23T07:00:00.000Z",
    end_date: "2018-09-29T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 40,
    start_date: "2018-09-30T07:00:00.000Z",
    end_date: "2018-10-06T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 41,
    start_date: "2018-10-07T07:00:00.000Z",
    end_date: "2018-10-13T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 42,
    start_date: "2018-10-14T07:00:00.000Z",
    end_date: "2018-10-20T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 43,
    start_date: "2018-10-21T07:00:00.000Z",
    end_date: "2018-10-27T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 44,
    start_date: "2018-10-28T07:00:00.000Z",
    end_date: "2018-11-03T07:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 45,
    start_date: "2018-11-04T07:00:00.000Z",
    end_date: "2018-11-10T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 46,
    start_date: "2018-11-11T08:00:00.000Z",
    end_date: "2018-11-17T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 47,
    start_date: "2018-11-18T08:00:00.000Z",
    end_date: "2018-11-24T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 48,
    start_date: "2018-11-25T08:00:00.000Z",
    end_date: "2018-12-01T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 49,
    start_date: "2018-12-02T08:00:00.000Z",
    end_date: "2018-12-08T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 50,
    start_date: "2018-12-09T08:00:00.000Z",
    end_date: "2018-12-15T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 51,
    start_date: "2018-12-16T08:00:00.000Z",
    end_date: "2018-12-22T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 52,
    start_date: "2018-12-23T08:00:00.000Z",
    end_date: "2018-12-29T08:00:00.000Z",
  },
  {
    disease_year: 2018,
    disease_week: 53,
    start_date: "2018-12-30T08:00:00.000Z",
    end_date: "2019-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 1,
    start_date: "2018-12-30T08:00:00.000Z",
    end_date: "2019-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 2,
    start_date: "2019-01-06T08:00:00.000Z",
    end_date: "2019-01-12T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 3,
    start_date: "2019-01-13T08:00:00.000Z",
    end_date: "2019-01-19T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 4,
    start_date: "2019-01-20T08:00:00.000Z",
    end_date: "2019-01-26T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 5,
    start_date: "2019-01-27T08:00:00.000Z",
    end_date: "2019-02-02T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 6,
    start_date: "2019-02-03T08:00:00.000Z",
    end_date: "2019-02-09T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 7,
    start_date: "2019-02-10T08:00:00.000Z",
    end_date: "2019-02-16T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 8,
    start_date: "2019-02-17T08:00:00.000Z",
    end_date: "2019-02-23T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 9,
    start_date: "2019-02-24T08:00:00.000Z",
    end_date: "2019-03-02T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 10,
    start_date: "2019-03-03T08:00:00.000Z",
    end_date: "2019-03-09T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 11,
    start_date: "2019-03-10T08:00:00.000Z",
    end_date: "2019-03-16T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 12,
    start_date: "2019-03-17T07:00:00.000Z",
    end_date: "2019-03-23T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 13,
    start_date: "2019-03-24T07:00:00.000Z",
    end_date: "2019-03-30T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 14,
    start_date: "2019-03-31T07:00:00.000Z",
    end_date: "2019-04-06T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 15,
    start_date: "2019-04-07T07:00:00.000Z",
    end_date: "2019-04-13T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 16,
    start_date: "2019-04-14T07:00:00.000Z",
    end_date: "2019-04-20T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 17,
    start_date: "2019-04-21T07:00:00.000Z",
    end_date: "2019-04-27T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 18,
    start_date: "2019-04-28T07:00:00.000Z",
    end_date: "2019-05-04T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 19,
    start_date: "2019-05-05T07:00:00.000Z",
    end_date: "2019-05-11T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 20,
    start_date: "2019-05-12T07:00:00.000Z",
    end_date: "2019-05-18T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 21,
    start_date: "2019-05-19T07:00:00.000Z",
    end_date: "2019-05-25T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 22,
    start_date: "2019-05-26T07:00:00.000Z",
    end_date: "2019-06-01T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 23,
    start_date: "2019-06-02T07:00:00.000Z",
    end_date: "2019-06-08T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 24,
    start_date: "2019-06-09T07:00:00.000Z",
    end_date: "2019-06-15T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 25,
    start_date: "2019-06-16T07:00:00.000Z",
    end_date: "2019-06-22T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 26,
    start_date: "2019-06-23T07:00:00.000Z",
    end_date: "2019-06-29T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 27,
    start_date: "2019-06-30T07:00:00.000Z",
    end_date: "2019-07-06T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 28,
    start_date: "2019-07-07T07:00:00.000Z",
    end_date: "2019-07-13T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 29,
    start_date: "2019-07-14T07:00:00.000Z",
    end_date: "2019-07-20T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 30,
    start_date: "2019-07-21T07:00:00.000Z",
    end_date: "2019-07-27T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 31,
    start_date: "2019-07-28T07:00:00.000Z",
    end_date: "2019-08-03T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 32,
    start_date: "2019-08-04T07:00:00.000Z",
    end_date: "2019-08-10T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 33,
    start_date: "2019-08-11T07:00:00.000Z",
    end_date: "2019-08-17T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 34,
    start_date: "2019-08-18T07:00:00.000Z",
    end_date: "2019-08-24T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 35,
    start_date: "2019-08-25T07:00:00.000Z",
    end_date: "2019-08-31T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 36,
    start_date: "2019-09-01T07:00:00.000Z",
    end_date: "2019-09-07T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 37,
    start_date: "2019-09-08T07:00:00.000Z",
    end_date: "2019-09-14T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 38,
    start_date: "2019-09-15T07:00:00.000Z",
    end_date: "2019-09-21T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 39,
    start_date: "2019-09-22T07:00:00.000Z",
    end_date: "2019-09-28T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 40,
    start_date: "2019-09-29T07:00:00.000Z",
    end_date: "2019-10-05T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 41,
    start_date: "2019-10-06T07:00:00.000Z",
    end_date: "2019-10-12T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 42,
    start_date: "2019-10-13T07:00:00.000Z",
    end_date: "2019-10-19T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 43,
    start_date: "2019-10-20T07:00:00.000Z",
    end_date: "2019-10-26T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 44,
    start_date: "2019-10-27T07:00:00.000Z",
    end_date: "2019-11-02T07:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 45,
    start_date: "2019-11-03T07:00:00.000Z",
    end_date: "2019-11-09T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 46,
    start_date: "2019-11-10T08:00:00.000Z",
    end_date: "2019-11-16T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 47,
    start_date: "2019-11-17T08:00:00.000Z",
    end_date: "2019-11-23T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 48,
    start_date: "2019-11-24T08:00:00.000Z",
    end_date: "2019-11-30T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 49,
    start_date: "2019-12-01T08:00:00.000Z",
    end_date: "2019-12-07T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 50,
    start_date: "2019-12-08T08:00:00.000Z",
    end_date: "2019-12-14T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 51,
    start_date: "2019-12-15T08:00:00.000Z",
    end_date: "2019-12-21T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 52,
    start_date: "2019-12-22T08:00:00.000Z",
    end_date: "2019-12-28T08:00:00.000Z",
  },
  {
    disease_year: 2019,
    disease_week: 53,
    start_date: "2019-12-29T08:00:00.000Z",
    end_date: "2020-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 1,
    start_date: "2019-12-29T08:00:00.000Z",
    end_date: "2020-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 2,
    start_date: "2020-01-05T08:00:00.000Z",
    end_date: "2020-01-11T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 3,
    start_date: "2020-01-12T08:00:00.000Z",
    end_date: "2020-01-18T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 4,
    start_date: "2020-01-19T08:00:00.000Z",
    end_date: "2020-01-25T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 5,
    start_date: "2020-01-26T08:00:00.000Z",
    end_date: "2020-02-01T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 6,
    start_date: "2020-02-02T08:00:00.000Z",
    end_date: "2020-02-08T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 7,
    start_date: "2020-02-09T08:00:00.000Z",
    end_date: "2020-02-15T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 8,
    start_date: "2020-02-16T08:00:00.000Z",
    end_date: "2020-02-22T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 9,
    start_date: "2020-02-23T08:00:00.000Z",
    end_date: "2020-02-29T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 10,
    start_date: "2020-03-01T08:00:00.000Z",
    end_date: "2020-03-07T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 11,
    start_date: "2020-03-08T08:00:00.000Z",
    end_date: "2020-03-14T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 12,
    start_date: "2020-03-15T07:00:00.000Z",
    end_date: "2020-03-21T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 13,
    start_date: "2020-03-22T07:00:00.000Z",
    end_date: "2020-03-28T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 14,
    start_date: "2020-03-29T07:00:00.000Z",
    end_date: "2020-04-04T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 15,
    start_date: "2020-04-05T07:00:00.000Z",
    end_date: "2020-04-11T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 16,
    start_date: "2020-04-12T07:00:00.000Z",
    end_date: "2020-04-18T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 17,
    start_date: "2020-04-19T07:00:00.000Z",
    end_date: "2020-04-25T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 18,
    start_date: "2020-04-26T07:00:00.000Z",
    end_date: "2020-05-02T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 19,
    start_date: "2020-05-03T07:00:00.000Z",
    end_date: "2020-05-09T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 20,
    start_date: "2020-05-10T07:00:00.000Z",
    end_date: "2020-05-16T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 21,
    start_date: "2020-05-17T07:00:00.000Z",
    end_date: "2020-05-23T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 22,
    start_date: "2020-05-24T07:00:00.000Z",
    end_date: "2020-05-30T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 23,
    start_date: "2020-05-31T07:00:00.000Z",
    end_date: "2020-06-06T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 24,
    start_date: "2020-06-07T07:00:00.000Z",
    end_date: "2020-06-13T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 25,
    start_date: "2020-06-14T07:00:00.000Z",
    end_date: "2020-06-20T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 26,
    start_date: "2020-06-21T07:00:00.000Z",
    end_date: "2020-06-27T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 27,
    start_date: "2020-06-28T07:00:00.000Z",
    end_date: "2020-07-04T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 28,
    start_date: "2020-07-05T07:00:00.000Z",
    end_date: "2020-07-11T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 29,
    start_date: "2020-07-12T07:00:00.000Z",
    end_date: "2020-07-18T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 30,
    start_date: "2020-07-19T07:00:00.000Z",
    end_date: "2020-07-25T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 31,
    start_date: "2020-07-26T07:00:00.000Z",
    end_date: "2020-08-01T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 32,
    start_date: "2020-08-02T07:00:00.000Z",
    end_date: "2020-08-08T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 33,
    start_date: "2020-08-09T07:00:00.000Z",
    end_date: "2020-08-15T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 34,
    start_date: "2020-08-16T07:00:00.000Z",
    end_date: "2020-08-22T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 35,
    start_date: "2020-08-23T07:00:00.000Z",
    end_date: "2020-08-29T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 36,
    start_date: "2020-08-30T07:00:00.000Z",
    end_date: "2020-09-05T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 37,
    start_date: "2020-09-06T07:00:00.000Z",
    end_date: "2020-09-12T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 38,
    start_date: "2020-09-13T07:00:00.000Z",
    end_date: "2020-09-19T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 39,
    start_date: "2020-09-20T07:00:00.000Z",
    end_date: "2020-09-26T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 40,
    start_date: "2020-09-27T07:00:00.000Z",
    end_date: "2020-10-03T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 41,
    start_date: "2020-10-04T07:00:00.000Z",
    end_date: "2020-10-10T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 42,
    start_date: "2020-10-11T07:00:00.000Z",
    end_date: "2020-10-17T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 43,
    start_date: "2020-10-18T07:00:00.000Z",
    end_date: "2020-10-24T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 44,
    start_date: "2020-10-25T07:00:00.000Z",
    end_date: "2020-10-31T07:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 45,
    start_date: "2020-11-01T07:00:00.000Z",
    end_date: "2020-11-07T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 46,
    start_date: "2020-11-08T08:00:00.000Z",
    end_date: "2020-11-14T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 47,
    start_date: "2020-11-15T08:00:00.000Z",
    end_date: "2020-11-21T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 48,
    start_date: "2020-11-22T08:00:00.000Z",
    end_date: "2020-11-28T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 49,
    start_date: "2020-11-29T08:00:00.000Z",
    end_date: "2020-12-05T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 50,
    start_date: "2020-12-06T08:00:00.000Z",
    end_date: "2020-12-12T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 51,
    start_date: "2020-12-13T08:00:00.000Z",
    end_date: "2020-12-19T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 52,
    start_date: "2020-12-20T08:00:00.000Z",
    end_date: "2020-12-26T08:00:00.000Z",
  },
  {
    disease_year: 2020,
    disease_week: 53,
    start_date: "2020-12-27T08:00:00.000Z",
    end_date: "2021-01-02T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 1,
    start_date: "2023-01-01T08:00:00.000Z",
    end_date: "2023-01-07T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 2,
    start_date: "2023-01-08T08:00:00.000Z",
    end_date: "2023-01-14T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 3,
    start_date: "2023-01-15T08:00:00.000Z",
    end_date: "2023-01-21T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 4,
    start_date: "2023-01-22T08:00:00.000Z",
    end_date: "2023-01-28T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 5,
    start_date: "2023-01-29T08:00:00.000Z",
    end_date: "2023-02-04T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 6,
    start_date: "2023-02-05T08:00:00.000Z",
    end_date: "2023-02-11T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 7,
    start_date: "2023-02-12T08:00:00.000Z",
    end_date: "2023-02-18T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 8,
    start_date: "2023-02-19T08:00:00.000Z",
    end_date: "2023-02-25T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 9,
    start_date: "2023-02-26T08:00:00.000Z",
    end_date: "2023-03-04T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 10,
    start_date: "2023-03-05T08:00:00.000Z",
    end_date: "2023-03-11T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 11,
    start_date: "2023-03-12T08:00:00.000Z",
    end_date: "2023-03-18T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 12,
    start_date: "2023-03-19T07:00:00.000Z",
    end_date: "2023-03-25T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 13,
    start_date: "2023-03-26T07:00:00.000Z",
    end_date: "2023-04-01T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 14,
    start_date: "2023-04-02T07:00:00.000Z",
    end_date: "2023-04-08T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 15,
    start_date: "2023-04-09T07:00:00.000Z",
    end_date: "2023-04-15T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 16,
    start_date: "2023-04-16T07:00:00.000Z",
    end_date: "2023-04-22T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 17,
    start_date: "2023-04-23T07:00:00.000Z",
    end_date: "2023-04-29T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 18,
    start_date: "2023-04-30T07:00:00.000Z",
    end_date: "2023-05-06T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 19,
    start_date: "2023-05-07T07:00:00.000Z",
    end_date: "2023-05-13T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 20,
    start_date: "2023-05-14T07:00:00.000Z",
    end_date: "2023-05-20T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 21,
    start_date: "2023-05-21T07:00:00.000Z",
    end_date: "2023-05-27T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 22,
    start_date: "2023-05-28T07:00:00.000Z",
    end_date: "2023-06-03T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 23,
    start_date: "2023-06-04T07:00:00.000Z",
    end_date: "2023-06-10T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 24,
    start_date: "2023-06-11T07:00:00.000Z",
    end_date: "2023-06-17T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 25,
    start_date: "2023-06-18T07:00:00.000Z",
    end_date: "2023-06-24T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 26,
    start_date: "2023-06-25T07:00:00.000Z",
    end_date: "2023-07-01T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 27,
    start_date: "2023-07-02T07:00:00.000Z",
    end_date: "2023-07-08T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 28,
    start_date: "2023-07-09T07:00:00.000Z",
    end_date: "2023-07-15T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 29,
    start_date: "2023-07-16T07:00:00.000Z",
    end_date: "2023-07-22T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 30,
    start_date: "2023-07-23T07:00:00.000Z",
    end_date: "2023-07-29T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 31,
    start_date: "2023-07-30T07:00:00.000Z",
    end_date: "2023-08-05T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 32,
    start_date: "2023-08-06T07:00:00.000Z",
    end_date: "2023-08-12T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 33,
    start_date: "2023-08-13T07:00:00.000Z",
    end_date: "2023-08-19T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 34,
    start_date: "2023-08-20T07:00:00.000Z",
    end_date: "2023-08-26T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 35,
    start_date: "2023-08-27T07:00:00.000Z",
    end_date: "2023-09-02T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 36,
    start_date: "2023-09-03T07:00:00.000Z",
    end_date: "2023-09-09T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 37,
    start_date: "2023-09-10T07:00:00.000Z",
    end_date: "2023-09-16T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 38,
    start_date: "2023-09-17T07:00:00.000Z",
    end_date: "2023-09-23T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 39,
    start_date: "2023-09-24T07:00:00.000Z",
    end_date: "2023-09-30T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 40,
    start_date: "2023-10-01T07:00:00.000Z",
    end_date: "2023-10-07T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 41,
    start_date: "2023-10-08T07:00:00.000Z",
    end_date: "2023-10-14T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 42,
    start_date: "2023-10-15T07:00:00.000Z",
    end_date: "2023-10-21T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 43,
    start_date: "2023-10-22T07:00:00.000Z",
    end_date: "2023-10-28T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 44,
    start_date: "2023-10-29T07:00:00.000Z",
    end_date: "2023-11-04T07:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 45,
    start_date: "2023-11-05T07:00:00.000Z",
    end_date: "2023-11-11T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 46,
    start_date: "2023-11-12T08:00:00.000Z",
    end_date: "2023-11-18T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 47,
    start_date: "2023-11-19T08:00:00.000Z",
    end_date: "2023-11-25T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 48,
    start_date: "2023-11-26T08:00:00.000Z",
    end_date: "2023-12-02T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 49,
    start_date: "2023-12-03T08:00:00.000Z",
    end_date: "2023-12-09T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 50,
    start_date: "2023-12-10T08:00:00.000Z",
    end_date: "2023-12-16T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 51,
    start_date: "2023-12-17T08:00:00.000Z",
    end_date: "2023-12-23T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 52,
    start_date: "2023-12-24T08:00:00.000Z",
    end_date: "2023-12-30T08:00:00.000Z",
  },
  {
    disease_year: 2023,
    disease_week: 53,
    start_date: "2023-12-31T08:00:00.000Z",
    end_date: "2024-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 1,
    start_date: "2023-12-31T08:00:00.000Z",
    end_date: "2024-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 2,
    start_date: "2024-01-07T08:00:00.000Z",
    end_date: "2024-01-13T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 3,
    start_date: "2024-01-14T08:00:00.000Z",
    end_date: "2024-01-20T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 4,
    start_date: "2024-01-21T08:00:00.000Z",
    end_date: "2024-01-27T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 5,
    start_date: "2024-01-28T08:00:00.000Z",
    end_date: "2024-02-03T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 6,
    start_date: "2024-02-04T08:00:00.000Z",
    end_date: "2024-02-10T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 7,
    start_date: "2024-02-11T08:00:00.000Z",
    end_date: "2024-02-17T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 8,
    start_date: "2024-02-18T08:00:00.000Z",
    end_date: "2024-02-24T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 9,
    start_date: "2024-02-25T08:00:00.000Z",
    end_date: "2024-03-02T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 10,
    start_date: "2024-03-03T08:00:00.000Z",
    end_date: "2024-03-09T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 11,
    start_date: "2024-03-10T08:00:00.000Z",
    end_date: "2024-03-16T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 12,
    start_date: "2024-03-17T07:00:00.000Z",
    end_date: "2024-03-23T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 13,
    start_date: "2024-03-24T07:00:00.000Z",
    end_date: "2024-03-30T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 14,
    start_date: "2024-03-31T07:00:00.000Z",
    end_date: "2024-04-06T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 15,
    start_date: "2024-04-07T07:00:00.000Z",
    end_date: "2024-04-13T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 16,
    start_date: "2024-04-14T07:00:00.000Z",
    end_date: "2024-04-20T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 17,
    start_date: "2024-04-21T07:00:00.000Z",
    end_date: "2024-04-27T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 18,
    start_date: "2024-04-28T07:00:00.000Z",
    end_date: "2024-05-04T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 19,
    start_date: "2024-05-05T07:00:00.000Z",
    end_date: "2024-05-11T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 20,
    start_date: "2024-05-12T07:00:00.000Z",
    end_date: "2024-05-18T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 21,
    start_date: "2024-05-19T07:00:00.000Z",
    end_date: "2024-05-25T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 22,
    start_date: "2024-05-26T07:00:00.000Z",
    end_date: "2024-06-01T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 23,
    start_date: "2024-06-02T07:00:00.000Z",
    end_date: "2024-06-08T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 24,
    start_date: "2024-06-09T07:00:00.000Z",
    end_date: "2024-06-15T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 25,
    start_date: "2024-06-16T07:00:00.000Z",
    end_date: "2024-06-22T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 26,
    start_date: "2024-06-23T07:00:00.000Z",
    end_date: "2024-06-29T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 27,
    start_date: "2024-06-30T07:00:00.000Z",
    end_date: "2024-07-06T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 28,
    start_date: "2024-07-07T07:00:00.000Z",
    end_date: "2024-07-13T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 29,
    start_date: "2024-07-14T07:00:00.000Z",
    end_date: "2024-07-20T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 30,
    start_date: "2024-07-21T07:00:00.000Z",
    end_date: "2024-07-27T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 31,
    start_date: "2024-07-28T07:00:00.000Z",
    end_date: "2024-08-03T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 32,
    start_date: "2024-08-04T07:00:00.000Z",
    end_date: "2024-08-10T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 33,
    start_date: "2024-08-11T07:00:00.000Z",
    end_date: "2024-08-17T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 34,
    start_date: "2024-08-18T07:00:00.000Z",
    end_date: "2024-08-24T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 35,
    start_date: "2024-08-25T07:00:00.000Z",
    end_date: "2024-08-31T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 36,
    start_date: "2024-09-01T07:00:00.000Z",
    end_date: "2024-09-07T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 37,
    start_date: "2024-09-08T07:00:00.000Z",
    end_date: "2024-09-14T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 38,
    start_date: "2024-09-15T07:00:00.000Z",
    end_date: "2024-09-21T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 39,
    start_date: "2024-09-22T07:00:00.000Z",
    end_date: "2024-09-28T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 40,
    start_date: "2024-09-29T07:00:00.000Z",
    end_date: "2024-10-05T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 41,
    start_date: "2024-10-06T07:00:00.000Z",
    end_date: "2024-10-12T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 42,
    start_date: "2024-10-13T07:00:00.000Z",
    end_date: "2024-10-19T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 43,
    start_date: "2024-10-20T07:00:00.000Z",
    end_date: "2024-10-26T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 44,
    start_date: "2024-10-27T07:00:00.000Z",
    end_date: "2024-11-02T07:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 45,
    start_date: "2024-11-03T07:00:00.000Z",
    end_date: "2024-11-09T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 46,
    start_date: "2024-11-10T08:00:00.000Z",
    end_date: "2024-11-16T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 47,
    start_date: "2024-11-17T08:00:00.000Z",
    end_date: "2024-11-23T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 48,
    start_date: "2024-11-24T08:00:00.000Z",
    end_date: "2024-11-30T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 49,
    start_date: "2024-12-01T08:00:00.000Z",
    end_date: "2024-12-07T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 50,
    start_date: "2024-12-08T08:00:00.000Z",
    end_date: "2024-12-14T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 51,
    start_date: "2024-12-15T08:00:00.000Z",
    end_date: "2024-12-21T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 52,
    start_date: "2024-12-22T08:00:00.000Z",
    end_date: "2024-12-28T08:00:00.000Z",
  },
  {
    disease_year: 2024,
    disease_week: 53,
    start_date: "2024-12-29T08:00:00.000Z",
    end_date: "2025-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 1,
    start_date: "2024-12-29T08:00:00.000Z",
    end_date: "2025-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 2,
    start_date: "2025-01-05T08:00:00.000Z",
    end_date: "2025-01-11T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 3,
    start_date: "2025-01-12T08:00:00.000Z",
    end_date: "2025-01-18T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 4,
    start_date: "2025-01-19T08:00:00.000Z",
    end_date: "2025-01-25T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 5,
    start_date: "2025-01-26T08:00:00.000Z",
    end_date: "2025-02-01T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 6,
    start_date: "2025-02-02T08:00:00.000Z",
    end_date: "2025-02-08T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 7,
    start_date: "2025-02-09T08:00:00.000Z",
    end_date: "2025-02-15T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 8,
    start_date: "2025-02-16T08:00:00.000Z",
    end_date: "2025-02-22T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 9,
    start_date: "2025-02-23T08:00:00.000Z",
    end_date: "2025-03-01T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 10,
    start_date: "2025-03-02T08:00:00.000Z",
    end_date: "2025-03-08T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 11,
    start_date: "2025-03-09T08:00:00.000Z",
    end_date: "2025-03-15T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 12,
    start_date: "2025-03-16T07:00:00.000Z",
    end_date: "2025-03-22T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 13,
    start_date: "2025-03-23T07:00:00.000Z",
    end_date: "2025-03-29T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 14,
    start_date: "2025-03-30T07:00:00.000Z",
    end_date: "2025-04-05T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 15,
    start_date: "2025-04-06T07:00:00.000Z",
    end_date: "2025-04-12T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 16,
    start_date: "2025-04-13T07:00:00.000Z",
    end_date: "2025-04-19T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 17,
    start_date: "2025-04-20T07:00:00.000Z",
    end_date: "2025-04-26T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 18,
    start_date: "2025-04-27T07:00:00.000Z",
    end_date: "2025-05-03T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 19,
    start_date: "2025-05-04T07:00:00.000Z",
    end_date: "2025-05-10T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 20,
    start_date: "2025-05-11T07:00:00.000Z",
    end_date: "2025-05-17T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 21,
    start_date: "2025-05-18T07:00:00.000Z",
    end_date: "2025-05-24T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 22,
    start_date: "2025-05-25T07:00:00.000Z",
    end_date: "2025-05-31T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 23,
    start_date: "2025-06-01T07:00:00.000Z",
    end_date: "2025-06-07T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 24,
    start_date: "2025-06-08T07:00:00.000Z",
    end_date: "2025-06-14T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 25,
    start_date: "2025-06-15T07:00:00.000Z",
    end_date: "2025-06-21T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 26,
    start_date: "2025-06-22T07:00:00.000Z",
    end_date: "2025-06-28T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 27,
    start_date: "2025-06-29T07:00:00.000Z",
    end_date: "2025-07-05T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 28,
    start_date: "2025-07-06T07:00:00.000Z",
    end_date: "2025-07-12T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 29,
    start_date: "2025-07-13T07:00:00.000Z",
    end_date: "2025-07-19T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 30,
    start_date: "2025-07-20T07:00:00.000Z",
    end_date: "2025-07-26T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 31,
    start_date: "2025-07-27T07:00:00.000Z",
    end_date: "2025-08-02T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 32,
    start_date: "2025-08-03T07:00:00.000Z",
    end_date: "2025-08-09T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 33,
    start_date: "2025-08-10T07:00:00.000Z",
    end_date: "2025-08-16T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 34,
    start_date: "2025-08-17T07:00:00.000Z",
    end_date: "2025-08-23T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 35,
    start_date: "2025-08-24T07:00:00.000Z",
    end_date: "2025-08-30T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 36,
    start_date: "2025-08-31T07:00:00.000Z",
    end_date: "2025-09-06T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 37,
    start_date: "2025-09-07T07:00:00.000Z",
    end_date: "2025-09-13T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 38,
    start_date: "2025-09-14T07:00:00.000Z",
    end_date: "2025-09-20T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 39,
    start_date: "2025-09-21T07:00:00.000Z",
    end_date: "2025-09-27T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 40,
    start_date: "2025-09-28T07:00:00.000Z",
    end_date: "2025-10-04T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 41,
    start_date: "2025-10-05T07:00:00.000Z",
    end_date: "2025-10-11T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 42,
    start_date: "2025-10-12T07:00:00.000Z",
    end_date: "2025-10-18T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 43,
    start_date: "2025-10-19T07:00:00.000Z",
    end_date: "2025-10-25T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 44,
    start_date: "2025-10-26T07:00:00.000Z",
    end_date: "2025-11-01T07:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 45,
    start_date: "2025-11-02T07:00:00.000Z",
    end_date: "2025-11-08T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 46,
    start_date: "2025-11-09T08:00:00.000Z",
    end_date: "2025-11-15T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 47,
    start_date: "2025-11-16T08:00:00.000Z",
    end_date: "2025-11-22T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 48,
    start_date: "2025-11-23T08:00:00.000Z",
    end_date: "2025-11-29T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 49,
    start_date: "2025-11-30T08:00:00.000Z",
    end_date: "2025-12-06T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 50,
    start_date: "2025-12-07T08:00:00.000Z",
    end_date: "2025-12-13T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 51,
    start_date: "2025-12-14T08:00:00.000Z",
    end_date: "2025-12-20T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 52,
    start_date: "2025-12-21T08:00:00.000Z",
    end_date: "2025-12-27T08:00:00.000Z",
  },
  {
    disease_year: 2025,
    disease_week: 53,
    start_date: "2025-12-28T08:00:00.000Z",
    end_date: "2026-01-03T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 1,
    start_date: "2028-12-31T08:00:00.000Z",
    end_date: "2029-01-06T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 2,
    start_date: "2029-01-07T08:00:00.000Z",
    end_date: "2029-01-13T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 3,
    start_date: "2029-01-14T08:00:00.000Z",
    end_date: "2029-01-20T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 4,
    start_date: "2029-01-21T08:00:00.000Z",
    end_date: "2029-01-27T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 5,
    start_date: "2029-01-28T08:00:00.000Z",
    end_date: "2029-02-03T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 6,
    start_date: "2029-02-04T08:00:00.000Z",
    end_date: "2029-02-10T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 7,
    start_date: "2029-02-11T08:00:00.000Z",
    end_date: "2029-02-17T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 8,
    start_date: "2029-02-18T08:00:00.000Z",
    end_date: "2029-02-24T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 9,
    start_date: "2029-02-25T08:00:00.000Z",
    end_date: "2029-03-03T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 10,
    start_date: "2029-03-04T08:00:00.000Z",
    end_date: "2029-03-10T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 11,
    start_date: "2029-03-11T08:00:00.000Z",
    end_date: "2029-03-17T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 12,
    start_date: "2029-03-18T07:00:00.000Z",
    end_date: "2029-03-24T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 13,
    start_date: "2029-03-25T07:00:00.000Z",
    end_date: "2029-03-31T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 14,
    start_date: "2029-04-01T07:00:00.000Z",
    end_date: "2029-04-07T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 15,
    start_date: "2029-04-08T07:00:00.000Z",
    end_date: "2029-04-14T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 16,
    start_date: "2029-04-15T07:00:00.000Z",
    end_date: "2029-04-21T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 17,
    start_date: "2029-04-22T07:00:00.000Z",
    end_date: "2029-04-28T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 18,
    start_date: "2029-04-29T07:00:00.000Z",
    end_date: "2029-05-05T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 19,
    start_date: "2029-05-06T07:00:00.000Z",
    end_date: "2029-05-12T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 20,
    start_date: "2029-05-13T07:00:00.000Z",
    end_date: "2029-05-19T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 21,
    start_date: "2029-05-20T07:00:00.000Z",
    end_date: "2029-05-26T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 22,
    start_date: "2029-05-27T07:00:00.000Z",
    end_date: "2029-06-02T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 23,
    start_date: "2029-06-03T07:00:00.000Z",
    end_date: "2029-06-09T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 24,
    start_date: "2029-06-10T07:00:00.000Z",
    end_date: "2029-06-16T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 25,
    start_date: "2029-06-17T07:00:00.000Z",
    end_date: "2029-06-23T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 26,
    start_date: "2029-06-24T07:00:00.000Z",
    end_date: "2029-06-30T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 27,
    start_date: "2029-07-01T07:00:00.000Z",
    end_date: "2029-07-07T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 28,
    start_date: "2029-07-08T07:00:00.000Z",
    end_date: "2029-07-14T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 29,
    start_date: "2029-07-15T07:00:00.000Z",
    end_date: "2029-07-21T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 30,
    start_date: "2029-07-22T07:00:00.000Z",
    end_date: "2029-07-28T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 31,
    start_date: "2029-07-29T07:00:00.000Z",
    end_date: "2029-08-04T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 32,
    start_date: "2029-08-05T07:00:00.000Z",
    end_date: "2029-08-11T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 33,
    start_date: "2029-08-12T07:00:00.000Z",
    end_date: "2029-08-18T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 34,
    start_date: "2029-08-19T07:00:00.000Z",
    end_date: "2029-08-25T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 35,
    start_date: "2029-08-26T07:00:00.000Z",
    end_date: "2029-09-01T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 36,
    start_date: "2029-09-02T07:00:00.000Z",
    end_date: "2029-09-08T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 37,
    start_date: "2029-09-09T07:00:00.000Z",
    end_date: "2029-09-15T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 38,
    start_date: "2029-09-16T07:00:00.000Z",
    end_date: "2029-09-22T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 39,
    start_date: "2029-09-23T07:00:00.000Z",
    end_date: "2029-09-29T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 40,
    start_date: "2029-09-30T07:00:00.000Z",
    end_date: "2029-10-06T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 41,
    start_date: "2029-10-07T07:00:00.000Z",
    end_date: "2029-10-13T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 42,
    start_date: "2029-10-14T07:00:00.000Z",
    end_date: "2029-10-20T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 43,
    start_date: "2029-10-21T07:00:00.000Z",
    end_date: "2029-10-27T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 44,
    start_date: "2029-10-28T07:00:00.000Z",
    end_date: "2029-11-03T07:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 45,
    start_date: "2029-11-04T07:00:00.000Z",
    end_date: "2029-11-10T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 46,
    start_date: "2029-11-11T08:00:00.000Z",
    end_date: "2029-11-17T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 47,
    start_date: "2029-11-18T08:00:00.000Z",
    end_date: "2029-11-24T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 48,
    start_date: "2029-11-25T08:00:00.000Z",
    end_date: "2029-12-01T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 49,
    start_date: "2029-12-02T08:00:00.000Z",
    end_date: "2029-12-08T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 50,
    start_date: "2029-12-09T08:00:00.000Z",
    end_date: "2029-12-15T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 51,
    start_date: "2029-12-16T08:00:00.000Z",
    end_date: "2029-12-22T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 52,
    start_date: "2029-12-23T08:00:00.000Z",
    end_date: "2029-12-29T08:00:00.000Z",
  },
  {
    disease_year: 2029,
    disease_week: 53,
    start_date: "2029-12-30T08:00:00.000Z",
    end_date: "2030-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 1,
    start_date: "2029-12-30T08:00:00.000Z",
    end_date: "2030-01-05T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 2,
    start_date: "2030-01-06T08:00:00.000Z",
    end_date: "2030-01-12T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 3,
    start_date: "2030-01-13T08:00:00.000Z",
    end_date: "2030-01-19T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 4,
    start_date: "2030-01-20T08:00:00.000Z",
    end_date: "2030-01-26T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 5,
    start_date: "2030-01-27T08:00:00.000Z",
    end_date: "2030-02-02T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 6,
    start_date: "2030-02-03T08:00:00.000Z",
    end_date: "2030-02-09T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 7,
    start_date: "2030-02-10T08:00:00.000Z",
    end_date: "2030-02-16T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 8,
    start_date: "2030-02-17T08:00:00.000Z",
    end_date: "2030-02-23T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 9,
    start_date: "2030-02-24T08:00:00.000Z",
    end_date: "2030-03-02T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 10,
    start_date: "2030-03-03T08:00:00.000Z",
    end_date: "2030-03-09T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 11,
    start_date: "2030-03-10T08:00:00.000Z",
    end_date: "2030-03-16T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 12,
    start_date: "2030-03-17T07:00:00.000Z",
    end_date: "2030-03-23T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 13,
    start_date: "2030-03-24T07:00:00.000Z",
    end_date: "2030-03-30T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 14,
    start_date: "2030-03-31T07:00:00.000Z",
    end_date: "2030-04-06T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 15,
    start_date: "2030-04-07T07:00:00.000Z",
    end_date: "2030-04-13T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 16,
    start_date: "2030-04-14T07:00:00.000Z",
    end_date: "2030-04-20T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 17,
    start_date: "2030-04-21T07:00:00.000Z",
    end_date: "2030-04-27T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 18,
    start_date: "2030-04-28T07:00:00.000Z",
    end_date: "2030-05-04T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 19,
    start_date: "2030-05-05T07:00:00.000Z",
    end_date: "2030-05-11T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 20,
    start_date: "2030-05-12T07:00:00.000Z",
    end_date: "2030-05-18T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 21,
    start_date: "2030-05-19T07:00:00.000Z",
    end_date: "2030-05-25T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 22,
    start_date: "2030-05-26T07:00:00.000Z",
    end_date: "2030-06-01T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 23,
    start_date: "2030-06-02T07:00:00.000Z",
    end_date: "2030-06-08T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 24,
    start_date: "2030-06-09T07:00:00.000Z",
    end_date: "2030-06-15T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 25,
    start_date: "2030-06-16T07:00:00.000Z",
    end_date: "2030-06-22T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 26,
    start_date: "2030-06-23T07:00:00.000Z",
    end_date: "2030-06-29T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 27,
    start_date: "2030-06-30T07:00:00.000Z",
    end_date: "2030-07-06T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 28,
    start_date: "2030-07-07T07:00:00.000Z",
    end_date: "2030-07-13T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 29,
    start_date: "2030-07-14T07:00:00.000Z",
    end_date: "2030-07-20T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 30,
    start_date: "2030-07-21T07:00:00.000Z",
    end_date: "2030-07-27T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 31,
    start_date: "2030-07-28T07:00:00.000Z",
    end_date: "2030-08-03T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 32,
    start_date: "2030-08-04T07:00:00.000Z",
    end_date: "2030-08-10T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 33,
    start_date: "2030-08-11T07:00:00.000Z",
    end_date: "2030-08-17T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 34,
    start_date: "2030-08-18T07:00:00.000Z",
    end_date: "2030-08-24T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 35,
    start_date: "2030-08-25T07:00:00.000Z",
    end_date: "2030-08-31T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 36,
    start_date: "2030-09-01T07:00:00.000Z",
    end_date: "2030-09-07T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 37,
    start_date: "2030-09-08T07:00:00.000Z",
    end_date: "2030-09-14T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 38,
    start_date: "2030-09-15T07:00:00.000Z",
    end_date: "2030-09-21T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 39,
    start_date: "2030-09-22T07:00:00.000Z",
    end_date: "2030-09-28T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 40,
    start_date: "2030-09-29T07:00:00.000Z",
    end_date: "2030-10-05T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 41,
    start_date: "2030-10-06T07:00:00.000Z",
    end_date: "2030-10-12T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 42,
    start_date: "2030-10-13T07:00:00.000Z",
    end_date: "2030-10-19T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 43,
    start_date: "2030-10-20T07:00:00.000Z",
    end_date: "2030-10-26T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 44,
    start_date: "2030-10-27T07:00:00.000Z",
    end_date: "2030-11-02T07:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 45,
    start_date: "2030-11-03T07:00:00.000Z",
    end_date: "2030-11-09T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 46,
    start_date: "2030-11-10T08:00:00.000Z",
    end_date: "2030-11-16T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 47,
    start_date: "2030-11-17T08:00:00.000Z",
    end_date: "2030-11-23T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 48,
    start_date: "2030-11-24T08:00:00.000Z",
    end_date: "2030-11-30T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 49,
    start_date: "2030-12-01T08:00:00.000Z",
    end_date: "2030-12-07T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 50,
    start_date: "2030-12-08T08:00:00.000Z",
    end_date: "2030-12-14T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 51,
    start_date: "2030-12-15T08:00:00.000Z",
    end_date: "2030-12-21T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 52,
    start_date: "2030-12-22T08:00:00.000Z",
    end_date: "2030-12-28T08:00:00.000Z",
  },
  {
    disease_year: 2030,
    disease_week: 53,
    start_date: "2030-12-29T08:00:00.000Z",
    end_date: "2031-01-04T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 2,
    start_date: "2004-01-11T08:00:00.000Z",
    end_date: "2004-01-17T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 3,
    start_date: "2004-01-18T08:00:00.000Z",
    end_date: "2004-01-24T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 4,
    start_date: "2004-01-25T08:00:00.000Z",
    end_date: "2004-01-31T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 5,
    start_date: "2004-02-01T08:00:00.000Z",
    end_date: "2004-02-07T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 6,
    start_date: "2004-02-08T08:00:00.000Z",
    end_date: "2004-02-14T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 7,
    start_date: "2004-02-15T08:00:00.000Z",
    end_date: "2004-02-21T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 8,
    start_date: "2004-02-22T08:00:00.000Z",
    end_date: "2004-02-28T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 9,
    start_date: "2004-02-29T08:00:00.000Z",
    end_date: "2004-03-06T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 10,
    start_date: "2004-03-07T08:00:00.000Z",
    end_date: "2004-03-13T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 11,
    start_date: "2004-03-14T08:00:00.000Z",
    end_date: "2004-03-20T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 12,
    start_date: "2004-03-21T08:00:00.000Z",
    end_date: "2004-03-27T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 13,
    start_date: "2004-03-28T08:00:00.000Z",
    end_date: "2004-04-03T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 14,
    start_date: "2004-04-04T08:00:00.000Z",
    end_date: "2004-04-10T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 15,
    start_date: "2004-04-11T07:00:00.000Z",
    end_date: "2004-04-17T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 16,
    start_date: "2004-04-18T07:00:00.000Z",
    end_date: "2004-04-24T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 17,
    start_date: "2004-04-25T07:00:00.000Z",
    end_date: "2004-05-01T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 18,
    start_date: "2004-05-02T07:00:00.000Z",
    end_date: "2004-05-08T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 19,
    start_date: "2004-05-09T07:00:00.000Z",
    end_date: "2004-05-15T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 20,
    start_date: "2004-05-16T07:00:00.000Z",
    end_date: "2004-05-22T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 21,
    start_date: "2004-05-23T07:00:00.000Z",
    end_date: "2004-05-29T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 22,
    start_date: "2004-05-30T07:00:00.000Z",
    end_date: "2004-06-05T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 23,
    start_date: "2004-06-06T07:00:00.000Z",
    end_date: "2004-06-12T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 24,
    start_date: "2004-06-13T07:00:00.000Z",
    end_date: "2004-06-19T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 25,
    start_date: "2004-06-20T07:00:00.000Z",
    end_date: "2004-06-26T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 26,
    start_date: "2004-06-27T07:00:00.000Z",
    end_date: "2004-07-03T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 27,
    start_date: "2004-07-04T07:00:00.000Z",
    end_date: "2004-07-10T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 28,
    start_date: "2004-07-11T07:00:00.000Z",
    end_date: "2004-07-17T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 29,
    start_date: "2004-07-18T07:00:00.000Z",
    end_date: "2004-07-24T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 30,
    start_date: "2004-07-25T07:00:00.000Z",
    end_date: "2004-07-31T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 31,
    start_date: "2004-08-01T07:00:00.000Z",
    end_date: "2004-08-07T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 32,
    start_date: "2004-08-08T07:00:00.000Z",
    end_date: "2004-08-14T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 33,
    start_date: "2004-08-15T07:00:00.000Z",
    end_date: "2004-08-21T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 34,
    start_date: "2004-08-22T07:00:00.000Z",
    end_date: "2004-08-28T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 35,
    start_date: "2004-08-29T07:00:00.000Z",
    end_date: "2004-09-04T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 36,
    start_date: "2004-09-05T07:00:00.000Z",
    end_date: "2004-09-11T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 37,
    start_date: "2004-09-12T07:00:00.000Z",
    end_date: "2004-09-18T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 38,
    start_date: "2004-09-19T07:00:00.000Z",
    end_date: "2004-09-25T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 39,
    start_date: "2004-09-26T07:00:00.000Z",
    end_date: "2004-10-02T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 40,
    start_date: "2004-10-03T07:00:00.000Z",
    end_date: "2004-10-09T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 41,
    start_date: "2004-10-10T07:00:00.000Z",
    end_date: "2004-10-16T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 42,
    start_date: "2004-10-17T07:00:00.000Z",
    end_date: "2004-10-23T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 43,
    start_date: "2004-10-24T07:00:00.000Z",
    end_date: "2004-10-30T07:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 44,
    start_date: "2004-10-31T07:00:00.000Z",
    end_date: "2004-11-06T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 45,
    start_date: "2004-11-07T08:00:00.000Z",
    end_date: "2004-11-13T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 46,
    start_date: "2004-11-14T08:00:00.000Z",
    end_date: "2004-11-20T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 47,
    start_date: "2004-11-21T08:00:00.000Z",
    end_date: "2004-11-27T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 48,
    start_date: "2004-11-28T08:00:00.000Z",
    end_date: "2004-12-04T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 49,
    start_date: "2004-12-05T08:00:00.000Z",
    end_date: "2004-12-11T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 50,
    start_date: "2004-12-12T08:00:00.000Z",
    end_date: "2004-12-18T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 51,
    start_date: "2004-12-19T08:00:00.000Z",
    end_date: "2004-12-25T08:00:00.000Z",
  },
  {
    disease_year: 2004,
    disease_week: 52,
    start_date: "2004-12-26T08:00:00.000Z",
    end_date: "2005-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 1,
    start_date: "1948-01-04T08:00:00.000Z",
    end_date: "1948-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 2,
    start_date: "1948-01-11T08:00:00.000Z",
    end_date: "1948-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 3,
    start_date: "1948-01-18T08:00:00.000Z",
    end_date: "1948-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 4,
    start_date: "1948-01-25T08:00:00.000Z",
    end_date: "1948-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 5,
    start_date: "1948-02-01T08:00:00.000Z",
    end_date: "1948-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 6,
    start_date: "1948-02-08T08:00:00.000Z",
    end_date: "1948-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 7,
    start_date: "1948-02-15T08:00:00.000Z",
    end_date: "1948-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 8,
    start_date: "1948-02-22T08:00:00.000Z",
    end_date: "1948-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 9,
    start_date: "1948-02-29T08:00:00.000Z",
    end_date: "1948-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 10,
    start_date: "1948-03-07T08:00:00.000Z",
    end_date: "1948-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 11,
    start_date: "1948-03-14T08:00:00.000Z",
    end_date: "1948-03-20T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 12,
    start_date: "1948-03-21T07:00:00.000Z",
    end_date: "1948-03-27T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 13,
    start_date: "1948-03-28T07:00:00.000Z",
    end_date: "1948-04-03T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 14,
    start_date: "1948-04-04T07:00:00.000Z",
    end_date: "1948-04-10T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 15,
    start_date: "1948-04-11T07:00:00.000Z",
    end_date: "1948-04-17T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 16,
    start_date: "1948-04-18T07:00:00.000Z",
    end_date: "1948-04-24T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 17,
    start_date: "1948-04-25T07:00:00.000Z",
    end_date: "1948-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 18,
    start_date: "1948-05-02T07:00:00.000Z",
    end_date: "1948-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 19,
    start_date: "1948-05-09T07:00:00.000Z",
    end_date: "1948-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 20,
    start_date: "1948-05-16T07:00:00.000Z",
    end_date: "1948-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 21,
    start_date: "1948-05-23T07:00:00.000Z",
    end_date: "1948-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 22,
    start_date: "1948-05-30T07:00:00.000Z",
    end_date: "1948-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 23,
    start_date: "1948-06-06T07:00:00.000Z",
    end_date: "1948-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 24,
    start_date: "1948-06-13T07:00:00.000Z",
    end_date: "1948-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 25,
    start_date: "1948-06-20T07:00:00.000Z",
    end_date: "1948-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 26,
    start_date: "1948-06-27T07:00:00.000Z",
    end_date: "1948-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 27,
    start_date: "1948-07-04T07:00:00.000Z",
    end_date: "1948-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 28,
    start_date: "1948-07-11T07:00:00.000Z",
    end_date: "1948-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 29,
    start_date: "1948-07-18T07:00:00.000Z",
    end_date: "1948-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 30,
    start_date: "1948-07-25T07:00:00.000Z",
    end_date: "1948-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 31,
    start_date: "1948-08-01T07:00:00.000Z",
    end_date: "1948-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 32,
    start_date: "1948-08-08T07:00:00.000Z",
    end_date: "1948-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 33,
    start_date: "1948-08-15T07:00:00.000Z",
    end_date: "1948-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 34,
    start_date: "1948-08-22T07:00:00.000Z",
    end_date: "1948-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 35,
    start_date: "1948-08-29T07:00:00.000Z",
    end_date: "1948-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 36,
    start_date: "1948-09-05T07:00:00.000Z",
    end_date: "1948-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 37,
    start_date: "1948-09-12T07:00:00.000Z",
    end_date: "1948-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 38,
    start_date: "1948-09-19T07:00:00.000Z",
    end_date: "1948-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 39,
    start_date: "1948-09-26T07:00:00.000Z",
    end_date: "1948-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 40,
    start_date: "1948-10-03T07:00:00.000Z",
    end_date: "1948-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 41,
    start_date: "1948-10-10T07:00:00.000Z",
    end_date: "1948-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 42,
    start_date: "1948-10-17T07:00:00.000Z",
    end_date: "1948-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 43,
    start_date: "1948-10-24T07:00:00.000Z",
    end_date: "1948-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 44,
    start_date: "1948-10-31T07:00:00.000Z",
    end_date: "1948-11-06T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 45,
    start_date: "1948-11-07T07:00:00.000Z",
    end_date: "1948-11-13T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 46,
    start_date: "1948-11-14T07:00:00.000Z",
    end_date: "1948-11-20T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 47,
    start_date: "1948-11-21T07:00:00.000Z",
    end_date: "1948-11-27T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 48,
    start_date: "1948-11-28T07:00:00.000Z",
    end_date: "1948-12-04T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 49,
    start_date: "1948-12-05T07:00:00.000Z",
    end_date: "1948-12-11T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 50,
    start_date: "1948-12-12T07:00:00.000Z",
    end_date: "1948-12-18T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 51,
    start_date: "1948-12-19T07:00:00.000Z",
    end_date: "1948-12-25T07:00:00.000Z",
  },
  {
    disease_year: 1948,
    disease_week: 52,
    start_date: "1948-12-26T07:00:00.000Z",
    end_date: "1949-01-01T07:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 1,
    start_date: "1949-01-02T08:00:00.000Z",
    end_date: "1949-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 2,
    start_date: "1949-01-09T08:00:00.000Z",
    end_date: "1949-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 3,
    start_date: "1949-01-16T08:00:00.000Z",
    end_date: "1949-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 4,
    start_date: "1949-01-23T08:00:00.000Z",
    end_date: "1949-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 5,
    start_date: "1949-01-30T08:00:00.000Z",
    end_date: "1949-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 6,
    start_date: "1949-02-06T08:00:00.000Z",
    end_date: "1949-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 7,
    start_date: "1949-02-13T08:00:00.000Z",
    end_date: "1949-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 8,
    start_date: "1949-02-20T08:00:00.000Z",
    end_date: "1949-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 9,
    start_date: "1949-02-27T08:00:00.000Z",
    end_date: "1949-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 10,
    start_date: "1949-03-06T08:00:00.000Z",
    end_date: "1949-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 11,
    start_date: "1949-03-13T08:00:00.000Z",
    end_date: "1949-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 12,
    start_date: "1949-03-20T08:00:00.000Z",
    end_date: "1949-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 13,
    start_date: "1949-03-27T08:00:00.000Z",
    end_date: "1949-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 14,
    start_date: "1949-04-03T08:00:00.000Z",
    end_date: "1949-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 15,
    start_date: "1949-04-10T08:00:00.000Z",
    end_date: "1949-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 16,
    start_date: "1949-04-17T08:00:00.000Z",
    end_date: "1949-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 17,
    start_date: "1949-04-24T08:00:00.000Z",
    end_date: "1949-04-30T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 18,
    start_date: "1949-05-01T08:00:00.000Z",
    end_date: "1949-05-07T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 19,
    start_date: "1949-05-08T08:00:00.000Z",
    end_date: "1949-05-14T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 20,
    start_date: "1949-05-15T08:00:00.000Z",
    end_date: "1949-05-21T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 21,
    start_date: "1949-05-22T08:00:00.000Z",
    end_date: "1949-05-28T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 22,
    start_date: "1949-05-29T08:00:00.000Z",
    end_date: "1949-06-04T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 23,
    start_date: "1949-06-05T08:00:00.000Z",
    end_date: "1949-06-11T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 24,
    start_date: "1949-06-12T08:00:00.000Z",
    end_date: "1949-06-18T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 25,
    start_date: "1949-06-19T08:00:00.000Z",
    end_date: "1949-06-25T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 26,
    start_date: "1949-06-26T08:00:00.000Z",
    end_date: "1949-07-02T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 27,
    start_date: "1949-07-03T08:00:00.000Z",
    end_date: "1949-07-09T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 28,
    start_date: "1949-07-10T08:00:00.000Z",
    end_date: "1949-07-16T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 29,
    start_date: "1949-07-17T08:00:00.000Z",
    end_date: "1949-07-23T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 30,
    start_date: "1949-07-24T08:00:00.000Z",
    end_date: "1949-07-30T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 31,
    start_date: "1949-07-31T08:00:00.000Z",
    end_date: "1949-08-06T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 32,
    start_date: "1949-08-07T08:00:00.000Z",
    end_date: "1949-08-13T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 33,
    start_date: "1949-08-14T08:00:00.000Z",
    end_date: "1949-08-20T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 34,
    start_date: "1949-08-21T08:00:00.000Z",
    end_date: "1949-08-27T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 35,
    start_date: "1949-08-28T08:00:00.000Z",
    end_date: "1949-09-03T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 36,
    start_date: "1949-09-04T08:00:00.000Z",
    end_date: "1949-09-10T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 37,
    start_date: "1949-09-11T08:00:00.000Z",
    end_date: "1949-09-17T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 38,
    start_date: "1949-09-18T08:00:00.000Z",
    end_date: "1949-09-24T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 39,
    start_date: "1949-09-25T08:00:00.000Z",
    end_date: "1949-10-01T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 40,
    start_date: "1949-10-02T08:00:00.000Z",
    end_date: "1949-10-08T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 41,
    start_date: "1949-10-09T08:00:00.000Z",
    end_date: "1949-10-15T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 42,
    start_date: "1949-10-16T08:00:00.000Z",
    end_date: "1949-10-22T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 43,
    start_date: "1949-10-23T08:00:00.000Z",
    end_date: "1949-10-29T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 44,
    start_date: "1949-10-30T08:00:00.000Z",
    end_date: "1949-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 45,
    start_date: "1949-11-06T08:00:00.000Z",
    end_date: "1949-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 46,
    start_date: "1949-11-13T08:00:00.000Z",
    end_date: "1949-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 47,
    start_date: "1949-11-20T08:00:00.000Z",
    end_date: "1949-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 48,
    start_date: "1949-11-27T08:00:00.000Z",
    end_date: "1949-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 49,
    start_date: "1949-12-04T08:00:00.000Z",
    end_date: "1949-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 50,
    start_date: "1949-12-11T08:00:00.000Z",
    end_date: "1949-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 51,
    start_date: "1949-12-18T08:00:00.000Z",
    end_date: "1949-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1949,
    disease_week: 52,
    start_date: "1949-12-25T08:00:00.000Z",
    end_date: "1949-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 1,
    start_date: "1953-01-04T08:00:00.000Z",
    end_date: "1953-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 2,
    start_date: "1953-01-11T08:00:00.000Z",
    end_date: "1953-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 3,
    start_date: "1953-01-18T08:00:00.000Z",
    end_date: "1953-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 4,
    start_date: "1953-01-25T08:00:00.000Z",
    end_date: "1953-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 5,
    start_date: "1953-02-01T08:00:00.000Z",
    end_date: "1953-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 6,
    start_date: "1953-02-08T08:00:00.000Z",
    end_date: "1953-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 7,
    start_date: "1953-02-15T08:00:00.000Z",
    end_date: "1953-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 8,
    start_date: "1953-02-22T08:00:00.000Z",
    end_date: "1953-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 9,
    start_date: "1953-03-01T08:00:00.000Z",
    end_date: "1953-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 10,
    start_date: "1953-03-08T08:00:00.000Z",
    end_date: "1953-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 11,
    start_date: "1953-03-15T08:00:00.000Z",
    end_date: "1953-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 12,
    start_date: "1953-03-22T08:00:00.000Z",
    end_date: "1953-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 13,
    start_date: "1953-03-29T08:00:00.000Z",
    end_date: "1953-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 14,
    start_date: "1953-04-05T08:00:00.000Z",
    end_date: "1953-04-11T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 15,
    start_date: "1953-04-12T08:00:00.000Z",
    end_date: "1953-04-18T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 16,
    start_date: "1953-04-19T08:00:00.000Z",
    end_date: "1953-04-25T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 17,
    start_date: "1953-04-26T08:00:00.000Z",
    end_date: "1953-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 18,
    start_date: "1953-05-03T07:00:00.000Z",
    end_date: "1953-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 19,
    start_date: "1953-05-10T07:00:00.000Z",
    end_date: "1953-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 20,
    start_date: "1953-05-17T07:00:00.000Z",
    end_date: "1953-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 21,
    start_date: "1953-05-24T07:00:00.000Z",
    end_date: "1953-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 22,
    start_date: "1953-05-31T07:00:00.000Z",
    end_date: "1953-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 23,
    start_date: "1953-06-07T07:00:00.000Z",
    end_date: "1953-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 24,
    start_date: "1953-06-14T07:00:00.000Z",
    end_date: "1953-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 25,
    start_date: "1953-06-21T07:00:00.000Z",
    end_date: "1953-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 26,
    start_date: "1953-06-28T07:00:00.000Z",
    end_date: "1953-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 27,
    start_date: "1953-07-05T07:00:00.000Z",
    end_date: "1953-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 28,
    start_date: "1953-07-12T07:00:00.000Z",
    end_date: "1953-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 29,
    start_date: "1953-07-19T07:00:00.000Z",
    end_date: "1953-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 30,
    start_date: "1953-07-26T07:00:00.000Z",
    end_date: "1953-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 31,
    start_date: "1953-08-02T07:00:00.000Z",
    end_date: "1953-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 32,
    start_date: "1953-08-09T07:00:00.000Z",
    end_date: "1953-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 33,
    start_date: "1953-08-16T07:00:00.000Z",
    end_date: "1953-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 34,
    start_date: "1953-08-23T07:00:00.000Z",
    end_date: "1953-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 35,
    start_date: "1953-08-30T07:00:00.000Z",
    end_date: "1953-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 36,
    start_date: "1953-09-06T07:00:00.000Z",
    end_date: "1953-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 37,
    start_date: "1953-09-13T07:00:00.000Z",
    end_date: "1953-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 38,
    start_date: "1953-09-20T07:00:00.000Z",
    end_date: "1953-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 39,
    start_date: "1953-09-27T07:00:00.000Z",
    end_date: "1953-10-03T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 40,
    start_date: "1953-10-04T08:00:00.000Z",
    end_date: "1953-10-10T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 41,
    start_date: "1953-10-11T08:00:00.000Z",
    end_date: "1953-10-17T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 42,
    start_date: "1953-10-18T08:00:00.000Z",
    end_date: "1953-10-24T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 43,
    start_date: "1953-10-25T08:00:00.000Z",
    end_date: "1953-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 44,
    start_date: "1953-11-01T08:00:00.000Z",
    end_date: "1953-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 45,
    start_date: "1953-11-08T08:00:00.000Z",
    end_date: "1953-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 46,
    start_date: "1953-11-15T08:00:00.000Z",
    end_date: "1953-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 47,
    start_date: "1953-11-22T08:00:00.000Z",
    end_date: "1953-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 48,
    start_date: "1953-11-29T08:00:00.000Z",
    end_date: "1953-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 49,
    start_date: "1953-12-06T08:00:00.000Z",
    end_date: "1953-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 50,
    start_date: "1953-12-13T08:00:00.000Z",
    end_date: "1953-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 51,
    start_date: "1953-12-20T08:00:00.000Z",
    end_date: "1953-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1953,
    disease_week: 52,
    start_date: "1953-12-27T08:00:00.000Z",
    end_date: "1954-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 1,
    start_date: "1954-01-03T08:00:00.000Z",
    end_date: "1954-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 2,
    start_date: "1954-01-10T08:00:00.000Z",
    end_date: "1954-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 3,
    start_date: "1954-01-17T08:00:00.000Z",
    end_date: "1954-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 4,
    start_date: "1954-01-24T08:00:00.000Z",
    end_date: "1954-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 5,
    start_date: "1954-01-31T08:00:00.000Z",
    end_date: "1954-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 6,
    start_date: "1954-02-07T08:00:00.000Z",
    end_date: "1954-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 7,
    start_date: "1954-02-14T08:00:00.000Z",
    end_date: "1954-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 8,
    start_date: "1954-02-21T08:00:00.000Z",
    end_date: "1954-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 9,
    start_date: "1954-02-28T08:00:00.000Z",
    end_date: "1954-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 10,
    start_date: "1954-03-07T08:00:00.000Z",
    end_date: "1954-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 11,
    start_date: "1954-03-14T08:00:00.000Z",
    end_date: "1954-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 12,
    start_date: "1954-03-21T08:00:00.000Z",
    end_date: "1954-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 13,
    start_date: "1954-03-28T08:00:00.000Z",
    end_date: "1954-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 14,
    start_date: "1954-04-04T08:00:00.000Z",
    end_date: "1954-04-10T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 15,
    start_date: "1954-04-11T08:00:00.000Z",
    end_date: "1954-04-17T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 16,
    start_date: "1954-04-18T08:00:00.000Z",
    end_date: "1954-04-24T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 17,
    start_date: "1954-04-25T08:00:00.000Z",
    end_date: "1954-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 18,
    start_date: "1954-05-02T07:00:00.000Z",
    end_date: "1954-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 19,
    start_date: "1954-05-09T07:00:00.000Z",
    end_date: "1954-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 20,
    start_date: "1954-05-16T07:00:00.000Z",
    end_date: "1954-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 21,
    start_date: "1954-05-23T07:00:00.000Z",
    end_date: "1954-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 22,
    start_date: "1954-05-30T07:00:00.000Z",
    end_date: "1954-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 23,
    start_date: "1954-06-06T07:00:00.000Z",
    end_date: "1954-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 24,
    start_date: "1954-06-13T07:00:00.000Z",
    end_date: "1954-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 25,
    start_date: "1954-06-20T07:00:00.000Z",
    end_date: "1954-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 26,
    start_date: "1954-06-27T07:00:00.000Z",
    end_date: "1954-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 27,
    start_date: "1954-07-04T07:00:00.000Z",
    end_date: "1954-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 28,
    start_date: "1954-07-11T07:00:00.000Z",
    end_date: "1954-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 29,
    start_date: "1954-07-18T07:00:00.000Z",
    end_date: "1954-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 30,
    start_date: "1954-07-25T07:00:00.000Z",
    end_date: "1954-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 31,
    start_date: "1954-08-01T07:00:00.000Z",
    end_date: "1954-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 32,
    start_date: "1954-08-08T07:00:00.000Z",
    end_date: "1954-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 33,
    start_date: "1954-08-15T07:00:00.000Z",
    end_date: "1954-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 34,
    start_date: "1954-08-22T07:00:00.000Z",
    end_date: "1954-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 35,
    start_date: "1954-08-29T07:00:00.000Z",
    end_date: "1954-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 36,
    start_date: "1954-09-05T07:00:00.000Z",
    end_date: "1954-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 37,
    start_date: "1954-09-12T07:00:00.000Z",
    end_date: "1954-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 38,
    start_date: "1954-09-19T07:00:00.000Z",
    end_date: "1954-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 39,
    start_date: "1954-09-26T07:00:00.000Z",
    end_date: "1954-10-02T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 40,
    start_date: "1954-10-03T08:00:00.000Z",
    end_date: "1954-10-09T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 41,
    start_date: "1954-10-10T08:00:00.000Z",
    end_date: "1954-10-16T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 42,
    start_date: "1954-10-17T08:00:00.000Z",
    end_date: "1954-10-23T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 43,
    start_date: "1954-10-24T08:00:00.000Z",
    end_date: "1954-10-30T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 44,
    start_date: "1954-10-31T08:00:00.000Z",
    end_date: "1954-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 45,
    start_date: "1954-11-07T08:00:00.000Z",
    end_date: "1954-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 46,
    start_date: "1954-11-14T08:00:00.000Z",
    end_date: "1954-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 47,
    start_date: "1954-11-21T08:00:00.000Z",
    end_date: "1954-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 48,
    start_date: "1954-11-28T08:00:00.000Z",
    end_date: "1954-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 49,
    start_date: "1954-12-05T08:00:00.000Z",
    end_date: "1954-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 50,
    start_date: "1954-12-12T08:00:00.000Z",
    end_date: "1954-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 51,
    start_date: "1954-12-19T08:00:00.000Z",
    end_date: "1954-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1954,
    disease_week: 52,
    start_date: "1954-12-26T08:00:00.000Z",
    end_date: "1955-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 1,
    start_date: "1955-01-02T08:00:00.000Z",
    end_date: "1955-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 2,
    start_date: "1955-01-09T08:00:00.000Z",
    end_date: "1955-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 3,
    start_date: "1955-01-16T08:00:00.000Z",
    end_date: "1955-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 4,
    start_date: "1955-01-23T08:00:00.000Z",
    end_date: "1955-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 5,
    start_date: "1955-01-30T08:00:00.000Z",
    end_date: "1955-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 6,
    start_date: "1955-02-06T08:00:00.000Z",
    end_date: "1955-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 7,
    start_date: "1955-02-13T08:00:00.000Z",
    end_date: "1955-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 8,
    start_date: "1955-02-20T08:00:00.000Z",
    end_date: "1955-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 9,
    start_date: "1955-02-27T08:00:00.000Z",
    end_date: "1955-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 10,
    start_date: "1955-03-06T08:00:00.000Z",
    end_date: "1955-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 11,
    start_date: "1955-03-13T08:00:00.000Z",
    end_date: "1955-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 12,
    start_date: "1955-03-20T08:00:00.000Z",
    end_date: "1955-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 13,
    start_date: "1955-03-27T08:00:00.000Z",
    end_date: "1955-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 14,
    start_date: "1955-04-03T08:00:00.000Z",
    end_date: "1955-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 15,
    start_date: "1955-04-10T08:00:00.000Z",
    end_date: "1955-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 16,
    start_date: "1955-04-17T08:00:00.000Z",
    end_date: "1955-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 17,
    start_date: "1955-04-24T08:00:00.000Z",
    end_date: "1955-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 18,
    start_date: "1955-05-01T07:00:00.000Z",
    end_date: "1955-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 19,
    start_date: "1955-05-08T07:00:00.000Z",
    end_date: "1955-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 20,
    start_date: "1955-05-15T07:00:00.000Z",
    end_date: "1955-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 21,
    start_date: "1955-05-22T07:00:00.000Z",
    end_date: "1955-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 22,
    start_date: "1955-05-29T07:00:00.000Z",
    end_date: "1955-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 23,
    start_date: "1955-06-05T07:00:00.000Z",
    end_date: "1955-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 24,
    start_date: "1955-06-12T07:00:00.000Z",
    end_date: "1955-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 25,
    start_date: "1955-06-19T07:00:00.000Z",
    end_date: "1955-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 26,
    start_date: "1955-06-26T07:00:00.000Z",
    end_date: "1955-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 27,
    start_date: "1955-07-03T07:00:00.000Z",
    end_date: "1955-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 28,
    start_date: "1955-07-10T07:00:00.000Z",
    end_date: "1955-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 29,
    start_date: "1955-07-17T07:00:00.000Z",
    end_date: "1955-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 30,
    start_date: "1955-07-24T07:00:00.000Z",
    end_date: "1955-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 31,
    start_date: "1955-07-31T07:00:00.000Z",
    end_date: "1955-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 32,
    start_date: "1955-08-07T07:00:00.000Z",
    end_date: "1955-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 33,
    start_date: "1955-08-14T07:00:00.000Z",
    end_date: "1955-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 34,
    start_date: "1955-08-21T07:00:00.000Z",
    end_date: "1955-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 35,
    start_date: "1955-08-28T07:00:00.000Z",
    end_date: "1955-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 36,
    start_date: "1955-09-04T07:00:00.000Z",
    end_date: "1955-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 37,
    start_date: "1955-09-11T07:00:00.000Z",
    end_date: "1955-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 38,
    start_date: "1955-09-18T07:00:00.000Z",
    end_date: "1955-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 39,
    start_date: "1955-09-25T07:00:00.000Z",
    end_date: "1955-10-01T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 40,
    start_date: "1955-10-02T08:00:00.000Z",
    end_date: "1955-10-08T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 41,
    start_date: "1955-10-09T08:00:00.000Z",
    end_date: "1955-10-15T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 42,
    start_date: "1955-10-16T08:00:00.000Z",
    end_date: "1955-10-22T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 43,
    start_date: "1955-10-23T08:00:00.000Z",
    end_date: "1955-10-29T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 44,
    start_date: "1955-10-30T08:00:00.000Z",
    end_date: "1955-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 45,
    start_date: "1955-11-06T08:00:00.000Z",
    end_date: "1955-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 46,
    start_date: "1955-11-13T08:00:00.000Z",
    end_date: "1955-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 47,
    start_date: "1955-11-20T08:00:00.000Z",
    end_date: "1955-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 48,
    start_date: "1955-11-27T08:00:00.000Z",
    end_date: "1955-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 49,
    start_date: "1955-12-04T08:00:00.000Z",
    end_date: "1955-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 50,
    start_date: "1955-12-11T08:00:00.000Z",
    end_date: "1955-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 51,
    start_date: "1955-12-18T08:00:00.000Z",
    end_date: "1955-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1955,
    disease_week: 52,
    start_date: "1955-12-25T08:00:00.000Z",
    end_date: "1955-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 1,
    start_date: "1959-01-04T08:00:00.000Z",
    end_date: "1959-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 2,
    start_date: "1959-01-11T08:00:00.000Z",
    end_date: "1959-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 3,
    start_date: "1959-01-18T08:00:00.000Z",
    end_date: "1959-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 4,
    start_date: "1959-01-25T08:00:00.000Z",
    end_date: "1959-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 5,
    start_date: "1959-02-01T08:00:00.000Z",
    end_date: "1959-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 6,
    start_date: "1959-02-08T08:00:00.000Z",
    end_date: "1959-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 7,
    start_date: "1959-02-15T08:00:00.000Z",
    end_date: "1959-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 8,
    start_date: "1959-02-22T08:00:00.000Z",
    end_date: "1959-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 9,
    start_date: "1959-03-01T08:00:00.000Z",
    end_date: "1959-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 10,
    start_date: "1959-03-08T08:00:00.000Z",
    end_date: "1959-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 11,
    start_date: "1959-03-15T08:00:00.000Z",
    end_date: "1959-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 12,
    start_date: "1959-03-22T08:00:00.000Z",
    end_date: "1959-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 13,
    start_date: "1959-03-29T08:00:00.000Z",
    end_date: "1959-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 14,
    start_date: "1959-04-05T08:00:00.000Z",
    end_date: "1959-04-11T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 15,
    start_date: "1959-04-12T08:00:00.000Z",
    end_date: "1959-04-18T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 16,
    start_date: "1959-04-19T08:00:00.000Z",
    end_date: "1959-04-25T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 17,
    start_date: "1959-04-26T08:00:00.000Z",
    end_date: "1959-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 18,
    start_date: "1959-05-03T07:00:00.000Z",
    end_date: "1959-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 19,
    start_date: "1959-05-10T07:00:00.000Z",
    end_date: "1959-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 20,
    start_date: "1959-05-17T07:00:00.000Z",
    end_date: "1959-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 21,
    start_date: "1959-05-24T07:00:00.000Z",
    end_date: "1959-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 22,
    start_date: "1959-05-31T07:00:00.000Z",
    end_date: "1959-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 23,
    start_date: "1959-06-07T07:00:00.000Z",
    end_date: "1959-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 24,
    start_date: "1959-06-14T07:00:00.000Z",
    end_date: "1959-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 25,
    start_date: "1959-06-21T07:00:00.000Z",
    end_date: "1959-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 26,
    start_date: "1959-06-28T07:00:00.000Z",
    end_date: "1959-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 27,
    start_date: "1959-07-05T07:00:00.000Z",
    end_date: "1959-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 28,
    start_date: "1959-07-12T07:00:00.000Z",
    end_date: "1959-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 29,
    start_date: "1959-07-19T07:00:00.000Z",
    end_date: "1959-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 30,
    start_date: "1959-07-26T07:00:00.000Z",
    end_date: "1959-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 31,
    start_date: "1959-08-02T07:00:00.000Z",
    end_date: "1959-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 32,
    start_date: "1959-08-09T07:00:00.000Z",
    end_date: "1959-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 33,
    start_date: "1959-08-16T07:00:00.000Z",
    end_date: "1959-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 34,
    start_date: "1959-08-23T07:00:00.000Z",
    end_date: "1959-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 35,
    start_date: "1959-08-30T07:00:00.000Z",
    end_date: "1959-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 36,
    start_date: "1959-09-06T07:00:00.000Z",
    end_date: "1959-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 37,
    start_date: "1959-09-13T07:00:00.000Z",
    end_date: "1959-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 38,
    start_date: "1959-09-20T07:00:00.000Z",
    end_date: "1959-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 39,
    start_date: "1959-09-27T07:00:00.000Z",
    end_date: "1959-10-03T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 40,
    start_date: "1959-10-04T08:00:00.000Z",
    end_date: "1959-10-10T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 41,
    start_date: "1959-10-11T08:00:00.000Z",
    end_date: "1959-10-17T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 42,
    start_date: "1959-10-18T08:00:00.000Z",
    end_date: "1959-10-24T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 43,
    start_date: "1959-10-25T08:00:00.000Z",
    end_date: "1959-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 44,
    start_date: "1959-11-01T08:00:00.000Z",
    end_date: "1959-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 45,
    start_date: "1959-11-08T08:00:00.000Z",
    end_date: "1959-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 46,
    start_date: "1959-11-15T08:00:00.000Z",
    end_date: "1959-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 47,
    start_date: "1959-11-22T08:00:00.000Z",
    end_date: "1959-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 48,
    start_date: "1959-11-29T08:00:00.000Z",
    end_date: "1959-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 49,
    start_date: "1959-12-06T08:00:00.000Z",
    end_date: "1959-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 50,
    start_date: "1959-12-13T08:00:00.000Z",
    end_date: "1959-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 51,
    start_date: "1959-12-20T08:00:00.000Z",
    end_date: "1959-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1959,
    disease_week: 52,
    start_date: "1959-12-27T08:00:00.000Z",
    end_date: "1960-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 1,
    start_date: "1960-01-03T08:00:00.000Z",
    end_date: "1960-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 2,
    start_date: "1960-01-10T08:00:00.000Z",
    end_date: "1960-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 3,
    start_date: "1960-01-17T08:00:00.000Z",
    end_date: "1960-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 4,
    start_date: "1960-01-24T08:00:00.000Z",
    end_date: "1960-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 5,
    start_date: "1960-01-31T08:00:00.000Z",
    end_date: "1960-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 6,
    start_date: "1960-02-07T08:00:00.000Z",
    end_date: "1960-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 7,
    start_date: "1960-02-14T08:00:00.000Z",
    end_date: "1960-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 8,
    start_date: "1960-02-21T08:00:00.000Z",
    end_date: "1960-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 9,
    start_date: "1960-02-28T08:00:00.000Z",
    end_date: "1960-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 10,
    start_date: "1960-03-06T08:00:00.000Z",
    end_date: "1960-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 11,
    start_date: "1960-03-13T08:00:00.000Z",
    end_date: "1960-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 12,
    start_date: "1960-03-20T08:00:00.000Z",
    end_date: "1960-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 13,
    start_date: "1960-03-27T08:00:00.000Z",
    end_date: "1960-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 14,
    start_date: "1960-04-03T08:00:00.000Z",
    end_date: "1960-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 15,
    start_date: "1960-04-10T08:00:00.000Z",
    end_date: "1960-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 16,
    start_date: "1960-04-17T08:00:00.000Z",
    end_date: "1960-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 17,
    start_date: "1960-04-24T08:00:00.000Z",
    end_date: "1960-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 18,
    start_date: "1960-05-01T07:00:00.000Z",
    end_date: "1960-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 19,
    start_date: "1960-05-08T07:00:00.000Z",
    end_date: "1960-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 20,
    start_date: "1960-05-15T07:00:00.000Z",
    end_date: "1960-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 21,
    start_date: "1960-05-22T07:00:00.000Z",
    end_date: "1960-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 22,
    start_date: "1960-05-29T07:00:00.000Z",
    end_date: "1960-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 23,
    start_date: "1960-06-05T07:00:00.000Z",
    end_date: "1960-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 24,
    start_date: "1960-06-12T07:00:00.000Z",
    end_date: "1960-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 25,
    start_date: "1960-06-19T07:00:00.000Z",
    end_date: "1960-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 26,
    start_date: "1960-06-26T07:00:00.000Z",
    end_date: "1960-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 27,
    start_date: "1960-07-03T07:00:00.000Z",
    end_date: "1960-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 28,
    start_date: "1960-07-10T07:00:00.000Z",
    end_date: "1960-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 29,
    start_date: "1960-07-17T07:00:00.000Z",
    end_date: "1960-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 30,
    start_date: "1960-07-24T07:00:00.000Z",
    end_date: "1960-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 31,
    start_date: "1960-07-31T07:00:00.000Z",
    end_date: "1960-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 32,
    start_date: "1960-08-07T07:00:00.000Z",
    end_date: "1960-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 33,
    start_date: "1960-08-14T07:00:00.000Z",
    end_date: "1960-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 34,
    start_date: "1960-08-21T07:00:00.000Z",
    end_date: "1960-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 35,
    start_date: "1960-08-28T07:00:00.000Z",
    end_date: "1960-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 36,
    start_date: "1960-09-04T07:00:00.000Z",
    end_date: "1960-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 37,
    start_date: "1960-09-11T07:00:00.000Z",
    end_date: "1960-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 38,
    start_date: "1960-09-18T07:00:00.000Z",
    end_date: "1960-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 39,
    start_date: "1960-09-25T07:00:00.000Z",
    end_date: "1960-10-01T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 40,
    start_date: "1960-10-02T08:00:00.000Z",
    end_date: "1960-10-08T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 41,
    start_date: "1960-10-09T08:00:00.000Z",
    end_date: "1960-10-15T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 42,
    start_date: "1960-10-16T08:00:00.000Z",
    end_date: "1960-10-22T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 43,
    start_date: "1960-10-23T08:00:00.000Z",
    end_date: "1960-10-29T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 44,
    start_date: "1960-10-30T08:00:00.000Z",
    end_date: "1960-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 45,
    start_date: "1960-11-06T08:00:00.000Z",
    end_date: "1960-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 46,
    start_date: "1960-11-13T08:00:00.000Z",
    end_date: "1960-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 47,
    start_date: "1960-11-20T08:00:00.000Z",
    end_date: "1960-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 48,
    start_date: "1960-11-27T08:00:00.000Z",
    end_date: "1960-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 49,
    start_date: "1960-12-04T08:00:00.000Z",
    end_date: "1960-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 50,
    start_date: "1960-12-11T08:00:00.000Z",
    end_date: "1960-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 51,
    start_date: "1960-12-18T08:00:00.000Z",
    end_date: "1960-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1960,
    disease_week: 52,
    start_date: "1960-12-25T08:00:00.000Z",
    end_date: "1960-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 1,
    start_date: "1965-01-03T08:00:00.000Z",
    end_date: "1965-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 2,
    start_date: "1965-01-10T08:00:00.000Z",
    end_date: "1965-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 3,
    start_date: "1965-01-17T08:00:00.000Z",
    end_date: "1965-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 4,
    start_date: "1965-01-24T08:00:00.000Z",
    end_date: "1965-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 5,
    start_date: "1965-01-31T08:00:00.000Z",
    end_date: "1965-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 6,
    start_date: "1965-02-07T08:00:00.000Z",
    end_date: "1965-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 7,
    start_date: "1965-02-14T08:00:00.000Z",
    end_date: "1965-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 8,
    start_date: "1965-02-21T08:00:00.000Z",
    end_date: "1965-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 9,
    start_date: "1965-02-28T08:00:00.000Z",
    end_date: "1965-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 10,
    start_date: "1965-03-07T08:00:00.000Z",
    end_date: "1965-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 11,
    start_date: "1965-03-14T08:00:00.000Z",
    end_date: "1965-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 12,
    start_date: "1965-03-21T08:00:00.000Z",
    end_date: "1965-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 13,
    start_date: "1965-03-28T08:00:00.000Z",
    end_date: "1965-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 14,
    start_date: "1965-04-04T08:00:00.000Z",
    end_date: "1965-04-10T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 15,
    start_date: "1965-04-11T08:00:00.000Z",
    end_date: "1965-04-17T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 16,
    start_date: "1965-04-18T08:00:00.000Z",
    end_date: "1965-04-24T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 17,
    start_date: "1965-04-25T08:00:00.000Z",
    end_date: "1965-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 18,
    start_date: "1965-05-02T07:00:00.000Z",
    end_date: "1965-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 19,
    start_date: "1965-05-09T07:00:00.000Z",
    end_date: "1965-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 20,
    start_date: "1965-05-16T07:00:00.000Z",
    end_date: "1965-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 21,
    start_date: "1965-05-23T07:00:00.000Z",
    end_date: "1965-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 22,
    start_date: "1965-05-30T07:00:00.000Z",
    end_date: "1965-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 23,
    start_date: "1965-06-06T07:00:00.000Z",
    end_date: "1965-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 24,
    start_date: "1965-06-13T07:00:00.000Z",
    end_date: "1965-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 25,
    start_date: "1965-06-20T07:00:00.000Z",
    end_date: "1965-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 26,
    start_date: "1965-06-27T07:00:00.000Z",
    end_date: "1965-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 27,
    start_date: "1965-07-04T07:00:00.000Z",
    end_date: "1965-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 28,
    start_date: "1965-07-11T07:00:00.000Z",
    end_date: "1965-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 29,
    start_date: "1965-07-18T07:00:00.000Z",
    end_date: "1965-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 30,
    start_date: "1965-07-25T07:00:00.000Z",
    end_date: "1965-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 31,
    start_date: "1965-08-01T07:00:00.000Z",
    end_date: "1965-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 32,
    start_date: "1965-08-08T07:00:00.000Z",
    end_date: "1965-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 33,
    start_date: "1965-08-15T07:00:00.000Z",
    end_date: "1965-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 34,
    start_date: "1965-08-22T07:00:00.000Z",
    end_date: "1965-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 35,
    start_date: "1965-08-29T07:00:00.000Z",
    end_date: "1965-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 36,
    start_date: "1965-09-05T07:00:00.000Z",
    end_date: "1965-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 37,
    start_date: "1965-09-12T07:00:00.000Z",
    end_date: "1965-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 38,
    start_date: "1965-09-19T07:00:00.000Z",
    end_date: "1965-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 39,
    start_date: "1965-09-26T07:00:00.000Z",
    end_date: "1965-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 40,
    start_date: "1965-10-03T07:00:00.000Z",
    end_date: "1965-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 41,
    start_date: "1965-10-10T07:00:00.000Z",
    end_date: "1965-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 42,
    start_date: "1965-10-17T07:00:00.000Z",
    end_date: "1965-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 43,
    start_date: "1965-10-24T07:00:00.000Z",
    end_date: "1965-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 44,
    start_date: "1965-10-31T07:00:00.000Z",
    end_date: "1965-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 45,
    start_date: "1965-11-07T08:00:00.000Z",
    end_date: "1965-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 46,
    start_date: "1965-11-14T08:00:00.000Z",
    end_date: "1965-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 47,
    start_date: "1965-11-21T08:00:00.000Z",
    end_date: "1965-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 48,
    start_date: "1965-11-28T08:00:00.000Z",
    end_date: "1965-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 49,
    start_date: "1965-12-05T08:00:00.000Z",
    end_date: "1965-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 50,
    start_date: "1965-12-12T08:00:00.000Z",
    end_date: "1965-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 51,
    start_date: "1965-12-19T08:00:00.000Z",
    end_date: "1965-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1965,
    disease_week: 52,
    start_date: "1965-12-26T08:00:00.000Z",
    end_date: "1966-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 1,
    start_date: "1966-01-02T08:00:00.000Z",
    end_date: "1966-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 2,
    start_date: "1966-01-09T08:00:00.000Z",
    end_date: "1966-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 3,
    start_date: "1966-01-16T08:00:00.000Z",
    end_date: "1966-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 4,
    start_date: "1966-01-23T08:00:00.000Z",
    end_date: "1966-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 5,
    start_date: "1966-01-30T08:00:00.000Z",
    end_date: "1966-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 6,
    start_date: "1966-02-06T08:00:00.000Z",
    end_date: "1966-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 7,
    start_date: "1966-02-13T08:00:00.000Z",
    end_date: "1966-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 8,
    start_date: "1966-02-20T08:00:00.000Z",
    end_date: "1966-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 9,
    start_date: "1966-02-27T08:00:00.000Z",
    end_date: "1966-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 10,
    start_date: "1966-03-06T08:00:00.000Z",
    end_date: "1966-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 11,
    start_date: "1966-03-13T08:00:00.000Z",
    end_date: "1966-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 12,
    start_date: "1966-03-20T08:00:00.000Z",
    end_date: "1966-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 13,
    start_date: "1966-03-27T08:00:00.000Z",
    end_date: "1966-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 14,
    start_date: "1966-04-03T08:00:00.000Z",
    end_date: "1966-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 15,
    start_date: "1966-04-10T08:00:00.000Z",
    end_date: "1966-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 16,
    start_date: "1966-04-17T08:00:00.000Z",
    end_date: "1966-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 17,
    start_date: "1966-04-24T08:00:00.000Z",
    end_date: "1966-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 18,
    start_date: "1966-05-01T07:00:00.000Z",
    end_date: "1966-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 19,
    start_date: "1966-05-08T07:00:00.000Z",
    end_date: "1966-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 20,
    start_date: "1966-05-15T07:00:00.000Z",
    end_date: "1966-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 21,
    start_date: "1966-05-22T07:00:00.000Z",
    end_date: "1966-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 22,
    start_date: "1966-05-29T07:00:00.000Z",
    end_date: "1966-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 23,
    start_date: "1966-06-05T07:00:00.000Z",
    end_date: "1966-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 24,
    start_date: "1966-06-12T07:00:00.000Z",
    end_date: "1966-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 25,
    start_date: "1966-06-19T07:00:00.000Z",
    end_date: "1966-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 26,
    start_date: "1966-06-26T07:00:00.000Z",
    end_date: "1966-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 27,
    start_date: "1966-07-03T07:00:00.000Z",
    end_date: "1966-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 28,
    start_date: "1966-07-10T07:00:00.000Z",
    end_date: "1966-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 29,
    start_date: "1966-07-17T07:00:00.000Z",
    end_date: "1966-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 30,
    start_date: "1966-07-24T07:00:00.000Z",
    end_date: "1966-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 31,
    start_date: "1966-07-31T07:00:00.000Z",
    end_date: "1966-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 32,
    start_date: "1966-08-07T07:00:00.000Z",
    end_date: "1966-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 33,
    start_date: "1966-08-14T07:00:00.000Z",
    end_date: "1966-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 34,
    start_date: "1966-08-21T07:00:00.000Z",
    end_date: "1966-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 35,
    start_date: "1966-08-28T07:00:00.000Z",
    end_date: "1966-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 36,
    start_date: "1966-09-04T07:00:00.000Z",
    end_date: "1966-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 37,
    start_date: "1966-09-11T07:00:00.000Z",
    end_date: "1966-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 38,
    start_date: "1966-09-18T07:00:00.000Z",
    end_date: "1966-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 39,
    start_date: "1966-09-25T07:00:00.000Z",
    end_date: "1966-10-01T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 40,
    start_date: "1966-10-02T07:00:00.000Z",
    end_date: "1966-10-08T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 41,
    start_date: "1966-10-09T07:00:00.000Z",
    end_date: "1966-10-15T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 42,
    start_date: "1966-10-16T07:00:00.000Z",
    end_date: "1966-10-22T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 43,
    start_date: "1966-10-23T07:00:00.000Z",
    end_date: "1966-10-29T07:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 44,
    start_date: "1966-10-30T07:00:00.000Z",
    end_date: "1966-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 45,
    start_date: "1966-11-06T08:00:00.000Z",
    end_date: "1966-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 46,
    start_date: "1966-11-13T08:00:00.000Z",
    end_date: "1966-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 47,
    start_date: "1966-11-20T08:00:00.000Z",
    end_date: "1966-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 48,
    start_date: "1966-11-27T08:00:00.000Z",
    end_date: "1966-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 49,
    start_date: "1966-12-04T08:00:00.000Z",
    end_date: "1966-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 50,
    start_date: "1966-12-11T08:00:00.000Z",
    end_date: "1966-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 51,
    start_date: "1966-12-18T08:00:00.000Z",
    end_date: "1966-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1966,
    disease_week: 52,
    start_date: "1966-12-25T08:00:00.000Z",
    end_date: "1966-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 1,
    start_date: "1970-01-04T08:00:00.000Z",
    end_date: "1970-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 2,
    start_date: "1970-01-11T08:00:00.000Z",
    end_date: "1970-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 3,
    start_date: "1970-01-18T08:00:00.000Z",
    end_date: "1970-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 4,
    start_date: "1970-01-25T08:00:00.000Z",
    end_date: "1970-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 5,
    start_date: "1970-02-01T08:00:00.000Z",
    end_date: "1970-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 6,
    start_date: "1970-02-08T08:00:00.000Z",
    end_date: "1970-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 7,
    start_date: "1970-02-15T08:00:00.000Z",
    end_date: "1970-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 8,
    start_date: "1970-02-22T08:00:00.000Z",
    end_date: "1970-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 9,
    start_date: "1970-03-01T08:00:00.000Z",
    end_date: "1970-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 10,
    start_date: "1970-03-08T08:00:00.000Z",
    end_date: "1970-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 11,
    start_date: "1970-03-15T08:00:00.000Z",
    end_date: "1970-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 12,
    start_date: "1970-03-22T08:00:00.000Z",
    end_date: "1970-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 13,
    start_date: "1970-03-29T08:00:00.000Z",
    end_date: "1970-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 14,
    start_date: "1970-04-05T08:00:00.000Z",
    end_date: "1970-04-11T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 15,
    start_date: "1970-04-12T08:00:00.000Z",
    end_date: "1970-04-18T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 16,
    start_date: "1970-04-19T08:00:00.000Z",
    end_date: "1970-04-25T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 17,
    start_date: "1970-04-26T08:00:00.000Z",
    end_date: "1970-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 18,
    start_date: "1970-05-03T07:00:00.000Z",
    end_date: "1970-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 19,
    start_date: "1970-05-10T07:00:00.000Z",
    end_date: "1970-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 20,
    start_date: "1970-05-17T07:00:00.000Z",
    end_date: "1970-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 21,
    start_date: "1970-05-24T07:00:00.000Z",
    end_date: "1970-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 22,
    start_date: "1970-05-31T07:00:00.000Z",
    end_date: "1970-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 23,
    start_date: "1970-06-07T07:00:00.000Z",
    end_date: "1970-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 24,
    start_date: "1970-06-14T07:00:00.000Z",
    end_date: "1970-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 25,
    start_date: "1970-06-21T07:00:00.000Z",
    end_date: "1970-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 26,
    start_date: "1970-06-28T07:00:00.000Z",
    end_date: "1970-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 27,
    start_date: "1970-07-05T07:00:00.000Z",
    end_date: "1970-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 28,
    start_date: "1970-07-12T07:00:00.000Z",
    end_date: "1970-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 29,
    start_date: "1970-07-19T07:00:00.000Z",
    end_date: "1970-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 30,
    start_date: "1970-07-26T07:00:00.000Z",
    end_date: "1970-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 31,
    start_date: "1970-08-02T07:00:00.000Z",
    end_date: "1970-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 32,
    start_date: "1970-08-09T07:00:00.000Z",
    end_date: "1970-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 33,
    start_date: "1970-08-16T07:00:00.000Z",
    end_date: "1970-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 34,
    start_date: "1970-08-23T07:00:00.000Z",
    end_date: "1970-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 35,
    start_date: "1970-08-30T07:00:00.000Z",
    end_date: "1970-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 36,
    start_date: "1970-09-06T07:00:00.000Z",
    end_date: "1970-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 37,
    start_date: "1970-09-13T07:00:00.000Z",
    end_date: "1970-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 38,
    start_date: "1970-09-20T07:00:00.000Z",
    end_date: "1970-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 39,
    start_date: "1970-09-27T07:00:00.000Z",
    end_date: "1970-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 40,
    start_date: "1970-10-04T07:00:00.000Z",
    end_date: "1970-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 41,
    start_date: "1970-10-11T07:00:00.000Z",
    end_date: "1970-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 42,
    start_date: "1970-10-18T07:00:00.000Z",
    end_date: "1970-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 43,
    start_date: "1970-10-25T07:00:00.000Z",
    end_date: "1970-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 44,
    start_date: "1970-11-01T08:00:00.000Z",
    end_date: "1970-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 45,
    start_date: "1970-11-08T08:00:00.000Z",
    end_date: "1970-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 46,
    start_date: "1970-11-15T08:00:00.000Z",
    end_date: "1970-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 47,
    start_date: "1970-11-22T08:00:00.000Z",
    end_date: "1970-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 48,
    start_date: "1970-11-29T08:00:00.000Z",
    end_date: "1970-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 49,
    start_date: "1970-12-06T08:00:00.000Z",
    end_date: "1970-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 50,
    start_date: "1970-12-13T08:00:00.000Z",
    end_date: "1970-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 51,
    start_date: "1970-12-20T08:00:00.000Z",
    end_date: "1970-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1970,
    disease_week: 52,
    start_date: "1970-12-27T08:00:00.000Z",
    end_date: "1971-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 1,
    start_date: "1971-01-03T08:00:00.000Z",
    end_date: "1971-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 2,
    start_date: "1971-01-10T08:00:00.000Z",
    end_date: "1971-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 3,
    start_date: "1971-01-17T08:00:00.000Z",
    end_date: "1971-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 4,
    start_date: "1971-01-24T08:00:00.000Z",
    end_date: "1971-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 5,
    start_date: "1971-01-31T08:00:00.000Z",
    end_date: "1971-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 6,
    start_date: "1971-02-07T08:00:00.000Z",
    end_date: "1971-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 7,
    start_date: "1971-02-14T08:00:00.000Z",
    end_date: "1971-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 8,
    start_date: "1971-02-21T08:00:00.000Z",
    end_date: "1971-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 9,
    start_date: "1971-02-28T08:00:00.000Z",
    end_date: "1971-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 10,
    start_date: "1971-03-07T08:00:00.000Z",
    end_date: "1971-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 11,
    start_date: "1971-03-14T08:00:00.000Z",
    end_date: "1971-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 12,
    start_date: "1971-03-21T08:00:00.000Z",
    end_date: "1971-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 13,
    start_date: "1971-03-28T08:00:00.000Z",
    end_date: "1971-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 14,
    start_date: "1971-04-04T08:00:00.000Z",
    end_date: "1971-04-10T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 15,
    start_date: "1971-04-11T08:00:00.000Z",
    end_date: "1971-04-17T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 16,
    start_date: "1971-04-18T08:00:00.000Z",
    end_date: "1971-04-24T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 17,
    start_date: "1971-04-25T08:00:00.000Z",
    end_date: "1971-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 18,
    start_date: "1971-05-02T07:00:00.000Z",
    end_date: "1971-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 19,
    start_date: "1971-05-09T07:00:00.000Z",
    end_date: "1971-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 20,
    start_date: "1971-05-16T07:00:00.000Z",
    end_date: "1971-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 21,
    start_date: "1971-05-23T07:00:00.000Z",
    end_date: "1971-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 22,
    start_date: "1971-05-30T07:00:00.000Z",
    end_date: "1971-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 23,
    start_date: "1971-06-06T07:00:00.000Z",
    end_date: "1971-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 24,
    start_date: "1971-06-13T07:00:00.000Z",
    end_date: "1971-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 25,
    start_date: "1971-06-20T07:00:00.000Z",
    end_date: "1971-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 26,
    start_date: "1971-06-27T07:00:00.000Z",
    end_date: "1971-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 27,
    start_date: "1971-07-04T07:00:00.000Z",
    end_date: "1971-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 28,
    start_date: "1971-07-11T07:00:00.000Z",
    end_date: "1971-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 29,
    start_date: "1971-07-18T07:00:00.000Z",
    end_date: "1971-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 30,
    start_date: "1971-07-25T07:00:00.000Z",
    end_date: "1971-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 31,
    start_date: "1971-08-01T07:00:00.000Z",
    end_date: "1971-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 32,
    start_date: "1971-08-08T07:00:00.000Z",
    end_date: "1971-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 33,
    start_date: "1971-08-15T07:00:00.000Z",
    end_date: "1971-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 34,
    start_date: "1971-08-22T07:00:00.000Z",
    end_date: "1971-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 35,
    start_date: "1971-08-29T07:00:00.000Z",
    end_date: "1971-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 36,
    start_date: "1971-09-05T07:00:00.000Z",
    end_date: "1971-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 37,
    start_date: "1971-09-12T07:00:00.000Z",
    end_date: "1971-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 38,
    start_date: "1971-09-19T07:00:00.000Z",
    end_date: "1971-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 39,
    start_date: "1971-09-26T07:00:00.000Z",
    end_date: "1971-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 40,
    start_date: "1971-10-03T07:00:00.000Z",
    end_date: "1971-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 41,
    start_date: "1971-10-10T07:00:00.000Z",
    end_date: "1971-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 42,
    start_date: "1971-10-17T07:00:00.000Z",
    end_date: "1971-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 43,
    start_date: "1971-10-24T07:00:00.000Z",
    end_date: "1971-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 44,
    start_date: "1971-10-31T07:00:00.000Z",
    end_date: "1971-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 45,
    start_date: "1971-11-07T08:00:00.000Z",
    end_date: "1971-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 46,
    start_date: "1971-11-14T08:00:00.000Z",
    end_date: "1971-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 47,
    start_date: "1971-11-21T08:00:00.000Z",
    end_date: "1971-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 48,
    start_date: "1971-11-28T08:00:00.000Z",
    end_date: "1971-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 49,
    start_date: "1971-12-05T08:00:00.000Z",
    end_date: "1971-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 50,
    start_date: "1971-12-12T08:00:00.000Z",
    end_date: "1971-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 51,
    start_date: "1971-12-19T08:00:00.000Z",
    end_date: "1971-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1971,
    disease_week: 52,
    start_date: "1971-12-26T08:00:00.000Z",
    end_date: "1972-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 1,
    start_date: "1972-01-02T08:00:00.000Z",
    end_date: "1972-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 2,
    start_date: "1972-01-09T08:00:00.000Z",
    end_date: "1972-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 3,
    start_date: "1972-01-16T08:00:00.000Z",
    end_date: "1972-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 4,
    start_date: "1972-01-23T08:00:00.000Z",
    end_date: "1972-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 5,
    start_date: "1972-01-30T08:00:00.000Z",
    end_date: "1972-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 6,
    start_date: "1972-02-06T08:00:00.000Z",
    end_date: "1972-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 7,
    start_date: "1972-02-13T08:00:00.000Z",
    end_date: "1972-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 8,
    start_date: "1972-02-20T08:00:00.000Z",
    end_date: "1972-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 9,
    start_date: "1972-02-27T08:00:00.000Z",
    end_date: "1972-03-04T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 10,
    start_date: "1972-03-05T08:00:00.000Z",
    end_date: "1972-03-11T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 11,
    start_date: "1972-03-12T08:00:00.000Z",
    end_date: "1972-03-18T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 12,
    start_date: "1972-03-19T08:00:00.000Z",
    end_date: "1972-03-25T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 13,
    start_date: "1972-03-26T08:00:00.000Z",
    end_date: "1972-04-01T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 14,
    start_date: "1972-04-02T08:00:00.000Z",
    end_date: "1972-04-08T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 15,
    start_date: "1972-04-09T08:00:00.000Z",
    end_date: "1972-04-15T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 16,
    start_date: "1972-04-16T08:00:00.000Z",
    end_date: "1972-04-22T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 17,
    start_date: "1972-04-23T08:00:00.000Z",
    end_date: "1972-04-29T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 18,
    start_date: "1972-04-30T08:00:00.000Z",
    end_date: "1972-05-06T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 19,
    start_date: "1972-05-07T07:00:00.000Z",
    end_date: "1972-05-13T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 20,
    start_date: "1972-05-14T07:00:00.000Z",
    end_date: "1972-05-20T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 21,
    start_date: "1972-05-21T07:00:00.000Z",
    end_date: "1972-05-27T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 22,
    start_date: "1972-05-28T07:00:00.000Z",
    end_date: "1972-06-03T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 23,
    start_date: "1972-06-04T07:00:00.000Z",
    end_date: "1972-06-10T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 24,
    start_date: "1972-06-11T07:00:00.000Z",
    end_date: "1972-06-17T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 25,
    start_date: "1972-06-18T07:00:00.000Z",
    end_date: "1972-06-24T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 26,
    start_date: "1972-06-25T07:00:00.000Z",
    end_date: "1972-07-01T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 27,
    start_date: "1972-07-02T07:00:00.000Z",
    end_date: "1972-07-08T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 28,
    start_date: "1972-07-09T07:00:00.000Z",
    end_date: "1972-07-15T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 29,
    start_date: "1972-07-16T07:00:00.000Z",
    end_date: "1972-07-22T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 30,
    start_date: "1972-07-23T07:00:00.000Z",
    end_date: "1972-07-29T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 31,
    start_date: "1972-07-30T07:00:00.000Z",
    end_date: "1972-08-05T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 32,
    start_date: "1972-08-06T07:00:00.000Z",
    end_date: "1972-08-12T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 33,
    start_date: "1972-08-13T07:00:00.000Z",
    end_date: "1972-08-19T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 34,
    start_date: "1972-08-20T07:00:00.000Z",
    end_date: "1972-08-26T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 35,
    start_date: "1972-08-27T07:00:00.000Z",
    end_date: "1972-09-02T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 36,
    start_date: "1972-09-03T07:00:00.000Z",
    end_date: "1972-09-09T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 37,
    start_date: "1972-09-10T07:00:00.000Z",
    end_date: "1972-09-16T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 38,
    start_date: "1972-09-17T07:00:00.000Z",
    end_date: "1972-09-23T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 39,
    start_date: "1972-09-24T07:00:00.000Z",
    end_date: "1972-09-30T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 40,
    start_date: "1972-10-01T07:00:00.000Z",
    end_date: "1972-10-07T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 41,
    start_date: "1972-10-08T07:00:00.000Z",
    end_date: "1972-10-14T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 42,
    start_date: "1972-10-15T07:00:00.000Z",
    end_date: "1972-10-21T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 43,
    start_date: "1972-10-22T07:00:00.000Z",
    end_date: "1972-10-28T07:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 44,
    start_date: "1972-10-29T07:00:00.000Z",
    end_date: "1972-11-04T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 45,
    start_date: "1972-11-05T08:00:00.000Z",
    end_date: "1972-11-11T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 46,
    start_date: "1972-11-12T08:00:00.000Z",
    end_date: "1972-11-18T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 47,
    start_date: "1972-11-19T08:00:00.000Z",
    end_date: "1972-11-25T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 48,
    start_date: "1972-11-26T08:00:00.000Z",
    end_date: "1972-12-02T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 49,
    start_date: "1972-12-03T08:00:00.000Z",
    end_date: "1972-12-09T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 50,
    start_date: "1972-12-10T08:00:00.000Z",
    end_date: "1972-12-16T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 51,
    start_date: "1972-12-17T08:00:00.000Z",
    end_date: "1972-12-23T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 52,
    start_date: "1972-12-24T08:00:00.000Z",
    end_date: "1972-12-30T08:00:00.000Z",
  },
  {
    disease_year: 1972,
    disease_week: 53,
    start_date: "1972-12-31T08:00:00.000Z",
    end_date: "1973-01-06T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 1,
    start_date: "1976-01-04T08:00:00.000Z",
    end_date: "1976-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 2,
    start_date: "1976-01-11T08:00:00.000Z",
    end_date: "1976-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 3,
    start_date: "1976-01-18T08:00:00.000Z",
    end_date: "1976-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 4,
    start_date: "1976-01-25T08:00:00.000Z",
    end_date: "1976-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 5,
    start_date: "1976-02-01T08:00:00.000Z",
    end_date: "1976-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 6,
    start_date: "1976-02-08T08:00:00.000Z",
    end_date: "1976-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 7,
    start_date: "1976-02-15T08:00:00.000Z",
    end_date: "1976-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 8,
    start_date: "1976-02-22T08:00:00.000Z",
    end_date: "1976-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 9,
    start_date: "1976-02-29T08:00:00.000Z",
    end_date: "1976-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 10,
    start_date: "1976-03-07T08:00:00.000Z",
    end_date: "1976-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 11,
    start_date: "1976-03-14T08:00:00.000Z",
    end_date: "1976-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 12,
    start_date: "1976-03-21T08:00:00.000Z",
    end_date: "1976-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 13,
    start_date: "1976-03-28T08:00:00.000Z",
    end_date: "1976-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 14,
    start_date: "1976-04-04T08:00:00.000Z",
    end_date: "1976-04-10T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 15,
    start_date: "1976-04-11T08:00:00.000Z",
    end_date: "1976-04-17T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 16,
    start_date: "1976-04-18T08:00:00.000Z",
    end_date: "1976-04-24T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 17,
    start_date: "1976-04-25T08:00:00.000Z",
    end_date: "1976-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 18,
    start_date: "1976-05-02T07:00:00.000Z",
    end_date: "1976-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 19,
    start_date: "1976-05-09T07:00:00.000Z",
    end_date: "1976-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 20,
    start_date: "1976-05-16T07:00:00.000Z",
    end_date: "1976-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 21,
    start_date: "1976-05-23T07:00:00.000Z",
    end_date: "1976-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 22,
    start_date: "1976-05-30T07:00:00.000Z",
    end_date: "1976-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 23,
    start_date: "1976-06-06T07:00:00.000Z",
    end_date: "1976-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 24,
    start_date: "1976-06-13T07:00:00.000Z",
    end_date: "1976-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 25,
    start_date: "1976-06-20T07:00:00.000Z",
    end_date: "1976-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 26,
    start_date: "1976-06-27T07:00:00.000Z",
    end_date: "1976-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 27,
    start_date: "1976-07-04T07:00:00.000Z",
    end_date: "1976-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 28,
    start_date: "1976-07-11T07:00:00.000Z",
    end_date: "1976-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 29,
    start_date: "1976-07-18T07:00:00.000Z",
    end_date: "1976-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 30,
    start_date: "1976-07-25T07:00:00.000Z",
    end_date: "1976-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 31,
    start_date: "1976-08-01T07:00:00.000Z",
    end_date: "1976-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 32,
    start_date: "1976-08-08T07:00:00.000Z",
    end_date: "1976-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 33,
    start_date: "1976-08-15T07:00:00.000Z",
    end_date: "1976-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 34,
    start_date: "1976-08-22T07:00:00.000Z",
    end_date: "1976-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 35,
    start_date: "1976-08-29T07:00:00.000Z",
    end_date: "1976-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 36,
    start_date: "1976-09-05T07:00:00.000Z",
    end_date: "1976-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 37,
    start_date: "1976-09-12T07:00:00.000Z",
    end_date: "1976-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 38,
    start_date: "1976-09-19T07:00:00.000Z",
    end_date: "1976-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 39,
    start_date: "1976-09-26T07:00:00.000Z",
    end_date: "1976-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 40,
    start_date: "1976-10-03T07:00:00.000Z",
    end_date: "1976-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 41,
    start_date: "1976-10-10T07:00:00.000Z",
    end_date: "1976-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 42,
    start_date: "1976-10-17T07:00:00.000Z",
    end_date: "1976-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 43,
    start_date: "1976-10-24T07:00:00.000Z",
    end_date: "1976-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 44,
    start_date: "1976-10-31T07:00:00.000Z",
    end_date: "1976-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 45,
    start_date: "1976-11-07T08:00:00.000Z",
    end_date: "1976-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 46,
    start_date: "1976-11-14T08:00:00.000Z",
    end_date: "1976-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 47,
    start_date: "1976-11-21T08:00:00.000Z",
    end_date: "1976-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 48,
    start_date: "1976-11-28T08:00:00.000Z",
    end_date: "1976-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 49,
    start_date: "1976-12-05T08:00:00.000Z",
    end_date: "1976-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 50,
    start_date: "1976-12-12T08:00:00.000Z",
    end_date: "1976-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 51,
    start_date: "1976-12-19T08:00:00.000Z",
    end_date: "1976-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1976,
    disease_week: 52,
    start_date: "1976-12-26T08:00:00.000Z",
    end_date: "1977-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 1,
    start_date: "1977-01-02T08:00:00.000Z",
    end_date: "1977-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 2,
    start_date: "1977-01-09T08:00:00.000Z",
    end_date: "1977-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 3,
    start_date: "1977-01-16T08:00:00.000Z",
    end_date: "1977-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 4,
    start_date: "1977-01-23T08:00:00.000Z",
    end_date: "1977-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 5,
    start_date: "1977-01-30T08:00:00.000Z",
    end_date: "1977-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 6,
    start_date: "1977-02-06T08:00:00.000Z",
    end_date: "1977-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 7,
    start_date: "1977-02-13T08:00:00.000Z",
    end_date: "1977-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 8,
    start_date: "1977-02-20T08:00:00.000Z",
    end_date: "1977-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 9,
    start_date: "1977-02-27T08:00:00.000Z",
    end_date: "1977-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 10,
    start_date: "1977-03-06T08:00:00.000Z",
    end_date: "1977-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 11,
    start_date: "1977-03-13T08:00:00.000Z",
    end_date: "1977-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 12,
    start_date: "1977-03-20T08:00:00.000Z",
    end_date: "1977-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 13,
    start_date: "1977-03-27T08:00:00.000Z",
    end_date: "1977-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 14,
    start_date: "1977-04-03T08:00:00.000Z",
    end_date: "1977-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 15,
    start_date: "1977-04-10T08:00:00.000Z",
    end_date: "1977-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 16,
    start_date: "1977-04-17T08:00:00.000Z",
    end_date: "1977-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 17,
    start_date: "1977-04-24T08:00:00.000Z",
    end_date: "1977-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 18,
    start_date: "1977-05-01T07:00:00.000Z",
    end_date: "1977-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 19,
    start_date: "1977-05-08T07:00:00.000Z",
    end_date: "1977-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 20,
    start_date: "1977-05-15T07:00:00.000Z",
    end_date: "1977-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 21,
    start_date: "1977-05-22T07:00:00.000Z",
    end_date: "1977-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 22,
    start_date: "1977-05-29T07:00:00.000Z",
    end_date: "1977-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 23,
    start_date: "1977-06-05T07:00:00.000Z",
    end_date: "1977-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 24,
    start_date: "1977-06-12T07:00:00.000Z",
    end_date: "1977-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 25,
    start_date: "1977-06-19T07:00:00.000Z",
    end_date: "1977-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 26,
    start_date: "1977-06-26T07:00:00.000Z",
    end_date: "1977-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 27,
    start_date: "1977-07-03T07:00:00.000Z",
    end_date: "1977-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 28,
    start_date: "1977-07-10T07:00:00.000Z",
    end_date: "1977-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 29,
    start_date: "1977-07-17T07:00:00.000Z",
    end_date: "1977-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 30,
    start_date: "1977-07-24T07:00:00.000Z",
    end_date: "1977-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 31,
    start_date: "1977-07-31T07:00:00.000Z",
    end_date: "1977-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 32,
    start_date: "1977-08-07T07:00:00.000Z",
    end_date: "1977-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 33,
    start_date: "1977-08-14T07:00:00.000Z",
    end_date: "1977-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 34,
    start_date: "1977-08-21T07:00:00.000Z",
    end_date: "1977-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 35,
    start_date: "1977-08-28T07:00:00.000Z",
    end_date: "1977-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 36,
    start_date: "1977-09-04T07:00:00.000Z",
    end_date: "1977-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 37,
    start_date: "1977-09-11T07:00:00.000Z",
    end_date: "1977-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 38,
    start_date: "1977-09-18T07:00:00.000Z",
    end_date: "1977-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 39,
    start_date: "1977-09-25T07:00:00.000Z",
    end_date: "1977-10-01T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 40,
    start_date: "1977-10-02T07:00:00.000Z",
    end_date: "1977-10-08T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 41,
    start_date: "1977-10-09T07:00:00.000Z",
    end_date: "1977-10-15T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 42,
    start_date: "1977-10-16T07:00:00.000Z",
    end_date: "1977-10-22T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 43,
    start_date: "1977-10-23T07:00:00.000Z",
    end_date: "1977-10-29T07:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 44,
    start_date: "1977-10-30T07:00:00.000Z",
    end_date: "1977-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 45,
    start_date: "1977-11-06T08:00:00.000Z",
    end_date: "1977-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 46,
    start_date: "1977-11-13T08:00:00.000Z",
    end_date: "1977-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 47,
    start_date: "1977-11-20T08:00:00.000Z",
    end_date: "1977-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 48,
    start_date: "1977-11-27T08:00:00.000Z",
    end_date: "1977-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 49,
    start_date: "1977-12-04T08:00:00.000Z",
    end_date: "1977-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 50,
    start_date: "1977-12-11T08:00:00.000Z",
    end_date: "1977-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 51,
    start_date: "1977-12-18T08:00:00.000Z",
    end_date: "1977-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1977,
    disease_week: 52,
    start_date: "1977-12-25T08:00:00.000Z",
    end_date: "1977-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 1,
    start_date: "1981-01-04T08:00:00.000Z",
    end_date: "1981-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 2,
    start_date: "1981-01-11T08:00:00.000Z",
    end_date: "1981-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 3,
    start_date: "1981-01-18T08:00:00.000Z",
    end_date: "1981-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 4,
    start_date: "1981-01-25T08:00:00.000Z",
    end_date: "1981-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 5,
    start_date: "1981-02-01T08:00:00.000Z",
    end_date: "1981-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 6,
    start_date: "1981-02-08T08:00:00.000Z",
    end_date: "1981-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 7,
    start_date: "1981-02-15T08:00:00.000Z",
    end_date: "1981-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 8,
    start_date: "1981-02-22T08:00:00.000Z",
    end_date: "1981-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 9,
    start_date: "1981-03-01T08:00:00.000Z",
    end_date: "1981-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 10,
    start_date: "1981-03-08T08:00:00.000Z",
    end_date: "1981-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 11,
    start_date: "1981-03-15T08:00:00.000Z",
    end_date: "1981-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 12,
    start_date: "1981-03-22T08:00:00.000Z",
    end_date: "1981-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 13,
    start_date: "1981-03-29T08:00:00.000Z",
    end_date: "1981-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 14,
    start_date: "1981-04-05T08:00:00.000Z",
    end_date: "1981-04-11T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 15,
    start_date: "1981-04-12T08:00:00.000Z",
    end_date: "1981-04-18T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 16,
    start_date: "1981-04-19T08:00:00.000Z",
    end_date: "1981-04-25T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 17,
    start_date: "1981-04-26T08:00:00.000Z",
    end_date: "1981-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 18,
    start_date: "1981-05-03T07:00:00.000Z",
    end_date: "1981-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 19,
    start_date: "1981-05-10T07:00:00.000Z",
    end_date: "1981-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 20,
    start_date: "1981-05-17T07:00:00.000Z",
    end_date: "1981-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 21,
    start_date: "1981-05-24T07:00:00.000Z",
    end_date: "1981-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 22,
    start_date: "1981-05-31T07:00:00.000Z",
    end_date: "1981-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 23,
    start_date: "1981-06-07T07:00:00.000Z",
    end_date: "1981-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 24,
    start_date: "1981-06-14T07:00:00.000Z",
    end_date: "1981-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 25,
    start_date: "1981-06-21T07:00:00.000Z",
    end_date: "1981-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 26,
    start_date: "1981-06-28T07:00:00.000Z",
    end_date: "1981-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 27,
    start_date: "1981-07-05T07:00:00.000Z",
    end_date: "1981-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 28,
    start_date: "1981-07-12T07:00:00.000Z",
    end_date: "1981-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 29,
    start_date: "1981-07-19T07:00:00.000Z",
    end_date: "1981-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 30,
    start_date: "1981-07-26T07:00:00.000Z",
    end_date: "1981-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 31,
    start_date: "1981-08-02T07:00:00.000Z",
    end_date: "1981-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 32,
    start_date: "1981-08-09T07:00:00.000Z",
    end_date: "1981-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 33,
    start_date: "1981-08-16T07:00:00.000Z",
    end_date: "1981-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 34,
    start_date: "1981-08-23T07:00:00.000Z",
    end_date: "1981-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 35,
    start_date: "1981-08-30T07:00:00.000Z",
    end_date: "1981-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 36,
    start_date: "1981-09-06T07:00:00.000Z",
    end_date: "1981-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 37,
    start_date: "1981-09-13T07:00:00.000Z",
    end_date: "1981-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 38,
    start_date: "1981-09-20T07:00:00.000Z",
    end_date: "1981-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 39,
    start_date: "1981-09-27T07:00:00.000Z",
    end_date: "1981-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 40,
    start_date: "1981-10-04T07:00:00.000Z",
    end_date: "1981-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 41,
    start_date: "1981-10-11T07:00:00.000Z",
    end_date: "1981-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 42,
    start_date: "1981-10-18T07:00:00.000Z",
    end_date: "1981-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 43,
    start_date: "1981-10-25T07:00:00.000Z",
    end_date: "1981-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 44,
    start_date: "1981-11-01T08:00:00.000Z",
    end_date: "1981-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 45,
    start_date: "1981-11-08T08:00:00.000Z",
    end_date: "1981-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 46,
    start_date: "1981-11-15T08:00:00.000Z",
    end_date: "1981-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 47,
    start_date: "1981-11-22T08:00:00.000Z",
    end_date: "1981-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 48,
    start_date: "1981-11-29T08:00:00.000Z",
    end_date: "1981-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 49,
    start_date: "1981-12-06T08:00:00.000Z",
    end_date: "1981-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 50,
    start_date: "1981-12-13T08:00:00.000Z",
    end_date: "1981-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 51,
    start_date: "1981-12-20T08:00:00.000Z",
    end_date: "1981-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1981,
    disease_week: 52,
    start_date: "1981-12-27T08:00:00.000Z",
    end_date: "1982-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 1,
    start_date: "1982-01-03T08:00:00.000Z",
    end_date: "1982-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 2,
    start_date: "1982-01-10T08:00:00.000Z",
    end_date: "1982-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 3,
    start_date: "1982-01-17T08:00:00.000Z",
    end_date: "1982-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 4,
    start_date: "1982-01-24T08:00:00.000Z",
    end_date: "1982-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 5,
    start_date: "1982-01-31T08:00:00.000Z",
    end_date: "1982-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 6,
    start_date: "1982-02-07T08:00:00.000Z",
    end_date: "1982-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 7,
    start_date: "1982-02-14T08:00:00.000Z",
    end_date: "1982-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 8,
    start_date: "1982-02-21T08:00:00.000Z",
    end_date: "1982-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 9,
    start_date: "1982-02-28T08:00:00.000Z",
    end_date: "1982-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 10,
    start_date: "1982-03-07T08:00:00.000Z",
    end_date: "1982-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 11,
    start_date: "1982-03-14T08:00:00.000Z",
    end_date: "1982-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 12,
    start_date: "1982-03-21T08:00:00.000Z",
    end_date: "1982-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 13,
    start_date: "1982-03-28T08:00:00.000Z",
    end_date: "1982-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 14,
    start_date: "1982-04-04T08:00:00.000Z",
    end_date: "1982-04-10T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 15,
    start_date: "1982-04-11T08:00:00.000Z",
    end_date: "1982-04-17T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 16,
    start_date: "1982-04-18T08:00:00.000Z",
    end_date: "1982-04-24T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 17,
    start_date: "1982-04-25T08:00:00.000Z",
    end_date: "1982-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 18,
    start_date: "1982-05-02T07:00:00.000Z",
    end_date: "1982-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 19,
    start_date: "1982-05-09T07:00:00.000Z",
    end_date: "1982-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 20,
    start_date: "1982-05-16T07:00:00.000Z",
    end_date: "1982-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 21,
    start_date: "1982-05-23T07:00:00.000Z",
    end_date: "1982-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 22,
    start_date: "1982-05-30T07:00:00.000Z",
    end_date: "1982-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 23,
    start_date: "1982-06-06T07:00:00.000Z",
    end_date: "1982-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 24,
    start_date: "1982-06-13T07:00:00.000Z",
    end_date: "1982-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 25,
    start_date: "1982-06-20T07:00:00.000Z",
    end_date: "1982-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 26,
    start_date: "1982-06-27T07:00:00.000Z",
    end_date: "1982-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 27,
    start_date: "1982-07-04T07:00:00.000Z",
    end_date: "1982-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 28,
    start_date: "1982-07-11T07:00:00.000Z",
    end_date: "1982-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 29,
    start_date: "1982-07-18T07:00:00.000Z",
    end_date: "1982-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 30,
    start_date: "1982-07-25T07:00:00.000Z",
    end_date: "1982-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 31,
    start_date: "1982-08-01T07:00:00.000Z",
    end_date: "1982-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 32,
    start_date: "1982-08-08T07:00:00.000Z",
    end_date: "1982-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 33,
    start_date: "1982-08-15T07:00:00.000Z",
    end_date: "1982-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 34,
    start_date: "1982-08-22T07:00:00.000Z",
    end_date: "1982-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 35,
    start_date: "1982-08-29T07:00:00.000Z",
    end_date: "1982-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 36,
    start_date: "1982-09-05T07:00:00.000Z",
    end_date: "1982-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 37,
    start_date: "1982-09-12T07:00:00.000Z",
    end_date: "1982-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 38,
    start_date: "1982-09-19T07:00:00.000Z",
    end_date: "1982-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 39,
    start_date: "1982-09-26T07:00:00.000Z",
    end_date: "1982-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 40,
    start_date: "1982-10-03T07:00:00.000Z",
    end_date: "1982-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 41,
    start_date: "1982-10-10T07:00:00.000Z",
    end_date: "1982-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 42,
    start_date: "1982-10-17T07:00:00.000Z",
    end_date: "1982-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 43,
    start_date: "1982-10-24T07:00:00.000Z",
    end_date: "1982-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 44,
    start_date: "1982-10-31T07:00:00.000Z",
    end_date: "1982-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 45,
    start_date: "1982-11-07T08:00:00.000Z",
    end_date: "1982-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 46,
    start_date: "1982-11-14T08:00:00.000Z",
    end_date: "1982-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 47,
    start_date: "1982-11-21T08:00:00.000Z",
    end_date: "1982-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 48,
    start_date: "1982-11-28T08:00:00.000Z",
    end_date: "1982-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 49,
    start_date: "1982-12-05T08:00:00.000Z",
    end_date: "1982-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 50,
    start_date: "1982-12-12T08:00:00.000Z",
    end_date: "1982-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 51,
    start_date: "1982-12-19T08:00:00.000Z",
    end_date: "1982-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1982,
    disease_week: 52,
    start_date: "1982-12-26T08:00:00.000Z",
    end_date: "1983-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 1,
    start_date: "1983-01-02T08:00:00.000Z",
    end_date: "1983-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 2,
    start_date: "1983-01-09T08:00:00.000Z",
    end_date: "1983-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 3,
    start_date: "1983-01-16T08:00:00.000Z",
    end_date: "1983-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 4,
    start_date: "1983-01-23T08:00:00.000Z",
    end_date: "1983-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 5,
    start_date: "1983-01-30T08:00:00.000Z",
    end_date: "1983-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 6,
    start_date: "1983-02-06T08:00:00.000Z",
    end_date: "1983-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 7,
    start_date: "1983-02-13T08:00:00.000Z",
    end_date: "1983-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 8,
    start_date: "1983-02-20T08:00:00.000Z",
    end_date: "1983-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 9,
    start_date: "1983-02-27T08:00:00.000Z",
    end_date: "1983-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 10,
    start_date: "1983-03-06T08:00:00.000Z",
    end_date: "1983-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 11,
    start_date: "1983-03-13T08:00:00.000Z",
    end_date: "1983-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 12,
    start_date: "1983-03-20T08:00:00.000Z",
    end_date: "1983-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 13,
    start_date: "1983-03-27T08:00:00.000Z",
    end_date: "1983-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 14,
    start_date: "1983-04-03T08:00:00.000Z",
    end_date: "1983-04-09T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 15,
    start_date: "1983-04-10T08:00:00.000Z",
    end_date: "1983-04-16T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 16,
    start_date: "1983-04-17T08:00:00.000Z",
    end_date: "1983-04-23T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 17,
    start_date: "1983-04-24T08:00:00.000Z",
    end_date: "1983-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 18,
    start_date: "1983-05-01T07:00:00.000Z",
    end_date: "1983-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 19,
    start_date: "1983-05-08T07:00:00.000Z",
    end_date: "1983-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 20,
    start_date: "1983-05-15T07:00:00.000Z",
    end_date: "1983-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 21,
    start_date: "1983-05-22T07:00:00.000Z",
    end_date: "1983-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 22,
    start_date: "1983-05-29T07:00:00.000Z",
    end_date: "1983-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 23,
    start_date: "1983-06-05T07:00:00.000Z",
    end_date: "1983-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 24,
    start_date: "1983-06-12T07:00:00.000Z",
    end_date: "1983-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 25,
    start_date: "1983-06-19T07:00:00.000Z",
    end_date: "1983-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 26,
    start_date: "1983-06-26T07:00:00.000Z",
    end_date: "1983-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 27,
    start_date: "1983-07-03T07:00:00.000Z",
    end_date: "1983-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 28,
    start_date: "1983-07-10T07:00:00.000Z",
    end_date: "1983-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 29,
    start_date: "1983-07-17T07:00:00.000Z",
    end_date: "1983-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 30,
    start_date: "1983-07-24T07:00:00.000Z",
    end_date: "1983-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 31,
    start_date: "1983-07-31T07:00:00.000Z",
    end_date: "1983-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 32,
    start_date: "1983-08-07T07:00:00.000Z",
    end_date: "1983-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 33,
    start_date: "1983-08-14T07:00:00.000Z",
    end_date: "1983-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 34,
    start_date: "1983-08-21T07:00:00.000Z",
    end_date: "1983-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 35,
    start_date: "1983-08-28T07:00:00.000Z",
    end_date: "1983-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 36,
    start_date: "1983-09-04T07:00:00.000Z",
    end_date: "1983-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 37,
    start_date: "1983-09-11T07:00:00.000Z",
    end_date: "1983-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 38,
    start_date: "1983-09-18T07:00:00.000Z",
    end_date: "1983-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 39,
    start_date: "1983-09-25T07:00:00.000Z",
    end_date: "1983-10-01T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 40,
    start_date: "1983-10-02T07:00:00.000Z",
    end_date: "1983-10-08T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 41,
    start_date: "1983-10-09T07:00:00.000Z",
    end_date: "1983-10-15T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 42,
    start_date: "1983-10-16T07:00:00.000Z",
    end_date: "1983-10-22T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 43,
    start_date: "1983-10-23T07:00:00.000Z",
    end_date: "1983-10-29T07:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 44,
    start_date: "1983-10-30T07:00:00.000Z",
    end_date: "1983-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 45,
    start_date: "1983-11-06T08:00:00.000Z",
    end_date: "1983-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 46,
    start_date: "1983-11-13T08:00:00.000Z",
    end_date: "1983-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 47,
    start_date: "1983-11-20T08:00:00.000Z",
    end_date: "1983-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 48,
    start_date: "1983-11-27T08:00:00.000Z",
    end_date: "1983-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 49,
    start_date: "1983-12-04T08:00:00.000Z",
    end_date: "1983-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 50,
    start_date: "1983-12-11T08:00:00.000Z",
    end_date: "1983-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 51,
    start_date: "1983-12-18T08:00:00.000Z",
    end_date: "1983-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1983,
    disease_week: 52,
    start_date: "1983-12-25T08:00:00.000Z",
    end_date: "1983-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 1,
    start_date: "1987-01-04T08:00:00.000Z",
    end_date: "1987-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 2,
    start_date: "1987-01-11T08:00:00.000Z",
    end_date: "1987-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 3,
    start_date: "1987-01-18T08:00:00.000Z",
    end_date: "1987-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 4,
    start_date: "1987-01-25T08:00:00.000Z",
    end_date: "1987-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 5,
    start_date: "1987-02-01T08:00:00.000Z",
    end_date: "1987-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 6,
    start_date: "1987-02-08T08:00:00.000Z",
    end_date: "1987-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 7,
    start_date: "1987-02-15T08:00:00.000Z",
    end_date: "1987-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 8,
    start_date: "1987-02-22T08:00:00.000Z",
    end_date: "1987-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 9,
    start_date: "1987-03-01T08:00:00.000Z",
    end_date: "1987-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 10,
    start_date: "1987-03-08T08:00:00.000Z",
    end_date: "1987-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 11,
    start_date: "1987-03-15T08:00:00.000Z",
    end_date: "1987-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 12,
    start_date: "1987-03-22T08:00:00.000Z",
    end_date: "1987-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 13,
    start_date: "1987-03-29T08:00:00.000Z",
    end_date: "1987-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 14,
    start_date: "1987-04-05T08:00:00.000Z",
    end_date: "1987-04-11T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 15,
    start_date: "1987-04-12T07:00:00.000Z",
    end_date: "1987-04-18T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 16,
    start_date: "1987-04-19T07:00:00.000Z",
    end_date: "1987-04-25T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 17,
    start_date: "1987-04-26T07:00:00.000Z",
    end_date: "1987-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 18,
    start_date: "1987-05-03T07:00:00.000Z",
    end_date: "1987-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 19,
    start_date: "1987-05-10T07:00:00.000Z",
    end_date: "1987-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 20,
    start_date: "1987-05-17T07:00:00.000Z",
    end_date: "1987-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 21,
    start_date: "1987-05-24T07:00:00.000Z",
    end_date: "1987-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 22,
    start_date: "1987-05-31T07:00:00.000Z",
    end_date: "1987-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 23,
    start_date: "1987-06-07T07:00:00.000Z",
    end_date: "1987-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 24,
    start_date: "1987-06-14T07:00:00.000Z",
    end_date: "1987-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 25,
    start_date: "1987-06-21T07:00:00.000Z",
    end_date: "1987-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 26,
    start_date: "1987-06-28T07:00:00.000Z",
    end_date: "1987-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 27,
    start_date: "1987-07-05T07:00:00.000Z",
    end_date: "1987-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 28,
    start_date: "1987-07-12T07:00:00.000Z",
    end_date: "1987-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 29,
    start_date: "1987-07-19T07:00:00.000Z",
    end_date: "1987-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 30,
    start_date: "1987-07-26T07:00:00.000Z",
    end_date: "1987-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 31,
    start_date: "1987-08-02T07:00:00.000Z",
    end_date: "1987-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 32,
    start_date: "1987-08-09T07:00:00.000Z",
    end_date: "1987-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 33,
    start_date: "1987-08-16T07:00:00.000Z",
    end_date: "1987-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 34,
    start_date: "1987-08-23T07:00:00.000Z",
    end_date: "1987-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 35,
    start_date: "1987-08-30T07:00:00.000Z",
    end_date: "1987-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 36,
    start_date: "1987-09-06T07:00:00.000Z",
    end_date: "1987-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 37,
    start_date: "1987-09-13T07:00:00.000Z",
    end_date: "1987-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 38,
    start_date: "1987-09-20T07:00:00.000Z",
    end_date: "1987-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 39,
    start_date: "1987-09-27T07:00:00.000Z",
    end_date: "1987-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 40,
    start_date: "1987-10-04T07:00:00.000Z",
    end_date: "1987-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 41,
    start_date: "1987-10-11T07:00:00.000Z",
    end_date: "1987-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 42,
    start_date: "1987-10-18T07:00:00.000Z",
    end_date: "1987-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 43,
    start_date: "1987-10-25T07:00:00.000Z",
    end_date: "1987-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 44,
    start_date: "1987-11-01T08:00:00.000Z",
    end_date: "1987-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 45,
    start_date: "1987-11-08T08:00:00.000Z",
    end_date: "1987-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 46,
    start_date: "1987-11-15T08:00:00.000Z",
    end_date: "1987-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 47,
    start_date: "1987-11-22T08:00:00.000Z",
    end_date: "1987-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 48,
    start_date: "1987-11-29T08:00:00.000Z",
    end_date: "1987-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 49,
    start_date: "1987-12-06T08:00:00.000Z",
    end_date: "1987-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 50,
    start_date: "1987-12-13T08:00:00.000Z",
    end_date: "1987-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 51,
    start_date: "1987-12-20T08:00:00.000Z",
    end_date: "1987-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1987,
    disease_week: 52,
    start_date: "1987-12-27T08:00:00.000Z",
    end_date: "1988-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 1,
    start_date: "1988-01-03T08:00:00.000Z",
    end_date: "1988-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 2,
    start_date: "1988-01-10T08:00:00.000Z",
    end_date: "1988-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 3,
    start_date: "1988-01-17T08:00:00.000Z",
    end_date: "1988-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 4,
    start_date: "1988-01-24T08:00:00.000Z",
    end_date: "1988-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 5,
    start_date: "1988-01-31T08:00:00.000Z",
    end_date: "1988-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 6,
    start_date: "1988-02-07T08:00:00.000Z",
    end_date: "1988-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 7,
    start_date: "1988-02-14T08:00:00.000Z",
    end_date: "1988-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 8,
    start_date: "1988-02-21T08:00:00.000Z",
    end_date: "1988-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 9,
    start_date: "1988-02-28T08:00:00.000Z",
    end_date: "1988-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 10,
    start_date: "1988-03-06T08:00:00.000Z",
    end_date: "1988-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 11,
    start_date: "1988-03-13T08:00:00.000Z",
    end_date: "1988-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 12,
    start_date: "1988-03-20T08:00:00.000Z",
    end_date: "1988-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 13,
    start_date: "1988-03-27T08:00:00.000Z",
    end_date: "1988-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 14,
    start_date: "1988-04-03T08:00:00.000Z",
    end_date: "1988-04-09T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 15,
    start_date: "1988-04-10T07:00:00.000Z",
    end_date: "1988-04-16T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 16,
    start_date: "1988-04-17T07:00:00.000Z",
    end_date: "1988-04-23T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 17,
    start_date: "1988-04-24T07:00:00.000Z",
    end_date: "1988-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 18,
    start_date: "1988-05-01T07:00:00.000Z",
    end_date: "1988-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 19,
    start_date: "1988-05-08T07:00:00.000Z",
    end_date: "1988-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 20,
    start_date: "1988-05-15T07:00:00.000Z",
    end_date: "1988-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 21,
    start_date: "1988-05-22T07:00:00.000Z",
    end_date: "1988-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 22,
    start_date: "1988-05-29T07:00:00.000Z",
    end_date: "1988-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 23,
    start_date: "1988-06-05T07:00:00.000Z",
    end_date: "1988-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 24,
    start_date: "1988-06-12T07:00:00.000Z",
    end_date: "1988-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 25,
    start_date: "1988-06-19T07:00:00.000Z",
    end_date: "1988-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 26,
    start_date: "1988-06-26T07:00:00.000Z",
    end_date: "1988-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 27,
    start_date: "1988-07-03T07:00:00.000Z",
    end_date: "1988-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 28,
    start_date: "1988-07-10T07:00:00.000Z",
    end_date: "1988-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 29,
    start_date: "1988-07-17T07:00:00.000Z",
    end_date: "1988-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 30,
    start_date: "1988-07-24T07:00:00.000Z",
    end_date: "1988-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 31,
    start_date: "1988-07-31T07:00:00.000Z",
    end_date: "1988-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 32,
    start_date: "1988-08-07T07:00:00.000Z",
    end_date: "1988-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 33,
    start_date: "1988-08-14T07:00:00.000Z",
    end_date: "1988-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 34,
    start_date: "1988-08-21T07:00:00.000Z",
    end_date: "1988-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 35,
    start_date: "1988-08-28T07:00:00.000Z",
    end_date: "1988-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 36,
    start_date: "1988-09-04T07:00:00.000Z",
    end_date: "1988-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 37,
    start_date: "1988-09-11T07:00:00.000Z",
    end_date: "1988-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 38,
    start_date: "1988-09-18T07:00:00.000Z",
    end_date: "1988-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 39,
    start_date: "1988-09-25T07:00:00.000Z",
    end_date: "1988-10-01T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 40,
    start_date: "1988-10-02T07:00:00.000Z",
    end_date: "1988-10-08T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 41,
    start_date: "1988-10-09T07:00:00.000Z",
    end_date: "1988-10-15T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 42,
    start_date: "1988-10-16T07:00:00.000Z",
    end_date: "1988-10-22T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 43,
    start_date: "1988-10-23T07:00:00.000Z",
    end_date: "1988-10-29T07:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 44,
    start_date: "1988-10-30T07:00:00.000Z",
    end_date: "1988-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 45,
    start_date: "1988-11-06T08:00:00.000Z",
    end_date: "1988-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 46,
    start_date: "1988-11-13T08:00:00.000Z",
    end_date: "1988-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 47,
    start_date: "1988-11-20T08:00:00.000Z",
    end_date: "1988-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 48,
    start_date: "1988-11-27T08:00:00.000Z",
    end_date: "1988-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 49,
    start_date: "1988-12-04T08:00:00.000Z",
    end_date: "1988-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 50,
    start_date: "1988-12-11T08:00:00.000Z",
    end_date: "1988-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 51,
    start_date: "1988-12-18T08:00:00.000Z",
    end_date: "1988-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1988,
    disease_week: 52,
    start_date: "1988-12-25T08:00:00.000Z",
    end_date: "1988-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 1,
    start_date: "1993-01-03T08:00:00.000Z",
    end_date: "1993-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 2,
    start_date: "1993-01-10T08:00:00.000Z",
    end_date: "1993-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 3,
    start_date: "1993-01-17T08:00:00.000Z",
    end_date: "1993-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 4,
    start_date: "1993-01-24T08:00:00.000Z",
    end_date: "1993-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 5,
    start_date: "1993-01-31T08:00:00.000Z",
    end_date: "1993-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 6,
    start_date: "1993-02-07T08:00:00.000Z",
    end_date: "1993-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 7,
    start_date: "1993-02-14T08:00:00.000Z",
    end_date: "1993-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 8,
    start_date: "1993-02-21T08:00:00.000Z",
    end_date: "1993-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 9,
    start_date: "1993-02-28T08:00:00.000Z",
    end_date: "1993-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 10,
    start_date: "1993-03-07T08:00:00.000Z",
    end_date: "1993-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 11,
    start_date: "1993-03-14T08:00:00.000Z",
    end_date: "1993-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 12,
    start_date: "1993-03-21T08:00:00.000Z",
    end_date: "1993-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 13,
    start_date: "1993-03-28T08:00:00.000Z",
    end_date: "1993-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 14,
    start_date: "1993-04-04T08:00:00.000Z",
    end_date: "1993-04-10T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 15,
    start_date: "1993-04-11T07:00:00.000Z",
    end_date: "1993-04-17T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 16,
    start_date: "1993-04-18T07:00:00.000Z",
    end_date: "1993-04-24T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 17,
    start_date: "1993-04-25T07:00:00.000Z",
    end_date: "1993-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 18,
    start_date: "1993-05-02T07:00:00.000Z",
    end_date: "1993-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 19,
    start_date: "1993-05-09T07:00:00.000Z",
    end_date: "1993-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 20,
    start_date: "1993-05-16T07:00:00.000Z",
    end_date: "1993-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 21,
    start_date: "1993-05-23T07:00:00.000Z",
    end_date: "1993-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 22,
    start_date: "1993-05-30T07:00:00.000Z",
    end_date: "1993-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 23,
    start_date: "1993-06-06T07:00:00.000Z",
    end_date: "1993-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 24,
    start_date: "1993-06-13T07:00:00.000Z",
    end_date: "1993-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 25,
    start_date: "1993-06-20T07:00:00.000Z",
    end_date: "1993-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 26,
    start_date: "1993-06-27T07:00:00.000Z",
    end_date: "1993-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 27,
    start_date: "1993-07-04T07:00:00.000Z",
    end_date: "1993-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 28,
    start_date: "1993-07-11T07:00:00.000Z",
    end_date: "1993-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 29,
    start_date: "1993-07-18T07:00:00.000Z",
    end_date: "1993-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 30,
    start_date: "1993-07-25T07:00:00.000Z",
    end_date: "1993-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 31,
    start_date: "1993-08-01T07:00:00.000Z",
    end_date: "1993-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 32,
    start_date: "1993-08-08T07:00:00.000Z",
    end_date: "1993-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 33,
    start_date: "1993-08-15T07:00:00.000Z",
    end_date: "1993-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 34,
    start_date: "1993-08-22T07:00:00.000Z",
    end_date: "1993-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 35,
    start_date: "1993-08-29T07:00:00.000Z",
    end_date: "1993-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 36,
    start_date: "1993-09-05T07:00:00.000Z",
    end_date: "1993-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 37,
    start_date: "1993-09-12T07:00:00.000Z",
    end_date: "1993-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 38,
    start_date: "1993-09-19T07:00:00.000Z",
    end_date: "1993-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 39,
    start_date: "1993-09-26T07:00:00.000Z",
    end_date: "1993-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 40,
    start_date: "1993-10-03T07:00:00.000Z",
    end_date: "1993-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 41,
    start_date: "1993-10-10T07:00:00.000Z",
    end_date: "1993-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 42,
    start_date: "1993-10-17T07:00:00.000Z",
    end_date: "1993-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 43,
    start_date: "1993-10-24T07:00:00.000Z",
    end_date: "1993-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 44,
    start_date: "1993-10-31T07:00:00.000Z",
    end_date: "1993-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 45,
    start_date: "1993-11-07T08:00:00.000Z",
    end_date: "1993-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 46,
    start_date: "1993-11-14T08:00:00.000Z",
    end_date: "1993-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 47,
    start_date: "1993-11-21T08:00:00.000Z",
    end_date: "1993-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 48,
    start_date: "1993-11-28T08:00:00.000Z",
    end_date: "1993-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 49,
    start_date: "1993-12-05T08:00:00.000Z",
    end_date: "1993-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 50,
    start_date: "1993-12-12T08:00:00.000Z",
    end_date: "1993-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 51,
    start_date: "1993-12-19T08:00:00.000Z",
    end_date: "1993-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1993,
    disease_week: 52,
    start_date: "1993-12-26T08:00:00.000Z",
    end_date: "1994-01-01T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 1,
    start_date: "1994-01-02T08:00:00.000Z",
    end_date: "1994-01-08T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 2,
    start_date: "1994-01-09T08:00:00.000Z",
    end_date: "1994-01-15T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 3,
    start_date: "1994-01-16T08:00:00.000Z",
    end_date: "1994-01-22T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 4,
    start_date: "1994-01-23T08:00:00.000Z",
    end_date: "1994-01-29T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 5,
    start_date: "1994-01-30T08:00:00.000Z",
    end_date: "1994-02-05T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 6,
    start_date: "1994-02-06T08:00:00.000Z",
    end_date: "1994-02-12T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 7,
    start_date: "1994-02-13T08:00:00.000Z",
    end_date: "1994-02-19T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 8,
    start_date: "1994-02-20T08:00:00.000Z",
    end_date: "1994-02-26T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 9,
    start_date: "1994-02-27T08:00:00.000Z",
    end_date: "1994-03-05T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 10,
    start_date: "1994-03-06T08:00:00.000Z",
    end_date: "1994-03-12T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 11,
    start_date: "1994-03-13T08:00:00.000Z",
    end_date: "1994-03-19T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 12,
    start_date: "1994-03-20T08:00:00.000Z",
    end_date: "1994-03-26T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 13,
    start_date: "1994-03-27T08:00:00.000Z",
    end_date: "1994-04-02T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 14,
    start_date: "1994-04-03T08:00:00.000Z",
    end_date: "1994-04-09T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 15,
    start_date: "1994-04-10T07:00:00.000Z",
    end_date: "1994-04-16T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 16,
    start_date: "1994-04-17T07:00:00.000Z",
    end_date: "1994-04-23T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 17,
    start_date: "1994-04-24T07:00:00.000Z",
    end_date: "1994-04-30T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 18,
    start_date: "1994-05-01T07:00:00.000Z",
    end_date: "1994-05-07T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 19,
    start_date: "1994-05-08T07:00:00.000Z",
    end_date: "1994-05-14T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 20,
    start_date: "1994-05-15T07:00:00.000Z",
    end_date: "1994-05-21T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 21,
    start_date: "1994-05-22T07:00:00.000Z",
    end_date: "1994-05-28T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 22,
    start_date: "1994-05-29T07:00:00.000Z",
    end_date: "1994-06-04T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 23,
    start_date: "1994-06-05T07:00:00.000Z",
    end_date: "1994-06-11T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 24,
    start_date: "1994-06-12T07:00:00.000Z",
    end_date: "1994-06-18T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 25,
    start_date: "1994-06-19T07:00:00.000Z",
    end_date: "1994-06-25T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 26,
    start_date: "1994-06-26T07:00:00.000Z",
    end_date: "1994-07-02T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 27,
    start_date: "1994-07-03T07:00:00.000Z",
    end_date: "1994-07-09T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 28,
    start_date: "1994-07-10T07:00:00.000Z",
    end_date: "1994-07-16T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 29,
    start_date: "1994-07-17T07:00:00.000Z",
    end_date: "1994-07-23T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 30,
    start_date: "1994-07-24T07:00:00.000Z",
    end_date: "1994-07-30T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 31,
    start_date: "1994-07-31T07:00:00.000Z",
    end_date: "1994-08-06T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 32,
    start_date: "1994-08-07T07:00:00.000Z",
    end_date: "1994-08-13T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 33,
    start_date: "1994-08-14T07:00:00.000Z",
    end_date: "1994-08-20T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 34,
    start_date: "1994-08-21T07:00:00.000Z",
    end_date: "1994-08-27T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 35,
    start_date: "1994-08-28T07:00:00.000Z",
    end_date: "1994-09-03T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 36,
    start_date: "1994-09-04T07:00:00.000Z",
    end_date: "1994-09-10T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 37,
    start_date: "1994-09-11T07:00:00.000Z",
    end_date: "1994-09-17T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 38,
    start_date: "1994-09-18T07:00:00.000Z",
    end_date: "1994-09-24T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 39,
    start_date: "1994-09-25T07:00:00.000Z",
    end_date: "1994-10-01T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 40,
    start_date: "1994-10-02T07:00:00.000Z",
    end_date: "1994-10-08T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 41,
    start_date: "1994-10-09T07:00:00.000Z",
    end_date: "1994-10-15T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 42,
    start_date: "1994-10-16T07:00:00.000Z",
    end_date: "1994-10-22T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 43,
    start_date: "1994-10-23T07:00:00.000Z",
    end_date: "1994-10-29T07:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 44,
    start_date: "1994-10-30T07:00:00.000Z",
    end_date: "1994-11-05T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 45,
    start_date: "1994-11-06T08:00:00.000Z",
    end_date: "1994-11-12T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 46,
    start_date: "1994-11-13T08:00:00.000Z",
    end_date: "1994-11-19T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 47,
    start_date: "1994-11-20T08:00:00.000Z",
    end_date: "1994-11-26T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 48,
    start_date: "1994-11-27T08:00:00.000Z",
    end_date: "1994-12-03T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 49,
    start_date: "1994-12-04T08:00:00.000Z",
    end_date: "1994-12-10T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 50,
    start_date: "1994-12-11T08:00:00.000Z",
    end_date: "1994-12-17T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 51,
    start_date: "1994-12-18T08:00:00.000Z",
    end_date: "1994-12-24T08:00:00.000Z",
  },
  {
    disease_year: 1994,
    disease_week: 52,
    start_date: "1994-12-25T08:00:00.000Z",
    end_date: "1994-12-31T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 1,
    start_date: "1998-01-04T08:00:00.000Z",
    end_date: "1998-01-10T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 2,
    start_date: "1998-01-11T08:00:00.000Z",
    end_date: "1998-01-17T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 3,
    start_date: "1998-01-18T08:00:00.000Z",
    end_date: "1998-01-24T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 4,
    start_date: "1998-01-25T08:00:00.000Z",
    end_date: "1998-01-31T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 5,
    start_date: "1998-02-01T08:00:00.000Z",
    end_date: "1998-02-07T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 6,
    start_date: "1998-02-08T08:00:00.000Z",
    end_date: "1998-02-14T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 7,
    start_date: "1998-02-15T08:00:00.000Z",
    end_date: "1998-02-21T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 8,
    start_date: "1998-02-22T08:00:00.000Z",
    end_date: "1998-02-28T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 9,
    start_date: "1998-03-01T08:00:00.000Z",
    end_date: "1998-03-07T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 10,
    start_date: "1998-03-08T08:00:00.000Z",
    end_date: "1998-03-14T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 11,
    start_date: "1998-03-15T08:00:00.000Z",
    end_date: "1998-03-21T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 12,
    start_date: "1998-03-22T08:00:00.000Z",
    end_date: "1998-03-28T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 13,
    start_date: "1998-03-29T08:00:00.000Z",
    end_date: "1998-04-04T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 14,
    start_date: "1998-04-05T08:00:00.000Z",
    end_date: "1998-04-11T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 15,
    start_date: "1998-04-12T07:00:00.000Z",
    end_date: "1998-04-18T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 16,
    start_date: "1998-04-19T07:00:00.000Z",
    end_date: "1998-04-25T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 17,
    start_date: "1998-04-26T07:00:00.000Z",
    end_date: "1998-05-02T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 18,
    start_date: "1998-05-03T07:00:00.000Z",
    end_date: "1998-05-09T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 19,
    start_date: "1998-05-10T07:00:00.000Z",
    end_date: "1998-05-16T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 20,
    start_date: "1998-05-17T07:00:00.000Z",
    end_date: "1998-05-23T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 21,
    start_date: "1998-05-24T07:00:00.000Z",
    end_date: "1998-05-30T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 22,
    start_date: "1998-05-31T07:00:00.000Z",
    end_date: "1998-06-06T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 23,
    start_date: "1998-06-07T07:00:00.000Z",
    end_date: "1998-06-13T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 24,
    start_date: "1998-06-14T07:00:00.000Z",
    end_date: "1998-06-20T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 25,
    start_date: "1998-06-21T07:00:00.000Z",
    end_date: "1998-06-27T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 26,
    start_date: "1998-06-28T07:00:00.000Z",
    end_date: "1998-07-04T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 27,
    start_date: "1998-07-05T07:00:00.000Z",
    end_date: "1998-07-11T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 28,
    start_date: "1998-07-12T07:00:00.000Z",
    end_date: "1998-07-18T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 29,
    start_date: "1998-07-19T07:00:00.000Z",
    end_date: "1998-07-25T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 30,
    start_date: "1998-07-26T07:00:00.000Z",
    end_date: "1998-08-01T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 31,
    start_date: "1998-08-02T07:00:00.000Z",
    end_date: "1998-08-08T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 32,
    start_date: "1998-08-09T07:00:00.000Z",
    end_date: "1998-08-15T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 33,
    start_date: "1998-08-16T07:00:00.000Z",
    end_date: "1998-08-22T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 34,
    start_date: "1998-08-23T07:00:00.000Z",
    end_date: "1998-08-29T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 35,
    start_date: "1998-08-30T07:00:00.000Z",
    end_date: "1998-09-05T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 36,
    start_date: "1998-09-06T07:00:00.000Z",
    end_date: "1998-09-12T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 37,
    start_date: "1998-09-13T07:00:00.000Z",
    end_date: "1998-09-19T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 38,
    start_date: "1998-09-20T07:00:00.000Z",
    end_date: "1998-09-26T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 39,
    start_date: "1998-09-27T07:00:00.000Z",
    end_date: "1998-10-03T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 40,
    start_date: "1998-10-04T07:00:00.000Z",
    end_date: "1998-10-10T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 41,
    start_date: "1998-10-11T07:00:00.000Z",
    end_date: "1998-10-17T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 42,
    start_date: "1998-10-18T07:00:00.000Z",
    end_date: "1998-10-24T07:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 43,
    start_date: "1998-10-25T07:00:00.000Z",
    end_date: "1998-10-31T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 44,
    start_date: "1998-11-01T08:00:00.000Z",
    end_date: "1998-11-07T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 45,
    start_date: "1998-11-08T08:00:00.000Z",
    end_date: "1998-11-14T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 46,
    start_date: "1998-11-15T08:00:00.000Z",
    end_date: "1998-11-21T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 47,
    start_date: "1998-11-22T08:00:00.000Z",
    end_date: "1998-11-28T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 48,
    start_date: "1998-11-29T08:00:00.000Z",
    end_date: "1998-12-05T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 49,
    start_date: "1998-12-06T08:00:00.000Z",
    end_date: "1998-12-12T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 50,
    start_date: "1998-12-13T08:00:00.000Z",
    end_date: "1998-12-19T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 51,
    start_date: "1998-12-20T08:00:00.000Z",
    end_date: "1998-12-26T08:00:00.000Z",
  },
  {
    disease_year: 1998,
    disease_week: 52,
    start_date: "1998-12-27T08:00:00.000Z",
    end_date: "1999-01-02T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 1,
    start_date: "1999-01-03T08:00:00.000Z",
    end_date: "1999-01-09T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 2,
    start_date: "1999-01-10T08:00:00.000Z",
    end_date: "1999-01-16T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 3,
    start_date: "1999-01-17T08:00:00.000Z",
    end_date: "1999-01-23T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 4,
    start_date: "1999-01-24T08:00:00.000Z",
    end_date: "1999-01-30T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 5,
    start_date: "1999-01-31T08:00:00.000Z",
    end_date: "1999-02-06T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 6,
    start_date: "1999-02-07T08:00:00.000Z",
    end_date: "1999-02-13T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 7,
    start_date: "1999-02-14T08:00:00.000Z",
    end_date: "1999-02-20T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 8,
    start_date: "1999-02-21T08:00:00.000Z",
    end_date: "1999-02-27T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 9,
    start_date: "1999-02-28T08:00:00.000Z",
    end_date: "1999-03-06T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 10,
    start_date: "1999-03-07T08:00:00.000Z",
    end_date: "1999-03-13T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 11,
    start_date: "1999-03-14T08:00:00.000Z",
    end_date: "1999-03-20T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 12,
    start_date: "1999-03-21T08:00:00.000Z",
    end_date: "1999-03-27T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 13,
    start_date: "1999-03-28T08:00:00.000Z",
    end_date: "1999-04-03T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 14,
    start_date: "1999-04-04T08:00:00.000Z",
    end_date: "1999-04-10T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 15,
    start_date: "1999-04-11T07:00:00.000Z",
    end_date: "1999-04-17T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 16,
    start_date: "1999-04-18T07:00:00.000Z",
    end_date: "1999-04-24T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 17,
    start_date: "1999-04-25T07:00:00.000Z",
    end_date: "1999-05-01T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 18,
    start_date: "1999-05-02T07:00:00.000Z",
    end_date: "1999-05-08T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 19,
    start_date: "1999-05-09T07:00:00.000Z",
    end_date: "1999-05-15T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 20,
    start_date: "1999-05-16T07:00:00.000Z",
    end_date: "1999-05-22T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 21,
    start_date: "1999-05-23T07:00:00.000Z",
    end_date: "1999-05-29T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 22,
    start_date: "1999-05-30T07:00:00.000Z",
    end_date: "1999-06-05T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 23,
    start_date: "1999-06-06T07:00:00.000Z",
    end_date: "1999-06-12T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 24,
    start_date: "1999-06-13T07:00:00.000Z",
    end_date: "1999-06-19T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 25,
    start_date: "1999-06-20T07:00:00.000Z",
    end_date: "1999-06-26T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 26,
    start_date: "1999-06-27T07:00:00.000Z",
    end_date: "1999-07-03T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 27,
    start_date: "1999-07-04T07:00:00.000Z",
    end_date: "1999-07-10T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 28,
    start_date: "1999-07-11T07:00:00.000Z",
    end_date: "1999-07-17T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 29,
    start_date: "1999-07-18T07:00:00.000Z",
    end_date: "1999-07-24T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 30,
    start_date: "1999-07-25T07:00:00.000Z",
    end_date: "1999-07-31T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 31,
    start_date: "1999-08-01T07:00:00.000Z",
    end_date: "1999-08-07T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 32,
    start_date: "1999-08-08T07:00:00.000Z",
    end_date: "1999-08-14T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 33,
    start_date: "1999-08-15T07:00:00.000Z",
    end_date: "1999-08-21T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 34,
    start_date: "1999-08-22T07:00:00.000Z",
    end_date: "1999-08-28T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 35,
    start_date: "1999-08-29T07:00:00.000Z",
    end_date: "1999-09-04T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 36,
    start_date: "1999-09-05T07:00:00.000Z",
    end_date: "1999-09-11T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 37,
    start_date: "1999-09-12T07:00:00.000Z",
    end_date: "1999-09-18T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 38,
    start_date: "1999-09-19T07:00:00.000Z",
    end_date: "1999-09-25T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 39,
    start_date: "1999-09-26T07:00:00.000Z",
    end_date: "1999-10-02T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 40,
    start_date: "1999-10-03T07:00:00.000Z",
    end_date: "1999-10-09T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 41,
    start_date: "1999-10-10T07:00:00.000Z",
    end_date: "1999-10-16T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 42,
    start_date: "1999-10-17T07:00:00.000Z",
    end_date: "1999-10-23T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 43,
    start_date: "1999-10-24T07:00:00.000Z",
    end_date: "1999-10-30T07:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 44,
    start_date: "1999-10-31T07:00:00.000Z",
    end_date: "1999-11-06T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 45,
    start_date: "1999-11-07T08:00:00.000Z",
    end_date: "1999-11-13T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 46,
    start_date: "1999-11-14T08:00:00.000Z",
    end_date: "1999-11-20T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 47,
    start_date: "1999-11-21T08:00:00.000Z",
    end_date: "1999-11-27T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 48,
    start_date: "1999-11-28T08:00:00.000Z",
    end_date: "1999-12-04T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 49,
    start_date: "1999-12-05T08:00:00.000Z",
    end_date: "1999-12-11T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 50,
    start_date: "1999-12-12T08:00:00.000Z",
    end_date: "1999-12-18T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 51,
    start_date: "1999-12-19T08:00:00.000Z",
    end_date: "1999-12-25T08:00:00.000Z",
  },
  {
    disease_year: 1999,
    disease_week: 52,
    start_date: "1999-12-26T08:00:00.000Z",
    end_date: "2000-01-01T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 1,
    start_date: "2000-01-02T08:00:00.000Z",
    end_date: "2000-01-08T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 2,
    start_date: "2000-01-09T08:00:00.000Z",
    end_date: "2000-01-15T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 3,
    start_date: "2000-01-16T08:00:00.000Z",
    end_date: "2000-01-22T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 4,
    start_date: "2000-01-23T08:00:00.000Z",
    end_date: "2000-01-29T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 5,
    start_date: "2000-01-30T08:00:00.000Z",
    end_date: "2000-02-05T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 6,
    start_date: "2000-02-06T08:00:00.000Z",
    end_date: "2000-02-12T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 7,
    start_date: "2000-02-13T08:00:00.000Z",
    end_date: "2000-02-19T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 8,
    start_date: "2000-02-20T08:00:00.000Z",
    end_date: "2000-02-26T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 9,
    start_date: "2000-02-27T08:00:00.000Z",
    end_date: "2000-03-04T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 10,
    start_date: "2000-03-05T08:00:00.000Z",
    end_date: "2000-03-11T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 11,
    start_date: "2000-03-12T08:00:00.000Z",
    end_date: "2000-03-18T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 12,
    start_date: "2000-03-19T08:00:00.000Z",
    end_date: "2000-03-25T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 13,
    start_date: "2000-03-26T08:00:00.000Z",
    end_date: "2000-04-01T08:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 14,
    start_date: "2000-04-02T08:00:00.000Z",
    end_date: "2000-04-08T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 15,
    start_date: "2000-04-09T07:00:00.000Z",
    end_date: "2000-04-15T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 16,
    start_date: "2000-04-16T07:00:00.000Z",
    end_date: "2000-04-22T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 17,
    start_date: "2000-04-23T07:00:00.000Z",
    end_date: "2000-04-29T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 18,
    start_date: "2000-04-30T07:00:00.000Z",
    end_date: "2000-05-06T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 19,
    start_date: "2000-05-07T07:00:00.000Z",
    end_date: "2000-05-13T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 20,
    start_date: "2000-05-14T07:00:00.000Z",
    end_date: "2000-05-20T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 21,
    start_date: "2000-05-21T07:00:00.000Z",
    end_date: "2000-05-27T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 22,
    start_date: "2000-05-28T07:00:00.000Z",
    end_date: "2000-06-03T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 23,
    start_date: "2000-06-04T07:00:00.000Z",
    end_date: "2000-06-10T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 24,
    start_date: "2000-06-11T07:00:00.000Z",
    end_date: "2000-06-17T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 25,
    start_date: "2000-06-18T07:00:00.000Z",
    end_date: "2000-06-24T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 26,
    start_date: "2000-06-25T07:00:00.000Z",
    end_date: "2000-07-01T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 27,
    start_date: "2000-07-02T07:00:00.000Z",
    end_date: "2000-07-08T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 28,
    start_date: "2000-07-09T07:00:00.000Z",
    end_date: "2000-07-15T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 29,
    start_date: "2000-07-16T07:00:00.000Z",
    end_date: "2000-07-22T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 30,
    start_date: "2000-07-23T07:00:00.000Z",
    end_date: "2000-07-29T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 31,
    start_date: "2000-07-30T07:00:00.000Z",
    end_date: "2000-08-05T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 32,
    start_date: "2000-08-06T07:00:00.000Z",
    end_date: "2000-08-12T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 33,
    start_date: "2000-08-13T07:00:00.000Z",
    end_date: "2000-08-19T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 34,
    start_date: "2000-08-20T07:00:00.000Z",
    end_date: "2000-08-26T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 35,
    start_date: "2000-08-27T07:00:00.000Z",
    end_date: "2000-09-02T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 36,
    start_date: "2000-09-03T07:00:00.000Z",
    end_date: "2000-09-09T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 37,
    start_date: "2000-09-10T07:00:00.000Z",
    end_date: "2000-09-16T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 38,
    start_date: "2000-09-17T07:00:00.000Z",
    end_date: "2000-09-23T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 39,
    start_date: "2000-09-24T07:00:00.000Z",
    end_date: "2000-09-30T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 40,
    start_date: "2000-10-01T07:00:00.000Z",
    end_date: "2000-10-07T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 41,
    start_date: "2000-10-08T07:00:00.000Z",
    end_date: "2000-10-14T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 42,
    start_date: "2000-10-15T07:00:00.000Z",
    end_date: "2000-10-21T07:00:00.000Z",
  },
  {
    disease_year: 2000,
    disease_week: 43,
    start_date: "2000-10-22T07:00:00.000Z",
    end_date: "2000-10-28T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 35,
    start_date: "2005-08-28T07:00:00.000Z",
    end_date: "2005-09-03T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 36,
    start_date: "2005-09-04T07:00:00.000Z",
    end_date: "2005-09-10T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 37,
    start_date: "2005-09-11T07:00:00.000Z",
    end_date: "2005-09-17T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 38,
    start_date: "2005-09-18T07:00:00.000Z",
    end_date: "2005-09-24T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 39,
    start_date: "2005-09-25T07:00:00.000Z",
    end_date: "2005-10-01T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 40,
    start_date: "2005-10-02T07:00:00.000Z",
    end_date: "2005-10-08T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 41,
    start_date: "2005-10-09T07:00:00.000Z",
    end_date: "2005-10-15T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 42,
    start_date: "2005-10-16T07:00:00.000Z",
    end_date: "2005-10-22T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 43,
    start_date: "2005-10-23T07:00:00.000Z",
    end_date: "2005-10-29T07:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 44,
    start_date: "2005-10-30T07:00:00.000Z",
    end_date: "2005-11-05T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 45,
    start_date: "2005-11-06T08:00:00.000Z",
    end_date: "2005-11-12T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 46,
    start_date: "2005-11-13T08:00:00.000Z",
    end_date: "2005-11-19T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 47,
    start_date: "2005-11-20T08:00:00.000Z",
    end_date: "2005-11-26T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 48,
    start_date: "2005-11-27T08:00:00.000Z",
    end_date: "2005-12-03T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 49,
    start_date: "2005-12-04T08:00:00.000Z",
    end_date: "2005-12-10T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 50,
    start_date: "2005-12-11T08:00:00.000Z",
    end_date: "2005-12-17T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 51,
    start_date: "2005-12-18T08:00:00.000Z",
    end_date: "2005-12-24T08:00:00.000Z",
  },
  {
    disease_year: 2005,
    disease_week: 52,
    start_date: "2005-12-25T08:00:00.000Z",
    end_date: "2005-12-31T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 1,
    start_date: "2009-01-04T08:00:00.000Z",
    end_date: "2009-01-10T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 2,
    start_date: "2009-01-11T08:00:00.000Z",
    end_date: "2009-01-17T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 3,
    start_date: "2009-01-18T08:00:00.000Z",
    end_date: "2009-01-24T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 4,
    start_date: "2009-01-25T08:00:00.000Z",
    end_date: "2009-01-31T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 5,
    start_date: "2009-02-01T08:00:00.000Z",
    end_date: "2009-02-07T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 6,
    start_date: "2009-02-08T08:00:00.000Z",
    end_date: "2009-02-14T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 7,
    start_date: "2009-02-15T08:00:00.000Z",
    end_date: "2009-02-21T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 8,
    start_date: "2009-02-22T08:00:00.000Z",
    end_date: "2009-02-28T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 9,
    start_date: "2009-03-01T08:00:00.000Z",
    end_date: "2009-03-07T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 10,
    start_date: "2009-03-08T08:00:00.000Z",
    end_date: "2009-03-14T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 11,
    start_date: "2009-03-15T07:00:00.000Z",
    end_date: "2009-03-21T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 12,
    start_date: "2009-03-22T07:00:00.000Z",
    end_date: "2009-03-28T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 13,
    start_date: "2009-03-29T07:00:00.000Z",
    end_date: "2009-04-04T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 14,
    start_date: "2009-04-05T07:00:00.000Z",
    end_date: "2009-04-11T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 15,
    start_date: "2009-04-12T07:00:00.000Z",
    end_date: "2009-04-18T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 16,
    start_date: "2009-04-19T07:00:00.000Z",
    end_date: "2009-04-25T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 17,
    start_date: "2009-04-26T07:00:00.000Z",
    end_date: "2009-05-02T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 18,
    start_date: "2009-05-03T07:00:00.000Z",
    end_date: "2009-05-09T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 19,
    start_date: "2009-05-10T07:00:00.000Z",
    end_date: "2009-05-16T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 20,
    start_date: "2009-05-17T07:00:00.000Z",
    end_date: "2009-05-23T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 21,
    start_date: "2009-05-24T07:00:00.000Z",
    end_date: "2009-05-30T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 22,
    start_date: "2009-05-31T07:00:00.000Z",
    end_date: "2009-06-06T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 23,
    start_date: "2009-06-07T07:00:00.000Z",
    end_date: "2009-06-13T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 24,
    start_date: "2009-06-14T07:00:00.000Z",
    end_date: "2009-06-20T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 25,
    start_date: "2009-06-21T07:00:00.000Z",
    end_date: "2009-06-27T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 26,
    start_date: "2009-06-28T07:00:00.000Z",
    end_date: "2009-07-04T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 27,
    start_date: "2009-07-05T07:00:00.000Z",
    end_date: "2009-07-11T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 28,
    start_date: "2009-07-12T07:00:00.000Z",
    end_date: "2009-07-18T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 29,
    start_date: "2009-07-19T07:00:00.000Z",
    end_date: "2009-07-25T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 30,
    start_date: "2009-07-26T07:00:00.000Z",
    end_date: "2009-08-01T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 31,
    start_date: "2009-08-02T07:00:00.000Z",
    end_date: "2009-08-08T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 32,
    start_date: "2009-08-09T07:00:00.000Z",
    end_date: "2009-08-15T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 33,
    start_date: "2009-08-16T07:00:00.000Z",
    end_date: "2009-08-22T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 34,
    start_date: "2009-08-23T07:00:00.000Z",
    end_date: "2009-08-29T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 35,
    start_date: "2009-08-30T07:00:00.000Z",
    end_date: "2009-09-05T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 36,
    start_date: "2009-09-06T07:00:00.000Z",
    end_date: "2009-09-12T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 37,
    start_date: "2009-09-13T07:00:00.000Z",
    end_date: "2009-09-19T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 38,
    start_date: "2009-09-20T07:00:00.000Z",
    end_date: "2009-09-26T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 39,
    start_date: "2009-09-27T07:00:00.000Z",
    end_date: "2009-10-03T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 40,
    start_date: "2009-10-04T07:00:00.000Z",
    end_date: "2009-10-10T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 41,
    start_date: "2009-10-11T07:00:00.000Z",
    end_date: "2009-10-17T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 42,
    start_date: "2009-10-18T07:00:00.000Z",
    end_date: "2009-10-24T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 43,
    start_date: "2009-10-25T07:00:00.000Z",
    end_date: "2009-10-31T07:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 44,
    start_date: "2009-11-01T07:00:00.000Z",
    end_date: "2009-11-07T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 45,
    start_date: "2009-11-08T08:00:00.000Z",
    end_date: "2009-11-14T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 46,
    start_date: "2009-11-15T08:00:00.000Z",
    end_date: "2009-11-21T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 47,
    start_date: "2009-11-22T08:00:00.000Z",
    end_date: "2009-11-28T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 48,
    start_date: "2009-11-29T08:00:00.000Z",
    end_date: "2009-12-05T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 49,
    start_date: "2009-12-06T08:00:00.000Z",
    end_date: "2009-12-12T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 50,
    start_date: "2009-12-13T08:00:00.000Z",
    end_date: "2009-12-19T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 51,
    start_date: "2009-12-20T08:00:00.000Z",
    end_date: "2009-12-26T08:00:00.000Z",
  },
  {
    disease_year: 2009,
    disease_week: 52,
    start_date: "2009-12-27T08:00:00.000Z",
    end_date: "2010-01-02T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 1,
    start_date: "2010-01-03T08:00:00.000Z",
    end_date: "2010-01-09T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 2,
    start_date: "2010-01-10T08:00:00.000Z",
    end_date: "2010-01-16T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 3,
    start_date: "2010-01-17T08:00:00.000Z",
    end_date: "2010-01-23T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 4,
    start_date: "2010-01-24T08:00:00.000Z",
    end_date: "2010-01-30T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 5,
    start_date: "2010-01-31T08:00:00.000Z",
    end_date: "2010-02-06T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 6,
    start_date: "2010-02-07T08:00:00.000Z",
    end_date: "2010-02-13T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 7,
    start_date: "2010-02-14T08:00:00.000Z",
    end_date: "2010-02-20T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 8,
    start_date: "2010-02-21T08:00:00.000Z",
    end_date: "2010-02-27T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 9,
    start_date: "2010-02-28T08:00:00.000Z",
    end_date: "2010-03-06T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 10,
    start_date: "2010-03-07T08:00:00.000Z",
    end_date: "2010-03-13T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 11,
    start_date: "2010-03-14T08:00:00.000Z",
    end_date: "2010-03-20T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 12,
    start_date: "2010-03-21T07:00:00.000Z",
    end_date: "2010-03-27T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 13,
    start_date: "2010-03-28T07:00:00.000Z",
    end_date: "2010-04-03T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 14,
    start_date: "2010-04-04T07:00:00.000Z",
    end_date: "2010-04-10T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 15,
    start_date: "2010-04-11T07:00:00.000Z",
    end_date: "2010-04-17T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 16,
    start_date: "2010-04-18T07:00:00.000Z",
    end_date: "2010-04-24T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 17,
    start_date: "2010-04-25T07:00:00.000Z",
    end_date: "2010-05-01T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 18,
    start_date: "2010-05-02T07:00:00.000Z",
    end_date: "2010-05-08T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 19,
    start_date: "2010-05-09T07:00:00.000Z",
    end_date: "2010-05-15T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 20,
    start_date: "2010-05-16T07:00:00.000Z",
    end_date: "2010-05-22T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 21,
    start_date: "2010-05-23T07:00:00.000Z",
    end_date: "2010-05-29T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 22,
    start_date: "2010-05-30T07:00:00.000Z",
    end_date: "2010-06-05T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 23,
    start_date: "2010-06-06T07:00:00.000Z",
    end_date: "2010-06-12T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 24,
    start_date: "2010-06-13T07:00:00.000Z",
    end_date: "2010-06-19T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 25,
    start_date: "2010-06-20T07:00:00.000Z",
    end_date: "2010-06-26T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 26,
    start_date: "2010-06-27T07:00:00.000Z",
    end_date: "2010-07-03T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 27,
    start_date: "2010-07-04T07:00:00.000Z",
    end_date: "2010-07-10T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 28,
    start_date: "2010-07-11T07:00:00.000Z",
    end_date: "2010-07-17T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 29,
    start_date: "2010-07-18T07:00:00.000Z",
    end_date: "2010-07-24T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 30,
    start_date: "2010-07-25T07:00:00.000Z",
    end_date: "2010-07-31T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 31,
    start_date: "2010-08-01T07:00:00.000Z",
    end_date: "2010-08-07T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 32,
    start_date: "2010-08-08T07:00:00.000Z",
    end_date: "2010-08-14T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 33,
    start_date: "2010-08-15T07:00:00.000Z",
    end_date: "2010-08-21T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 34,
    start_date: "2010-08-22T07:00:00.000Z",
    end_date: "2010-08-28T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 35,
    start_date: "2010-08-29T07:00:00.000Z",
    end_date: "2010-09-04T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 36,
    start_date: "2010-09-05T07:00:00.000Z",
    end_date: "2010-09-11T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 37,
    start_date: "2010-09-12T07:00:00.000Z",
    end_date: "2010-09-18T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 38,
    start_date: "2010-09-19T07:00:00.000Z",
    end_date: "2010-09-25T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 39,
    start_date: "2010-09-26T07:00:00.000Z",
    end_date: "2010-10-02T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 40,
    start_date: "2010-10-03T07:00:00.000Z",
    end_date: "2010-10-09T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 41,
    start_date: "2010-10-10T07:00:00.000Z",
    end_date: "2010-10-16T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 42,
    start_date: "2010-10-17T07:00:00.000Z",
    end_date: "2010-10-23T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 43,
    start_date: "2010-10-24T07:00:00.000Z",
    end_date: "2010-10-30T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 44,
    start_date: "2010-10-31T07:00:00.000Z",
    end_date: "2010-11-06T07:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 45,
    start_date: "2010-11-07T07:00:00.000Z",
    end_date: "2010-11-13T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 46,
    start_date: "2010-11-14T08:00:00.000Z",
    end_date: "2010-11-20T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 47,
    start_date: "2010-11-21T08:00:00.000Z",
    end_date: "2010-11-27T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 48,
    start_date: "2010-11-28T08:00:00.000Z",
    end_date: "2010-12-04T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 49,
    start_date: "2010-12-05T08:00:00.000Z",
    end_date: "2010-12-11T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 50,
    start_date: "2010-12-12T08:00:00.000Z",
    end_date: "2010-12-18T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 51,
    start_date: "2010-12-19T08:00:00.000Z",
    end_date: "2010-12-25T08:00:00.000Z",
  },
  {
    disease_year: 2010,
    disease_week: 52,
    start_date: "2010-12-26T08:00:00.000Z",
    end_date: "2011-01-01T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 1,
    start_date: "2011-01-02T08:00:00.000Z",
    end_date: "2011-01-08T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 2,
    start_date: "2011-01-09T08:00:00.000Z",
    end_date: "2011-01-15T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 3,
    start_date: "2011-01-16T08:00:00.000Z",
    end_date: "2011-01-22T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 4,
    start_date: "2011-01-23T08:00:00.000Z",
    end_date: "2011-01-29T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 5,
    start_date: "2011-01-30T08:00:00.000Z",
    end_date: "2011-02-05T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 6,
    start_date: "2011-02-06T08:00:00.000Z",
    end_date: "2011-02-12T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 7,
    start_date: "2011-02-13T08:00:00.000Z",
    end_date: "2011-02-19T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 8,
    start_date: "2011-02-20T08:00:00.000Z",
    end_date: "2011-02-26T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 9,
    start_date: "2011-02-27T08:00:00.000Z",
    end_date: "2011-03-05T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 10,
    start_date: "2011-03-06T08:00:00.000Z",
    end_date: "2011-03-12T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 11,
    start_date: "2011-03-13T08:00:00.000Z",
    end_date: "2011-03-19T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 12,
    start_date: "2011-03-20T07:00:00.000Z",
    end_date: "2011-03-26T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 13,
    start_date: "2011-03-27T07:00:00.000Z",
    end_date: "2011-04-02T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 14,
    start_date: "2011-04-03T07:00:00.000Z",
    end_date: "2011-04-09T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 15,
    start_date: "2011-04-10T07:00:00.000Z",
    end_date: "2011-04-16T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 16,
    start_date: "2011-04-17T07:00:00.000Z",
    end_date: "2011-04-23T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 17,
    start_date: "2011-04-24T07:00:00.000Z",
    end_date: "2011-04-30T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 18,
    start_date: "2011-05-01T07:00:00.000Z",
    end_date: "2011-05-07T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 19,
    start_date: "2011-05-08T07:00:00.000Z",
    end_date: "2011-05-14T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 20,
    start_date: "2011-05-15T07:00:00.000Z",
    end_date: "2011-05-21T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 21,
    start_date: "2011-05-22T07:00:00.000Z",
    end_date: "2011-05-28T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 22,
    start_date: "2011-05-29T07:00:00.000Z",
    end_date: "2011-06-04T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 23,
    start_date: "2011-06-05T07:00:00.000Z",
    end_date: "2011-06-11T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 24,
    start_date: "2011-06-12T07:00:00.000Z",
    end_date: "2011-06-18T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 25,
    start_date: "2011-06-19T07:00:00.000Z",
    end_date: "2011-06-25T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 26,
    start_date: "2011-06-26T07:00:00.000Z",
    end_date: "2011-07-02T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 27,
    start_date: "2011-07-03T07:00:00.000Z",
    end_date: "2011-07-09T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 28,
    start_date: "2011-07-10T07:00:00.000Z",
    end_date: "2011-07-16T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 29,
    start_date: "2011-07-17T07:00:00.000Z",
    end_date: "2011-07-23T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 30,
    start_date: "2011-07-24T07:00:00.000Z",
    end_date: "2011-07-30T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 31,
    start_date: "2011-07-31T07:00:00.000Z",
    end_date: "2011-08-06T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 32,
    start_date: "2011-08-07T07:00:00.000Z",
    end_date: "2011-08-13T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 33,
    start_date: "2011-08-14T07:00:00.000Z",
    end_date: "2011-08-20T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 34,
    start_date: "2011-08-21T07:00:00.000Z",
    end_date: "2011-08-27T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 35,
    start_date: "2011-08-28T07:00:00.000Z",
    end_date: "2011-09-03T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 36,
    start_date: "2011-09-04T07:00:00.000Z",
    end_date: "2011-09-10T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 37,
    start_date: "2011-09-11T07:00:00.000Z",
    end_date: "2011-09-17T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 38,
    start_date: "2011-09-18T07:00:00.000Z",
    end_date: "2011-09-24T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 39,
    start_date: "2011-09-25T07:00:00.000Z",
    end_date: "2011-10-01T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 40,
    start_date: "2011-10-02T07:00:00.000Z",
    end_date: "2011-10-08T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 41,
    start_date: "2011-10-09T07:00:00.000Z",
    end_date: "2011-10-15T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 42,
    start_date: "2011-10-16T07:00:00.000Z",
    end_date: "2011-10-22T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 43,
    start_date: "2011-10-23T07:00:00.000Z",
    end_date: "2011-10-29T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 44,
    start_date: "2011-10-30T07:00:00.000Z",
    end_date: "2011-11-05T07:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 45,
    start_date: "2011-11-06T07:00:00.000Z",
    end_date: "2011-11-12T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 46,
    start_date: "2011-11-13T08:00:00.000Z",
    end_date: "2011-11-19T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 47,
    start_date: "2011-11-20T08:00:00.000Z",
    end_date: "2011-11-26T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 48,
    start_date: "2011-11-27T08:00:00.000Z",
    end_date: "2011-12-03T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 49,
    start_date: "2011-12-04T08:00:00.000Z",
    end_date: "2011-12-10T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 50,
    start_date: "2011-12-11T08:00:00.000Z",
    end_date: "2011-12-17T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 51,
    start_date: "2011-12-18T08:00:00.000Z",
    end_date: "2011-12-24T08:00:00.000Z",
  },
  {
    disease_year: 2011,
    disease_week: 52,
    start_date: "2011-12-25T08:00:00.000Z",
    end_date: "2011-12-31T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 1,
    start_date: "2015-01-04T08:00:00.000Z",
    end_date: "2015-01-10T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 2,
    start_date: "2015-01-11T08:00:00.000Z",
    end_date: "2015-01-17T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 3,
    start_date: "2015-01-18T08:00:00.000Z",
    end_date: "2015-01-24T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 4,
    start_date: "2015-01-25T08:00:00.000Z",
    end_date: "2015-01-31T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 5,
    start_date: "2015-02-01T08:00:00.000Z",
    end_date: "2015-02-07T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 6,
    start_date: "2015-02-08T08:00:00.000Z",
    end_date: "2015-02-14T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 7,
    start_date: "2015-02-15T08:00:00.000Z",
    end_date: "2015-02-21T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 8,
    start_date: "2015-02-22T08:00:00.000Z",
    end_date: "2015-02-28T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 9,
    start_date: "2015-03-01T08:00:00.000Z",
    end_date: "2015-03-07T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 10,
    start_date: "2015-03-08T08:00:00.000Z",
    end_date: "2015-03-14T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 11,
    start_date: "2015-03-15T07:00:00.000Z",
    end_date: "2015-03-21T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 12,
    start_date: "2015-03-22T07:00:00.000Z",
    end_date: "2015-03-28T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 13,
    start_date: "2015-03-29T07:00:00.000Z",
    end_date: "2015-04-04T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 14,
    start_date: "2015-04-05T07:00:00.000Z",
    end_date: "2015-04-11T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 15,
    start_date: "2015-04-12T07:00:00.000Z",
    end_date: "2015-04-18T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 16,
    start_date: "2015-04-19T07:00:00.000Z",
    end_date: "2015-04-25T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 17,
    start_date: "2015-04-26T07:00:00.000Z",
    end_date: "2015-05-02T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 18,
    start_date: "2015-05-03T07:00:00.000Z",
    end_date: "2015-05-09T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 19,
    start_date: "2015-05-10T07:00:00.000Z",
    end_date: "2015-05-16T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 20,
    start_date: "2015-05-17T07:00:00.000Z",
    end_date: "2015-05-23T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 21,
    start_date: "2015-05-24T07:00:00.000Z",
    end_date: "2015-05-30T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 22,
    start_date: "2015-05-31T07:00:00.000Z",
    end_date: "2015-06-06T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 23,
    start_date: "2015-06-07T07:00:00.000Z",
    end_date: "2015-06-13T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 24,
    start_date: "2015-06-14T07:00:00.000Z",
    end_date: "2015-06-20T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 25,
    start_date: "2015-06-21T07:00:00.000Z",
    end_date: "2015-06-27T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 26,
    start_date: "2015-06-28T07:00:00.000Z",
    end_date: "2015-07-04T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 27,
    start_date: "2015-07-05T07:00:00.000Z",
    end_date: "2015-07-11T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 28,
    start_date: "2015-07-12T07:00:00.000Z",
    end_date: "2015-07-18T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 29,
    start_date: "2015-07-19T07:00:00.000Z",
    end_date: "2015-07-25T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 30,
    start_date: "2015-07-26T07:00:00.000Z",
    end_date: "2015-08-01T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 31,
    start_date: "2015-08-02T07:00:00.000Z",
    end_date: "2015-08-08T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 32,
    start_date: "2015-08-09T07:00:00.000Z",
    end_date: "2015-08-15T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 33,
    start_date: "2015-08-16T07:00:00.000Z",
    end_date: "2015-08-22T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 34,
    start_date: "2015-08-23T07:00:00.000Z",
    end_date: "2015-08-29T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 35,
    start_date: "2015-08-30T07:00:00.000Z",
    end_date: "2015-09-05T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 36,
    start_date: "2015-09-06T07:00:00.000Z",
    end_date: "2015-09-12T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 37,
    start_date: "2015-09-13T07:00:00.000Z",
    end_date: "2015-09-19T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 38,
    start_date: "2015-09-20T07:00:00.000Z",
    end_date: "2015-09-26T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 39,
    start_date: "2015-09-27T07:00:00.000Z",
    end_date: "2015-10-03T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 40,
    start_date: "2015-10-04T07:00:00.000Z",
    end_date: "2015-10-10T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 41,
    start_date: "2015-10-11T07:00:00.000Z",
    end_date: "2015-10-17T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 42,
    start_date: "2015-10-18T07:00:00.000Z",
    end_date: "2015-10-24T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 43,
    start_date: "2015-10-25T07:00:00.000Z",
    end_date: "2015-10-31T07:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 44,
    start_date: "2015-11-01T07:00:00.000Z",
    end_date: "2015-11-07T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 45,
    start_date: "2015-11-08T08:00:00.000Z",
    end_date: "2015-11-14T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 46,
    start_date: "2015-11-15T08:00:00.000Z",
    end_date: "2015-11-21T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 47,
    start_date: "2015-11-22T08:00:00.000Z",
    end_date: "2015-11-28T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 48,
    start_date: "2015-11-29T08:00:00.000Z",
    end_date: "2015-12-05T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 49,
    start_date: "2015-12-06T08:00:00.000Z",
    end_date: "2015-12-12T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 50,
    start_date: "2015-12-13T08:00:00.000Z",
    end_date: "2015-12-19T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 51,
    start_date: "2015-12-20T08:00:00.000Z",
    end_date: "2015-12-26T08:00:00.000Z",
  },
  {
    disease_year: 2015,
    disease_week: 52,
    start_date: "2015-12-27T08:00:00.000Z",
    end_date: "2016-01-02T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 1,
    start_date: "2016-01-03T08:00:00.000Z",
    end_date: "2016-01-09T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 2,
    start_date: "2016-01-10T08:00:00.000Z",
    end_date: "2016-01-16T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 3,
    start_date: "2016-01-17T08:00:00.000Z",
    end_date: "2016-01-23T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 4,
    start_date: "2016-01-24T08:00:00.000Z",
    end_date: "2016-01-30T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 5,
    start_date: "2016-01-31T08:00:00.000Z",
    end_date: "2016-02-06T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 6,
    start_date: "2016-02-07T08:00:00.000Z",
    end_date: "2016-02-13T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 7,
    start_date: "2016-02-14T08:00:00.000Z",
    end_date: "2016-02-20T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 8,
    start_date: "2016-02-21T08:00:00.000Z",
    end_date: "2016-02-27T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 9,
    start_date: "2016-02-28T08:00:00.000Z",
    end_date: "2016-03-05T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 10,
    start_date: "2016-03-06T08:00:00.000Z",
    end_date: "2016-03-12T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 11,
    start_date: "2016-03-13T08:00:00.000Z",
    end_date: "2016-03-19T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 12,
    start_date: "2016-03-20T07:00:00.000Z",
    end_date: "2016-03-26T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 13,
    start_date: "2016-03-27T07:00:00.000Z",
    end_date: "2016-04-02T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 14,
    start_date: "2016-04-03T07:00:00.000Z",
    end_date: "2016-04-09T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 15,
    start_date: "2016-04-10T07:00:00.000Z",
    end_date: "2016-04-16T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 16,
    start_date: "2016-04-17T07:00:00.000Z",
    end_date: "2016-04-23T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 17,
    start_date: "2016-04-24T07:00:00.000Z",
    end_date: "2016-04-30T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 18,
    start_date: "2016-05-01T07:00:00.000Z",
    end_date: "2016-05-07T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 19,
    start_date: "2016-05-08T07:00:00.000Z",
    end_date: "2016-05-14T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 20,
    start_date: "2016-05-15T07:00:00.000Z",
    end_date: "2016-05-21T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 21,
    start_date: "2016-05-22T07:00:00.000Z",
    end_date: "2016-05-28T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 22,
    start_date: "2016-05-29T07:00:00.000Z",
    end_date: "2016-06-04T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 23,
    start_date: "2016-06-05T07:00:00.000Z",
    end_date: "2016-06-11T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 24,
    start_date: "2016-06-12T07:00:00.000Z",
    end_date: "2016-06-18T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 25,
    start_date: "2016-06-19T07:00:00.000Z",
    end_date: "2016-06-25T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 26,
    start_date: "2016-06-26T07:00:00.000Z",
    end_date: "2016-07-02T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 27,
    start_date: "2016-07-03T07:00:00.000Z",
    end_date: "2016-07-09T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 28,
    start_date: "2016-07-10T07:00:00.000Z",
    end_date: "2016-07-16T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 29,
    start_date: "2016-07-17T07:00:00.000Z",
    end_date: "2016-07-23T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 30,
    start_date: "2016-07-24T07:00:00.000Z",
    end_date: "2016-07-30T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 31,
    start_date: "2016-07-31T07:00:00.000Z",
    end_date: "2016-08-06T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 32,
    start_date: "2016-08-07T07:00:00.000Z",
    end_date: "2016-08-13T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 33,
    start_date: "2016-08-14T07:00:00.000Z",
    end_date: "2016-08-20T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 34,
    start_date: "2016-08-21T07:00:00.000Z",
    end_date: "2016-08-27T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 35,
    start_date: "2016-08-28T07:00:00.000Z",
    end_date: "2016-09-03T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 36,
    start_date: "2016-09-04T07:00:00.000Z",
    end_date: "2016-09-10T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 37,
    start_date: "2016-09-11T07:00:00.000Z",
    end_date: "2016-09-17T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 38,
    start_date: "2016-09-18T07:00:00.000Z",
    end_date: "2016-09-24T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 39,
    start_date: "2016-09-25T07:00:00.000Z",
    end_date: "2016-10-01T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 40,
    start_date: "2016-10-02T07:00:00.000Z",
    end_date: "2016-10-08T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 41,
    start_date: "2016-10-09T07:00:00.000Z",
    end_date: "2016-10-15T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 42,
    start_date: "2016-10-16T07:00:00.000Z",
    end_date: "2016-10-22T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 43,
    start_date: "2016-10-23T07:00:00.000Z",
    end_date: "2016-10-29T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 44,
    start_date: "2016-10-30T07:00:00.000Z",
    end_date: "2016-11-05T07:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 45,
    start_date: "2016-11-06T07:00:00.000Z",
    end_date: "2016-11-12T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 46,
    start_date: "2016-11-13T08:00:00.000Z",
    end_date: "2016-11-19T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 47,
    start_date: "2016-11-20T08:00:00.000Z",
    end_date: "2016-11-26T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 48,
    start_date: "2016-11-27T08:00:00.000Z",
    end_date: "2016-12-03T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 49,
    start_date: "2016-12-04T08:00:00.000Z",
    end_date: "2016-12-10T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 50,
    start_date: "2016-12-11T08:00:00.000Z",
    end_date: "2016-12-17T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 51,
    start_date: "2016-12-18T08:00:00.000Z",
    end_date: "2016-12-24T08:00:00.000Z",
  },
  {
    disease_year: 2016,
    disease_week: 52,
    start_date: "2016-12-25T08:00:00.000Z",
    end_date: "2016-12-31T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 1,
    start_date: "2021-01-03T08:00:00.000Z",
    end_date: "2021-01-09T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 2,
    start_date: "2021-01-10T08:00:00.000Z",
    end_date: "2021-01-16T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 3,
    start_date: "2021-01-17T08:00:00.000Z",
    end_date: "2021-01-23T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 4,
    start_date: "2021-01-24T08:00:00.000Z",
    end_date: "2021-01-30T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 5,
    start_date: "2021-01-31T08:00:00.000Z",
    end_date: "2021-02-06T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 6,
    start_date: "2021-02-07T08:00:00.000Z",
    end_date: "2021-02-13T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 7,
    start_date: "2021-02-14T08:00:00.000Z",
    end_date: "2021-02-20T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 8,
    start_date: "2021-02-21T08:00:00.000Z",
    end_date: "2021-02-27T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 9,
    start_date: "2021-02-28T08:00:00.000Z",
    end_date: "2021-03-06T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 10,
    start_date: "2021-03-07T08:00:00.000Z",
    end_date: "2021-03-13T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 11,
    start_date: "2021-03-14T08:00:00.000Z",
    end_date: "2021-03-20T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 12,
    start_date: "2021-03-21T07:00:00.000Z",
    end_date: "2021-03-27T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 13,
    start_date: "2021-03-28T07:00:00.000Z",
    end_date: "2021-04-03T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 14,
    start_date: "2021-04-04T07:00:00.000Z",
    end_date: "2021-04-10T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 15,
    start_date: "2021-04-11T07:00:00.000Z",
    end_date: "2021-04-17T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 16,
    start_date: "2021-04-18T07:00:00.000Z",
    end_date: "2021-04-24T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 17,
    start_date: "2021-04-25T07:00:00.000Z",
    end_date: "2021-05-01T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 18,
    start_date: "2021-05-02T07:00:00.000Z",
    end_date: "2021-05-08T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 19,
    start_date: "2021-05-09T07:00:00.000Z",
    end_date: "2021-05-15T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 20,
    start_date: "2021-05-16T07:00:00.000Z",
    end_date: "2021-05-22T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 21,
    start_date: "2021-05-23T07:00:00.000Z",
    end_date: "2021-05-29T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 22,
    start_date: "2021-05-30T07:00:00.000Z",
    end_date: "2021-06-05T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 23,
    start_date: "2021-06-06T07:00:00.000Z",
    end_date: "2021-06-12T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 24,
    start_date: "2021-06-13T07:00:00.000Z",
    end_date: "2021-06-19T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 25,
    start_date: "2021-06-20T07:00:00.000Z",
    end_date: "2021-06-26T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 26,
    start_date: "2021-06-27T07:00:00.000Z",
    end_date: "2021-07-03T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 27,
    start_date: "2021-07-04T07:00:00.000Z",
    end_date: "2021-07-10T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 28,
    start_date: "2021-07-11T07:00:00.000Z",
    end_date: "2021-07-17T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 29,
    start_date: "2021-07-18T07:00:00.000Z",
    end_date: "2021-07-24T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 30,
    start_date: "2021-07-25T07:00:00.000Z",
    end_date: "2021-07-31T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 31,
    start_date: "2021-08-01T07:00:00.000Z",
    end_date: "2021-08-07T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 32,
    start_date: "2021-08-08T07:00:00.000Z",
    end_date: "2021-08-14T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 33,
    start_date: "2021-08-15T07:00:00.000Z",
    end_date: "2021-08-21T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 34,
    start_date: "2021-08-22T07:00:00.000Z",
    end_date: "2021-08-28T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 35,
    start_date: "2021-08-29T07:00:00.000Z",
    end_date: "2021-09-04T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 36,
    start_date: "2021-09-05T07:00:00.000Z",
    end_date: "2021-09-11T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 37,
    start_date: "2021-09-12T07:00:00.000Z",
    end_date: "2021-09-18T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 38,
    start_date: "2021-09-19T07:00:00.000Z",
    end_date: "2021-09-25T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 39,
    start_date: "2021-09-26T07:00:00.000Z",
    end_date: "2021-10-02T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 40,
    start_date: "2021-10-03T07:00:00.000Z",
    end_date: "2021-10-09T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 41,
    start_date: "2021-10-10T07:00:00.000Z",
    end_date: "2021-10-16T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 42,
    start_date: "2021-10-17T07:00:00.000Z",
    end_date: "2021-10-23T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 43,
    start_date: "2021-10-24T07:00:00.000Z",
    end_date: "2021-10-30T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 44,
    start_date: "2021-10-31T07:00:00.000Z",
    end_date: "2021-11-06T07:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 45,
    start_date: "2021-11-07T07:00:00.000Z",
    end_date: "2021-11-13T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 46,
    start_date: "2021-11-14T08:00:00.000Z",
    end_date: "2021-11-20T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 47,
    start_date: "2021-11-21T08:00:00.000Z",
    end_date: "2021-11-27T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 48,
    start_date: "2021-11-28T08:00:00.000Z",
    end_date: "2021-12-04T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 49,
    start_date: "2021-12-05T08:00:00.000Z",
    end_date: "2021-12-11T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 50,
    start_date: "2021-12-12T08:00:00.000Z",
    end_date: "2021-12-18T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 51,
    start_date: "2021-12-19T08:00:00.000Z",
    end_date: "2021-12-25T08:00:00.000Z",
  },
  {
    disease_year: 2021,
    disease_week: 52,
    start_date: "2021-12-26T08:00:00.000Z",
    end_date: "2022-01-01T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 1,
    start_date: "2022-01-02T08:00:00.000Z",
    end_date: "2022-01-08T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 2,
    start_date: "2022-01-09T08:00:00.000Z",
    end_date: "2022-01-15T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 3,
    start_date: "2022-01-16T08:00:00.000Z",
    end_date: "2022-01-22T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 4,
    start_date: "2022-01-23T08:00:00.000Z",
    end_date: "2022-01-29T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 5,
    start_date: "2022-01-30T08:00:00.000Z",
    end_date: "2022-02-05T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 6,
    start_date: "2022-02-06T08:00:00.000Z",
    end_date: "2022-02-12T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 7,
    start_date: "2022-02-13T08:00:00.000Z",
    end_date: "2022-02-19T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 8,
    start_date: "2022-02-20T08:00:00.000Z",
    end_date: "2022-02-26T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 9,
    start_date: "2022-02-27T08:00:00.000Z",
    end_date: "2022-03-05T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 10,
    start_date: "2022-03-06T08:00:00.000Z",
    end_date: "2022-03-12T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 11,
    start_date: "2022-03-13T08:00:00.000Z",
    end_date: "2022-03-19T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 12,
    start_date: "2022-03-20T07:00:00.000Z",
    end_date: "2022-03-26T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 13,
    start_date: "2022-03-27T07:00:00.000Z",
    end_date: "2022-04-02T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 14,
    start_date: "2022-04-03T07:00:00.000Z",
    end_date: "2022-04-09T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 15,
    start_date: "2022-04-10T07:00:00.000Z",
    end_date: "2022-04-16T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 16,
    start_date: "2022-04-17T07:00:00.000Z",
    end_date: "2022-04-23T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 17,
    start_date: "2022-04-24T07:00:00.000Z",
    end_date: "2022-04-30T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 18,
    start_date: "2022-05-01T07:00:00.000Z",
    end_date: "2022-05-07T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 19,
    start_date: "2022-05-08T07:00:00.000Z",
    end_date: "2022-05-14T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 20,
    start_date: "2022-05-15T07:00:00.000Z",
    end_date: "2022-05-21T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 21,
    start_date: "2022-05-22T07:00:00.000Z",
    end_date: "2022-05-28T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 22,
    start_date: "2022-05-29T07:00:00.000Z",
    end_date: "2022-06-04T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 23,
    start_date: "2022-06-05T07:00:00.000Z",
    end_date: "2022-06-11T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 24,
    start_date: "2022-06-12T07:00:00.000Z",
    end_date: "2022-06-18T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 25,
    start_date: "2022-06-19T07:00:00.000Z",
    end_date: "2022-06-25T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 26,
    start_date: "2022-06-26T07:00:00.000Z",
    end_date: "2022-07-02T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 27,
    start_date: "2022-07-03T07:00:00.000Z",
    end_date: "2022-07-09T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 28,
    start_date: "2022-07-10T07:00:00.000Z",
    end_date: "2022-07-16T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 29,
    start_date: "2022-07-17T07:00:00.000Z",
    end_date: "2022-07-23T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 30,
    start_date: "2022-07-24T07:00:00.000Z",
    end_date: "2022-07-30T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 31,
    start_date: "2022-07-31T07:00:00.000Z",
    end_date: "2022-08-06T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 32,
    start_date: "2022-08-07T07:00:00.000Z",
    end_date: "2022-08-13T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 33,
    start_date: "2022-08-14T07:00:00.000Z",
    end_date: "2022-08-20T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 34,
    start_date: "2022-08-21T07:00:00.000Z",
    end_date: "2022-08-27T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 35,
    start_date: "2022-08-28T07:00:00.000Z",
    end_date: "2022-09-03T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 36,
    start_date: "2022-09-04T07:00:00.000Z",
    end_date: "2022-09-10T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 37,
    start_date: "2022-09-11T07:00:00.000Z",
    end_date: "2022-09-17T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 38,
    start_date: "2022-09-18T07:00:00.000Z",
    end_date: "2022-09-24T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 39,
    start_date: "2022-09-25T07:00:00.000Z",
    end_date: "2022-10-01T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 40,
    start_date: "2022-10-02T07:00:00.000Z",
    end_date: "2022-10-08T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 41,
    start_date: "2022-10-09T07:00:00.000Z",
    end_date: "2022-10-15T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 42,
    start_date: "2022-10-16T07:00:00.000Z",
    end_date: "2022-10-22T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 43,
    start_date: "2022-10-23T07:00:00.000Z",
    end_date: "2022-10-29T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 44,
    start_date: "2022-10-30T07:00:00.000Z",
    end_date: "2022-11-05T07:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 45,
    start_date: "2022-11-06T07:00:00.000Z",
    end_date: "2022-11-12T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 46,
    start_date: "2022-11-13T08:00:00.000Z",
    end_date: "2022-11-19T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 47,
    start_date: "2022-11-20T08:00:00.000Z",
    end_date: "2022-11-26T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 48,
    start_date: "2022-11-27T08:00:00.000Z",
    end_date: "2022-12-03T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 49,
    start_date: "2022-12-04T08:00:00.000Z",
    end_date: "2022-12-10T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 50,
    start_date: "2022-12-11T08:00:00.000Z",
    end_date: "2022-12-17T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 51,
    start_date: "2022-12-18T08:00:00.000Z",
    end_date: "2022-12-24T08:00:00.000Z",
  },
  {
    disease_year: 2022,
    disease_week: 52,
    start_date: "2022-12-25T08:00:00.000Z",
    end_date: "2022-12-31T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 1,
    start_date: "2026-01-04T08:00:00.000Z",
    end_date: "2026-01-10T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 2,
    start_date: "2026-01-11T08:00:00.000Z",
    end_date: "2026-01-17T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 3,
    start_date: "2026-01-18T08:00:00.000Z",
    end_date: "2026-01-24T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 4,
    start_date: "2026-01-25T08:00:00.000Z",
    end_date: "2026-01-31T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 5,
    start_date: "2026-02-01T08:00:00.000Z",
    end_date: "2026-02-07T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 6,
    start_date: "2026-02-08T08:00:00.000Z",
    end_date: "2026-02-14T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 7,
    start_date: "2026-02-15T08:00:00.000Z",
    end_date: "2026-02-21T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 8,
    start_date: "2026-02-22T08:00:00.000Z",
    end_date: "2026-02-28T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 9,
    start_date: "2026-03-01T08:00:00.000Z",
    end_date: "2026-03-07T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 10,
    start_date: "2026-03-08T08:00:00.000Z",
    end_date: "2026-03-14T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 11,
    start_date: "2026-03-15T07:00:00.000Z",
    end_date: "2026-03-21T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 12,
    start_date: "2026-03-22T07:00:00.000Z",
    end_date: "2026-03-28T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 13,
    start_date: "2026-03-29T07:00:00.000Z",
    end_date: "2026-04-04T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 14,
    start_date: "2026-04-05T07:00:00.000Z",
    end_date: "2026-04-11T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 15,
    start_date: "2026-04-12T07:00:00.000Z",
    end_date: "2026-04-18T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 16,
    start_date: "2026-04-19T07:00:00.000Z",
    end_date: "2026-04-25T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 17,
    start_date: "2026-04-26T07:00:00.000Z",
    end_date: "2026-05-02T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 18,
    start_date: "2026-05-03T07:00:00.000Z",
    end_date: "2026-05-09T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 19,
    start_date: "2026-05-10T07:00:00.000Z",
    end_date: "2026-05-16T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 20,
    start_date: "2026-05-17T07:00:00.000Z",
    end_date: "2026-05-23T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 21,
    start_date: "2026-05-24T07:00:00.000Z",
    end_date: "2026-05-30T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 22,
    start_date: "2026-05-31T07:00:00.000Z",
    end_date: "2026-06-06T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 23,
    start_date: "2026-06-07T07:00:00.000Z",
    end_date: "2026-06-13T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 24,
    start_date: "2026-06-14T07:00:00.000Z",
    end_date: "2026-06-20T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 25,
    start_date: "2026-06-21T07:00:00.000Z",
    end_date: "2026-06-27T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 26,
    start_date: "2026-06-28T07:00:00.000Z",
    end_date: "2026-07-04T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 27,
    start_date: "2026-07-05T07:00:00.000Z",
    end_date: "2026-07-11T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 28,
    start_date: "2026-07-12T07:00:00.000Z",
    end_date: "2026-07-18T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 29,
    start_date: "2026-07-19T07:00:00.000Z",
    end_date: "2026-07-25T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 30,
    start_date: "2026-07-26T07:00:00.000Z",
    end_date: "2026-08-01T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 31,
    start_date: "2026-08-02T07:00:00.000Z",
    end_date: "2026-08-08T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 32,
    start_date: "2026-08-09T07:00:00.000Z",
    end_date: "2026-08-15T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 33,
    start_date: "2026-08-16T07:00:00.000Z",
    end_date: "2026-08-22T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 34,
    start_date: "2026-08-23T07:00:00.000Z",
    end_date: "2026-08-29T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 35,
    start_date: "2026-08-30T07:00:00.000Z",
    end_date: "2026-09-05T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 36,
    start_date: "2026-09-06T07:00:00.000Z",
    end_date: "2026-09-12T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 37,
    start_date: "2026-09-13T07:00:00.000Z",
    end_date: "2026-09-19T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 38,
    start_date: "2026-09-20T07:00:00.000Z",
    end_date: "2026-09-26T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 39,
    start_date: "2026-09-27T07:00:00.000Z",
    end_date: "2026-10-03T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 40,
    start_date: "2026-10-04T07:00:00.000Z",
    end_date: "2026-10-10T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 41,
    start_date: "2026-10-11T07:00:00.000Z",
    end_date: "2026-10-17T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 42,
    start_date: "2026-10-18T07:00:00.000Z",
    end_date: "2026-10-24T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 43,
    start_date: "2026-10-25T07:00:00.000Z",
    end_date: "2026-10-31T07:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 44,
    start_date: "2026-11-01T07:00:00.000Z",
    end_date: "2026-11-07T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 45,
    start_date: "2026-11-08T08:00:00.000Z",
    end_date: "2026-11-14T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 46,
    start_date: "2026-11-15T08:00:00.000Z",
    end_date: "2026-11-21T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 47,
    start_date: "2026-11-22T08:00:00.000Z",
    end_date: "2026-11-28T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 48,
    start_date: "2026-11-29T08:00:00.000Z",
    end_date: "2026-12-05T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 49,
    start_date: "2026-12-06T08:00:00.000Z",
    end_date: "2026-12-12T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 50,
    start_date: "2026-12-13T08:00:00.000Z",
    end_date: "2026-12-19T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 51,
    start_date: "2026-12-20T08:00:00.000Z",
    end_date: "2026-12-26T08:00:00.000Z",
  },
  {
    disease_year: 2026,
    disease_week: 52,
    start_date: "2026-12-27T08:00:00.000Z",
    end_date: "2027-01-02T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 1,
    start_date: "2027-01-03T08:00:00.000Z",
    end_date: "2027-01-09T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 2,
    start_date: "2027-01-10T08:00:00.000Z",
    end_date: "2027-01-16T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 3,
    start_date: "2027-01-17T08:00:00.000Z",
    end_date: "2027-01-23T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 4,
    start_date: "2027-01-24T08:00:00.000Z",
    end_date: "2027-01-30T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 5,
    start_date: "2027-01-31T08:00:00.000Z",
    end_date: "2027-02-06T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 6,
    start_date: "2027-02-07T08:00:00.000Z",
    end_date: "2027-02-13T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 7,
    start_date: "2027-02-14T08:00:00.000Z",
    end_date: "2027-02-20T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 8,
    start_date: "2027-02-21T08:00:00.000Z",
    end_date: "2027-02-27T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 9,
    start_date: "2027-02-28T08:00:00.000Z",
    end_date: "2027-03-06T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 10,
    start_date: "2027-03-07T08:00:00.000Z",
    end_date: "2027-03-13T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 11,
    start_date: "2027-03-14T08:00:00.000Z",
    end_date: "2027-03-20T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 12,
    start_date: "2027-03-21T07:00:00.000Z",
    end_date: "2027-03-27T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 13,
    start_date: "2027-03-28T07:00:00.000Z",
    end_date: "2027-04-03T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 14,
    start_date: "2027-04-04T07:00:00.000Z",
    end_date: "2027-04-10T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 15,
    start_date: "2027-04-11T07:00:00.000Z",
    end_date: "2027-04-17T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 16,
    start_date: "2027-04-18T07:00:00.000Z",
    end_date: "2027-04-24T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 17,
    start_date: "2027-04-25T07:00:00.000Z",
    end_date: "2027-05-01T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 18,
    start_date: "2027-05-02T07:00:00.000Z",
    end_date: "2027-05-08T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 19,
    start_date: "2027-05-09T07:00:00.000Z",
    end_date: "2027-05-15T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 20,
    start_date: "2027-05-16T07:00:00.000Z",
    end_date: "2027-05-22T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 21,
    start_date: "2027-05-23T07:00:00.000Z",
    end_date: "2027-05-29T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 22,
    start_date: "2027-05-30T07:00:00.000Z",
    end_date: "2027-06-05T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 23,
    start_date: "2027-06-06T07:00:00.000Z",
    end_date: "2027-06-12T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 24,
    start_date: "2027-06-13T07:00:00.000Z",
    end_date: "2027-06-19T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 25,
    start_date: "2027-06-20T07:00:00.000Z",
    end_date: "2027-06-26T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 26,
    start_date: "2027-06-27T07:00:00.000Z",
    end_date: "2027-07-03T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 27,
    start_date: "2027-07-04T07:00:00.000Z",
    end_date: "2027-07-10T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 28,
    start_date: "2027-07-11T07:00:00.000Z",
    end_date: "2027-07-17T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 29,
    start_date: "2027-07-18T07:00:00.000Z",
    end_date: "2027-07-24T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 30,
    start_date: "2027-07-25T07:00:00.000Z",
    end_date: "2027-07-31T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 31,
    start_date: "2027-08-01T07:00:00.000Z",
    end_date: "2027-08-07T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 32,
    start_date: "2027-08-08T07:00:00.000Z",
    end_date: "2027-08-14T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 33,
    start_date: "2027-08-15T07:00:00.000Z",
    end_date: "2027-08-21T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 34,
    start_date: "2027-08-22T07:00:00.000Z",
    end_date: "2027-08-28T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 35,
    start_date: "2027-08-29T07:00:00.000Z",
    end_date: "2027-09-04T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 36,
    start_date: "2027-09-05T07:00:00.000Z",
    end_date: "2027-09-11T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 37,
    start_date: "2027-09-12T07:00:00.000Z",
    end_date: "2027-09-18T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 38,
    start_date: "2027-09-19T07:00:00.000Z",
    end_date: "2027-09-25T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 39,
    start_date: "2027-09-26T07:00:00.000Z",
    end_date: "2027-10-02T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 40,
    start_date: "2027-10-03T07:00:00.000Z",
    end_date: "2027-10-09T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 41,
    start_date: "2027-10-10T07:00:00.000Z",
    end_date: "2027-10-16T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 42,
    start_date: "2027-10-17T07:00:00.000Z",
    end_date: "2027-10-23T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 43,
    start_date: "2027-10-24T07:00:00.000Z",
    end_date: "2027-10-30T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 44,
    start_date: "2027-10-31T07:00:00.000Z",
    end_date: "2027-11-06T07:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 45,
    start_date: "2027-11-07T07:00:00.000Z",
    end_date: "2027-11-13T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 46,
    start_date: "2027-11-14T08:00:00.000Z",
    end_date: "2027-11-20T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 47,
    start_date: "2027-11-21T08:00:00.000Z",
    end_date: "2027-11-27T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 48,
    start_date: "2027-11-28T08:00:00.000Z",
    end_date: "2027-12-04T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 49,
    start_date: "2027-12-05T08:00:00.000Z",
    end_date: "2027-12-11T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 50,
    start_date: "2027-12-12T08:00:00.000Z",
    end_date: "2027-12-18T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 51,
    start_date: "2027-12-19T08:00:00.000Z",
    end_date: "2027-12-25T08:00:00.000Z",
  },
  {
    disease_year: 2027,
    disease_week: 52,
    start_date: "2027-12-26T08:00:00.000Z",
    end_date: "2028-01-01T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 1,
    start_date: "2028-01-02T08:00:00.000Z",
    end_date: "2028-01-08T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 2,
    start_date: "2028-01-09T08:00:00.000Z",
    end_date: "2028-01-15T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 3,
    start_date: "2028-01-16T08:00:00.000Z",
    end_date: "2028-01-22T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 4,
    start_date: "2028-01-23T08:00:00.000Z",
    end_date: "2028-01-29T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 5,
    start_date: "2028-01-30T08:00:00.000Z",
    end_date: "2028-02-05T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 6,
    start_date: "2028-02-06T08:00:00.000Z",
    end_date: "2028-02-12T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 7,
    start_date: "2028-02-13T08:00:00.000Z",
    end_date: "2028-02-19T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 8,
    start_date: "2028-02-20T08:00:00.000Z",
    end_date: "2028-02-26T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 9,
    start_date: "2028-02-27T08:00:00.000Z",
    end_date: "2028-03-04T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 10,
    start_date: "2028-03-05T08:00:00.000Z",
    end_date: "2028-03-11T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 11,
    start_date: "2028-03-12T08:00:00.000Z",
    end_date: "2028-03-18T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 12,
    start_date: "2028-03-19T07:00:00.000Z",
    end_date: "2028-03-25T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 13,
    start_date: "2028-03-26T07:00:00.000Z",
    end_date: "2028-04-01T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 14,
    start_date: "2028-04-02T07:00:00.000Z",
    end_date: "2028-04-08T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 15,
    start_date: "2028-04-09T07:00:00.000Z",
    end_date: "2028-04-15T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 16,
    start_date: "2028-04-16T07:00:00.000Z",
    end_date: "2028-04-22T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 17,
    start_date: "2028-04-23T07:00:00.000Z",
    end_date: "2028-04-29T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 18,
    start_date: "2028-04-30T07:00:00.000Z",
    end_date: "2028-05-06T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 19,
    start_date: "2028-05-07T07:00:00.000Z",
    end_date: "2028-05-13T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 20,
    start_date: "2028-05-14T07:00:00.000Z",
    end_date: "2028-05-20T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 21,
    start_date: "2028-05-21T07:00:00.000Z",
    end_date: "2028-05-27T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 22,
    start_date: "2028-05-28T07:00:00.000Z",
    end_date: "2028-06-03T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 23,
    start_date: "2028-06-04T07:00:00.000Z",
    end_date: "2028-06-10T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 24,
    start_date: "2028-06-11T07:00:00.000Z",
    end_date: "2028-06-17T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 25,
    start_date: "2028-06-18T07:00:00.000Z",
    end_date: "2028-06-24T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 26,
    start_date: "2028-06-25T07:00:00.000Z",
    end_date: "2028-07-01T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 27,
    start_date: "2028-07-02T07:00:00.000Z",
    end_date: "2028-07-08T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 28,
    start_date: "2028-07-09T07:00:00.000Z",
    end_date: "2028-07-15T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 29,
    start_date: "2028-07-16T07:00:00.000Z",
    end_date: "2028-07-22T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 30,
    start_date: "2028-07-23T07:00:00.000Z",
    end_date: "2028-07-29T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 31,
    start_date: "2028-07-30T07:00:00.000Z",
    end_date: "2028-08-05T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 32,
    start_date: "2028-08-06T07:00:00.000Z",
    end_date: "2028-08-12T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 33,
    start_date: "2028-08-13T07:00:00.000Z",
    end_date: "2028-08-19T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 34,
    start_date: "2028-08-20T07:00:00.000Z",
    end_date: "2028-08-26T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 35,
    start_date: "2028-08-27T07:00:00.000Z",
    end_date: "2028-09-02T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 36,
    start_date: "2028-09-03T07:00:00.000Z",
    end_date: "2028-09-09T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 37,
    start_date: "2028-09-10T07:00:00.000Z",
    end_date: "2028-09-16T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 38,
    start_date: "2028-09-17T07:00:00.000Z",
    end_date: "2028-09-23T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 39,
    start_date: "2028-09-24T07:00:00.000Z",
    end_date: "2028-09-30T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 40,
    start_date: "2028-10-01T07:00:00.000Z",
    end_date: "2028-10-07T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 41,
    start_date: "2028-10-08T07:00:00.000Z",
    end_date: "2028-10-14T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 42,
    start_date: "2028-10-15T07:00:00.000Z",
    end_date: "2028-10-21T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 43,
    start_date: "2028-10-22T07:00:00.000Z",
    end_date: "2028-10-28T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 44,
    start_date: "2028-10-29T07:00:00.000Z",
    end_date: "2028-11-04T07:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 45,
    start_date: "2028-11-05T07:00:00.000Z",
    end_date: "2028-11-11T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 46,
    start_date: "2028-11-12T08:00:00.000Z",
    end_date: "2028-11-18T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 47,
    start_date: "2028-11-19T08:00:00.000Z",
    end_date: "2028-11-25T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 48,
    start_date: "2028-11-26T08:00:00.000Z",
    end_date: "2028-12-02T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 49,
    start_date: "2028-12-03T08:00:00.000Z",
    end_date: "2028-12-09T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 50,
    start_date: "2028-12-10T08:00:00.000Z",
    end_date: "2028-12-16T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 51,
    start_date: "2028-12-17T08:00:00.000Z",
    end_date: "2028-12-23T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 52,
    start_date: "2028-12-24T08:00:00.000Z",
    end_date: "2028-12-30T08:00:00.000Z",
  },
  {
    disease_year: 2028,
    disease_week: 53,
    start_date: "2028-12-31T08:00:00.000Z",
    end_date: "2029-01-06T08:00:00.000Z",
  },
];

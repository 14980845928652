import React, { PureComponent } from "react";
import {
  Card,
  Container,
  MapContainer,
  SliderContainer,
  ScrollBox,
  Visualizations,
} from "../../styles";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import queryString from "query-string";
import { timeFormat } from "d3-time-format";
import Cookies from "universal-cookie";
import Slider from "rc-slider";
import { ContinuousColorLegend } from "react-vis";
import {
  abundanceDW,
  colors,
  // decodeGeometry,
  getWeekNumber,
  // initMapOptions,
  layerTuplesByMapStyle,
  MAPBOX_TOKEN,
  mapCenters,
  mapStyles,
  trapTypes as defaultTrapTypes,
  truncateStr,
  vectorSurvLogo,
  yearsSince,
} from "../../helpers";
import {
  getAgencyAbundanceRates,
  getStateAbundanceRates,
  getZoneAbundanceRates,
  calculateSingleWeekRates,
} from "./pacificHelpers";
import AgencyChart from "./AgencyChart";
import DiverseDropdown from "../DiverseDropdown";
import LoadingIcon from "../LoadingIcon";
import SpeciesPieChart from "./SpeciesPieChart";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

const formatDate = timeFormat("%b %d, %Y");
mapboxgl.accessToken = MAPBOX_TOKEN;

const cookies = new Cookies();
let { username, authtoken } = cookies.getAll();
if (process.env.NODE_ENV === "development") {
  username = process.env.REACT_APP_AGENCY_USERNAME;
  authtoken = process.env.REACT_APP_AUTH_TOKEN;
}
const authQueryString = `?username=${username}&authtoken=${authtoken}`;
const dropdownStyle = {
  width: 120,
};
const speciesDropDownStyle = {
  width: 200,
};
const years = yearsSince(1952);

// Used when switching between agencies
const agencyCenters = {
  "Palau Division of Environmental Health": {
    center: [134.45739154841453, 7.28898528350436],
    zoom: 8.84,
  },
  "FSM Department of Health and Social Affairs": {
    center: [153.92380945223044, 8.967328694680688],
    zoom: 4.6,
  },
  "Guam Department of Public Health and Social Services": {
    center: [144.8566012367237, 13.400369449773137],
    zoom: 9.45,
  },
  "RMI Ministry of Health & Human Services": {
    center: [169.27424385175289, 7.832437445688271],
    zoom: 6.5,
  },
  "CNMI Bureau of Environmental Health": {
    center: [145.50562785948273, 14.675727818236965],
    zoom: 8.41,
  },
  "Hawaii Department of Health": {
    center: [-157.8581, 21.3099],
    zoom: 5.664419380533153,
  },
};

export const agencies = {
  "Guam Department of Public Health and Social Services": {
    agency: "Guam Department of Public Health and Social Services",
    code: "PI-GUAM",
    id: 107,
  },
  "CNMI Bureau of Environmental Health": {
    agency: "CNMI Bureau of Environmental Health",
    code: "PI-CNMI",
    id: 116,
  },
  "FSM Department of Health and Social Affairs": {
    agency: "FSM Department of Health and Social Affairs",
    code: "PI-FSMN",
    id: 191,
  },
  "Hawaii Department of Health": {
    agency: "Hawaii Department of Health",
    code: "PI-HDOH",
    id: 170,
  },
  "RMI Ministry of Health & Human Services": {
    agency: "RMI Ministry of Health & Human Services",
    code: "PI-MIEH",
    id: 190,
  },
  "Palau Division of Environmental Health": {
    agency: "Palau Division of Environmental Health",
    code: "PI-PDEH",
    id: 184,
  },
};

const translateZoneAgencies = {
  Hawaii: "Hawaii Department of Health",
  "Marshall Islands": "RMI Ministry of Health & Human Services",
  Micronesia: "FSM Department of Health and Social Affairs",
  "Northern Mariana Islands": "CNMI Bureau of Environmental Health",
  Guam: "Guam Department of Public Health and Social Services",
  Palau: "Palau Division of Environmental Health",
};

const dropdownCardStyles = {
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  overflow: "visible",
  width: "100%",
  margin: "10px 0 20px 0",
  paddingTop: 5,
  paddingBottom: 5,
};

const today = new Date();
const initialYear = today.getFullYear();
const initialWeek = getWeekNumber(today) - 1 || 1;
const initialEndDate = new Date(abundanceDW[initialYear][initialWeek]);
const initialStartDate = new Date(abundanceDW[initialYear][initialWeek]);
initialStartDate.setDate(initialStartDate.getDate() - 6);
const initialTraps = {};

class Pacific extends PureComponent {
  constructor(props) {
    super(props);

    const stateAbundanceRates = {};
    stateAbundanceRates[this.props.usState] = {};
    stateAbundanceRates[this.props.usState][initialYear] = {};
    stateAbundanceRates[this.props.usState][initialYear]["allpops"] = {};
    stateAbundanceRates[this.props.usState][initialYear]["allpops"][
      "alltraps"
    ] = [{ species: "All Species", color: "black", value: 0 }];
    const currentUsState = this.props.usState;
    initialTraps[currentUsState] = {};
    initialTraps[currentUsState][initialYear] = defaultTrapTypes;

    this.state = {
      agencyAbundanceRates: {},
      agency: null,
      agencyId: null,
      agencyCode: null,
      chartData: { agency: null, state: null, zone: null },
      chartType: null, // could be agency, zone
      dataYearsIntervals: {},
      dataYearsNumsDenoms: {},
      sliderWeek: initialWeek,
      domain: [10, 50],
      endWeek: initialWeek,
      geojson: { type: "FeatureCollection", features: [] },
      intervalAbundanceRates: { state: {}, agency: {}, zone: {} },
      intervalEndDate: formatDate(initialEndDate),
      intervalLength: 0,
      intervalStartDate: formatDate(initialStartDate),
      loading: true,
      // mapHover: false,
      mapStyle: this.props.mapStyle || "Light (Recommended)",
      pieType: "state-year", // could be state-year, state-interval, agency-year, agency-interval, zone-year, zone-interval
      species: ["All Species"], // This is an array to enable multiselect in the future
      stateAbundanceRates,
      stateDataYearsIntervals: {},
      stateDataYearsNumsDenoms: {},
      trap: "alltraps",
      traps: initialTraps,
      usState: currentUsState,
      vizWidth: 600,
      weeksInYear: Object.keys(abundanceDW[initialYear]).length,
      year: initialYear,
      whichLayer: "By Agency", // By Agency or By Zone
      zone: null,
      zoneId: null,
      zoneCode: null,
      zones: [],
      zoneInfoByName: {},
      zonesByAgency: {},
      zoneDataYearsIntervals: {},
      zoneDataYearsNumsDenoms: {},
      zoneAbundanceRates: {},
    };
  }

  componentDidMount() {
    const {
      endWeek,
      intervalLength,
      species,
      stateAbundanceRates,
      trap,
      usState,
      year,
      whichLayer,
      traps,
    } = this.state;
    const urlParams = queryString.parse(this.props.location.search);
    const center = [150.9611488823266, 11.017254687570201];
    const zoom = 3.8284747049893344;
    // const { center, zoom } = initMapOptions({ urlParams, usState });
    const mapStyle =
      urlParams.mapStyle || this.props.mapStyle || "Light (Recommended)";
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: mapStyles[mapStyle],
      center,
      zoom,
      minZoom: 2,
      maxZoom: 12,
      preserveDrawingBuffer: true,
    });

    // Gets the shapes, then fills them
    const populateMap = async () => {
      try {
        const { mapStyle } = this.state;
        const geojsonRes = await axios.get(
          // `http://localhost:8011/v2/pacific/usapiBigs${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/usapiBigs${authQueryString}`
        );
        const geojson = geojsonRes.data;
        geojson.features = geojson.features.map((f) => {
          // f.geometry = decodeGeometry(f.geometry);
          f.id = f.properties.id;
          return f;
        });

        let tempID = 10000000; // Temp until we put in db fr
        const smallGeojsonRes = await axios.get(
          // `http://localhost:8011/v2/pacific/usapiSmalls${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/usapiSmalls${authQueryString}`
        );
        const smallGeojson = smallGeojsonRes.data;
        const zoneInfoByName = {};
        const zones = [];
        const zonesByAgency = {};
        smallGeojson.features = smallGeojson.features.map((f) => {
          // f.geometry = decodeGeometry(f.geometry);
          const code = f.properties.GID_1
            ? f.properties.GID_1
            : f.properties.ID_1;
          f.properties.code = code; // These seem to be unique
          const agencyName = f.properties.NAME_0
            ? f.properties.NAME_0
            : f.properties.COUNTRY;
          const name = agencyName + " - " + f.properties.NAME_1;
          f.properties.name = name;
          f.properties.acronym = f.properties.NAME_1;
          f.id = tempID;
          f.properties.id = tempID;
          zones.push(name);
          const agency = translateZoneAgencies[agencyName];
          zoneInfoByName[name] = {
            agency,
            name,
            code: code,
            id: tempID,
          };
          if (!zonesByAgency[agency]) {
            zonesByAgency[agency] = [];
          }
          zonesByAgency[agency].push(name);
          tempID += 1;
          return f;
        });

        // Get zone abundance
        const currentYearZoneDataRes = await axios.get(
          // `http://localhost:8011/v2/pacific/zone/${year}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/zone/${year}${authQueryString}`
        );

        const currentYearZoneData = currentYearZoneDataRes.data;

        const currentYearDataRes = await axios.get(
          // `http://localhost:8011/v2/pacific/${year}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/${year}${authQueryString}`
        );
        const currentYearData = currentYearDataRes.data;

        const currentYearStateTrapRes = await axios.get(
          // `http://localhost:8011/v2/pacific/trapsByYear/${year}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/trapsByYear/${year}${authQueryString}`
        );
        const currentYearStateTraps = currentYearStateTrapRes.data;

        traps[usState][year] = currentYearStateTraps;

        const currentStateAbundanceRates = await getStateAbundanceRates(
          year,
          usState,
          trap,
          authQueryString,
          currentYearStateTraps
        );

        stateAbundanceRates[usState][year][trap] = currentStateAbundanceRates;

        const zoneDataYearsNumsDenoms = {};
        zoneDataYearsNumsDenoms[year] = currentYearZoneData;

        const zoneDataYearsIntervals = {};
        zoneDataYearsIntervals[year] = {};
        zoneDataYearsIntervals[year][intervalLength] = calculateSingleWeekRates(
          zoneDataYearsNumsDenoms[year]
        );

        const dataYearsNumsDenoms = {};
        dataYearsNumsDenoms[year] = currentYearData;

        const dataYearsIntervals = {};
        dataYearsIntervals[year] = {};
        dataYearsIntervals[year][intervalLength] =
          calculateSingleWeekRates(currentYearData);

        if (!dataYearsIntervals[year][0]) {
          dataYearsIntervals[year][0] =
            calculateSingleWeekRates(currentYearData);
        }

        geojson.features.map((f) => {
          const agency = f.properties.code;
          let mosq = 0;
          if (
            !dataYearsIntervals?.[year]?.[intervalLength]?.[agency]?.[
              endWeek
            ]?.[trap]
          ) {
            mosq = -1;
          } else {
            for (let i = 0; i < species.length; i += 1) {
              let current = species[i];
              if (
                !dataYearsIntervals[year][intervalLength][agency][endWeek][
                  trap
                ][current]
              ) {
                mosq += 0;
              } else {
                mosq +=
                  dataYearsIntervals[year][intervalLength][agency][endWeek][
                    trap
                  ][current];
              }
            }
          }
          f.properties.mosq = mosq;
          return f;
        });

        smallGeojson.features.map((f) => {
          const agency = f.properties.code;
          let mosq = 0;
          if (
            !zoneDataYearsIntervals?.[year]?.[intervalLength]?.[agency]?.[
              endWeek
            ]?.[trap]
          ) {
            mosq = -1;
          } else {
            for (let i = 0; i < species.length; i += 1) {
              let current = species[i];
              if (
                !zoneDataYearsIntervals[year][intervalLength][agency][endWeek][
                  trap
                ][current]
              ) {
                mosq += 0;
              } else {
                mosq +=
                  zoneDataYearsIntervals[year][intervalLength][agency][endWeek][
                    trap
                  ][current];
              }
            }
          }
          f.properties.mosq = mosq;
          return f;
        });

        // Find the index of the layer in the map style above which our layer should rest
        const layers = this.map.getStyle().layers;
        let firstSymbolId;
        const layerTuple = layerTuplesByMapStyle[mapStyle];
        for (let i = 0; i < layers.length; i++) {
          if (layers[i]?.[layerTuple[0]] === layerTuple[1]) {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        this.map.addSource("abundanceGeojson", {
          type: "geojson",
          data: geojson,
        });

        this.map.addLayer(
          {
            id: "abundance",
            type: "fill",
            layout: {
              visibility: whichLayer === "By Agency" ? "visible" : "none",
            },
            interactive: true,
            source: "abundanceGeojson",
            paint: {
              "fill-color": {
                property: "mosq",
                stops: [
                  [-1, colors["nonSurveillance"]],
                  [0, colors["green"]],
                  [10, "yellow"],
                  [50, "red"],
                ],
              },
              "fill-outline-color": "black",
              "fill-opacity": 1,
            },
          },
          firstSymbolId
        );

        // Layer for only highlighted agency outline
        this.map.addLayer(
          {
            id: "abundance-borders",
            type: "line",
            layout: {
              visibility: whichLayer === "By Agency" ? "visible" : "none",
            },
            interactive: true,
            source: "abundanceGeojson",
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                colors["vectorsurv-green"],
                "black",
              ],
              "line-width": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                2,
                0,
              ],
              "line-opacity": 1,
            },
          },
          firstSymbolId
        );

        this.map.addSource("usapiSmallsGeojson", {
          type: "geojson",
          data: smallGeojson,
        });

        // Add the sublayers
        this.map.addLayer(
          {
            id: "usapi-smalls",
            type: "fill",
            layout: {
              visibility: whichLayer === "By Zone" ? "visible" : "none",
            },
            interactive: true,
            source: "usapiSmallsGeojson",
            paint: {
              "fill-color": {
                property: "mosq",
                stops: [
                  [-1, colors["nonSurveillance"]],
                  [0, "green"],
                  [10, "yellow"],
                  [50, "red"],
                ],
              },
              "fill-outline-color": "black",
              "fill-opacity": 1,
            },
          },
          firstSymbolId
        );

        // Layer for only highlighted neighborhood outline
        this.map.addLayer(
          {
            id: "usapi-smalls-borders",
            type: "line",
            layout: {
              visibility: whichLayer === "By Zone" ? "visible" : "none",
            },
            interactive: true,
            source: "usapiSmallsGeojson",
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                colors["vectorsurv-green"],
                "black",
              ],
              "line-width": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                2,
                0,
              ],
              "line-opacity": 1,
            },
          },
          firstSymbolId
        );

        this.setState(
          {
            dataYearsIntervals,
            dataYearsNumsDenoms,
            geojson,
            loading: false,
            stateAbundanceRates,
            zoneInfoByName,
            zones,
            zonesByAgency,
            zoneDataYearsIntervals,
            zoneDataYearsNumsDenoms,
            smallGeojson,
            traps,
          },
          () => {
            this.generateDomain();
            const initialAgency = this.getInitialAgency();
            if (initialAgency) {
              this.handleAgencyChange({
                flyTo: true,
                ...agencies[initialAgency],
              });
            }
          }
        );
      } catch (err) {
        console.error("Pacific map failed to populate with error", err);
      }
    };

    // add the vectorsurv logo to the map view
    document
      .getElementsByClassName("mapboxgl-ctrl-bottom-left")[0]
      .insertAdjacentHTML("beforeend", vectorSurvLogo);

    // this pattern ensures style wins race against data fetch
    this.map.on("style.load", () => {
      const waitForStyleToLoad = () => {
        if (this.map.isStyleLoaded()) {
          populateMap();
        } else {
          setTimeout(waitForStyleToLoad, 10);
        }
      };
      waitForStyleToLoad();
    });

    const generatePopupText = (e) => {
      const { intervalEndDate, intervalStartDate } = this.state;
      const { mosq, agency } = e.features[0].properties;
      const html =
        mosq > -1
          ? `<div> ${mosq} mosq per trap night <br/>${intervalStartDate} to ${intervalEndDate} <br/> ${agency}</div>`
          : `<div> No surveillance <br/>${intervalStartDate} to ${intervalEndDate} <br/> ${agency}</div>`;
      return html;
    };

    const generateSmallsPopupText = (e) => {
      // const html = `<div>${e.features[0].properties.name}</div>`;
      // return html;
      const { intervalEndDate, intervalStartDate } = this.state;
      const { mosq, name } = e.features[0].properties;
      const html =
        mosq > -1
          ? `<div> ${mosq} mosq per trap night <br/>${intervalStartDate} to ${intervalEndDate} <br/> ${name}</div>`
          : `<div> No surveillance <br/>${intervalStartDate} to ${intervalEndDate} <br/> ${name}</div>`;
      return html;
    };

    this.map.on("mouseenter", "abundance", (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      const html = generatePopupText(e);
      this.popup = new mapboxgl.Popup({ closeButton: false })
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.map);
    });
    this.map.on("mouseleave", "abundance", () => {
      this.map.getCanvas().style.cursor = "";
      this.popup.remove();
    });
    this.map.on("mousemove", "abundance", (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      const html = generatePopupText(e);
      this.popup.setLngLat(e.lngLat).setHTML(html);
    });
    this.map.on("click", "abundance", (e) => {
      this.handleAgencyChange(e.features[0].properties);
    });
    this.map.on("mouseenter", "usapi-smalls", (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      const html = generateSmallsPopupText(e);
      this.popup = new mapboxgl.Popup({ closeButton: false })
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.map);
    });
    this.map.on("mouseleave", "usapi-smalls", () => {
      this.map.getCanvas().style.cursor = "";
      this.popup.remove();
    });
    this.map.on("mousemove", "usapi-smalls", (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      const html = generateSmallsPopupText(e);
      this.popup.setLngLat(e.lngLat).setHTML(html);
    });
    this.map.on("click", "usapi-smalls", (e) => {
      this.handleZoneChange(e.features[0].properties);
    });

    this.map["keyboard"].disable();

    window.addEventListener("resize", this.setWidth, true);
    document.addEventListener("keydown", this.handleKeyPress, true);

    // // Used to find zooms for defaults
    // this.map.on("zoom", () => {
    //   console.log("center is", this.map.getCenter());
    //   console.log("zoom is", this.map.getZoom());
    // });

    const vizWidth = document.getElementById("vizContainer").clientWidth;
    this.setState({ vizWidth });
    this.props.updateMap(this.map);
    this.props.updateToastMap();
    this.props.updateSvgInfo({
      filename: "pacificData.png",
    });
  }

  componentWillUnmount() {
    this.map.remove();
    window.removeEventListener("resize", this.setWidth, true);
    document.removeEventListener("keydown", this.handleKeyPress, true);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.usState !== prevProps.usState &&
      mapCenters[this.props.usState]
    ) {
      this.map.flyTo(mapCenters[this.props.usState]);
      this.setState({ loading: true }, () => {
        this.changeUsState(this.props.usState);
      });
    }
    const newMapStyle = this.props.mapStyle;
    if (newMapStyle !== prevProps.mapStyle) {
      this.changeMapStyle(newMapStyle);
    }
  }

  // Visible = desired visibility
  toggleZones = (visible) => {
    if (visible) {
      this.map.setLayoutProperty("usapi-smalls", "visibility", "visible"); //abundance-borders
      this.map.setLayoutProperty(
        "usapi-smalls-borders",
        "visibility",
        "visible"
      );
    } else {
      this.map.setLayoutProperty("usapi-smalls", "visibility", "none");
      this.map.setLayoutProperty("usapi-smalls-borders", "visibility", "none");
    }
  };

  // Visible = desired visibility
  toggleAgencies = (visible) => {
    if (visible) {
      this.map.setLayoutProperty("abundance", "visibility", "visible");
      this.map.setLayoutProperty("abundance-borders", "visibility", "visible");
    } else {
      this.map.setLayoutProperty("abundance", "visibility", "none");
      this.map.setLayoutProperty("abundance-borders", "visibility", "none");
    }
  };

  changeMapStyle = (newMapStyle) => {
    const { mapStyle } = this.state;
    if (!newMapStyle || mapStyle === newMapStyle || !mapStyles[newMapStyle]) {
      return false;
    }
    console.log("attempt change map style to", newMapStyle);
    this.map.setStyle(mapStyles[newMapStyle]);
    this.setState({ mapStyle: newMapStyle });
  };

  updateGeojson = (generateDomain = false) => {
    const {
      dataYearsIntervals,
      endWeek,
      geojson,
      intervalLength,
      species,
      year,
      smallGeojson,
      zoneDataYearsIntervals,
    } = this.state;

    let { trap } = this.state;
    if (trap === "All Traps") {
      trap = "alltraps";
    }

    const callback = () => {
      if (generateDomain) {
        this.generateDomain();
      }
    };

    let data = dataYearsIntervals[year][intervalLength];

    geojson.features.map((f) => {
      const agency = f.properties.code;
      let mosq = 0;
      if (!data?.[agency]?.[endWeek]?.[trap]) {
        mosq = -1;
      } else {
        for (let i = 0; i < species.length; i += 1) {
          if (!data[agency][endWeek][trap][species]) {
            mosq += 0;
          } else {
            mosq += data[agency][endWeek][trap][species];
          }
        }
      }
      f.properties.mosq = mosq;
      return f;
    });

    let zoneData = zoneDataYearsIntervals[year][intervalLength];
    smallGeojson.features.map((f) => {
      const agency = f.properties.code;

      let mosq = 0;
      if (!zoneData?.[agency]?.[endWeek]?.[trap]) {
        mosq = -1;
      } else {
        for (let i = 0; i < species.length; i += 1) {
          if (!zoneData[agency][endWeek][trap][species]) {
            mosq += 0;
          } else {
            mosq += zoneData[agency][endWeek][trap][species];
          }
        }
      }
      f.properties.mosq = mosq;
      return f;
    });

    this.map.getSource("abundanceGeojson").setData(geojson);
    this.map.getSource("usapiSmallsGeojson").setData(smallGeojson);
    this.setState(
      { dataYearsIntervals, geojson, loading: false, smallGeojson },
      callback
    );
  };

  // This recolors the map based on selections made
  generateDomain = () => {
    const { dataYearsIntervals, species, year } = this.state;
    let { trap } = this.state;
    if (trap === "All Traps") {
      trap = "alltraps";
    }

    const data = dataYearsIntervals[year][0]; // Domain is always based on single week numbers

    let values = [];
    let fifty, ninety;

    Object.keys(data).forEach((agency) => {
      Object.keys(data[agency]).forEach((week) => {
        let currentSum = 0;
        if (data[agency][week] && data[agency][week][trap]) {
          for (let i = 0; i < species.length; i += 1) {
            let current = species[i];
            if (data[agency][week][trap][current]) {
              currentSum += data[agency][week][trap][current];
            }
          }
          if (currentSum) {
            values.push(currentSum);
          }
        }
      });
    });

    values = values.sort((a, b) => a - b);
    const midPoint = Math.floor(values.length / 2);
    const ninetyPoint = Math.floor(values.length * 0.95);

    if (values.length > 2) {
      fifty = values[midPoint];
      ninety = values[ninetyPoint];
    } else {
      fifty = values[ninetyPoint] * 0.5;
      ninety = values[values.length - 1];
    }

    if (!fifty) fifty = 0.5;
    if (!ninety) ninety = 1;

    this.map.setPaintProperty("abundance", "fill-color", {
      property: "mosq",
      stops: [
        [-1, colors["nonSurveillance"]],
        [0, colors["green"]],
        [fifty, colors["yellow"]],
        [ninety, colors["red"]],
      ],
    });
    this.setState({ domain: [fifty, ninety] });
  };

  changeWeek = (weekNumber) => {
    const { intervalLength, year } = this.state;
    const { intervalEndDate, intervalStartDate } = this.getIntervalDates(
      weekNumber,
      year,
      intervalLength
    );

    this.setState(
      {
        endWeek: weekNumber,
        intervalEndDate,
        intervalStartDate,
        loading: true,
        sliderWeek: weekNumber,
      },
      () => this.updateIntervalAbundanceRates(false)
    );
  };

  handleLayerDropdown = (newLayer) => {
    const { whichLayer, agency, zone, zoneInfoByName, zonesByAgency } =
      this.state;

    let { chartType } = this.state;
    if (
      !["By Agency", "By Zone"].includes(newLayer) ||
      whichLayer === newLayer
    ) {
      return false;
    }
    // Toggle the visible layer
    if (newLayer === "By Agency") {
      this.toggleZones(false);
      this.toggleAgencies(true);
    } else {
      this.toggleZones(true);
      this.toggleAgencies(false);
    }
    // logic for selection after toggle
    const callback = () => {
      if (whichLayer === "By Zone" && newLayer === "By Agency") {
        if (zone) {
          // If there was a zone selected, toggling to agency layer should have that zone's agency selected instead
          if (agency && zoneInfoByName[zone].agency === agency) {
            chartType = "agency";
          } else {
            this.changeAgency({ ...agencies[zoneInfoByName[zone].agency] });
          }
        } else {
          if (agency) {
            // If no zone but yes agency, make that the agency
            this.changeAgency({ ...agencies[agency] });
          } else {
            this.clearAgency();
          }
        }
      } else if (whichLayer === "By Agency" && newLayer === "By Zone") {
        if (zone && zonesByAgency[agency].includes(zone)) {
          // If there was an agency selected and we toggled away, on toggle back it should still be selected
          this.changeZone({ ...zoneInfoByName[zone] });
        } else {
          this.clearZone();
        }
      }
    };

    this.setState({ whichLayer: newLayer, chartType }, callback);
  };

  handlePieChartDropdown = (proposedPieType) => {
    const {
      agency,
      intervalEndDate,
      intervalStartDate,
      pieType,
      usState,
      year,
      zone,
    } = this.state;

    const translatePieChartValues = {
      "- Pie chart options. Pie chart colors bar chart -":
        "- Pie chart options. Pie chart colors bar chart -",
      "agency-year": `${agency}: ${year}`,
      "state-year": `${usState}: ${year}`,
      "zone-year": `${zone}: ${year}`,
      "agency-interval": `${agency}: ${intervalStartDate} to ${intervalEndDate}`,
      "state-interval": `${usState}: ${intervalStartDate} to ${intervalEndDate}`,
      "zone-interval": `${zone}: ${intervalStartDate} to ${intervalEndDate}`,
      "- Click an agency or zone on the map to enable its pie chart -":
        "- Click an agency or zone on the map to enable its pie chart -",
    };
    if (translatePieChartValues[pieType] === proposedPieType) {
      return false;
    }
    let newPieType = null;

    Object.keys(translatePieChartValues).forEach((key) => {
      if (!newPieType && translatePieChartValues[key] === proposedPieType) {
        newPieType = key;
      }
    });

    if (!newPieType) {
      console.log("no new pieType");
      return false;
    }

    this.setState({ loading: true, pieType: newPieType }, () =>
      this.updateIntervalAbundanceRates(false)
    );
  };

  showHelp = () => {
    this.setState({ chartType: null });
  };

  adjustSlider = (week) => {
    this.setState({ sliderWeek: week });
  };

  changeUsState = async (newUsState) => {
    if (!newUsState) return;
    const { chartData, trap, year } = this.state;

    let { stateAbundanceRates } = this.state;
    stateAbundanceRates = JSON.parse(JSON.stringify(stateAbundanceRates));

    if (mapCenters[this.props.usState]) {
      this.map.flyTo(mapCenters[newUsState]);
    }
    chartData.state = null;
    this.clearAgency();

    if (!stateAbundanceRates?.[newUsState]?.[year]?.[trap]) {
      if (!stateAbundanceRates[newUsState]) {
        stateAbundanceRates[newUsState] = {};
      }
      if (!stateAbundanceRates[newUsState][year]) {
        stateAbundanceRates[newUsState][year] = {};
      }
      if (!stateAbundanceRates[newUsState][year]) {
        stateAbundanceRates[newUsState][year] = {};
      }
      const newStateAbundanceRates = await getStateAbundanceRates(
        year,
        newUsState,
        trap,
        authQueryString
      );
      stateAbundanceRates[newUsState][year][trap] = newStateAbundanceRates;
    }

    this.setState(
      {
        chartData,
        chartType: null,
        stateAbundanceRates,
        usState: newUsState,
      },
      () => {
        this.updateIntervalAbundanceRates(true);
      }
    );
  };

  handleKeyPress = (e) => {
    const { endWeek, loading, weeksInYear } = this.state;
    if (loading) {
      return false;
    }
    if (e.keyCode === 37) {
      // key is left arrow
      if (endWeek < 2) return;
      this.changeWeek(endWeek - 1);
      return;
    } else if (e.keyCode === 39) {
      // right arrow
      if (endWeek === weeksInYear) return;
      this.changeWeek(endWeek + 1);
      return;
    } else return;
  };

  handleChangeYear = (e) => {
    const newYear = +e.target.value;
    const { year } = this.state;
    if (newYear === year) {
      return false;
    }
    const callback = () => {
      this.changeYear(newYear);
    };
    this.setState({ loading: true }, callback);
  };

  changeYear = async (newYear) => {
    const {
      dataYearsIntervals,
      dataYearsNumsDenoms,
      endWeek,
      intervalLength,
      trap,
      traps,
      usState,
      year,
      zoneDataYearsIntervals,
      zoneDataYearsNumsDenoms,
      zoneCode,
      zone,
      zoneInfoByName,
    } = this.state;
    let {
      agencyCode,
      agencyAbundanceRates,
      chartData,
      stateAbundanceRates,
      zoneAbundanceRates,
    } = this.state;

    agencyAbundanceRates = JSON.parse(JSON.stringify(agencyAbundanceRates));
    stateAbundanceRates = JSON.parse(JSON.stringify(stateAbundanceRates));
    zoneAbundanceRates = JSON.parse(JSON.stringify(zoneAbundanceRates));

    // If there's a zone, the corresponding agency's information will also matter
    if (zoneCode && !agencyCode) {
      let { agency } = zoneInfoByName[zone];
      agencyCode = agencies[agency].code;
    }

    try {
      // These are for to get colors
      if (!stateAbundanceRates?.[usState]?.[newYear]?.[trap]) {
        if (!stateAbundanceRates[usState]) {
          stateAbundanceRates[usState] = {};
        }
        if (!stateAbundanceRates[usState][newYear]) {
          stateAbundanceRates[usState][newYear] = {};
        }
        if (!stateAbundanceRates[usState][newYear]) {
          stateAbundanceRates[usState][newYear] = {};
        }

        const newYearStateAbundanceRates = await getStateAbundanceRates(
          newYear,
          usState,
          trap,
          authQueryString
        );
        stateAbundanceRates[usState][newYear][trap] =
          newYearStateAbundanceRates;
      }

      if (
        agencyCode &&
        !agencyAbundanceRates?.[agencyCode]?.[newYear]?.[trap]
      ) {
        if (!agencyAbundanceRates[agencyCode]) {
          agencyAbundanceRates[agencyCode] = {};
        }
        if (!agencyAbundanceRates[agencyCode][newYear]) {
          agencyAbundanceRates[agencyCode][newYear] = {};
        }
        if (!agencyAbundanceRates[agencyCode][newYear]) {
          agencyAbundanceRates[agencyCode][newYear] = {};
        }
        const newYearAgencyAbundanceRates = await getAgencyAbundanceRates(
          year,
          agencyCode,
          trap,
          authQueryString
        );
        agencyAbundanceRates[agencyCode][newYear][trap] =
          newYearAgencyAbundanceRates;
      }

      if (zoneCode && !zoneAbundanceRates?.[agencyCode]?.[newYear]?.[trap]) {
        if (!zoneAbundanceRates[agencyCode]) {
          zoneAbundanceRates[agencyCode] = {};
        }
        if (!zoneAbundanceRates[agencyCode][newYear]) {
          zoneAbundanceRates[agencyCode][newYear] = {};
        }
        if (!zoneAbundanceRates[agencyCode][newYear]) {
          zoneAbundanceRates[agencyCode][newYear] = {};
        }
        const newYearZoneAbundanceRates = await getZoneAbundanceRates(
          year,
          zoneCode,
          trap,
          authQueryString
        );
        zoneAbundanceRates[agencyCode][newYear][trap] =
          newYearZoneAbundanceRates;
      }

      // if new year's nums and denoms have never been calculated, do so here zones
      if (!zoneDataYearsNumsDenoms[newYear]) {
        const currentYearZoneDataRes = await axios.get(
          // `http://localhost:8011/v2/pacific/zone${newYear}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/zone${newYear}${authQueryString}`
        );

        const currentYearZoneData = currentYearZoneDataRes.data;

        zoneDataYearsNumsDenoms[newYear] = currentYearZoneData;
      }

      // if current year's traps have never been gotten, get them
      if (!traps?.[usState]?.[newYear]) {
        const currentYearStateTrapRes = await axios.get(
          // `http://localhost:8011/v2/pacific/trapsByYear/${newYear}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/trapsByYear/${newYear}${authQueryString}`
        );
        const currentYearStateTraps = currentYearStateTrapRes.data;

        traps[usState][newYear] = currentYearStateTraps;
      }

      // Create the date year intervals for the new year if needed
      if (!zoneDataYearsIntervals[newYear]) {
        zoneDataYearsIntervals[newYear] = {};
      }

      if (!zoneDataYearsIntervals?.[newYear]?.[intervalLength]) {
        zoneDataYearsIntervals[newYear][intervalLength] =
          calculateSingleWeekRates(zoneDataYearsNumsDenoms[newYear]);
      }

      if (zoneCode) {
        chartData.zone =
          zoneDataYearsIntervals[newYear][intervalLength][zoneCode];
      }

      // if current year's nums and denoms have never been calculated, do so here
      if (!dataYearsNumsDenoms[newYear]) {
        const currentYearDataRes = await axios.get(
          // `http://localhost:8011/v2/pacific/${newYear}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/${newYear}${authQueryString}`
        );

        const currentYearData = currentYearDataRes.data;

        dataYearsNumsDenoms[newYear] = currentYearData;
      }
      // Create the date year intervals for the new current year if needed
      if (!dataYearsIntervals[newYear]) {
        dataYearsIntervals[newYear] = {};
      }

      if (!dataYearsIntervals?.[newYear]?.[intervalLength]) {
        dataYearsIntervals[newYear][intervalLength] = calculateSingleWeekRates(
          dataYearsNumsDenoms[newYear]
        );
      }

      if (agencyCode) {
        chartData.agency =
          dataYearsIntervals[newYear][intervalLength][agencyCode];
      }

      const weeksInYear = Object.keys(abundanceDW[newYear]).length;
      const { intervalEndDate, intervalStartDate } = this.getIntervalDates(
        endWeek,
        newYear,
        intervalLength
      );
      this.setState(
        {
          agencyAbundanceRates,
          chartData,
          dataYearsIntervals,
          dataYearsNumsDenoms,
          intervalEndDate,
          intervalStartDate,
          loading: true,
          stateAbundanceRates,
          weeksInYear,
          year: newYear,
          zoneDataYearsIntervals,
          zoneDataYearsNumsDenoms,
          zoneAbundanceRates,
          traps,
        },
        () => {
          this.updateGeojson(true);
        }
      );
    } catch (err) {
      console.error(
        "Changing year failed in the Pacific with the error",
        err.stack
      );
    }
  };

  updateIntervalAbundanceRates = async (generateDomain = false) => {
    const {
      agencyCode,
      intervalEndDate,
      intervalStartDate,
      pieType,
      trap,
      usState,
      year,
      zoneCode,
    } = this.state;

    let { stateAbundanceRates, intervalAbundanceRates } = this.state;
    stateAbundanceRates = JSON.parse(JSON.stringify(stateAbundanceRates));
    intervalAbundanceRates = JSON.parse(JSON.stringify(intervalAbundanceRates));

    try {
      if (
        pieType === "zone-interval" &&
        zoneCode &&
        !intervalAbundanceRates.zone?.[trap]?.[
          `${intervalStartDate}-${intervalEndDate}`
        ]?.[zoneCode]
      ) {
        // Get the rates for the agency in the current interval with the current settings
        const zoneIntervalAbundanceRatesResponse = await axios.get(
          // `http://localhost:8011/v2/pacific/zoneRatesByWeekRange/${year}/${zoneCode}/${getWeekNumber(
          //   new Date(intervalStartDate)
          // )}/${getWeekNumber(new Date(intervalEndDate))}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/zoneRatesByWeekRange/${year}/${zoneCode}/${getWeekNumber(
            new Date(intervalStartDate)
          )}/${getWeekNumber(new Date(intervalEndDate))}${authQueryString}`
        );

        const zoneIntervalAbundanceRates =
          zoneIntervalAbundanceRatesResponse.data;
        if (
          !intervalAbundanceRates.zone?.[trap]?.[
            `${intervalStartDate}-${intervalEndDate}`
          ]
        ) {
          if (!intervalAbundanceRates.zone) {
            intervalAbundanceRates.zone = {};
          }
          if (!intervalAbundanceRates.zone?.[trap]) {
            intervalAbundanceRates.zone[trap] = {};
          }
          // This can just be set because we know it didn't exist. It's the end of the chain.
          intervalAbundanceRates.zone[trap][
            `${intervalStartDate}-${intervalEndDate}`
          ] = {};
        }
        intervalAbundanceRates.zone[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][zoneCode] = zoneIntervalAbundanceRates;
      }

      if (
        (pieType === "agency-interval" || agencyCode) &&
        agencyCode &&
        !intervalAbundanceRates.agency?.[
          `${intervalStartDate}-${intervalEndDate}`
        ]?.[agencyCode]
      ) {
        // Get the rates for the agency in the current interval with the current settings
        const agencyIntervalAbundanceRatesResponse = await axios.get(
          // `http://localhost:8011/v2/pacific/abundanceRatesByWeekRange/${year}/${agencyCode}/${getWeekNumber(
          //    new Date(intervalStartDate)
          // )}/${getWeekNumber(new Date(intervalEndDate))}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/abundanceRatesByWeekRange/${year}/${agencyCode}/${getWeekNumber(
            new Date(intervalStartDate)
          )}/${getWeekNumber(new Date(intervalEndDate))}${authQueryString}`
        );

        const agencyIntervalAbundanceRates =
          agencyIntervalAbundanceRatesResponse.data;
        if (
          !intervalAbundanceRates.agency?.[trap]?.[
            `${intervalStartDate}-${intervalEndDate}`
          ]
        ) {
          if (!intervalAbundanceRates.agency) {
            intervalAbundanceRates.agency = {};
          }
          if (!intervalAbundanceRates.agency?.[trap]) {
            intervalAbundanceRates.agency[trap] = {};
          }
          // This can just be set because we know it didn't exist. It's the end of the chain.
          intervalAbundanceRates.agency[trap][
            `${intervalStartDate}-${intervalEndDate}`
          ] = {};
        }
        intervalAbundanceRates.agency[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][agencyCode] = agencyIntervalAbundanceRates;
      }

      // Get the rates for the state in the current interval with the current settings
      if (
        pieType === "state-interval" &&
        !intervalAbundanceRates.state?.[
          `${intervalStartDate}-${intervalEndDate}`
        ]?.[usState]
      ) {
        let startDateWeekNumber = getWeekNumber(new Date(intervalStartDate));
        const endDateWeekNumber = getWeekNumber(new Date(intervalEndDate));
        if (
          endDateWeekNumber === 1 &&
          startDateWeekNumber >= endDateWeekNumber
        ) {
          // This case comes up at the beginning of the year only, the startDateWeekNumber will translate to something like 52 or 53
          startDateWeekNumber = 0;
        }
        const stateIntervalAbundanceRatesResponse = await axios.get(
          // `http://localhost:8011/v2/pacific/abundanceRatesByWeekRange/${year}/${usState}/${startDateWeekNumber}/${endDateWeekNumber}${authQueryString}`
          `https://mathew.vectorsurv.org/v2/pacific/abundanceRatesByWeekRange/${year}/${usState}/${startDateWeekNumber}/${endDateWeekNumber}${authQueryString}`
        );

        const stateIntervalAbundanceRates =
          stateIntervalAbundanceRatesResponse.data;
        if (
          !intervalAbundanceRates.state?.[trap]?.[
            `${intervalStartDate}-${intervalEndDate}`
          ]
        ) {
          if (!intervalAbundanceRates.state) {
            intervalAbundanceRates.state = {};
          }
          if (!intervalAbundanceRates.state?.[trap]) {
            intervalAbundanceRates.state[trap] = {};
          }
          // This can just be set because we know it didn't exist. It's the end of the chain.
          intervalAbundanceRates.state[trap][
            `${intervalStartDate}-${intervalEndDate}`
          ] = {};
        }
        intervalAbundanceRates.state[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][usState] = stateIntervalAbundanceRates;
      }

      // Get the rates for the year for the whole state with the current settings
      if (
        pieType === "state-year" &&
        !stateAbundanceRates?.[usState]?.[year]?.[trap]
      ) {
        if (!stateAbundanceRates[usState]?.[year]) {
          stateAbundanceRates[usState][year] = {};
        }
        if (!stateAbundanceRates[usState][year]) {
          stateAbundanceRates[usState][year] = {};
        }
        const currentStateAbundanceRates = await getStateAbundanceRates(
          year,
          usState,
          trap,
          authQueryString
        );
        stateAbundanceRates[usState][year][trap] = currentStateAbundanceRates;
      }

      const callBack = () => {
        this.updateGeojson(generateDomain);
      };

      this.setState(
        {
          intervalAbundanceRates,
          stateAbundanceRates,
        },
        callBack
      );
    } catch (err) {
      console.error(err.stack);
    }
  };

  changeSpecies = (species) => {
    // if coming in from the dropdown, need target.value,
    // otherwise can pass the string of the species itself
    species = species.target ? species.target.value : species;
    species = species.replace(/\//g, "+"); // Replace the '/' in cases where they came from db with a '/' in them
    species = [species];
    this.setState({ loading: true, species }, () => {
      this.updateIntervalAbundanceRates(true);
    });
  };

  changeTrap = (trap) => {
    trap = trap.target.value;
    if (trap === "All Traps") trap = "alltraps";
    this.setState({ loading: true, trap }, () => {
      this.updateIntervalAbundanceRates(true);
    });
  };

  /**
   * Func to change agencies. Updates state and calls func to configure map data afterward
   *
   * @param {Object} props - props object containing various properties
   * @param {string} props.agency - agency name
   * @param {string} props.code - agency code
   * @param {number} props.id - agency id
   * @param {boolean} props.flyTo - indicates whether to fly to the agency's coordinates on resolution
   */
  changeAgency = async (props) => {
    const {
      agencyId,
      dataYearsIntervals,
      intervalLength,
      year,
      trap,
      zonesByAgency,
      zone,
    } = this.state;

    let { agencyAbundanceRates, chartData } = this.state;

    agencyAbundanceRates = JSON.parse(JSON.stringify(agencyAbundanceRates));
    chartData = JSON.parse(JSON.stringify(chartData));

    const { agency, code, id, flyTo } = props;

    chartData.agency = dataYearsIntervals[year][intervalLength][code];

    if (!agencyAbundanceRates?.[code]?.[year]?.[trap]) {
      if (!agencyAbundanceRates[code]) {
        agencyAbundanceRates[code] = {};
      }
      if (!agencyAbundanceRates[code][year]) {
        agencyAbundanceRates[code][year] = {};
      }
      if (!agencyAbundanceRates[code][year]) {
        agencyAbundanceRates[code][year] = {};
      }
      try {
        agencyAbundanceRates[code][year][trap] = await getAgencyAbundanceRates(
          year,
          code,
          trap,
          authQueryString
        );
      } catch (err) {
        console.error(err.stack);
      }
    }
    // Remove map highlight if exists
    if (agencyId) {
      this.removeAgencyHighlight();
    }
    // Set highlight id on map
    this.addAgencyHighlight(id);
    // Zoom over to agency as needed
    if (flyTo) {
      this.map.flyTo(agencyCenters[agency]);
    }

    if (zone && !zonesByAgency[agency].includes(zone)) {
      this.clearZone();
    }

    // Ensure the correct layer is showing
    this.toggleZones(false);
    this.toggleAgencies(true);

    localStorage.setItem("pacificAgency", agency);

    this.setState(
      {
        agency,
        agencyAbundanceRates,
        agencyCode: code,
        agencyId: id,
        chartData,
        chartType: "agency",
        whichLayer: "By Agency",
        loading: true,
      },
      () => this.updateIntervalAbundanceRates(false)
    );
  };

  clearAgency = () => {
    this.removeAgencyHighlight();
    const { chartData, pieType } = this.state;
    let { chartType } = this.state;
    let newPieType = pieType;
    if (pieType === "agency-interval") {
      newPieType = "state-interval";
    } else if (pieType === "agency-year") {
      newPieType = "state-year";
    }
    if (chartType === "agency") {
      chartType = null;
    }
    chartData.agency = null;
    this.setState({
      agency: null,
      agencyCode: null,
      agencyId: null,
      chartData,
      chartType,
      pieType: newPieType,
    });
  };

  /**
   * determine the correct agency on load to init on
   * @returns {string|null} The primary agency name, or false if not found.
   */
  getInitialAgency = () => {
    // Default to localstorage if available
    const savedPacificAgency = localStorage.getItem("pacificAgency");
    if (savedPacificAgency) {
      if (agencies[savedPacificAgency]) {
        return savedPacificAgency;
      } else {
        localStorage.removeItem("primaryAgency");
      }
    }

    // Next, use their primary agency if acceptable
    const primaryAgency = this.props.agency;
    if (agencies[primaryAgency]) {
      return primaryAgency;
    }

    // If none of those, don't default to any agency
    return false;
  };

  /**
   * Decides how to handle an agency change request
   *
   * @param {Object} props - props object containing various properties
   * @param {string} props.agency - agency name
   * @param {string} props.code - agency code
   * @param {number} props.id - agency id
   * @param {boolean} props.flyTo - indicates whether to fly to the agency's coordinates on resolution
   */
  handleAgencyChange = (props) => {
    const { agencyId } = this.state;
    const { id } = props;

    if (agencyId === id) {
      // It's a de-select
      this.clearAgency();
      this.clearZone();
      return false;
    } else {
      const callback = () => {
        this.changeAgency(props);
      };
      this.setState({ loading: true }, callback);
    }
  };

  /**
   * Handles the selection of a new agency name from a dropdown
   * Updates the current agency name in state if the selection is different from the current agency
   *
   * @param {string} agencyName - The selected agency name from the dropdown
   */
  handleAgencyDropdown = (newAgency) => {
    if (newAgency === "No agency") {
      this.clearAgency();
      return false;
    }
    const { agency } = this.state;
    if (agency === newAgency) {
      return false;
    }
    this.setState({ loading: true }, () => {
      this.handleAgencyChange({ ...agencies[newAgency], flyTo: true });
    });
  };

  addAgencyHighlight = (agencyId) => {
    if (agencyId && agencyId > 0) {
      this.map.setFeatureState(
        { source: "abundanceGeojson", id: agencyId },
        { hover: true }
      );
    }
  };

  removeAgencyHighlight = () => {
    const { agencyId } = this.state;
    if (agencyId && agencyId > 0) {
      this.map.setFeatureState(
        { source: "abundanceGeojson", id: agencyId },
        { hover: false }
      );
    }
  };

  // Props should be such that {agency: 'Owens Valley MAP', code: 'INYO', id: 36}
  handleZoneDropdown = (newZone) => {
    if (newZone === "No zone") {
      this.clearZone();
      return false;
    }

    const { zone, zoneInfoByName } = this.state;
    if (zone === newZone) {
      return false;
    } else {
      const callback = () => {
        this.changeZone({ ...zoneInfoByName[newZone], dropdown: true });
      };
      this.setState({ loading: true }, callback);
    }
  };

  // Props should be such that {agency: 'Owens Valley MAP', code: 'INYO', id: 36}
  handleZoneChange = (props) => {
    if (!props) {
      return false;
    }

    const { zoneId } = this.state;
    const { id } = props;
    if (!id) {
      return false;
    }
    if (zoneId === id) {
      // It's a de-select
      this.clearZone();
      return false;
    } else {
      const callback = () => {
        this.changeZone(props);
      };
      this.setState({ loading: true }, callback);
    }
  };

  changeZone = async (props) => {
    const {
      zoneId,
      chartData,
      zoneDataYearsIntervals,
      intervalLength,
      trap,
      year,
      zoneInfoByName,
    } = this.state;

    let { zoneAbundanceRates, agencyAbundanceRates } = this.state;

    zoneAbundanceRates = JSON.parse(JSON.stringify(zoneAbundanceRates));
    agencyAbundanceRates = JSON.parse(JSON.stringify(agencyAbundanceRates));

    // If ever we get centers for these features, dropdown is a bool that can be used to conditionally fly to feature
    const { name, code, id } = props;

    const agencyCode = agencies[zoneInfoByName[name].agency].code;
    const agency = zoneInfoByName[name].agency;
    const agencyId = agencies[zoneInfoByName[name].agency].id;

    // Set the chartdata
    chartData.zone = zoneDataYearsIntervals[year][intervalLength][code];

    if (!agencyAbundanceRates?.[agencyCode]?.[year]?.[trap]) {
    }

    if (
      !zoneAbundanceRates?.[code]?.[year]?.[trap] ||
      !agencyAbundanceRates?.[agencyCode]?.[year]?.[trap]
    ) {
      // Create the place in zoneAbundanceRates if not exist
      if (!zoneAbundanceRates[code]) {
        zoneAbundanceRates[code] = {};
      }
      if (!zoneAbundanceRates[code][year]) {
        zoneAbundanceRates[code][year] = {};
      }
      if (!zoneAbundanceRates[code][year]) {
        zoneAbundanceRates[code][year] = {};
      }
      // Create the place in agencyAbundanceRates if not exist
      if (!agencyAbundanceRates[agencyCode]) {
        agencyAbundanceRates[agencyCode] = {};
      }
      if (!agencyAbundanceRates[agencyCode][year]) {
        agencyAbundanceRates[agencyCode][year] = {};
      }
      if (!agencyAbundanceRates[agencyCode][year]) {
        agencyAbundanceRates[agencyCode][year] = {};
      }

      try {
        if (!zoneAbundanceRates?.[code]?.[year]?.[trap]) {
          zoneAbundanceRates[code][year][trap] = await getZoneAbundanceRates(
            year,
            code,
            trap,
            authQueryString
          );
        }
        if (!agencyAbundanceRates?.[agencyCode]?.[year]?.[trap]) {
          agencyAbundanceRates[agencyCode][year][trap] =
            await getAgencyAbundanceRates(
              year,
              agencyCode,
              trap,
              authQueryString
            );
        }
      } catch (err) {
        console.error(err.stack);
      }
    }
    // Remove map highlight if exists
    if (zoneId) {
      this.removeZoneHighlight();
    }
    // Set highlight id on map
    this.addZoneHighlight(id);

    this.toggleZones(true);
    this.toggleAgencies(false);

    this.setState(
      {
        agency,
        agencyAbundanceRates,
        agencyCode,
        agencyId,
        zoneId: id,
        zoneCode: code,
        zone: name,
        chartData,
        chartType: "zone",
        loading: false,
        whichLayer: "By Zone",
        zoneAbundanceRates,
      },
      () => this.updateIntervalAbundanceRates(false)
    );
  };

  clearZone = () => {
    this.removeZoneHighlight();
    const { chartData, pieType, agency, zonesByAgency, zone } = this.state;
    let { chartType } = this.state;
    let newPieType = pieType;
    if (pieType === "zone-interval") {
      newPieType = "agency-interval";
    } else if (pieType === "zone-year") {
      newPieType = "agency-year";
    }
    if (pieType === "agency-interval") {
      newPieType = "state-interval";
    } else if (pieType === "agency-year") {
      newPieType = "state-year";
    }
    if (chartType === "zone") {
      // default to the state to which the current zone belongs, if its data are loaded.
      if (agency && zonesByAgency[agency].includes(zone)) {
        chartType = "agency";
      } else {
        chartType = null;
      }
    }
    if (!agency) {
      chartData.agency = null;
    }
    this.setState({
      zone: null,
      zoneCode: null,
      zoneId: null,
      chartData,
      chartType,
      pieType: newPieType,
    });
  };

  addZoneHighlight = (zoneId) => {
    if (zoneId && zoneId > 0) {
      this.map.setFeatureState(
        { source: "usapiSmallsGeojson", id: zoneId },
        { hover: true }
      );
    }
  };

  removeZoneHighlight = () => {
    const { zoneId } = this.state;
    if (zoneId && zoneId > 0) {
      this.map.setFeatureState(
        { source: "usapiSmallsGeojson", id: zoneId },
        { hover: false }
      );
    }
  };

  setWidth = () => {
    this.setState({
      vizWidth: document.getElementById("vizContainer").clientWidth,
    });
  };

  getIntervalDates = (weekNumber, year, intervalLength) => {
    let startDate = new Date(abundanceDW[year][weekNumber]);
    let daysInInterval = 6;
    let weeksCounted = 0;
    while (weeksCounted < intervalLength) {
      weeksCounted += 1;
      daysInInterval += 7;
    }
    startDate.setDate(startDate.getDate() - daysInInterval);
    startDate = formatDate(startDate);
    const endDate = formatDate(new Date(abundanceDW[year][weekNumber]));
    return { intervalStartDate: startDate, intervalEndDate: endDate };
  };

  render() {
    const {
      agency,
      agencyAbundanceRates,
      agencyCode,
      chartData,
      chartType,
      domain,
      intervalAbundanceRates,
      intervalEndDate,
      intervalLength,
      intervalStartDate,
      loading,
      pieType,
      sliderWeek,
      species,
      stateAbundanceRates,
      trap,
      traps,
      usState,
      vizWidth,
      weeksInYear,
      year,
      whichLayer,
      zonesByAgency,
      zone,
      zoneAbundanceRates,
      zoneCode,
    } = this.state;

    let abundanceRates = [];
    let pieChartLocation = "PI";

    const dataToChart = chartData[chartType];

    const trapLabel = trap === "alltraps" ? "All Trap Type" : trap;

    const trapOptions = traps[usState][year].slice();
    if (!trapOptions.includes(trap) && trap !== "alltraps") {
      trapOptions.push(trap);
    }

    const layerDropdownValues = [
      "View agencies or zones",
      "By Agency",
      "By Zone",
    ];
    const disabledLayerDropdownValues = {
      "View agencies or zones": true,
    };

    const agencyDropdownValues = Object.keys(agencies);
    const agencySelectedValue = agency ? agency : "No agency";
    agencyDropdownValues.unshift("No agency");
    agencyDropdownValues.unshift("Select an agency");
    const disabledAgencyDropdownValues = {
      "Select an agency": true,
    };

    const zoneDropdownValues = agency
      ? zonesByAgency[agency].slice().sort()
      : [];
    const zoneSelectedValue = zone ? zone : "No zone";
    zoneDropdownValues.unshift("No zone");
    zoneDropdownValues.unshift("Select a zone");
    const disabledZoneDropdownValues = {
      "Select a zone": true,
    };

    const translatePieChartValues = {
      "- Pie chart options. Pie chart colors bar chart -":
        "- Pie chart options. Pie chart colors bar chart -",
      "agency-year": `${agency}: ${year}`,
      "state-year": `${usState}: ${year}`,
      "zone-year": `${zone}: ${year}`,
      "agency-interval": `${agency}: ${intervalStartDate} to ${intervalEndDate}`,
      "state-interval": `${usState}: ${intervalStartDate} to ${intervalEndDate}`,
      "zone-interval": `${zone}: ${intervalStartDate} to ${intervalEndDate}`,
      "- Click an agency or zone on the map to enable its pie chart -":
        "- Click an agency or zone on the map to enable its pie chart -",
    };
    let pieChartDropdownSelectedValue =
      translatePieChartValues[pieType || "state-year"];
    const pieChartDropdownValues = [
      "- Pie chart options. Pie chart colors bar chart -",
      "state-year",
      "state-interval",
    ];
    const pieChartDropdownDisabledValues = {
      "- Pie chart options. Pie chart colors bar chart -": true,
    };
    if (agency) {
      pieChartDropdownValues.push("agency-year");
      pieChartDropdownValues.push("agency-interval");
    }
    if (zone) {
      pieChartDropdownValues.push("zone-year");
      pieChartDropdownValues.push("zone-interval");
    }
    if (!zone && !agency) {
      const disabledAgencyOption =
        "- Click an agency or zone on the map to enable its pie chart -";
      pieChartDropdownDisabledValues[disabledAgencyOption] = true;
      pieChartDropdownValues.push(disabledAgencyOption);
    }

    pieChartDropdownValues.forEach((val, index) => {
      pieChartDropdownValues[index] = translatePieChartValues[val];
    });

    let selectedSpecies = species.join("+"); //.replace(/\//g, "+"); // If selected species is a complex, handle

    let speciesOptions = [];
    if (stateAbundanceRates?.[usState]?.[year]?.[trap]) {
      speciesOptions = stateAbundanceRates[usState][year][trap].reduce(
        (all, item) => {
          if (item.species.length > 3) {
            // Filters out things like 'cx' and 'na'
            all.push(item.species);
          }
          return all;
        },
        []
      );
    }

    // Add selected species to dropdown if not there already
    if (
      !speciesOptions.includes(selectedSpecies) &&
      selectedSpecies !== "All Species" // to ensure All Species stays on top
    ) {
      speciesOptions.push(selectedSpecies);
    }

    // Remove the / options, then sort
    speciesOptions = speciesOptions
      .filter((species) => !species.includes("/"))
      .sort();

    if (!speciesOptions.includes("All Species")) {
      speciesOptions.unshift("All Species");
    }

    let pieChartDate;
    // now 6 possibles: pie types agency-year, agency-interval, state-interval, state-year, zone-year, zone-interval
    if (
      pieType === "agency-year" &&
      agency &&
      agencyCode &&
      agencyAbundanceRates?.[agencyCode]?.[year]?.[trap]
    ) {
      abundanceRates = agencyAbundanceRates[agencyCode][year][trap];
      pieChartLocation = agency;
      pieChartDate = "in " + year;
    } else if (
      pieType === "agency-interval" &&
      agency &&
      agencyCode &&
      intervalAbundanceRates.agency?.[trap]?.[
        `${intervalStartDate}-${intervalEndDate}`
      ]?.[agencyCode]
    ) {
      abundanceRates =
        intervalAbundanceRates.agency[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][agencyCode];
      pieChartLocation = agency;
      pieChartDate = `from ${intervalStartDate} to ${intervalEndDate}`;
    } else if (
      pieType === "state-interval" &&
      usState &&
      intervalAbundanceRates.state?.[trap]?.[
        `${intervalStartDate}-${intervalEndDate}`
      ]?.[usState]
    ) {
      abundanceRates =
        intervalAbundanceRates.state[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][usState];
      pieChartLocation = usState;
      pieChartDate = `from ${intervalStartDate} to ${intervalEndDate}`;
    } else if (
      pieType === "zone-year" &&
      zone &&
      zoneCode &&
      zoneAbundanceRates?.[zoneCode]?.[year]?.[trap]
    ) {
      abundanceRates = zoneAbundanceRates[zoneCode][year][trap];
      pieChartLocation = zone;
      pieChartDate = "in " + year;
    } else if (
      pieType === "zone-interval" &&
      zone &&
      zoneCode &&
      intervalAbundanceRates.zone?.[trap]?.[
        `${intervalStartDate}-${intervalEndDate}`
      ]?.[zoneCode]
    ) {
      abundanceRates =
        intervalAbundanceRates.zone[trap][
          `${intervalStartDate}-${intervalEndDate}`
        ][zoneCode];
      pieChartLocation = zone;
      pieChartDate = `from ${intervalStartDate} to ${intervalEndDate}`;
    } else {
      // it's state-year, the default
      abundanceRates = stateAbundanceRates?.[usState]?.[year]?.[trap]
        ? stateAbundanceRates[usState][year][trap]
        : [{ species: "All Species", color: "black", value: 0 }];
      pieChartLocation = usState;
      pieChartDate = "in " + year;
    }

    return (
      <Container>
        <div
          style={{
            minWidth: "49%",
            maxWidth: "90%",
            position: "relative",
            marginRight: "10px",
          }}
        >
          {/* slider to change the week */}
          <SliderContainer
            row
            style={{
              padding: "10px 0",
              overflow: "visible",
              height: "auto",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                Surveillance from {intervalStartDate} to {intervalEndDate}
              </div>
            </div>
            <SliderWithTooltip
              disabled={loading}
              min={1}
              max={weeksInYear}
              step={1}
              value={sliderWeek}
              onChange={this.adjustSlider}
              onAfterChange={this.changeWeek}
              tipFormatter={(value) => {
                const { intervalEndDate } = this.getIntervalDates(
                  value,
                  year,
                  intervalLength
                );
                return `Change end date to ${intervalEndDate}`;
              }}
              trackStyle={{ display: "none" }}
              handleStyle={[{ width: 30, height: 30, marginTop: -12 }]}
              style={{
                paddingBottom: 18,
                width: "50%",
              }}
            />
          </SliderContainer>
          {/* Color legend card */}
          <Card
            style={{
              display: "block",
              margin: "20px 0px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <i>{selectedSpecies}</i> per trap night (
              {truncateStr(trapLabel, 28)}s in {year})
            </div>
            <div style={{ margin: "5px" }}>
              <ContinuousColorLegend
                width={vizWidth}
                height={20}
                startTitle="0"
                midTitle={`50th percentile: ${domain[0]}`}
                endTitle={`95th percentile: ${domain[1]}`}
                startColor={colors["green"]}
                midColor={colors["yellow"]}
                endColor={colors["red"]}
              />
            </div>
          </Card>

          <div>
            <MapContainer
              ref={(el) => {
                this.mapContainer = el;
              }}
              style={{ height: "80vh" }}
            >
              {loading && <LoadingIcon width={vizWidth} offsetLeft={true} />}
            </MapContainer>
          </div>
        </div>
        <Visualizations
          id="vizContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* Dropdowns */}
          <Card style={dropdownCardStyles}>
            <DiverseDropdown
              id="change-year"
              handler={this.handleChangeYear}
              values={years.map((value) => {
                return { value };
              })}
              value={year}
              style={{ width: 70 }}
              loading={loading}
            />
            <DiverseDropdown
              id="change-species"
              handler={this.changeSpecies}
              values={speciesOptions.map((value) => {
                return { value };
              })}
              value={selectedSpecies}
              style={speciesDropDownStyle}
              loading={loading}
            />
            <DiverseDropdown
              id="change-trap"
              handler={this.changeTrap}
              values={trapOptions.map((value) => {
                return { value };
              })}
              value={trap}
              style={dropdownStyle}
              loading={loading}
            />
            <span style={{ display: "flex", alignItems: "center" }}>
              <DiverseDropdown
                id="change-layer"
                handler={(e) => this.handleLayerDropdown(e.target.value)}
                values={layerDropdownValues.map((value) => {
                  return {
                    value,
                    disabled: !!disabledLayerDropdownValues[value],
                  };
                })}
                value={whichLayer}
                style={{
                  width: 180,
                }}
                loading={loading}
              />
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <DiverseDropdown
                id="change-agency"
                handler={(e) => this.handleAgencyDropdown(e.target.value)}
                values={agencyDropdownValues.map((value) => {
                  return {
                    value,
                    disabled: !!disabledAgencyDropdownValues[value],
                  };
                })}
                value={agencySelectedValue}
                style={{
                  width: 180,
                }}
                loading={loading}
              />
            </span>

            {agency && (
              <span style={{ display: "flex", alignItems: "center" }}>
                <DiverseDropdown
                  id="change-zone"
                  handler={(e) => this.handleZoneDropdown(e.target.value)}
                  value={zoneSelectedValue}
                  values={zoneDropdownValues.map((value) => {
                    return {
                      value,
                      disabled: !!disabledZoneDropdownValues[value],
                    };
                  })}
                  style={{
                    width: 180,
                  }}
                  loading={loading}
                />
              </span>
            )}
          </Card>
          <ScrollBox>
            <AgencyChart
              abundanceRates={abundanceRates}
              agency={agency}
              chartType={chartType}
              clearAgency={this.showHelp}
              data={dataToChart}
              intervalLength={intervalLength}
              loading={loading}
              species={species}
              trap={trap}
              weeksInYear={weeksInYear}
              width={vizWidth}
              year={year}
              zone={zone}
            />
            <SpeciesPieChart
              agency={agency}
              abundanceRates={abundanceRates}
              changeSpecies={this.changeSpecies}
              chartType={chartType}
              clearAgency={this.clearAgency}
              handleDropdown={this.handlePieChartDropdown}
              loading={loading}
              pieChartDate={pieChartDate}
              pieChartDropdownDisabledValues={pieChartDropdownDisabledValues}
              pieChartDropdownSelectedValue={pieChartDropdownSelectedValue}
              pieChartDropdownValues={pieChartDropdownValues}
              place={pieChartLocation}
              width={vizWidth}
            />
          </ScrollBox>
        </Visualizations>
      </Container>
    );
  }
}

export default Pacific;

import React from "react";

export const Arrows = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 24 24`}
      >
        <path
          fill={color}
          d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  );
};

export const BarChart = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  );
};

export const Box = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;
  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={color}
          d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        />
      </svg>
    </div>
  );
};

export const Calendar = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;
  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 24 24`}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={color}
          d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"
        />
      </svg>
    </div>
  );
};

export const Code = (props) => {
  const color = props.color || "#444";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill={color}
          d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
        />
      </svg>
    </div>
  );
};

// Lock icon associated with maps behind passwords
export const Lock = (props) => {
  const color = props.color || "#3CCE3C";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="14px"
      width="14px"
      viewBox="0 0 24 24"
      fill={color}
      style={{ marginLeft: "-4.5px", marginBottom: "-2px" }}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
    </svg>
  );
};

export const DownloadIcon = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;
  const cursor = props.cursor || "pointer";

  return (
    <div style={{ height: size, width: size, cursor }} onClick={props.handler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  );
};

export const Filter = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path fill={color} d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

export const Expand = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
      </svg>
    </div>
  );
};

export const Collapse = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
      </svg>
    </div>
  );
};

export const World = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z" />
      </svg>
    </div>
  );
};

export const LineChart = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;
  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  );
};

export const Link = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={color}
          d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
        />
      </svg>
    </div>
  );
};
export const Options = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={color}
          d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        />
      </svg>
    </div>
  );
};

export const PieChart = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;
  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill={color}
          d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"
        />
      </svg>
    </div>
  );
};

export const PolygonEdit = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>This icon was made by Graphic Tools from flaticon.com</title>
        <path
          d="m421 482h-339.5c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15h339.5c8.285156 0 15 6.714844 15 15s-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m421 452h-169.75v30h169.75c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0"
          fill={color}
        />
        <path
          d="m421 60h-339.5c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15h339.5c8.285156 0 15 6.714844 15 15s-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m467 436c-8.285156 0-15-6.714844-15-15v-339.5c0-8.285156 6.714844-15 15-15s15 6.714844 15 15v339.5c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m45 436c-8.285156 0-15-6.714844-15-15v-339.5c0-8.285156 6.714844-15 15-15s15 6.714844 15 15v339.5c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m75 90h-60c-8.285156 0-15-6.714844-15-15v-60c0-8.285156 6.714844-15 15-15h60c8.285156 0 15 6.714844 15 15v60c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m75 512h-60c-8.285156 0-15-6.714844-15-15v-60c0-8.285156 6.714844-15 15-15h60c8.285156 0 15 6.714844 15 15v60c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m497 512h-60c-8.285156 0-15-6.714844-15-15v-60c0-8.285156 6.714844-15 15-15h60c8.285156 0 15 6.714844 15 15v60c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
        <path
          d="m421 30h-169.75v30h169.75c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0"
          fill={color}
        />
        <path
          d="m497 90h-60c-8.285156 0-15-6.714844-15-15v-60c0-8.285156 6.714844-15 15-15h60c8.285156 0 15 6.714844 15 15v60c0 8.285156-6.714844 15-15 15zm0 0"
          fill={color}
        />
      </svg>
    </div>
  );
};
export const Share = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div
      style={{
        height: size,
        width: size,
        boxSizing: "content-box",
        marginRight: 20,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={color}
          d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
        />
      </svg>
    </div>
  );
};

export const Toggle = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <div style={{ height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  );
};

export const HelpIcon = (props) => {
  const color = props.color || "black";
  const size = props.size || 24;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
      />
    </svg>
  );
};
